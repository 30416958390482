export default [
    {
        question: "¿Has aplicado algún examen profesional?",
        answers: ["Si", "No"]
    },
    {
        question: "¿Qué tipo de examen presentaste?",
        answers: ["Personal", "En línea", "Otro"]
    },
    {
        question: "¿La experiencia que posees es de un nivel profesional?",
        answers: ["Si", "No"]
    },
    {
        question: "¿Cuentas con contenido ya creado o vas a empezar a crearlo?",
        answers: ["Ya cuento con contenido propio.", "Voy a empezar a crearlo.", "Tengo más de un contenido."]
    },
    {
        question: "¿Cuentas con un público para compartir tu curso?",
        answers: ["No por el momento", "Tengo algunos seguidores.", "Tengo bastantes seguidores."]
    }
];