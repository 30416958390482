import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, logError } from '../helpers/utility';
import { TemplateEmailModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function list(onlyActives = false): Promise<Response<TemplateEmailModel[]>>
{
    let response: Response<TemplateEmailModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.TemplateEmail);
        let query = onlyActives ? ref.where(Table.$TemplateEmail.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: TemplateEmailModel = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('templateEmailService :list', response);
    return response;
}

async function create(item: TemplateEmailModel, idUserCurrent = ''): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.TemplateEmail).add(data);
        item.id = insert.id;

        response.data = item;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('templateEmailService :create', response);
    return response;
}

async function update(item: TemplateEmailModel, idUserCurrent = ''): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.TemplateEmail).doc(item.id).update(data);

        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('templateEmailService :update', response);
    return response;
}

export default {
    list,
    create,
    update
}