import { Form, Input, Switch } from 'antd';
import React from 'react';

import UploadComponentIA from '../../utils/upload';
import { ContentMedia } from '../../SequenceQuestion/style';
import { ContentFormSequence } from '../../../QuizLayoutStyle';
import { isNull, isNullOrEmpty, readFile } from '../../../../../../helpers/utility';
import actions from '../../../../../../redux/currentExam/actions';
import uuid from "uuid/v4";
import { connect } from 'react-redux';


interface COMProps {
    selectedSequence: Object;
    existSelectedAnswer: Boolean;
    sequences: any[],
    onCancel: Function;
    onOk: Function;
}


class FormCAnwer extends React.Component<COMProps> {

    constructor(props) {
        super(props);

        this.state = {
            selectedSequence: this.props.selectedSequence,
            answers: this.props.sequences,
            messageError: ""
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        let { form } = this.props;
        let { selectedSequence } = this.state;

        form.validateFields(async (err, values) => {

            if (this.validItem(values.answerText) && this.validItem(values.image)) {
                this.setState({ messageError: 'Es necesario que agregue al menos un texto o imagen para poder agregar un elemento a la secuencia.' })
                return;
            }

            let item = {
                ...values,
                id: isNull(selectedSequence.id) ? "tmp_" + uuid() : selectedSequence.id,
                beforeImage: selectedSequence.beforeImage
            }

            if(isNull(item.image)) {
                item.answerImage = selectedSequence.answerImage;
            } else if(item.image instanceof File) {
                item.answerImage = await readFile(item.image);
            } else if(item.image.length === 0) {
                item.answerImage = '';
            } else {
                item.answerImage = selectedSequence.answerImage;
                item.image = null;
            }

            let answers = this.state.answers;
            let index = answers.findIndex(e => e.letter === this.state.selectedSequence.letter);
            answers[index] = { ...answers[index], ...item };



            this.props.setSequenceAnswer(answers);
            this.props.onCancel();
            form.resetFields();

            // this.setState({ answers }, () => this.handleCancel());
        });
    };

    validItem = item => { return typeof item === 'string' ? isNullOrEmpty(item) : isNull(item)};

    render() {
        const { form } = this.props;
        const { selectedSequence } = this.state;
        const { getFieldDecorator, setFieldsValue } = form;

        return (
            <Form id="formAnswerSequence" onSubmit={this.handleSubmit}>
                <ContentFormSequence>
                    <Form.Item>
                        {getFieldDecorator(`answerText`, {
                            initialValue: !!selectedSequence.answerText ? selectedSequence.answerText : "",
                            rules: [],
                        })(<Input.TextArea
                            autoSize={true}
                            style={{ minHeight: 55 }}
                            size="large" placeholder="Texto que conformará la respuesta de la pregunta." />)}
                    </Form.Item>
                    <ContentMedia>
                        <Form.Item>
                            {getFieldDecorator(`image`, { initialValue: selectedSequence.answerImage, rules: [] })(
                                <UploadComponentIA
                                    type="image"
                                    id={`image`}
                                    preview={selectedSequence.answerImage}
                                    onChangeFile={(e) => setFieldsValue({ image: e })} />
                            )}
                        </Form.Item>
                    </ContentMedia>
                </ContentFormSequence>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item style={{ marginBottom: 0 }}>
                        {getFieldDecorator(`correctAnswer`, {
                            initialValue: !!selectedSequence.correctAnswer ? selectedSequence.correctAnswer : false,
                            rules: []
                        })(<Switch disabled={this.props.existSelectedAnswer && selectedSequence.correctAnswer !== true} defaultChecked={!!selectedSequence.correctAnswer ? selectedSequence.correctAnswer : false} />)}
                    </Form.Item>
                    <span style={{ marginLeft: 20 }}>
                        {
                        this.props.existSelectedAnswer && selectedSequence.correctAnswer !== true
                        ? "Ya existe una respuesta marcada como correcta"
                        : "Marcar como respuesta correcta"
                        }
                    </span>
                </div>

                <span style={{ color: '#f5222d', fontSize: 14, display: 'block', marginTop: 10 }}>{this.state.messageError}</span>
            </Form>
        )
    }
}

const FormAnwer = Form.create({ name: 'form_sequence_anwer' })(FormCAnwer);

export default connect(null, actions)(FormAnwer);