class InfoCreator
{
    headline: String;
    biography: String;
    linkWebSite: String;
    linkFacebook: String;
    linkTwitter: String;
    linkYouTube: String;
    linkLinkedIn: String;

    constructor()
    {
        this.headline = null;
        this.biography = null;
        this.linkWebSite = null;
        this.linkFacebook = null;
        this.linkTwitter = null;
        this.linkYouTube = null;
        this.linkLinkedIn = null;
    }
}
class QuestionPoll
{
    question: String;
    answers: String[];
    selectAnswer: Number;
    otherAnswer: String;

    constructor()
    {
        this.question = '';
        this.answers = [];
        this.selectAnswer = -1;
    }
}
class Experience
{
    questions: QuestionPoll[];

    constructor()
    {
        this.questions = [];
    }
}
class PrivacySettings
{
    showWebSite: String;
    showFacebook: String;
    showTwitter: String;
    showYouTube: String;
    showLinkedIn: String;
    showBirthdate: String;
    showPhone: String;

    constructor()
    {
        this.showWebSite = true;
        this.showFacebook = false;
        this.showTwitter = false;
        this.showYouTube = false;
        this.showLinkedIn = true;
        this.showBirthdate = false;
        this.showPhone = false;
    }
}
class UserProfile
{
    infoCreator: InfoCreator;
    experience: Experience;
    privacity: PrivacySettings;

    constructor()
    {
        this.infoCreator = new InfoCreator();
        this.experience = new Experience();
        this.privacity = new PrivacySettings();
    }
}

export
{
    QuestionPoll,
    Experience,
    InfoCreator,
    PrivacySettings,
    UserProfile
}