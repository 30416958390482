import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { getToken } from '../../helpers/utility';
import actions from './actions';

export function* checkAuthorization()
{
	yield takeEvery(actions.CHECK_AUTHORIZATION, function* ()
	{
		const token = getToken().get('idToken');
		if (token)
		{
			yield put({
				type: actions.LOGIN_ACTIVE,
				payload: token
			});
		}
	});
}
export default function* rootSaga()
{
	yield all([
		fork(checkAuthorization)
	]);
}