import assetService from './assetService';
import quizService from './quizService';
import levelService from './levelService';
import binnacleService from './binnacleService';
import blogService from './blogService';
import categorieService from './categorieService';
import currencyService from './currencyService';
import promotionService from './promotionService';
import questionService from './questionService';
import bankService from './bankService';
import bannerSliderService from './bannerSliderService';
import answerTypeService from './answerTypeService';
import sectionService from './sectionService';
import subSectionService from './subSectionService';
import quizTypeService from './quizTypeService';
import sectionConfigurationService from './sectionConfigurationService';
import templateQuizService from './templateQuizService';
import templateQuizSectionConfigurationService from './templateQuizSectionConfigurationService';
import problemTypeService from './problemTypeService';
import rolServices from './rolServices';
import accessTypeService from './accessTypeService';
import deductionTypeService from './deductionTypeService';
import paymentStatusService from './paymentStatusService';
import paymentTypeService from './paymentTypeService';
import priceRangeService from './priceRangeService';
import trakingTypeService from './trakingTypeService';
import reasonService from './reasonService';
import questionReportSevice from './questionReportSevice';

import paymentService from './paymentService';
import conciliationService from './conciliationService';
import userService from './userService';
import memberService from './memberService';
import refundService from './refundService';
import depositOwnerService from './depositOwnerService';
import depositStoreService from './depositStoreService';
import deductionService from './deductionService';
import quizHistoryService from './quizHistoryService';
import questionTypeService from './questionTypeService';
import commentQualificationService from './commentQualificationService';
import questionGroupService from './questionGroupService';
import userEmailService from './userEmailService';
import userTypeService from './userTypeService';
import templateEmailService  from './templateEmailService ';
import ownerService from './owner.service';
import FrequentQuestionService from './FrequentQuestionService';
import DeductionsISRService from './deductionsISRService';
import userProfileService from './userProfileService';
import notifUserService from './notifUserService';

export {
    assetService,
    quizService,
    levelService,
    binnacleService,
    blogService,
    categorieService,
    currencyService,
    promotionService,
    questionService,
    bankService,
    bannerSliderService,
    answerTypeService,
    sectionService,
    subSectionService,
    quizTypeService,
    sectionConfigurationService,
    templateQuizService,
    templateQuizSectionConfigurationService,
    problemTypeService,
    rolServices,
    accessTypeService,
    deductionTypeService,
    paymentStatusService,
    paymentTypeService,
    priceRangeService,
    trakingTypeService,
    reasonService,
    questionReportSevice,

    paymentService,
    conciliationService,
    userService,
    memberService,
    refundService,
    depositOwnerService,
    depositStoreService,
    quizHistoryService,
    deductionService,
    questionTypeService,
    
    commentQualificationService,
    questionGroupService,
    userEmailService,
    userTypeService,
    templateEmailService,

    ownerService,
    FrequentQuestionService,
    DeductionsISRService,
    userProfileService,
    notifUserService
}