import update from 'immutability-helper';
import { initSequencesAnwers } from '../../containers/Administration/AdministrationQuiz/components/SequenceAnswer/SequenceAnswer';
import actions from './actions';

const initState = {
    currentExam: {},
    quizStatus: {},
    sections: [],
    questions: [],
    listQuestionsSection: [],
    paramExtern: null,

    bank: null,
    question: {
        answers: initSequencesAnwers,
        feedbackSequences: [],
        lesson: null,
        questionAnswerTypeId: null,
        questionBankId: null,
        questionLevelId: null,
        questionQuestionGroupId: null,
        questionQuestionTypeId: null,
        questionSectionId: null,
        questionSequences: [],
        questionSubSectionId: null,
        questionText: null,
        required: null,
        free: false,
        static: null,
        status: true
    },

}

export default function cardReducer(state = initState, { type, ...action }) {
    switch (type) {

        /** 
         * **************
         * Examen
         * **************
         */

        case actions.SET_CURRENT_EXAM:
            return {
                ...state,
                currentExam: action.payload
            }

        case actions.SET_PARAM_EXTERN:
            return {
                ...state,
                paramExtern: !!action.payload ? { ...action.payload } : null
            }

        case actions.SUM_QUESTIONS_COUNT:
            return update(state, {
                currentExam: {
                    questionsCount: { $set: (state.currentExam.questionsCount + 1) }
                }
            });

        case actions.SET_QUIZ_STATUS:
            return {
                ...state,
                quizStatus: action.payload
            }

        /** 
         * **************
         * Secciones
         * **************
         */

        case actions.SET_SECTIONS_EXAM:
            return {
                ...state,
                sections: action.payload
            }

        case actions.ADD_SECTION:
            return {
                ...state,
                sections: [...state.sections, action.payload]
            }

        case actions.UPDATE_SECTION:
            return {
                ...state,
                sections: state.sections.map(elem => {
                    if (elem.id === action.payload.id)
                        return action.payload;
                    return elem;
                })
            }

        /** 
         * **************
         * Bank
         * **************
         */


        case actions.SET_BANK:
            return {
                ...state,
                bank: action.payload
            }

        /** 
         * **************
         * Question
         * **************
         */

        case actions.EMPTY_QUESTION:
            return {
                ...state,
                question: {
                    answers: [
                        { letter: 'A', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 1, status: true, id: null },
                        { letter: 'B', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 2, status: true, id: null },
                        { letter: 'C', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 3, status: true, id: null },
                        { letter: 'D', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 4, status: true, id: null },
                    ],
                    feedbackSequences: [],
                    lesson: null,
                    questionAnswerTypeId: null,
                    questionBankId: null,
                    questionLevelId: null,
                    questionQuestionGroupId: null,
                    questionQuestionTypeId: null,
                    questionSectionId: null,
                    questionSequences: [],
                    questionSubSectionId: null,
                    questionText: null,
                    required: null,
                    static: null,
                    status: true
                }
            }


        case actions.SET_QUESTION:
            return {
                ...state,
                question: action.payload
            }

        case actions.SET_SECTION_QUESTION:
            return update(state, {
                question: {
                    questionSectionId: { $set: action.payload }
                }
            });

        case actions.SET_SUB_SECTION_QUESTION:
            return update(state, {
                question: {
                    questionSubSectionId: { $set: action.payload }
                }
            });

        case actions.SET_VERSION_QUESTION:
            return update(state, {
                question: {
                    questionQuestionTypeId: { $set: action.payload }
                }
            });

        case actions.SET_LESSON_QUESTION:
            return update(state, {
                question: {
                    lesson: { $set: action.payload }
                }
            });

        case actions.SET_FREE_QUESTION:
            return update(state, {
                question: {
                    free: { $set: action.payload }
                }
            });




        // Sequences --------------------------
        case actions.SET_SEQUENCE_QUESTION:
            return update(state, {
                question: {
                    questionSequences: { $set: action.payload }
                }
            });

        case actions.SET_SEQUENCE_FEEDBACK:
            return update(state, {
                question: {
                    feedbackSequences: { $set: action.payload }
                }
            });

        case actions.SET_SEQUENCE_ANSWER:
            return update(state, {
                question: {
                    answers: { $set: action.payload }
                }
            });
        // Sequences --------------------------








        case actions.SET_QUESTIONS_EXAM:
            return {
                ...state,
                questions: action.payload
            }

        case actions.ADD_QUESTION:
            return {
                ...state,
                listQuestionsSection: [action.payload, ...state.listQuestionsSection]
            }

        case actions.SET_LIST_QUESTIONS_SECTION:
            return {
                ...state,
                listQuestionsSection: action.payload
            }

        case actions.UPDATE_QUESTION_FROM_LIST:
            return {
                ...state,
                listQuestionsSection: state.listQuestionsSection.map(e => {
                    return e.id === action.payload.id ? action.payload : e
                })
            }

        default:
            return state;
    }
}