import React from 'react';
import moment from 'moment';
import { Select, message, Tooltip, Icon, notification, AutoComplete } from 'antd';

import IntlMessages from '../../../components/utility/intlMessages';
import { IntlProvider } from 'react-intl';
import AppLocale from '../../../languageProvider';
import config, { getCurrentLanguage } from '../../LanguageSwitcher/config';

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];
const Option = Select.Option;

/**Obtener options para un select
 * @param list Lista que seran las opciones del select
 * @param value Propiedad p/seleccionar y/o buscar `default "id"`
 * @param text Propiedad usada como texto `default "name"`
 * @param title Propiedad tooltip `default null` 
*/
function getOptions(list, value = 'id', text = 'name', title = null): Element {
    let options: [] = list.map((element, index) => {
        return (<Option
            key={!!element.id ? element.id : index.toString()}
            value={element[value]}
            title={title !== null ? element[title] : null}>
            {element[text]}
        </Option>);
    });
    return options;
}

function getOptionsAutocomplate(list, key = 'key', text = 'name'): Element {
    let options: [] = list.map((element, index) => {
        return (<AutoComplete.Option key={element[key]}>{element[text]}</AutoComplete.Option>);
    });
    return options;
}

function getLabel(text, tooltipText) {
    return (
        <span>
            {text}&nbsp;
            <Tooltip placement="topRight" title={tooltipText}>
                <Icon type="question-circle-o" />
            </Tooltip>
        </span>
    );
}

function getTitle(title, typeCtrl: 'Title' | 'TitleItem') {
    if (typeCtrl === 'Title')
        return (<strong style={{ color: '#5e97d5' }}>{title}</strong>);

    if (typeCtrl === 'TitleItem')
        return (<strong style={{ color: '#646464', fontWeight: '500' }}>{title}</strong>);

    return title;
}

function showNotify(title, message, type: 'success' | 'info' | 'warning' | 'error' = 'info', autoClose = true) {
    notification[type]({
        duration: autoClose ? type === 'warning' || type === 'error' ? 3 : 3 : 0,
        message: title,
        description: message
    });
}


// Time

function convertTimeToSeconds(time) {
    return moment(time, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
}

function convertSecondsToTime(duration) {
    var _duration = parseInt(duration);

    let hours = moment.duration(_duration, 'seconds').hours();
    let minutes = moment.duration(_duration, 'seconds').minutes();
    let seconds = moment.duration(_duration, 'seconds').seconds();


    return `${(hours === "0") ? "00" : (hours <= 9) ? "0" + hours : hours}:${(minutes <= 9) ? "0" + minutes : minutes}:${(seconds === "0") ? "00" : (seconds <= 9) ? "0" + seconds : seconds}`;
}


// Upload


function beforeUpload(file)
{
    if (file.type.includes('image/'))
    {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M)
        {
            message.error('La imagen debe pesar menos de 2 MB');
            return false;
        }
    }
    return true;
}

async function getSingleBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


async function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function getLabelForm(value, tooltipText, help, required_IntlProvider) {
    let tags;
    if (help) {
        tags = <span>
            <IntlMessages id={value} />&nbsp;
                <Tooltip title={<IntlMessages id={tooltipText} />}>
                <Icon type="question-circle-o" />
            </Tooltip>
        </span>
    }
    else {
        tags = <span>
            <IntlMessages id={value} />
        </span>
    }
    return required_IntlProvider ?
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            {tags}
        </IntlProvider> : tags;
}

export {
    convertSecondsToTime,
    convertTimeToSeconds,
    beforeUpload,
    getSingleBase64,
    getOptionsAutocomplate,
    getOptions,
    getBase64,
    getLabel,
    getLabelForm,
    getTitle,
    showNotify
}