import Payment from "./Payment";

export default class Member {

    id: String;
    nameUser: String;
    emailUser: String;
    dateValidity: String;
    createdAt: Number;
    updatedAt: Number;
    createdUser: String;
    updatedUser: String;
    status: Boolean;
    memberUserId: String;
    quizId: String;

    payment: Payment[]

    constructor() {
        this.nameUser = '';
    }

}