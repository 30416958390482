import { firestore } from 'firebase';

import Response from "../containers/Administration/utils/Response";

import CommentQualification from "../models/CommentQualification";
import { Table } from "../constants";
import { isNull, sortList } from '../helpers/utility';

const db = firestore();

async function listCommentQualifications(OwnerId): Promise<Response<CommentQualification[]>> {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.CommentQualification)
            .where(Table.$CommentQualification.commentQualificationUserOwnerId, '==', OwnerId).get();

        if (!query.empty) {
            response.data = query.docs.map(e => {
                let data = e.data();
                let item = {
                    ...data,
                    id: e.id,
                    reply: isNull(data.reply) ? false : data.reply,
                    validated: isNull(data.validated) ? false : data.validated,
                    message: isNull(data.message) ? [] : data.message
                };
                if (!!item.history && item.history.length > 0)
                    sortList(item.history, 'updatedAt', true, false);
                return item;
            }).filter(e => !isNull(e.name));
            sortList(response.data, 'createdAt', true, false);
            response.status = true;
        }
    } catch (e) {
        response.error = e;
    }
    return response;

}



async function updateCommentQualification(id: string, data: any): Promise<Response> {
    let response = new Response(false);
    try {

        let tmp = { ...data };

        if (!!tmp.id) delete tmp['id'];

        await db.collection(Table.CommentQualification).doc(id).update(tmp);
        response.status = true;

    } catch (err) {
        console.log(err)
        response.error = err;

    }
    return response;
}

export default {
    listCommentQualifications,
    updateCommentQualification,
}
