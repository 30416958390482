import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, convertToUnix, logError, sortList } from '../helpers/utility';
import { DeductionTypeModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function listDeductionType(onlyActives = false): Promise<Response<DeductionTypeModel[]>>
{
    let response: Response<DeductionTypeModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.DeductionType);
        let query = onlyActives ? ref.where(Table.$DeductionType.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: DeductionTypeModel = element.data();
            item.id = element.id;
            item.createdAt = convertToUnix(item.createdAt);
            sortList(item.amountsReceivable, 'order', true);
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createDeductionType(itemCatalog: DeductionTypeModel, idUserCurrent = ''): Promise<Response<DeductionTypeModel>>
{
    let response: Response<DeductionTypeModel> = new Response(false);
    try
    {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        data.amountsReceivable = data.amountsReceivable.map(element => { return element.convertToDB(); });
        const insert = await db.collection(Table.DeductionType).add(data);
        itemCatalog.id = insert.id;

        response.data = itemCatalog;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('deductionTypeService.js:createDeductionType', response);
    return response;
}

async function updateDeductionType(itemCatalog: DeductionTypeModel, idUserCurrent = ''): Promise<Response<DeductionTypeModel>>
{
    let response: Response<DeductionTypeModel> = new Response(false);
    try
    {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        data.amountsReceivable = data.amountsReceivable.map(element => { return element.convertToDB(); });
        await db.collection(Table.DeductionType).doc(itemCatalog.id).update(data);

        response.data = itemCatalog;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('deductionTypeService.js:updateDeductionType', response);
    return response;
}

export default {
    listDeductionType,
    createDeductionType,
    updateDeductionType
}