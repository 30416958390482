import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, convertToUnix, logError, sortList, isNullOrEmpty } from '../helpers/utility';
import { upload, removeFile } from '../containers/Administration/utils/UtilsUpload';
import { PathProps } from '../containers/Administration/utils/Global';
import { BlogModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function list(onlyActives = false): Promise<Response<BlogModel[]>>
{
    let response: Response<BlogModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.Blog);
        let query = onlyActives ? ref.where(Table.$Blog.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: BlogModel = element.data();
            item.id = element.id;
            item.createdAt = convertToUnix(item.createdAt);
            item.urlImageBackup = item.urlImage;
            return item;
        });
        sortList(response.data, 'order', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function getInfoById(idBlog: String): Promise<Response<BlogModel>>
{
    let response: Response<BlogModel> = new Response(false);
    response.data = null;
    try
    {
        let doc = await db.collection(Table.Blog).doc(idBlog).get();
        if (doc.exists)
        {
            response.data = doc.data()
            response.data.id = doc.id;
            response.data.createdAt = convertToUnix(response.data.createdAt);
            response.data.urlImageBackup = response.data.urlImage;
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('blogService:getInfoById', response);
    return response;
}

async function getUltimate(): Promise<Response<BlogModel>>
{
    let response: Response<BlogModel> = new Response(false);
    response.data = null;
    try
    {
        let data = await db.collection(Table.Blog).orderBy(Table.$Blog.order, 'desc').limit(1).get();
        if (!data.empty)
        {
            let doc = data.docs[0];
            response.data = doc.data()
            response.data.id = doc.id;
            response.data.createdAt = convertToUnix(response.data.createdAt);
            response.data.urlImageBackup = response.data.urlImage;
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('blogService:getInfoById', response);
    return response;
}

async function create(item: BlogModel, pathS3: PathProps, idUserCurrent = ''): Promise<Response<BlogModel>>
{
    let response: Response<BlogModel> = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        delete data['fileImage'];
        delete data['urlImageBackup'];
        const insert = await db.collection(Table.Blog).add(data);
        item.id = insert.id;

        // Imagen
        await uploadFileS3(item.fileImage, pathS3);

        response.data = item;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('blogService.js:create', response);
    return response;
}

async function update(item: BlogModel, pathS3: PathProps, idUserCurrent = ''): Promise<Response<BlogModel>>
{
    let response: Response<BlogModel> = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        delete data['fileImage'];
        delete data['urlImageBackup'];
        await db.collection(Table.Blog).doc(item.id).update(data);

        // Imagen
        if (!!pathS3)
        {
            await uploadFileS3(item.fileImage, pathS3);
            if (!!item.fileImage || isNullOrEmpty(item.urlImage))
                await removeFileS3(item.urlImageBackup, pathS3);
        }
        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('blogService.js:update', response);
    return response;
}

async function updateOrder(items: BlogModel[]): Promise<Response<Boolean>>
{
    let response: Response<Boolean> = new Response(false);
    try
    {
        let batch = db.batch();
        items.forEach(elem =>
        {
            let refDoc = db.collection(Table.Blog).doc(elem.id);
            batch.update(refDoc, Table.$Blog.order, elem.order);
        });
        await batch.commit();

        response.data = true;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('blogService.js:updateOrder', response);
    return response;
}


async function uploadFileS3(file: File, pathS3: PathProps)
{
    if (!!file)
    {
        const pathS3Folder = `${pathS3.path_assets}/blog`;
        const response = await upload(file, pathS3Folder);

        const type = file.type.split("/")[1];
        return response.key.includes(type);
    }
    return true;
}

async function removeFileS3(urlImage, pathS3: PathProps)
{
    if (!isNullOrEmpty(urlImage) && urlImage.includes('https'))
        return await removeFile(urlImage.replace(pathS3.path_cloudfront + '/', ''));

    return true;
}

export default {
    list,
    getInfoById,
    getUltimate,
    create,
    update,
    updateOrder
}