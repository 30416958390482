const { default: styled, css } = require("styled-components");

const WrapperFile = styled.div`
    display: flex;
    align-items: center;
    background-color: #f1f3f6;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    padding: 0 0 0 0;
    height: 30px;
    min-width: 85px;

    &:hover,
    &:focus {
        background-color: #eee;
    }

    .prepend-file {
        text-align: center;
        position: absolute;
        height: 100%;
        width: 40px;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .preview {
        height: 30px;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        outline: none;
    }

    .remove{
        height: 30px;
        margin-left: 5px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #8a7a79;
        outline: none;
        transition: all 0.5ms ease-in-out;
        &:hover {
            background-color: #ccc;
        }
    }

    audio {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const ButtonPlay = styled.button`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #4670a2;
    height: 100%;
    outline: none;
    font-size: 12px;
    font-weight: 500;
    border: none;
    background-color: transparent;
`;

const BackgroundImage = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const FileDescription = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;

    ${props => props.disabled && css`
        opacity: 0.3;
    `}

    .input-file-trigger {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 1.2em;
        transition: all .4s;
        cursor: pointer;
        height: 100%;
        padding: 3px 10px;   
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            svg{
                margin-right: 5px;
            }
        }
    }
    .input-file {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
    .input-file:hover + .input-file-trigger,
    .input-file:focus + .input-file-trigger,
    .input-file-trigger:hover,
    .input-file-trigger:focus {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
`;


export {
    BackgroundImage,
    FileDescription,
    WrapperFile,
    ButtonPlay
}