import { auth } from 'firebase';
import { Auth } from "aws-amplify";

const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

	LOAD_ROUTER_LIST: 'LOAD_ROUTER_LIST',
	load_router_list: (payload) => ({
		type: actions.LOAD_ROUTER_LIST,
		payload: payload
	}),

	LOAD_ROLES_AND_MODULES: 'LOAD_ROLES_AND_MODULES',
	load_roles_and_modules: (payload) => ({
		type: actions.LOAD_ROLES_AND_MODULES,
		payload: payload
	}),

	LOAD_OWNER_QUIZS: 'LOAD_OWNER_QUIZS',
	load_owner_quizs: (payload) => ({
		type: actions.LOAD_OWNER_QUIZS,
		payload: payload
	}),

	LOGIN_ACTIVE: 'LOGIN_ACTIVE',

	USER_FIREBASE_SIGNED_IN: 'USER_FIREBASE_SIGNED_IN',
	user_firebase_signed_in: (payload) => ({
		type: actions.USER_FIREBASE_SIGNED_IN,
		payload: payload
	}),

	USER_FIREBASE_SIGNED_FAIL: 'USER_FIREBASE_SIGNED_FAIL',
	user_firebase_signed_fail: () => ({
		type: actions.USER_FIREBASE_SIGNED_FAIL,
		payload: null
	}),

	USER_FIREBASE_START_SIGNED_OUT: 'USER_FIREBASE_START_SIGNED_OUT',
	startSignOut: () => ({
		type: actions.USER_FIREBASE_START_SIGNED_OUT
	}),

	USER_FIREBASE_SIGNED_OUT: 'USER_FIREBASE_SIGNED_OUT',
	user_firebase_signed_out: () => ({
		type: actions.USER_FIREBASE_SIGNED_OUT
	}),

	autoSignOut: async () =>
	{
		await auth().signOut();
		try { await Auth.signOut(); }
		catch (err) { }
	},

	checkAuthorization: () => ({
		type: actions.CHECK_AUTHORIZATION
	}),

	USER_FIREBASE_NEW_LOGIN: 'USER_FIREBASE_NEW_LOGIN',
	setIsNewLogin: (isNewLogin, linkedSessionId) =>
	{
		return (dispatch, getState) =>
		{
			dispatch({
				type: actions.USER_FIREBASE_NEW_LOGIN,
				payload: { isNewLogin, linkedSessionId }
			});
		}
	},

	USER_BACKUP_TOKEN_SESSION: 'USER_BACKUP_TOKEN_SESSION',
	setBackupTokenSession: (tokenSession) =>
	{
		return (dispatch, getState) =>
		{
			dispatch({
				type: actions.USER_BACKUP_TOKEN_SESSION,
				payload: tokenSession
			});
		}
	},

	USER_NOTIF_ID_URL: 'USER_NOTIF_ID_URL',
	setNotifIdURL: (notifId) =>
	{
		return (dispatch, getState) =>
		{
			dispatch({
				type: actions.USER_NOTIF_ID_URL,
				payload: notifId
			});
		}
	},

	USER_FIREBASE_REFRESH: 'USER_FIREBASE_REFRESH',
	userFireRefresh: (user) =>
	{
		return (dispatch, getState) =>
		{
			dispatch({
				type: actions.USER_FIREBASE_REFRESH,
				payload: { ...user }
			});
		}
	},

	USER_FIREBASE_TAB_PROFILE: 'USER_FIREBASE_TAB_PROFILE',
	setTabProfileUser: (tabKey) =>
	{
		return (dispatch, getState) =>
		{
			dispatch({
				type: actions.USER_FIREBASE_TAB_PROFILE,
				payload: tabKey
			});
		}
	}
};
export default actions;