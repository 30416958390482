import React from "react";
import { Modal } from "antd";
import Response from "../containers/Administration/utils/Response";
import { logError, fieldsAudit, sortList } from '../helpers/utility';
import { Table, Enum } from "../constants";
import { RefundModel } from "../models";
import { paymentService, memberService } from './';
import { firestore } from 'firebase';

const db = firestore();
const { confirm } = Modal;

async function confirmRefundError(error_data) 
{
    let code = !!error_data && !!error_data.code ? error_data.code : 'unknown';
    let message = !!error_data && !!error_data.message ? error_data.message : 'error_refund_store';

    return await new Promise((resolve, reject) => {
        confirm({
            title: 'Problema al realizar el reembolso con la tienda. ¿Desea continuar de todos modos?',
            content: <span>{`Código: ${code}`}<br />{`Mensaje: ${message}`}</span>,
            okText: 'Si',
            cancelText: 'No',
            onOk() {
                resolve({ yes: true });
            },
            onCancel() {
                resolve({ yes: false });
            },
        });
    });
}

async function listActives(idOwner): Promise<Response<RefundModel[]>> {
    let response: Response<RefundModel[]> = new Response(false);
    try {
        let query = db.collection(Table.Refund)
            .where(Table.$Refund.userOwnerId, '==', idOwner)
            .where(Table.$Refund.status, '==', true);

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item: RefundModel = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true, false);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('refundService.js:listActives', response);
    return response;
}

async function listToCharge(idOwner): Promise<Response<RefundModel[]>> {
    let response: Response<RefundModel[]> = new Response(false);
    try {
        let query = db.collection(Table.Refund)
            .where(Table.$Refund.userOwnerId, '==', idOwner)
            .where(Table.$Refund.chargedToOwner, '==', false)
            .where(Table.$Refund.status, '==', true);

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item: RefundModel = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true, false);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('refundService.js:listActives', response);
    return response;
}

async function listByIds(ids: String[]): Promise<Response<RefundModel[]>> {
    let response: Response<RefundModel[]> = new Response(false);
    try {
        response.data = [];
        let ref = db.collection(Table.Refund);
        let data = await Promise.all(
            ids.map(ID => { return ref.doc(ID).get(); })
        );
        data.forEach(element => {
            if (element.exists) {
                let item: RefundModel = element.data();
                item.id = element.id;
                response.data.push(item);
            }
        });
        sortList(response.data, 'createdAt', true, false);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('refundService.js:listByIds', response);
    return response;
}

async function create(prmItem: RefundModel, idUserCurrent = ''): Promise<Response<RefundModel>> {
    let response: Response<RefundModel> = new Response(false);
    try {
        // REEMBOLSO TIENDA
        let responseFetch = true;
        prmItem.memberId = prmItem.payment.paymentMemberId;
        prmItem.paymentId = prmItem.payment.id;

        if (prmItem.paymentType.key === Enum.PaymentType.TDS || prmItem.paymentType.key === Enum.PaymentType.TCS) 
        {
            // Array para convertir las reason que ya estan en la DB y que son las mismas par stripe pero diferente name
            let stripeReason = [
                { reason: 'requested_by_client', name: 'requested_by_customer' },
                { reason: 'suspected_fraud', name: 'fraudulent' },
                { reason: 'duplicated_transaction', name: 'duplicate' },
            ]

            let reasonName = prmItem.reason.name;
            let convertReason = stripeReason.find(f => f.reason === prmItem.reason.name);

            if (!!convertReason) 
                reasonName = convertReason.name;

            let res_refundStore = await refundStripe(prmItem.payment.orderId, reasonName, prmItem.amount);
            responseFetch = res_refundStore.status;

            if (!res_refundStore.status) 
            {
                let continueRefund = await confirmRefundError(res_refundStore.error_data);
                responseFetch = continueRefund.yes === true;
            }

            if (responseFetch !== true)
            {
                response.error = "Problemas al reembolsar con la tienda";
                throw new Error('ERROR_REFUND_STORE');
            }

            let item = {
                ...prmItem.toDB(),
                ...fieldsAudit(idUserCurrent, 'CREATE')
            };
            const insert = await db.collection(Table.Refund).add(item);
            item.id = insert.id;

            let updateMember = { [Table.$Member.status]: false };
            await memberService.update(prmItem.payment.paymentMemberId, updateMember, idUserCurrent, true);

            let updatePayment = {
                [Table.$Payment.paymentRefundId]: item.id,
                [Table.$Payment.status]: false
            };
            await paymentService.update(item.paymentId, updatePayment, idUserCurrent, true);

            prmItem.id = item.id;
            prmItem.createdAt = item.createdAt;
            prmItem.createdUser = item.createdUser;
        }
        else 
        {

            if (prmItem.paymentType.key === Enum.PaymentType.PLS) 
            {
                let res_refundStore = await refundPlayStore('mx.aspit.appmision', prmItem.payment.orderId);
                responseFetch = res_refundStore.status;

                if (!res_refundStore.status) 
                {
                    let continueRefund = await confirmRefundError(res_refundStore.error_data);
                    responseFetch = continueRefund.yes === true;
                }
            }
            else if (prmItem.paymentType.key === Enum.PaymentType.TC || prmItem.paymentType.key === Enum.PaymentType.TD) 
            {
                let res_refundStore = await refundConeckta(prmItem.reason.name, prmItem.payment.orderId);
                responseFetch = res_refundStore.status;

                if (!res_refundStore.status) 
                {
                    let continueRefund = await confirmRefundError(res_refundStore.error_data);
                    responseFetch = continueRefund.yes === true;
                }
            }

            if (responseFetch !== true)
            {
                response.error = "Problemas al reembolsar con la tienda";
                throw new Error('ERROR_REFUND_STORE');
            }

            // REEMBOLSO FIRESTORE
            let data = {
                ...prmItem.toDB(),
                ...fieldsAudit(idUserCurrent, 'CREATE')
            };
            const insert = await db.collection(Table.Refund).add(data);
            prmItem.id = insert.id;
            prmItem.createdAt = data.createdAt;
            prmItem.createdUser = data.createdUser;

            // MIEMBRO Y PAGO (FIRESTORE Y AWS)
            let updateMember = {
                [Table.$Member.status]: false
            };
            await memberService.update(prmItem.memberId, updateMember, idUserCurrent, true);

            let updatePayment = {
                [Table.$Payment.paymentRefundId]: prmItem.id,
                [Table.$Payment.status]: false
            };
            await paymentService.update(prmItem.paymentId, updatePayment, idUserCurrent, true);
        }

        response.data = prmItem;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('refundService.js:create', response);
    return response;
}

async function update(idRefund, prmData, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...prmData,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        await db.collection(Table.Refund).doc(idRefund).update(data);
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('refundService.js:update', response);
    return response;
}

async function refundConeckta(nameReason: String, orderId: String): Promise<Response> {
    let API_KEY_CUSTOM = process.env.REACT_APP_CONEKTA_PRIVATE_KEY_TEST;
    API_KEY_CUSTOM = API_KEY_CUSTOM.toLowerCase() === 'null' ? null : API_KEY_CUSTOM;

    let response = new Response(false);
    try {
        let responseFetch = await fetch('https://8snllk72z5.execute-api.us-west-2.amazonaws.com/apis/conekta', {
            method: 'POST',
            body: JSON.stringify({
                typeName: 'Refund',
                arguments: {
                    "api_key_custom": API_KEY_CUSTOM,
                    "orderId": orderId,
                    "reason": nameReason
                }
            })
        });
        let infoJSON = await responseFetch.json();
        if (!infoJSON.status && !!infoJSON.error.details && !!infoJSON.error.details.length > 0) {
            let detailError = infoJSON.error.details[0];
            infoJSON.error.message = detailError.message;
        }

        response.data = infoJSON;
        response.status = infoJSON.status;

        if (!response.status) {
            if (infoJSON.error.http_code === 404)
                infoJSON.error.message = "Orden no encontrada";
            response.error_data = infoJSON.error;
        }
    }
    catch (err) { response.error_data = err; }
    logError('refundService.js:refundConeckta', response);
    return response;
}

async function refundPlayStore(packageName: String, orderId: String): Promise<Response> {
    let response = new Response(false);
    try {
        let responseFetch = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/payment-createRefundPlayStore`, {
            method: 'POST',
            body: JSON.stringify({
                packageName: packageName,
                orderId: orderId
            })
        });
        let infoJSON = await responseFetch.json();

        response.data = infoJSON;
        response.status = infoJSON.status;
        if (!response.status)
            response.error_data = infoJSON.err;
    }
    catch (err) { response.error_data = err; }
    logError('refundService.js:refundPlayStore', response);
    return response;
}


async function refundStripe(orderId: String, reason: String, amount: Number): Promise<Response> {
    let response = new Response(false);
    try {
        let responseFetch = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/payment-createRefundStripe`, {
            method: 'POST',
            body: JSON.stringify({
                orderId: orderId,
                reason: reason,
                amount: amount
            }),
            headers: new Headers()
        });
        let infoJSON = await responseFetch.json();
        response.data = infoJSON;
        response.status = infoJSON.status;

        if (!response.status)
            response.error_data = infoJSON.error;

    } catch (err) { response.error_data = err; }
    logError('refundService.js:refundStripe', response);
    return response;
}

async function statusRefundPLS(productId: String, tokenPurchase: String): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let responseFetch = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/appmision_playStore-getPurchaseState`, {
            method: 'POST',
            body: JSON.stringify({
                productId,
                tokenPurchase
            }),
            headers: new Headers()
        });
        let infoJSON = await responseFetch.json();
        response.data = infoJSON;
        response.error_data = infoJSON.error;
        response.status = infoJSON.status;
    }
    catch (err) { response.error_data = err; }
    logError('refundService.js:statusRefundPLS', response);
    return response;
}

export default {
    listActives,
    listToCharge,
    listByIds,
    create,
    update,
    statusRefundPLS
}