import { PaymentModel } from './';

export default class DepositStore
{
    id: String;
    paymentStatusId: String;
    amount: Number;
    amountDeposit: Number;
    exchangeRate: Number;
    description: String;
    currency: String;
    dateConciliation: Number;
    monthConciliation: String;
    isRemediation: Boolean;
    observation: String;
    store: 'stripe' | 'play_store' | 'app_store' | 'manual';
    status: Boolean;
    createdAt: Number;
    createdUser: String;
    updatedUser: String;

    payments: PaymentModel[];
    paymentsUnused: PaymentModel[];

    constructor()
    {
        this.store = 'manual';
        this.isRemediation = false;
        this.observation = '';
        this.amount = 0;
        this.payments = [];
        this.paymentsUnused = [];
        this.status = true;
    }
}