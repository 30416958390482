const actions = {
    SET_CURRENT_EXAM: 'SET_CURRENT_EXAM',
    setCurrentExam: (quiz) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_CURRENT_EXAM,
                payload: quiz,
            });
        }
    },

    SET_PARAM_EXTERN: 'SET_PARAM_EXTERN',
    setParamExtern: (paramExtern) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_PARAM_EXTERN,
                payload: paramExtern,
            });
        }
    },

    SUM_QUESTIONS_COUNT: 'SUM_QUESTIONS_COUNT',
    sumQuestionsCount: () => {
        return (dispatch, getState) => {
            dispatch({ type: actions.SUM_QUESTIONS_COUNT });
        }
    },


    /* ---------- Sections ----------*/

    SET_SECTIONS_EXAM: 'SET_SECTIONS_EXAM',
    setSectionsExam: (sections) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_SECTIONS_EXAM,
                payload: sections,
            });
        }
    },

    ADD_SECTION: 'ADD_SECTION',
    addSection: (section) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.ADD_SECTION,
                payload: section,
            });
        }
    },

    UPDATE_SECTION: 'UPDATE_SECTION',
    updateSection: (section) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.UPDATE_SECTION,
                payload: section,
            });
        }
    },


    SET_QUESTIONS_EXAM: 'SET_QUESTIONS_EXAM',
    setQuestionsExam: (questions) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_QUESTIONS_EXAM,
                payload: questions,
            });
        }
    },


    ADD_QUESTION: 'ADD_QUESTION',
    addQuestion: (question) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.ADD_QUESTION,
                payload: question,
            });
        }
    },

    UPDATE_QUESTION_FROM_LIST: 'UPDATE_QUESTION_FROM_LIST',
    updateQuestionFromList: (question) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.UPDATE_QUESTION_FROM_LIST,
                payload: question,
            });
        }
    },


    SET_LIST_QUESTIONS_SECTION: 'SET_LIST_QUESTIONS_SECTION',
    setListQuestions: (questions) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_LIST_QUESTIONS_SECTION,
                payload: questions,
            });
        }
    },

    SET_QUIZ_STATUS: 'SET_QUIZ_STATUS',
    setQuizStatus: (status) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_QUIZ_STATUS,
                payload: status,
            });
        }
    },




    /* ---------- Bank ----------*/

    SET_BANK: 'SET_BANK',
    setBank: (bank) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_BANK,
                payload: bank,
            });
        }
    },


    /* ---------- Question ----------*/

    EMPTY_QUESTION: "EMPTY_QUESTION",
    emptyQuestion: () => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.EMPTY_QUESTION,
            });
        }
    },

    SET_QUESTION: 'SET_QUESTION',
    setQuestion: (question) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_QUESTION,
                payload: question,
            });
        }
    },


    SET_SECTION_QUESTION: 'SET_SECTION_QUESTION',
    setSectionQuestion: (section) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_SECTION_QUESTION,
                payload: section,
            });
        }
    },

    SET_SUB_SECTION_QUESTION: 'SET_SUB_SECTION_QUESTION',
    setSubSectionQuestion: (subSection) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_SUB_SECTION_QUESTION,
                payload: subSection,
            });
        }
    },

    SET_LESSON_QUESTION: 'SET_LESSON_QUESTION',
    setLessonQuestion: (lesson) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_LESSON_QUESTION,
                payload: lesson,
            });
        }
    },

    SET_FREE_QUESTION: 'SET_FREE_QUESTION',
    setFreeQuestion: (free) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_FREE_QUESTION,
                payload: free,
            });
        }
    },

    SET_VERSION_QUESTION: 'SET_VERSION_QUESTION',
    setVersionQuestion: (lesson) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_VERSION_QUESTION,
                payload: lesson,
            });
        }
    },

    SET_SEQUENCE_QUESTION: 'SET_SEQUENCE_QUESTION',
    setSequenceQuestion: (sequence) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_SEQUENCE_QUESTION,
                payload: sequence,
            });
        }
    },

    SET_SEQUENCE_FEEDBACK: 'SET_SEQUENCE_FEEDBACK',
    setSequenceFeedback: (sequence) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_SEQUENCE_FEEDBACK,
                payload: sequence,
            });
        }
    },

    SET_SEQUENCE_ANSWER: 'SET_SEQUENCE_ANSWER',
    setSequenceAnswer: (sequence) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_SEQUENCE_ANSWER,
                payload: sequence,
            });
        }
    },
}

export default actions;