import { SectionConfiguration } from './';
export class TemplateQuiz
{
    id: String;
    description: String;
    name: String;
    pointsApprove: Number;
    status: Boolean;
    templateQuizQuizId: String;
    templateQuizQuizTypeId: String;
    progress: Number;

    updatedAt: String;
    updatedUser: String;
    createdAt: Number;
    createdUser: String;
    
    sections: SectionConfiguration[];

    constructor()
    {
        this.pointsApprove = 0;
        this.progress = 0;
        this.sections = [];
    }

    loadInfo(template)
    {
        this.description = template.description;
        this.name = template.name;
        this.pointsApprove = template.pointsApprove;
        this.status = template.status;
        this.templateQuizQuizId = template.templateQuizQuizId;
        this.templateQuizQuizTypeId = template.templateQuizQuizTypeId;
    }
}