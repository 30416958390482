import Response from "../containers/Administration/utils/Response";
import { logError } from '../helpers/utility';
import { Table } from "../constants";
import { MemberModel } from "../models";
import { firestore } from 'firebase';

const db = firestore();

async function getInfo(quizHistoryId: String): Promise<Response>
{
    let response = new Response(false);
    try
    {
        const query = db.collection(Table.QuizHistory)
            .doc(quizHistoryId);
        const doc = await query.get();
        if(doc.exists)
        {
            response.data = doc.data();
            response.id = doc.id;
            response.status = true;
        }
        else
            throw new Error('NOT_FOUND:' + quizHistoryId);
    } 
    catch (err)
    {
        response.error_data = err;
        logError('quizHistoryService.getInfo', response);
    }
    return response;
}

async function listAllQuizHistoryByMember(memberId: String): Promise<Response> {
    let response = new Response(false);

    try {
        const query = db.collection(Table.QuizHistory)
        .where(Table.$QuizHistory.quizHistoryMemberId, '==', memberId);
        const data = await query.get();
        let contador = 0;
        response.data = data.docs.map(element =>
        {
            let item: MemberModel = data.docs[contador++].data();
            item.id = element.id;
            return item;
        });
    } catch (err) {
        response.error_data = err;
        logError('quizHistoryService.listAllQuizHistoryByMember', response);
    }

    return response;
}

export default {
    getInfo,
    listAllQuizHistoryByMember
}