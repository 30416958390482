const actions = {


	/** ------------------------------------------
	 *  Create Questions actions
	 ------------------------------------------ */

	CREATE_QUESTION: 'CREATE_QUESTION',

	createQuestion: (question) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.CREATE_QUESTION,
				payload: question,
			});
		}
	},


	SET_PARAMS_INIT_FORM_QUESTION: 'SET_PARAMS_INIT_FORM_QUESTION',

	setParamsInitFormQuestion: (params) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.SET_PARAMS_INIT_FORM_QUESTION,
				payload: params,
			});
		}
	},
	/** ------------------------------------------
	 *  Create Questions actions
	 ------------------------------------------ */




	UPDATE_TEXT_LIST_SEQUENCES: 'UPDATE_TEXT_LIST_SEQUENCES',
	UPDATE_FILE_LIST_SEQUENCES: 'UPDATE_FILE_LIST_SEQUENCES',
	UPDATE_STATUS_SEQUENCES: 'UPDATE_STATUS_SEQUENCES',

	DELETE_ITEM_LIST_SEQUENCES: 'DELETE_ITEM_LIST_SEQUENCES',
	ADD_ITEM_DELETE_SEQUENCE: 'ADD_ITEM_DELETE_SEQUENCE',
	SET_LIST_SEQUENCES: 'SET_LIST_SEQUENCES',
	ADD_ITEM_SEQUENCE: 'ADD_ITEM_SEQUENCE',


	RESET_LIST: 'RESET_LIST',

	SET_LIST_QUESTIONS: 'SET_LIST_QUESTIONS',
	SET_ITEM_LIST_FILTER: 'SET_ITEM_LIST_FILTER',

	setListQuestions: (list) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.SET_LIST_QUESTIONS,
				payload: list,
			});
		}
	},

	setListSequences: (list) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.SET_LIST_SEQUENCES,
				payload: list,
			});
		}
	},

	deleteItemSequence: item => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.DELETE_ITEM_LIST_SEQUENCES,
				payload: item
			})
		}
	},

	addDeleteItemSequence: item => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.ADD_ITEM_DELETE_SEQUENCE,
				payload: item
			})
		}
	},

	addItemSecuence: item => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.ADD_ITEM_SEQUENCE,
				payload: item
			})
		}
	},

	updateStatusListSequences: (status) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.UPDATE_STATUS_SEQUENCES,
				payload: status
			});
		}
	},

	updateTextListSequences: (text) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.UPDATE_TEXT_LIST_SEQUENCES,
				payload: text
			});
		}
	},

	updateFileListSequences: (file) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.UPDATE_FILE_LIST_SEQUENCES,
				payload: file
			});
		}
	},

	resetList: (list) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.RESET_LIST,
				namelist: list
			});
		}
	},


	/** ------------------------------------------
	 *  Free actions
	 ------------------------------------------ */

	CHANGE_STATUS_FREE: 'CHANGE_STATUS_FREE',

	changeStatusFree: (indice, free) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.CHANGE_STATUS_FREE,
				payload: {
					free: free,
					indice: indice
				}
			});
		}
	},

	SET_LOADING_FREE: 'SET_LOADING_FREE',

	setLoadingFree: (indice, loading) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.SET_LOADING_FREE,
				payload: {
					loading: loading,
					indice: indice
				}
			});
		}
	},

	/** ------------------------------------------
	 *  END / Free actions
	 ------------------------------------------ */
	UPDATE_ITEM_LIST_QUESTION: "UPDATE_ITEM_LIST_QUESTION",

	updateItemListQuestion: (question) => {
		// console.log(question);
		return (dispatch, getState) => {
			dispatch({
				type: actions.UPDATE_ITEM_LIST_QUESTION,
				payload: question
			});
		}
	},



	/** ------------------------------------------
	 *  Fiilter Table Question
	 ------------------------------------------ */


	SET_FILTER_TABLE_QUESTION: 'SET_FILTER_TABLE_QUESTION',
	SET_FILTER_INFO_TABLE_QUESTION: 'SET_FILTER_INFO_TABLE_QUESTION',
	setFilterTableQuestion: (filter) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.SET_FILTER_TABLE_QUESTION,
				payload: filter
			});
		}
	},
	setFilterInfoTableQuestion: (filter) => {
		return (dispatch, getState) => {
			dispatch({
				type: actions.SET_FILTER_INFO_TABLE_QUESTION,
				payload: filter
			});
		}
	},



};
export default actions;
