import Response from "../containers/Administration/utils/Response";
import { PaymentStatusModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function list(onlyActives = false): Promise<Response<PaymentStatusModel[]>>
{
    let response: Response<PaymentStatusModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.PaymentStatus);
        let query = onlyActives ? ref.where(Table.$PaymentStatus.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

export default {
    list
}