import Response from "../containers/Administration/utils/Response";
import { getIdUser } from "../containers/Administration/utils/Global";
import { firestore } from "firebase";

const db = firestore();

async function listAccessTypes(): Promise<Response> {
    let response = new Response();
    try {
        let query = await db.collection('AccessType').get();
        if (query.empty){
            response.data = query.docs.map(e => { return { ...e.data(), id: e.id } });
            response.status = true;
        }

    } catch (e) {
        response.status = false;
        response.error = e;
    }
    return response;

}

async function createAccessType(rol): Promise<Response> {

    let response = new Response();

    let idOnwer = await getIdUser();

    let input = {
        key: rol.key,
        name: rol.name,
        description: rol.description,
        createdUser: idOnwer,
        status: rol.status,
    }

    try {

        let query = await db.collection('AccessType').add(input);
        if (query.empty){
            response.data = query.docs.map(e => { return { ...input, id: query.id } });
            response.status = true;
        }

    } catch (e) {
        response.status = false;
        response.error = e;
    }
    return response;

}


async function updateAccessType(rol): Promise<Response> {

    let response = new Response();

    let idOnwer = await getIdUser();

    let input = {
        key: rol.key,
        name: rol.name,
        description: rol.description,
        createdUser: idOnwer,
        status: rol.status,
    }

    try {
        let query = await db.collection('AccessType').doc(rol.id).update(input);

        response.data = query.docs.map(e => { return { ...input, id: rol.id} });
        response.status = true;

    } catch (e) {
        response.status = false;
        response.error = e;
    }
    return response;

}


export default {
    listAccessTypes,
    createAccessType,
    updateAccessType
}