import { auth } from "firebase";


const is_production = true;

// const s3
const end_point_assets_s3 = "https://appmision-assets.s3-us-west-2.amazonaws.com/public";
const end_point_cloudfront = "https://d1kw9b8vn7bneg.cloudfront.net/public";



const path_ambient = (is_production) ? "prod" : "dev";


function getIdUser() {
    return new Promise((resolve, reject) => {
        let uid = auth().currentUser.uid;
        resolve(uid);
    })
}

export interface PathProps {
    path_assets?: String;
    path_cloudfront?: String;
    url_assets?: String
}

function getResourcesPath(idUserCurrent = ''): Promise<PathProps>
{
    if(idUserCurrent !== '')
    {
        const sub = idUserCurrent;
        return {
            path_assets: `${path_ambient}/${sub}`,
            url_assets: `${end_point_cloudfront}/${path_ambient}/${sub}`,
            path_cloudfront: end_point_cloudfront,
        }
    }
    return new Promise((resolve, reject) =>
    {
        getIdUser().then(response =>
        {
            const sub = response;
            const paths = {
                path_assets: `${path_ambient}/${sub}`,
                url_assets: `${end_point_cloudfront}/${path_ambient}/${sub}`,
                path_cloudfront: end_point_cloudfront,
            }
            resolve(paths);
        });
    })
}


function scrollTop() {
    var scr = document.getElementById('layout-page');
    scr.scrollTo(0, 0);
}


export {
    scrollTop,
    getIdUser,
    getResourcesPath,
    end_point_assets_s3
}