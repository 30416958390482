import Response from "../containers/Administration/utils/Response";

import { firestore } from "firebase";
import { fieldsAudit } from "../helpers/utility";

const db = firestore();

async function listTrakingTypes(): Promise<Response> {

    let result = new Response();

    try {
        let query = await db.collection('TrakingType').where('status', '==', true).get();
        result.data = query.docs.map(e => { return { ...e.data(), id: e.id } });
        result.status = true;
    } catch (e) {
        result.status = false;
        result.error = e.errors[0].message;
    }
    return result;

}

async function createTrakingType(form, idUserCurrent): Promise<Response> {

    let result = new Response();

    try {

        let data = {
            key: form.key,
            name: form.name,
            description: form.description,
            status: form.status,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        }

        let query = await db.collection('TrakingType').add(data);

        result.data = { ...data, id: query.id }
        result.status = true;
    } catch (e) {
        result.status = false;
        result.error = e.errors[0].message;
    }
    return result;

}


async function updateTrakingType(form, idUserCurrent): Promise<Response> {

    let result = new Response();

    try {
        let id = form.id;

        let data = {
            key: form.key,
            name: form.name,
            description: form.description,
            status: form.status,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        }

        await db.collection('TrakingType').doc(id).update(data);

        result.data = { ...data, id: form.id }
        result.status = true;
    } catch (e) {
        result.status = false;
        result.error = e.errors[0].message;
    }
    return result;

}


export default {
    listTrakingTypes,
    createTrakingType,
    updateTrakingType
}