import { CatalogsModel, MemberModel, QuizModel, PaymentStatusModel, DeductionModel, UserModel } from "./";

export default class Payment
{
    id: String;
    amount: Number;
    amountOutDiscount: Number;
    amountBusiness: Number;
    paidAt: Boolean;
    reference: String;
    platform: String;
    paymentDate: Number;
    createdAt: Number;
    updatedAt: Number;
    createdUser: String;
    updatedUser: String;
    tokenId: String;
    orderId: String;
    transactionDate: String;
    transactionReceipt: String;
    exchangeRate: Number;
    status: Boolean;

    paymentMemberId: String;
    paymentQuizId: String;
    paymentStatusId: String;
    paymentPaymentTypeId: String;
    paymentCurrencyId: String;
    paymentTrakingId: String;
    paymentPromotionId: String;
    paymentRefundId: String;
    quizsUserOwnerId: String;

    quiz: QuizModel;
    user: UserModel;
    member: MemberModel;
    paymentType: CatalogsModel;
    paymentStatus: PaymentStatusModel;
    deductions: DeductionModel[];
}