const infoQuestion = `
    id
    key
    required
    createdAt
    updatedAt
    createdUser
    updatedUser
    static
    points
    status
    free
    order
    bank
    {
      name
    }
    section
    {
      name
    }
    subSection{
      name
    }
    level
    {
      name
    }
    answerType
    {
      name
    }
    questionSequences
    {
      items {
        id
        questionImage
        questionText
        questionAudio
        order
        status
      }
    }
    feedbackSequences{
      items{
        id
        feedbackImage
        feedbackText
        order
        status
      }
    }
    answers{
      items{
        id
        answerImage
        answerText
        correctAnswer
        order
        status
      }
    }
    questionType {
      name
    }
    questionAnswerTypeId
    questionBankId
    questionLevelId
    questionSectionId
    questionSubSectionId
    questionQuestionTypeId
    questionQuestionGroupId
`;

export const listQuestions = `query ListQuestions(
  $id: ID
  $questionBankIdQuestionSectionId: ModelQuestionPrimaryCompositeKeyConditionInput
  $filter: ModelQuestionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listQuestions(
    id: $id
    questionBankIdQuestionSectionId: $questionBankIdQuestionSectionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      ${infoQuestion}
    }
    nextToken
  }
}
`;


export const itemsQuizesBankSection = `query ItemsQuizesBankSection(
    $questionBankId: String
    $questionSectionId: ModelStringKeyConditionInput
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsQuizesBankSection(
      questionBankId: $questionBankId
      questionSectionId: $questionSectionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${infoQuestion}
      }
      nextToken
    }
  }
  `;

export const listSectionsByBank = `query listSectionsByBank(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(limit:$limit, filter: $filter) {
      items {
        id
        name
        sectionBankId
        key
        description
        status
      }
      nextToken
    }
  }
  `;

export const getQuestion = `query GetQuestion(
    $id: ID!
    $questionBankId: String!
    $questionSectionId: String!
  ) {
    getQuestion(
      id: $id
      questionBankId: $questionBankId
      questionSectionId: $questionSectionId
    ) {
       ${infoQuestion}
    }
  }
  `;



export const listLevels = `query ListLevels{ 
      listLevels(filter: {status: {eq: true}}) {
        items {
          id
          name
        }
      }
  }
  `;

export const listAnswerTypes = `query ListAnswerTypes(
    $filter: ModelAnswerTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswerTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      nextToken
    }
  }
  `;

export const listBanks = `query ListBanks(
    $filter: ModelBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      nextToken
    }
  }
  `;



export const listSections = `query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        subSection{
          items{
            id
            key
            name
            description
            status
          }
        }
      }
      nextToken
    }
  }
  `;

export const getSection = `query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      key
      name
      subSection (limit: 100){
        items {
          id
          key
          name
          status
        }
      }
    }
  }
  `;

export const listCategories = `query ListCategories{
    listCategories(filter: {status: {eq: true}}) {
      items {
        id
        key
        name
        description
      }
    }
  }
  `;


export const listCurrencys = `query ListCurrencys(
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      nextToken
    }
  }
  `;

export const listPromotions = `query ListPromotions{
    listPromotions(filter: {status: {eq: true}}) {
      items {
        id
        name
        description
        code
        percentDiscount
        amountDiscount
        thumbnail
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      nextToken
    }
  }
  `;

export const getQuizs = `query GetQuizs($id: ID!) {
    getQuizs(id: $id) {
      id
      name
      intro
      description
      title
      price
      productId
      thumbnail
      thumbnail_min
      duration
      version
      createdAt
      updatedAt
      createdUser
      updatedUser
      numberQuestions
      productId
      status
      quizsCategorieId
      quizsUserOwnerId
      categorie {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      currency {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      level {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      promotion {
        id
        name
        description
        code
        percentDiscount
        amountDiscount
        thumbnail
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      userOwner {
        id
        username
        password
        photoUrl
        createdAt
        updatedAt
        createdUser
        updatedUser
        sub
        status
      }
      templateQuizes {
        nextToken
      }
      commentQualifications {
        nextToken
      }
      favorites {
        nextToken
      }
      payments {
        nextToken
      }
      quizHistories {
        nextToken
      }
    }
  }
  `;


export const listQuizTypes = `query ListQuizTypes(
    $filter: ModelQuizTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      nextToken
    }
  }
  `;


export const listSectionConfigurations = `query ListSectionConfigurations(
    $filter: ModelSectionConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSectionConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberQuestions
        bank{
          id
          name
        }
        section{
          id
          name
        }
        level{
          id
          name
        }
      }
      nextToken
    }
  }
  `;



export const listTemplateQuizs = `query ListTemplateQuizs(
    $filter: ModelTemplateQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateQuizs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        pointsApprove
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        templateQuizQuizId
        templateQuizQuizTypeId
        quizType{
          id
          name
        }
      }
      nextToken
    }
  }
  `;


export const listConfigurationsSectionByTemplateId = `query ListTemplateQuizSectionConfigurations(
    $filter: ModelTemplateQuizSectionConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateQuizSectionConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items{
        id
        sectionConfiguration{
          id
          numberQuestions
          name
          section{
            id
            name
          }
          bank{
            id
            name
          }
          level{
            id
            name
          }
          subSection{
            id
            name
          }
          questionType{
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;


export const listProblemReports = `query ListProblemReports(
  $filter: ModelProblemReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listProblemReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      message
      status
      createdAt
      quizHistoryId
      problemReportProblemTypeId
      problemReportUserId
      problemReportQuestionHistoryId
      problemReportReplayId
      validated
      reply
      user{
        id
        username
        photoUrl
        sub
        status
        person {
          id
          name
          lastName
          email
          createdAt
          updatedAt
          createdUser
          updatedUser
          status
        }
      }
      problemType {
        id
        name
        description
      }
    }
    nextToken
  }
}
`;

export const getQuestionHistory = `query GetQuestionHistory($id: ID!, $questionHistoryQuizHistoryId: String!) {
  getQuestionHistory(
    id: $id
    questionHistoryQuizHistoryId: $questionHistoryQuizHistoryId
  ) {
    id
    points
    getPoints
    required
    section
    subSection
    createdAt
    updatedAt
    createdUser
    updatedUser
    status
    order
    questionBankId
    questionSectionId
    questionHistoryQuestionId
    questionSequenceHistories {
      items {
        id
        questionText
        questionImage
        questionAudio
        order
        status
      }
    }

    feedbackSequencesHistory{
      items{
        id
        feedbackText
        feedbackImage
        status
        order
      }
    }

    answerHistory{
      items{
        id
        answerText
        answerImage
        correctAnswer
        status
        order
      }
    }
    
  }
}
`;

export const listPaymentMembers = `query ListPaymentMembers(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments (filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      createdAt
      paymentType {
        name
      }
      quiz {
        name
        thumbnail_min
        thumbnail
      }
      member {
        user {
          id
          username
          person {
            name
            lastName
          }
        }
      }
    }
    nextToken
  }
}
`;


export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    person {
      id
      name
      lastName
      email
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
    accessType {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
    username
    password
    photoUrl
    createdAt
    updatedAt
    createdUser
    updatedUser
    sub
    complete
    platform
    status
    userTrakingId
    test
    members {
      id
      dateValidity
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      memberUserId
    }
    traking {
      id
      identifierTraking
      name
      description
      thumbnail
      price
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      trakingUserId
    }
  }
}
`;

export const listUsersNoMembers = `query ListUsersNoMembers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
listUsers (filter: $filter, limit: $limit, nextToken: $nextToken) {
  items {
    id
    username
    status
    person {
      name
    }
  }
  nextToken
  }
}
`;


export const listCommentQualifications = `query ListCommentQualifications(
  $filter: ModelCommentQualificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommentQualifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      qualification
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      replay
      validated
      reply
      commentQualificationUserOwnerId
      commentQualificationQuizId
      commentQualificationUserId
      user{
        id
        username
        person{
          name
        }
      }
    }
    nextToken
  }
}
`;

export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      platform
      status
      complete
      person {
        name
      }
    }
    nextToken
  }
}
`;