
const reportActions = {
  LOAD_REPORTS: 'LOAD_REPORTS',
  LOAD_REPORT: 'LOAD_REPORT',
  FILTER_ATTRIBUTE: 'FILTER_ATTRIBUTE',
  SELECTED_REPORT: 'SELECTED_REPORT',
  WRITE_ANSWER_: 'WRITE_ANSWER_',
  REPLY_REPORT: 'REPLY_REPORT',

  loadReports: (reports, user) => {
    return (dispatch, getState) => {
      const allReports = getState().Reports.allReports;
      reports.map(element => {
        if (element.user.id !== user) {
          let index = allReports.findIndex(x => x.problemReportQuestionHistoryId === element.problemReportQuestionHistoryId);
          if (index < 0) {
            if (element.status) {
              allReports.push(element);
            }
          } else {
            var f1 = new Date(reports[index].createdAt);
            var f2 = new Date(element.createdAt)
            if (f1 > f2) {
              reports.splice(index, 1, element);
            }
          }
        }
        return null;
      });
      dispatch({
        type: reportActions.LOAD_REPORTS,
        allReports
      })
    }
  },
  loadReport: report => {
    return (dispatch, getState) => {
      dispatch({
        type: reportActions.LOAD_REPORT,
        report
      })
    }
  },
  filterAction: newFilterAttr => {
    return (dispatch, getState) => {
      const filterAttr = getState().Reports.filterAttr;
      console.log(filterAttr)
      if (newFilterAttr) {
        if (newFilterAttr.bucket) {
          filterAttr.bucket = newFilterAttr.bucket;
        }
      }
      dispatch({
        type: reportActions.FILTER_ATTRIBUTE,
        filterAttr
      });
    };
  },

  selectReport: selected => {
    return (dispatch, getState) => {
      const allReports = getState().Reports.allReports;
      allReports[
        allReports.findIndex(report => report.id === selected)
      ].status = true;
      dispatch({
        type: reportActions.SELECTED_REPORT,
        selected,
        allReports
      });
    };
  },

  changeReplyReport: replyReport => ({
    type: reportActions.REPLY_REPORT,
    replyReport
  }),







  /*------------------------------
  ++ ADD QUESTIONS PROBLEM TYPE
  ------------------------------*/

  ADD_QUESTIONS_PROBLEM_TYPE: "ADD_QUESTIONS_PROBLEM_TYPE",

  addQuestiondProblemType: (indice, reports) => {
    return (dispatch, getState) => {
      dispatch({
        type: reportActions.ADD_QUESTIONS_PROBLEM_TYPE,
        payload: { indice: indice, reports: reports }
      })
    }
  },

  /*------------------------------
  ++ SET REPORT PROBLEM LIST
  ------------------------------*/

  SET_REPORT_PROBLEM_LIST: "SET_REPORT_PROBLEM_LIST",

  setReportProblemList: list => {
    return (dispatch, getState) => {
      dispatch({
        type: reportActions.SET_REPORT_PROBLEM_LIST,
        payload: list
      })
    }
  },


  /*------------------------------
  ++ SET QUESTION REPLY
  ------------------------------*/

  SET_QUESTION_REPLY: "SET_QUESTION_REPLY",

  setQuestionReply: question => {
    return (dispatch, getState) => {
      dispatch({
        type: reportActions.SET_QUESTION_REPLY,
        payload: question
      })
    }
  },


  /*------------------------------
  ++ SET LIST MESSAGES REPORTS
  ------------------------------*/

  SET_LIST_MESSAGES_REPORTS: "SET_LIST_MESSAGES_REPORTS",

  setListMessagesReports: list => {
    return (dispatch, getState) => {
      dispatch({
        type: reportActions.SET_LIST_MESSAGES_REPORTS,
        payload: list
      })
    }
  },



  /*------------------------------
++ UPDATE AVLIDATED
------------------------------*/

  UPDATED_VALIDATED: "UPDATED_VALIDATED",

  updatedValidated: (index, reports) => {
    return (dispatch, getState) => {
      dispatch({
        type: reportActions.UPDATED_VALIDATED,
        payload: {
          index: index,
          reports: reports
        }
      })
    }
  },


  /*------------------------------
++ UPDATE AVLIDATED
------------------------------*/

  UPDATED_REPLY: "UPDATED_REPLY",

  updatedReply: (index, reports) => {
    return (dispatch, getState) => {
      dispatch({
        type: reportActions.UPDATED_REPLY,
        payload: {
          index: index,
          reports: reports
        }
      })
    }
  },


  

};
export default reportActions;
