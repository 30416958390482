import actions from './actions';
import { FilterSession } from '../../models/filters';

const initState = {
    members: null,
    users: null,
    total_users: 0,
    quizs: [],
    filterCurrent: new FilterSession(),
    sessions: null,
    typeInfo: 'ONLY_MEMBERS'
}

export default function (state = initState, action)
{
    switch (action.type)
    {
        // GENERAL
        case actions.SET_MEMBERS:
            return {
                ...state,
                members: action.payload
            }
        case actions.SET_USERS:
            return {
                ...state,
                users: action.payload,
                total_users: !!action.payload ? state.total_users : 0
            }
        case actions.SET_TOTAL_USERS:
            return {
                ...state,
                total_users: action.payload
            }
        case actions.SET_SESSIONS:
            return {
                ...state,
                sessions: action.payload
            }
        case actions.SET_TYPE_INFO:
            return {
                ...state,
                members: null,
                users: null,
                sessions: null,
                typeInfo: action.payload
            }
        case actions.SET_FILTER_CURRENT:
            {
                let newInfo = { ...action.payload };
                state.filterCurrent = null;
                state.filterCurrent = new FilterSession();
                state.filterCurrent.platform = newInfo.platform;
                state.filterCurrent.provider = newInfo.provider;
                state.filterCurrent.status = newInfo.status;
                state.filterCurrent.rangeTimeStart = newInfo.rangeTimeStart;
                return { ...state }
            }

        // CATÁLOGOS
        case actions.SET_QUIZS:
            return {
                ...state,
                quizs: action.payload
            }

        default:
            return state;
    }
}