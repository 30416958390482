import styled from "styled-components";

const WrapperSequence = styled.div`


    background-color    : #ffffff;
    box-shadow          : 0px 0px 20px 0px rgb(94 92 154 / 6%);
    padding             : 30px 20px 20px 20px;
    border-radius       : 6px;
    margin-top          : 30px;

    h1{
        font-size: 22px;
        border-bottom: 1px solid #f4f8fb;
        font-weight: 300;
        padding-bottom: 10px;
    }

    ul{
        padding     : 0;
        margin      : 20px 0 0;
        list-style  : none;
        display                 : grid;
        gap                     : 20px 20px; 
        grid-template-columns   : 1fr 1fr;
        

        li {
            position            : relative;
            /* margin-bottom: 30px; */

            &:last-child {
                margin-bottom: 0;
            }


            .sequence-row, 
            .add-sequence{
                display: flex;
                align-items: flex-start; 

                border: 1px solid #f4f8fb;
                padding: 10px;
                border-radius: 12px;

                .icons {
                    flex-shrink: 0;
                }
            }

            .letter-answer {
                width: 32px;
                height: 32px;
                background-color: #f60;
                border-radius: 50%;
                border: none;
                color: #ffffff;
                font-size: 18px;
                margin-right: 20px;
                background-color: #cccccc;
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                
                &:disabled {
                    opacity: 0.6;
                }

                &.correct {
                    background-color: green;
                }
            }

            .sequence-row .ant-typography  {
                margin-bottom: 0;
            }

            .add-sequence {
                align-items: center;
                border: 2px dashed #a9b4bd;
                border-radius: 6px;
                cursor: pointer;

                span.add {
                    color: #6c7d8c;
                    font-weight: 500;
                }

                .anticon {
                    margin-right: 10px;
                    font-size: 16px;
                }
                
            }

            .icons {
                margin-left: 30px;

                .edit{
                    padding: 6px 7px 0px;
                    border-radius: 8px;
                    display     : inline-block;
                    font-size   : 16px;
                    cursor      : pointer;
                    flex-shrink: 0;
                    border: none;
                }

                .edit{
                    background-color: #c6ddff;
                    color: #142e7a;
                }
                
            }

            .question-paragraph {
                font-weight : 400;
                font-size   : 15px;
                color       : #172b4d;
            }

            .content-chip {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                .chip {
                    height          : 32px;
                    width           : 32px;
                    display         : flex;
                    align-items     : center;
                    background-color: #f4f8fb;
                    border-radius   : 100vh;
                    margin-right    : 10px;
                    margin-top      : 10px;

                    &.image {
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    .ant-avatar {
                        color           : #a9b4bd;
                        display         : flex;
                        align-items     : center;
                        justify-content : center;
                        background-color: #f4f8fb;
                    }

                    & > span {
                        color      : #a9b4bd;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    @media(max-width: 456px) {
        ul {
            grid-template-columns: 1fr;
        }
    }
`;


const ContentMedia = styled.div`
    display: flex; 
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
`;


export {
    WrapperSequence,
    ContentMedia
}