import uuid from "uuid/v4";

export default class LessonSequence
{
    id: String;
    idQuestionGroup: String;
    lessonText: String;
    lessonImage: String;
    order: Number;
    status: Boolean;

    fileImage: File;
    lessonImageBackup: String;

    constructor()
    {
        this.id = `NEW_${uuid()}`;
        this.lessonImage = null;
        this.lessonText = '';
        this.order = 0;
        this.status = true;
    }
}