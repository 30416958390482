import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, convertToUnix, logError, sortList } from '../helpers/utility';
import { SectionModel } from '../models';
import { subSectionService } from './';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();
const prefixNEWSAVED = 'NEWSAVED_';
const prefixEDITED = 'EDITED_';

async function listSections(onlyActives = false, sectionBankId = null): Promise<Response<SectionModel[]>> {
    let response: Response<SectionModel[]> = new Response(false);
    try {

        console.log({ sectionBankId });
        
        let ref = db.collection(Table.Section);
        let query = onlyActives ? ref.where(Table.$Section.status, '==', true) : ref;

        if (!!sectionBankId)
            query = query.where(Table.$Section.sectionBankId, '==', sectionBankId);

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item: SectionModel = element.data();
            item.id = element.id;
            item.subsections = [];
            item.createdAt = convertToUnix(item.createdAt);
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createSection(itemCatalog: SectionModel, idUserCurrent = ''): Promise<Response<SectionModel>> {
    let response: Response<SectionModel> = new Response(false);
    try {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        delete data['id'];
        delete data['subsections'];
        const insert = await db.collection(Table.Section).add(data);
        itemCatalog.id = insert.id;


        await Promise.all(itemCatalog.subsections.map(async (element) => {
            delete element['id'];
            let res_subsection = await subSectionService.createSubsection(itemCatalog.id, element, idUserCurrent);
            if (res_subsection.status)
                element.id = res_subsection.data.id;

            return element;
        }));

        response.data = itemCatalog;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('sectionService.js:createSection', response);
    return response;
}


async function updateSection(itemCatalog: SectionModel, idUserCurrent = ''): Promise<Response<SectionModel>> {
    let response: Response<SectionModel> = new Response(false);
    try {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        delete data['subsections'];
        await db.collection(Table.Section).doc(itemCatalog.id).update(data);


        if (itemCatalog.subsections !== undefined && itemCatalog.subsections.length > 0) {
            await Promise.all(itemCatalog.subsections.map(async (element) => {
                let res_subsection = null;
                let isNew = element.id.includes(prefixNEWSAVED);
                element.id = isNew ? element.id.replace(prefixNEWSAVED, '') : element.id.replace(prefixEDITED, '');

                if (isNew) {
                    delete element['id'];
                    res_subsection = await subSectionService.createSubsection(itemCatalog.id, element, idUserCurrent);
                }
                else
                    res_subsection = await subSectionService.updateSubsection(itemCatalog.id, element, idUserCurrent);

                if (res_subsection.status)
                    element.id = res_subsection.data.id;

                return element;
            }));
        }

        response.data = itemCatalog;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('sectionService.js:updateSection', response);
    return response;
}


async function getSectionById(idSection: string): Promise<Response> {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.Section).doc(idSection).get();
        if (query.exists) {
            response.data = { ...query.data(), id: query.id };
            response.status = true;
        }
    } catch (error) {
        response.error = error;
    }
    return response;

}



export default {
    listSections,
    createSection,
    updateSection,
    getSectionById
}