import Response from "../containers/Administration/utils/Response";
import { logError, nowUnix, sortList } from '../helpers/utility';
import { NotifUserModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function getInfo(userId: String, notifId): Promise<Response<NotifUserModel>>
{
    let response: Response<NotifUserModel> = new Response(false);
    try
    {
        let query = db.collection(Table.User)
            .doc(userId)
            .collection(Table.$User.Notification)
            .doc(notifId);

        const doc = await query.get();
        if(doc.exists)
        {
            let item = doc.data();
            item.id = doc.id;
            response.data = item;
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('notifUserService.js:getInfo:' + userId, response);
    return response;
}

async function existsUnread(userId: String, typeNotif: String, hash: String): Promise<Response<NotifUserModel>>
{
    let response: Response<NotifUserModel> = new Response(false);
    try
    {
        let query = db.collection(Table.User)
            .doc(userId)
            .collection(Table.$User.Notification)
            .where(Table.$User.$Notification.type, '==', typeNotif)
            .where(Table.$User.$Notification.hash, '==', hash)
            .where(Table.$User.$Notification.status, '==', true);

        const data = await query.get();
        let items: NotifUserModel[] = data.docs.map(doc =>
        {
            let item = doc.data();
            item.id = doc.id;
            return item;
        });
        sortList(items, 'createdAt', true, false);
        if (items.length > 0 && items[0].readed === false)
        {
            response.data = items[0];
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('notifUserService.js:existsUnread:' + userId, response);
    return response;
}

async function create(userId: String, itemNotif: NotifUserModel): Promise<Response<NotifUserModel>>
{
    let response: Response<NotifUserModel> = new Response(false);
    try
    {
        let data = {
            ...itemNotif
        };
        delete data['id'];
        const insert = await db.collection(Table.User).doc(userId).collection(Table.$User.Notification).add(data);
        itemNotif.id = insert.id;

        response.data = itemNotif;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('notifUserService.js:create', response);
    return response;
}

async function markAsRead(userId: String, notifUserId: String): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let data = {
            readed: true,
            readedAt: nowUnix()
        };
        await db.collection(Table.User)
            .doc(userId).collection(Table.$User.Notification)
            .doc(notifUserId).update(data);
        response.data = data;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError(`notifUserService.js:markAsRead:${userId}:${notifUserId}`, response);
    return response;
}

async function remove(userId: String, notifUserId: String): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let data = {
            status: false
        };
        await db.collection(Table.User)
            .doc(userId).collection(Table.$User.Notification)
            .doc(notifUserId).update(data);
        response.data = data;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError(`notifUserService.js:remove:${userId}:${notifUserId}`, response);
    return response;
}

export default {
    getInfo,
    existsUnread,
    create,
    markAsRead,
    remove
}