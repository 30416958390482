import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, convertToUnix, logError, sortList, isNullOrEmpty } from '../helpers/utility';
import { upload, removeFile } from '../containers/Administration/utils/UtilsUpload';
import { PathProps } from '../containers/Administration/utils/Global';
import { BannerSliderModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function list(onlyActives = false): Promise<Response<BannerSliderModel[]>>
{
    let response: Response<BannerSliderModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.Slide);
        let query = onlyActives ? ref.where(Table.$Slide.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: BannerSliderModel = element.data();
            item.id = element.id;
            item.createdAt = convertToUnix(item.createdAt);
            item.imageMobileBackup = item.imageMobile;
            item.imageWebBackup = item.imageWeb;
            return item;
        });
        sortList(response.data, 'order', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function getUltimate(): Promise<Response<BannerSliderModel>>
{
    let response: Response<BannerSliderModel> = new Response(false);
    response.data = null;
    try
    {
        let data = await db.collection(Table.Slide).orderBy(Table.$Slide.order, 'desc').limit(1).get();
        if (!data.empty)
        {
            let doc = data.docs[0];
            response.data = doc.data()
            response.data.id = doc.id;
            response.data.createdAt = convertToUnix(response.data.createdAt);
            response.data.imageMobileBackup = response.data.imageMobile;
            response.data.imageWebBackup = response.data.imageWeb;
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('bannerSliderService:getUltimate', response);
    return response;
}

async function create(item: BannerSliderModel, pathS3: PathProps, idUserCurrent = ''): Promise<Response<BannerSliderModel>>
{
    let response: Response<BannerSliderModel> = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        delete data['fileMobile'];
        delete data['fileWeb'];
        delete data['imageMobileBackup'];
        delete data['imageWebBackup'];
        const insert = await db.collection(Table.Slide).add(data);
        item.id = insert.id;

        // Imagen
        await uploadFileS3(item.fileMobile, pathS3);
        await uploadFileS3(item.fileWeb, pathS3);

        response.data = item;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('bannerSliderService.js:create', response);
    return response;
}

async function update(item: BannerSliderModel, pathS3: PathProps, idUserCurrent = ''): Promise<Response<BannerSliderModel>>
{
    let response: Response<BannerSliderModel> = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        delete data['fileMobile'];
        delete data['fileWeb'];
        delete data['imageMobileBackup'];
        delete data['imageWebBackup'];
        await db.collection(Table.Slide).doc(item.id).update(data);

        // Imagen
        if (!!pathS3)
        {
            await uploadFileS3(item.fileMobile, pathS3);
            if (!!item.fileMobile || isNullOrEmpty(item.imageMobile))
                await removeFileS3(item.imageMobileBackup, pathS3);

            await uploadFileS3(item.fileWeb, pathS3);
            if (!!item.fileWeb || isNullOrEmpty(item.imageWeb))
                await removeFileS3(item.imageWebBackup, pathS3);
        }
        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('bannerSliderService.js:update', response);
    return response;
}

async function updateOrder(items: BannerSliderModel[]): Promise<Response<Boolean>>
{
    let response: Response<Boolean> = new Response(false);
    try
    {
        let batch = db.batch();
        items.forEach(elem =>
        {
            let refDoc = db.collection(Table.Slide).doc(elem.id);
            batch.update(refDoc, Table.$Slide.order, elem.order);
        });
        await batch.commit();

        response.data = true;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('bannerSliderService.js:updateOrder', response);
    return response;
}

async function uploadFileS3(file: File, pathS3: PathProps)
{
    if (!!file)
    {
        const pathS3Folder = `${pathS3.path_assets}/bannerSlider`;
        const response = await upload(file, pathS3Folder);

        const type = file.type.split("/")[1];
        return response.key.includes(type);
    }
    return true;
}

async function removeFileS3(urlImage, pathS3: PathProps)
{
    if (!isNullOrEmpty(urlImage) && urlImage.includes('https'))
        return await removeFile(urlImage.replace(pathS3.path_cloudfront + '/', ''));

    return true;
}

export default {
    list,
    getUltimate,
    create,
    update,
    updateOrder
}