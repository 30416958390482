const actions = {

    ADD_BREADCRUMB: 'ADD_BREADCRUMB',
    addBreadcrumb: (breadcrumb) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.ADD_BREADCRUMB,
                payload: breadcrumb,
            });
        }
    },

    REMOVE_BREADCRUMB: 'REMOVE_BREADCRUMB',
    removeBreadcrumb: (url) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.REMOVE_BREADCRUMB,
                payload: url,
            });
        }
    },

}

export default actions;