export default class GlobalPayments
{
    available: Number;
    pending: Number;
    count: Number;
    refund: Number;
    ASPIT: Number;

    constructor()
    {
        this.available = 0;
        this.pending = 0;
        this.count = 0;
        this.refund = 0;
        this.ASPIT = 0;
    }
}