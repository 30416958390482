import update from 'immutability-helper';
import actions from './actions';

const initState = {
    ListQuiz: [],
    currentPageQuizs: 1,

    catalogsLoaded: Boolean,
    currency: [],
    categorie: [],
    level: [],

    quiz: null,
    action: null,
    templates: [],
    template: {},
    idTemplate: null,


    sections: [],
    listRemoveSections: [],
}

export default function Quizes(state = initState, action)
{
    switch (action.type)
    {
        case actions.SET_LIST_QUIZ:
            return {
                ...state,
                ListQuiz: action.payload
            }
        case actions.SET_CURRENT_PAGE_QUIZ:
            return {
                ...state,
                action: actions.SET_CURRENT_PAGE_QUIZ,
                currentPageQuizs: action.payload
            }
        case actions.SET_QUIZ_CURRENT:
            return {
                ...state,
                action: actions.SET_QUIZ_CURRENT,
                quiz: action.payload
            }
        case actions.DELETE_QUIZ:
        case actions.DELETE_ITEM_LIST_SEQUENCES:
            return {
                ...state,
                ListQuiz: state.ListQuiz.filter((val, i) => i !== action.payload.indice)
            }
        case actions.CHANGE_STATUS:
            return update(state, {
                ListQuiz: {
                    [action.payload.indice]: {
                        status: { $set: action.payload.status },
                    }
                }
            });
        case actions.SET_LOADING_STATUS:
            return update(state, {
                ListQuiz: {
                    [action.payload.indice]: {
                        loading: { $set: action.payload.loading },
                    }
                }
            });


        case actions.SET_CATALOGS:
            return {
                ...state,
                catalogsLoaded: true,
                categorie: action.payload === null ? state.categorie : action.payload.categorie,
                currency: action.payload === null ? state.currency : action.payload.currency,
                level: action.payload === null ? state.level : action.payload.level
            }


        case actions.LIST_TEMPLATE_QUIZ:
            return {
                ...state,
                templates: [...action.templates]
            }

        case actions.CREATE_TEMPLATE_QUIZ:
            return {
                ...state,
                templates: [...state.templates, action.template]
            }
        case actions.SET_ID_TEMPLATE_QUIZ:
            return {
                ...state,
                idTemplate: action.idTemplate,
                template: action.template
            }

        case actions.UPDATE_ITEM_TEMPLATE_QUIZ:
            return update(state, {
                templates: {
                    [action.payload.indice]: { $set: action.payload.item },
                }
            });



        case actions.RESET_LIST_SECTIONS_CONFIGURATIONS:
            return {
                ...state,
                sections: []
            }

        case actions.CREATE_SECTION_CONFIGURATION:
            return {
                ...state,
                sections: [...state.sections, action.section]
            }

        case actions.LIST_SECTIONS_CONFIGURATIONS:
            return {
                ...state,
                sections: [...action.sections]
            }

        case actions.UPDATE_ITEM_SECTION_LIST:
            return update(state, {
                sections: {
                    [action.payload.indice]: {
                        sectionConfiguration: {
                            $set: action.payload.item.sectionConfiguration
                        }
                    },
                }
            });


        case actions.UPDATE_ID_SECTION:
            return update(state, {
                sections: {
                    [action.payload.indice]: {
                        id: { $set: action.payload.idTemplateSectionConfig },
                        sectionConfiguration: {
                            id: { $set: action.payload.idSectionConfig },
                        }
                    }
                }
            })
        case actions.SET_LOADER_SECTION:
            return update(state, {
                sections: {
                    [action.payload.indice]: {
                        loading: { $set: action.payload.loading },
                        statuscheck: { $set: action.payload.statuscheck },
                    }
                }
            })


        case actions.REMOVE_ITEM_SECTION_LIST:
            return {
                ...state,
                sections: state.sections.filter(x => x.id !== action.payload),
            }

        case actions.ADD_ITEM_REMOVE_LIST:
            return {
                ...state,
                listRemoveSections: [...state.listRemoveSections, action.payload]
            }
        case actions.RESET_REMOVE_LIST:
            return {
                ...state,
                listRemoveSections: []
            }

        default:
            return state;
    }

}