
const actions = {

    SET_PROBLEMS_REPORT: 'SET_PROBLEMS_REPORT',
    setProblemsReport: problems => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_PROBLEMS_REPORT,
                payload: problems
            })
        }
    },

    SET_INBOX_COMMENTS: 'SET_INBOX_COMMENTS',
    setInboxComments: comments => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_INBOX_COMMENTS,
                payload: comments
            })
        }
    },

    SET_IBOX_LIST_CONTENT: 'SET_IBOX_LIST_CONTENT',
    setInboxContentList: list => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_IBOX_LIST_CONTENT,
                payload: list
            })
        }
    },

    SET_IBOX_TYPE: 'SET_IBOX_TYPE',
    setInboxType: type => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_IBOX_TYPE,
                payload: type
            })
        }
    },

    UPDATED_MESSAGE_ROBLEMS_REPORT: 'UPDATED_MESSAGE_ROBLEMS_REPORT',
    addMessageReportProblem: data => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.UPDATED_MESSAGE_ROBLEMS_REPORT,
                payload: data
            })
        }
    },

    UPDATED_REPLY_ROBLEMS_REPORT: 'UPDATED_REPLY_ROBLEMS_REPORT',
    updatedStatusReply: data => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.UPDATED_REPLY_ROBLEMS_REPORT,
                payload: data
            })
        }
    },

    UPDATED_VALIDATED_ROBLEMS_REPORT: 'UPDATED_VALIDATED_ROBLEMS_REPORT',
    updatedStatusValidated: data => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.UPDATED_VALIDATED_ROBLEMS_REPORT,
                payload: data
            })
        }
    },


    UPDATED_VALIDATED_INBOX_COMMENTS : 'UPDATED_VALIDATED_INBOX_COMMENTS',
    updatedCommentsStatusValidated: data => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.UPDATED_VALIDATED_INBOX_COMMENTS,
                payload: data
            })
        }
    },


    UPDATED_REPLY_INBOX_COMMENTS : 'UPDATED_REPLY_INBOX_COMMENTS',
    updatedCommentsStatusReply: data => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.UPDATED_REPLY_INBOX_COMMENTS,
                payload: data
            })
        }
    },

    REMOVE_ITEM_INBOX_LIST_CONTENT: 'REMOVE_ITEM_INBOX_LIST_CONTENT',
    removeItemInboxListContent: id => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.REMOVE_ITEM_INBOX_LIST_CONTENT,
                payload: id
            })
        }
    },
}

export default actions;