
import Response from "../containers/Administration/utils/Response"
import { Table } from "../constants";
import { isNull } from "../helpers/utility";
import { ProblemReport } from "../models";

import { firestore } from "firebase";
let db = firestore();

async function listProblemReports(idOwner, limit, currentFilter, lastDoc: ProblemReport = null): Promise<Response<ProblemReport[]>>
{
    let response: Response<ProblemReport[]> = new Response(false);
    try
    {
        let query = await db.collection(Table.ProblemReport)
            .where(Table.$ProblemReport.problemReportUserOwnerId, '==', idOwner);
        
        if(currentFilter.problemTypeId !== 'all')
            query = query.where(Table.$ProblemReport.problemReportProblemTypeId, '==', currentFilter.problemTypeId);

        if (currentFilter.status !== 'all')
        {
            if (currentFilter.status === 'reply')
                query = query.where(Table.$ProblemReport.reply, '==', true);

            if (currentFilter.status === 'validated')
                query = query.where(Table.$ProblemReport.validated, '==', true);
        }
        
        query = query.orderBy(Table.$ProblemReport.createdAt, "desc");

        if (!!lastDoc)
            query = query.startAfter(lastDoc.createdAt);

        const data = await query.limit(limit).get();
        response.data = data.docs.map(e =>
        {
            let data = e.data();
            //data.name = isNull(data.name) ? 'Sin nombre' : data.name;
            data.reviews = isNull(data.reviews) ? [] : data.reviews;
            data.reviews = data.reviews.map(elem => 
            {
                elem.name = isNull(elem.name) ? 'Sin nombre' : elem.name;
                return elem;
            });

            if (data.status === true && !isNull(data.name))
                return {
                    ...data,
                    id: e.id,
                    reply: isNull(data.reply) ? false : data.reply,
                    validated: isNull(data.validated) ? false : data.validated,
                    reviews: isNull(data.reviews) ? [] : data.reviews,
                }

            return undefined;
        }).filter(e => e !== undefined);

        response.status = true;
    } 
    catch (error)
    {
        response.error = error;
        console.log('questionReportService.js:listProblemReports', response)
    }
    return response;
}

async function getProblemRpt(quizHistoryId, questionHistoryId): Promise<Response<ProblemReport>>
{
    let response: Response<ProblemReport> = new Response(false);
    try
    {
        let query = await db.collection(Table.ProblemReport)
            .where(Table.$ProblemReport.quizHistoryId, '==', quizHistoryId)
            .where(Table.$ProblemReport.problemReportQuestionHistoryId, '==', questionHistoryId);

        const result = await query.get();
        if(result.docs.length > 0)
        {
            let data = result.docs[0].data();
            let ID = result.docs[0].id;
            data.reviews = isNull(data.reviews) ? [] : data.reviews;
            data.reviews = data.reviews.map(elem => 
            {
                elem.name = isNull(elem.name) ? 'Sin nombre' : elem.name;
                return elem;
            });

            if (data.status === true && !isNull(data.name))
            {
                response.data = {
                    ...data,
                    id: ID,
                    reply: isNull(data.reply) ? false : data.reply,
                    validated: isNull(data.validated) ? false : data.validated,
                    reviews: isNull(data.reviews) ? [] : data.reviews,
                };
                response.status = true;
            }
        }
    } 
    catch (error)
    {
        response.error = error;
        console.log('questionReportService.js:getProblemRpt', response)
    }
    return response;
}

async function getQuizHistory(idQuizHistory: string): Promise<Response> {
    let response = new Response();
    try {
        let query = await db.collection('QuizHistory').doc(idQuizHistory).get();
        response.data = { ...query.data(), id: query.id };
        response.status = true;
    } catch (error) {
        response.error = error;
    }
    return response;
}


async function getQuestionHistory(questionHistoryQuizHistoryId: string): Promise<Response> {

    let response = new Response(false);
    try {
        let query = await db.collection('QuestionHistory').doc(questionHistoryQuizHistoryId).get()
        if (query.exists) {
            response.data = { ...query.data(), id: query.id };
            response.status = true;
        }
    } catch (error) {
        response.error = error;
    }

    return response;
}


async function updateProblemReport(id: string, values: null): Promise<Response> {
    let response = new Response(false);
    try {
        await db.collection(Table.ProblemReport).doc(id).update(values);
        response.status = true;
    } catch (err) {
        console.log(err)
        response.error = err;

    }
    return response;
}



export default {
    listProblemReports,
    getProblemRpt,
    getQuestionHistory,
    updateProblemReport,
    getQuizHistory
}