import { CatalogsModel } from './';

export default class Section
{
    id: String;
    key: String;
    name: String;
    description: String;
    instructions: String;
    status: Boolean;
    sectionBankId: String;

    subsections: CatalogsModel[];

    constructor()
    {
        this.subsections = [];
        this.status = true;
    }
}