import { Enum } from '../constants';

export class QuizStatus {
    status: String;
    refundMessages: [];
    presentation: Boolean;
    evaluation: Boolean;
    price: Boolean;

    constructor() {
        this.presentation = false;
        this.evaluation = false;
        this.price = false;
        this.status = Enum.typeStatusQuiz.IN_PROCESS;
    }
}