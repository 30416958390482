import Response from "../containers/Administration/utils/Response";

import { firestore } from 'firebase';
import { Table } from "../constants";
import { ProblemType } from "../models/ProblemType";
import { fieldsAudit } from "../helpers/utility";

const db = firestore();

async function getProblemTypes(): Promise<Response> {
    let response = new Response(false);

    try {

        let query = await db.collection(Table.ProblemType).where(Table.$ProblemType.status, '==', true).get();
        if (!query.empty) {
            response.data = query.docs.map(e => { return { ...e.data(), id: e.id } });
            response.status = true;
        }
    } catch (err) {
        response.error = err;
    }
    return response;
}

async function createTypeProblem(problemType: ProblemType, currentUser: string): Promise<Response> {

    let response = new Response(false);

    try {

        let data = {
            ...problemType,
            ...fieldsAudit(currentUser, 'CREATE')
        }

        let query = await db.collection(Table.ProblemType).add({ ...data });
        response.data = { ...data, id: query.id }
        response.status = true;
    } catch (err) {
        console.log(err)
        response.err = err;
    }
    return response;

}


async function updateTypeProblem(problemType: ProblemType, currentUser: string) {

    let response = new Response(false);

    try {
        
        let id: string = problemType.id;
        let data = {
            ...problemType,
            ...fieldsAudit(currentUser, 'UPDATE')
        }

        delete data['id'];

        db.collection(Table.ProblemType).doc(id).update({ ...data });
        response.data = { ...data, id: id }
        response.status = true;
    } catch (err) {
        console.log(err)
        response.err = err;
    }
    return response;


}

export default {
    getProblemTypes, createTypeProblem, updateTypeProblem,
}