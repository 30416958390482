import Response from "../containers/Administration/utils/Response";
import { logError, isNullOrEmpty, sortList, isNull, fieldsAudit } from '../helpers/utility';
import { upload, removeFile } from '../containers/Administration/utils/UtilsUpload';
import { QuestionGroupModel, LessonSequenceModel } from '../models';
import { PathProps } from '../containers/Administration/utils/Global';
import { questionService } from './';
import { Table } from "../constants";

import { firestore } from "firebase";
const db = firestore();

function objectQuestionGroup(doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>)
{
    let data = doc.data();
    data.id = doc.id;

    let item: QuestionGroupModel = new QuestionGroupModel();
    item.id = data.id;
    item.questionGroupUserId = data.questionGroupUserId;
    item.sectionId = data.sectionId;
    item.name = data.name;
    item.instructions = data.instructions;
    item.groupAudio = data.groupAudio;
    item.groupAudioBackup = data.groupAudio;
    item.progress = isNull(data.progress) ? 100 : data.progress;
    item.status = data.status;

    item.createdAt = data.createdAt;
    return item;
}

async function list(idUserOwner: String = null, onlyActives = false, sectionId = null): Promise<Response<QuestionGroupModel[]>> {
    let response: Response<QuestionGroupModel[]> = new Response(false);
    try {

        let ref = db.collection('QuestionGroup');

        if (!!idUserOwner) ref = ref.where('questionGroupUserId', '==', idUserOwner);
        if (!!idUserOwner) ref = ref.where('sectionId', '==', sectionId);
        if (onlyActives) ref = ref.where('status', '==', onlyActives);

        let query = await ref.get();
        response.data = query.docs.map(doc => { return objectQuestionGroup(doc); });
        response.status = true;

        sortList(response.data, 'createdAt', true);
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:list', response);
    return response;
}

async function getInfoById(idQuestionGroup): Promise<Response<QuestionGroupModel>> {
    let response: Response<QuestionGroupModel> = new Response(false);
    try 
    {
        let docQGroup = await db.collection('QuestionGroup').doc(idQuestionGroup).get();
        if (docQGroup.exists) 
        {
            let item: QuestionGroupModel = objectQuestionGroup(docQGroup);

            let queryQuestions = await db.collection('Question').where('questionQuestionGroupId', '==', idQuestionGroup).get();
            if (!queryQuestions.empty) 
            {
                let questions = await Promise.all(queryQuestions.docs.map(async doc =>
                {
                    let response = await questionService.getQuestionForId(doc.id)
                    if (response.status)
                        return response.data;
                }));

                item.questions = questions;
                sortList(item.questions, 'order', true);
            }

            // Lectura
            let res_lesson = await listLessonSeq(idQuestionGroup);
            if (res_lesson.status)
                item.lesson = res_lesson.data;

            response.data = item;
            response.status = true;
        }
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:getInfoById', response);
    return response;
}

async function listLessonSeq(idQuestionGroup, onlyActives = false): Promise<Response<LessonSequenceModel[]>> {
    let response: Response<LessonSequenceModel[]> = new Response(false);
    try {

        let items = [];

        let ref = db.collection('LessonSequence').where('lessonSequenceQuestionGroupId', '==', idQuestionGroup)
        if (onlyActives === true) ref = ref.where('status', '==', onlyActives)

        let query = await ref.get();
        if (!query.empty) {
            items = query.docs.map(e => { return { ...e.data(), id: e.id } })
        }

        response.data = items.map(element => {
            let item: LessonSequenceModel = new LessonSequenceModel();
            item.id = element.id;
            item.idQuestionGroup = idQuestionGroup;
            item.lessonText = element.lessonText;
            item.lessonImage = element.lessonImage;
            item.lessonImageBackup = element.lessonImage;
            item.order = element.order;
            item.status = element.status;
            return item;
        });

        sortList(response.data, 'order', true);
        response.status = true;
    }
    catch (e) {
        response.error_data = e;
        console.log('questionGroupService.js:listLessonSeq', response)
    }
    return response;
}

async function AWSCreate(item: QuestionGroupModel, pathS3: PathProps, sub): Promise<Response> {
    let response = new Response(false);
    try 
    {
        let obj_input = {
            ...item,
            ...fieldsAudit(sub, 'CREATE')
        }
        delete obj_input['id'];
        delete obj_input['questions'];
        delete obj_input['lesson'];
        delete obj_input['fileAudio'];
        delete obj_input['groupAudioBackup'];
        
        await db.collection('QuestionGroup').doc(item.id).set(obj_input);
        let data = { ...obj_input, id: item.id };

        // Audio grupo
        await uploadFileS3(item.fileAudio, pathS3);

        // Preguntas
        await Promise.all(item.questions.map(async (elem) => {
            return await linkedQuestion(item.id, elem, sub);
        }));

        // Lectura
        await Promise.all(item.lesson.map(async (elem) => {
            elem.id = elem.id.replace('NEW_', '');
            await uploadFileS3(elem.fileImage, pathS3);
            return await AWSCreateLesson(item.id, elem, sub);
        }));

        response.data = data;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:AWSCreate', response);
    return response;
}

async function linkedQuestion(idQuestionGroup: String, question: Object, sub) {
    let response = new Response(false);
    try {

        let input = {
            questionBankId: question.questionBankId,
            questionSectionId: question.questionSectionId,
            questionQuestionGroupId: idQuestionGroup,
            order: question.order,
            updatedUser: sub
        }
        await db.collection('Question').doc(question.id).update(input);
        response.data = { ...input, id: question.id };
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:linkedQuestion', response);
    return response;
}

async function linkedQuestionFullInfo(idQuestionGroup: String, question: Object, sub) {
    let response = new Response(false);
    try {

        const input = { 
            questionQuestionGroupId: idQuestionGroup,
            order: question.order,
            updatedUser: sub
        };
        await db.collection(Table.QuestionFullInfo).doc(question.id).update(input);
        response.data = { ...input, id: question.id };
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:linkedQuestionFullInfo', response);
    return response;
}

async function unlinkedQuestion(question: Object, sub) {
    let response = new Response(false);
    try {

        let input = {
            questionBankId: question.questionBankId,
            questionSectionId: question.questionSectionId,
            questionQuestionGroupId: null,
            order: null,
            updatedUser: sub
        }

        await db.collection('Question').doc(question.id).update(input);
        response.data = { ...input, id: question.id };
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:unlinkedQuestion', response);
    return response;
}

async function AWSCreateLesson(idQuestionGroup: String, lesson: LessonSequenceModel, sub) {
    let response = new Response(false);
    try {

        let obj_input = {
            lessonText: isNullOrEmpty(lesson.lessonText) ? null : lesson.lessonText,
            lessonImage: isNullOrEmpty(lesson.lessonImage) ? null : lesson.lessonImage,
            order: lesson.order,
            status: lesson.status,
            lessonSequenceQuestionGroupId: idQuestionGroup,
            createdUser: sub
        }

        await db.collection('LessonSequence').doc(lesson.id).set(obj_input);
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:AWSCreateLesson', response);
    return response;
}

async function AWSUpdate(item: QuestionGroupModel, itemRecycleBin: QuestionGroupModel, pathS3: PathProps, sub): Promise<Response> {
    let response = new Response(false);
    try {

        let obj_input = {
            ...item,
            ...fieldsAudit(sub, 'UPDATE')
        }
        delete obj_input['id'];
        delete obj_input['questions'];
        delete obj_input['lesson'];
        delete obj_input['fileAudio'];
        delete obj_input['groupAudioBackup'];

        await db.collection('QuestionGroup').doc(item.id).update(obj_input);
        let data = item;

        // Audio grupo
        await uploadFileS3(item.fileAudio, pathS3);
        if (!!item.fileAudio || isNullOrEmpty(item.groupAudio))
            await removeFileS3(item.groupAudioBackup, pathS3);

        // Preguntas
        await Promise.all(item.questions.map(async (elem) => {
            return await linkedQuestion(item.id, elem, sub);
        }));
        await Promise.all(item.questions.map(async elem => await linkedQuestionFullInfo(item.id, elem, sub)));
        if (!!itemRecycleBin)
        {
            await Promise.all(itemRecycleBin.questions.map(async (elem) =>
            {
                return await unlinkedQuestion(elem, sub);
            }));
            await Promise.all(itemRecycleBin.questions.map(async (elem) => await linkedQuestionFullInfo(null, elem, sub)));
        }

        // Lectura
        await Promise.all(item.lesson.map(async (elem) => {
            await uploadFileS3(elem.fileImage, pathS3);

            let prefix = 'NEW_';
            if (elem.id.includes(prefix)) {
                elem.id = elem.id.replace(prefix, '');
                return await AWSCreateLesson(item.id, elem, sub);
            }
            else 
            {
                if (!!elem.fileImage || isNullOrEmpty(elem.lessonImage))
                    await removeFileS3(elem.lessonImageBackup, pathS3);

                return await AWSUpdateLesson(elem, sub);
            }
        }));
        if (!!itemRecycleBin)
        {
            await Promise.all(itemRecycleBin.lesson.map(async (elem) =>
            {
                await removeFileS3(elem.lessonImageBackup, pathS3);
                return await AWSDeleteLesson(elem);
            }));
        }

        response.data = data;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:AWSUpdate', response);
    return response;
}

async function AWSUpdateLesson(lesson: LessonSequenceModel, sub) {
    let response = new Response(false);
    try {
        let input = {
            lessonText: isNullOrEmpty(lesson.lessonText) ? null : lesson.lessonText,
            lessonImage: isNullOrEmpty(lesson.lessonImage) ? null : lesson.lessonImage,
            order: lesson.order,
            status: lesson.status,
            updatedUser: sub
        }
        await db.collection('LessonSequence').doc(lesson.id).update(input);
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:AWSUpdateLesson', response);
    return response;
}

async function AWSDeleteLesson(lesson: LessonSequenceModel) {
    let response = new Response(false);
    try {
        let prefix = 'NEW_';
        let id = lesson.id;
        if (!id.includes(prefix))
            await db.collection('LessonSequence').doc(id).delete();
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('questionGroupService:AWSDeleteLesson', response);
    return response;
}

async function uploadFileS3(file: File, pathS3: PathProps) {
    if (!!file) {
        const pathS3Folder = `${pathS3.path_assets}/questionGroup`;
        const response = await upload(file, pathS3Folder);

        const type = file.type.split("/")[1];
        return response.key.includes(type);
    }
    return true;
}

async function removeFileS3(urlImage: any, pathS3: PathProps) {
    if (!isNullOrEmpty(urlImage) && urlImage.includes('https'))
        return await removeFile(urlImage.replace(pathS3.path_cloudfront + '/', ''));

    return true;
}

export default {
    list,
    getInfoById,
    AWSCreate,
    AWSUpdate
}