import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, logError } from '../helpers/utility';
import { CatalogsModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function list(onlyActives = false): Promise<Response<CatalogsModel[]>>
{
    let response: Response<CatalogsModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.UserType);
        let query = onlyActives ? ref.where(Table.$UserType.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: CatalogsModel = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('userTypeService:list', response);
    return response;
}

async function create(item: CatalogsModel, idUserCurrent = ''): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.UserType).add(data);
        item.id = insert.id;

        response.data = item;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('userTypeService:create', response);
    return response;
}

async function update(item: CatalogsModel, idUserCurrent = ''): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.UserType).doc(item.id).update(data);

        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userTypeService:update', response);
    return response;
}

export default {
    list,
    create,
    update
}