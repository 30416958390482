import Response from "../containers/Administration/utils/Response";
import { logError, fieldsAudit, sortList } from '../helpers/utility';
import { Table } from "../constants";
import { ConciliationModel } from "../models";
import { firestore } from 'firebase';

const db = firestore();

async function listActives(idOwner): Promise<Response<ConciliationModel[]>>
{
    let response: Response<ConciliationModel[]> = new Response(false);
    try
    {
        let query = db.collection(Table.Conciliation)
            .where(Table.$Conciliation.userOwnerId, '==', idOwner)
            .where(Table.$Conciliation.status, '==', true);

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: ConciliationModel = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('conciliationService.js:listActives', response);
    return response;
}

async function create(prmItem: ConciliationModel, idUserCurrent = ''): Promise<Response<ConciliationModel>>
{
    let response: Response<ConciliationModel> = new Response(false);
    try
    {
        let data = {
            ...prmItem,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.Conciliation).add(data);
        prmItem.id = insert.id;
        prmItem.createdAt = data.createdAt;
        prmItem.createdUser = data.createdUser;

        response.data = prmItem;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('conciliationService.js:create', response);
    return response;
}

async function update(prmItem: ConciliationModel, idUserCurrent = ''): Promise<Response<ConciliationModel>>
{
    let response: Response<ConciliationModel> = new Response(false);
    try
    {
        let data = {
            ...prmItem,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.Conciliation).doc(prmItem.id).update(data);

        response.data = prmItem;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('conciliationService.js:update', response);
    return response;
}

export default {
    listActives,
    create,
    update
}