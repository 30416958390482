import Auth from './auth/reducer';
import App from './app/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import Reports from './report/reducer';

import Inbox from './Inbox/reducers';

import Questions from './question/reducer';
import QGroup from './questionGroup/reducer';
import Quizes from './quiz/reducer';

import Exam from './currentExam/reducer';
import Question from './currentQuestion/reducer';

import FINGlobal from './finances/reducer';
import RdxSessions from './sessions/reducer';

import RdxBreadcrumb from './breadcrumb/reducer';

export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,

  Reports,
  FINGlobal,
  RdxSessions,
  RdxBreadcrumb,

  Questions,
  QGroup,
  Quizes,

  Exam,
  Question,

  Inbox

};
