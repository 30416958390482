export default class Response<P> {

    status: Boolean;
    error: String;
    error_data: any;
    data: P;
    obj_data: Array;
    nextToken: String;

    constructor(initStatus = true)
    {
        this.status = initStatus;
    }


}