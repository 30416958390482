import Response from "../containers/Administration/utils/Response";
import { logError, fieldsAudit, isNullOrEmpty, sortList } from '../helpers/utility';
import { PriceRangeModel } from '../models';
import { firestore } from 'firebase';
import { Table } from '../constants';

const db = firestore();

async function list(onlyActives = false) {
    let response: Response<PriceRangeModel[]> = new Response(false);
    try {
        let ref = db.collection(Table.PriceRange);
        let query = onlyActives ? ref.where(Table.$PriceRange.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function create(item: PriceRangeModel, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        delete data['id'];

        if (isNullOrEmpty(item.id))
        {
            const insert = await db.collection(Table.PriceRange).add(data);
            item.id = insert.id;
        }
        else
            await db.collection(Table.PriceRange).doc(item.id).set(data);

        response.data = item;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('priceRangeService:create', response);
    return response;
}

async function update(item: PriceRangeModel, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.PriceRange).doc(item.id).update(data);

        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('priceRangeService:update', response);
    return response;
}

export default {
    list,
    create,
    update
}