import React from 'react';
import { Map } from 'immutable';
import Response from '../containers/Administration/utils/Response';
import { UserModel, RoleModel, RoleModuleModel } from '../models';
import { Enum, PollQuestions } from '../constants';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import localization from 'moment/locale/es-us';
import 'moment/locale/es';

moment().locale("es", localization);
momentDurationFormatSetup(moment);

export function clearToken() { localStorage.removeItem('id_token'); }

export function getToken()
{
	try
	{
		const idToken = localStorage.getItem('id_token');
		return new Map({ idToken });
	}
	catch (err)
	{
		clearToken();
		return new Map();
	}
}

export const Cookie = {
	delete: (key: String) =>
	{
		if (!!document)
			document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; max-age=0`;
	},
	create: (key: string, value: String) =>
	{
		if (!!document)
		{
			let DOMAIN = process.env.REACT_APP_DOMAIN;
			document.cookie = `${key}=${value}; domain=${DOMAIN}`;
		}
	},
	getValue: (key: String) =>
	{
		if (!!document)
		{
			let exists = document.cookie.split(';').some((item) =>
			{
				return item.trim().indexOf(`${key}=`) >= 0;
			});
			if (exists)
			{
				const cookieValue = document.cookie.split('; ')
					.find(row => row.startsWith(key))
					.split('=')[1];
				if (!!cookieValue && cookieValue.trim().length > 0)
					return cookieValue;
			}
		}
		return null;
	}
}

export const Cache = {
	get: (key: String) =>
	{
		try
		{
			if (!!localStorage)
			{
				let value = localStorage.getItem(key);
				return JSON.parse(value);
			}
		}
		catch (error) { console.log('Cache.get', error); }
		return null;
	},
	set: (key: String, item) =>
	{
		try
		{
			if (!!localStorage)
				localStorage.setItem(key, JSON.stringify(item));
		}
		catch (error) { console.log('Cache.set', error); }
		return null;
	},
	remove: (key: String) =>
	{
		try
		{
			if (!!localStorage)
				localStorage.removeItem(key);
		}
		catch (error) { console.log('Cache.remove', error); }
		return null;
	}
};

export const Log = {
	isProduction: process.env.REACT_APP_FIREBASE_PROJECT_ID === 'appmision-9703f',
	info: (title, data) => 
	{
		!Log.isProduction && console.log(`%cINFO |%c${title}`
			, "display: inline-block ; background-image: url( 'https://cdn0.iconfinder.com/data/icons/brown-bear-emoticon-filled/64/cute_bear_face_avatar-02-512.png' ) ; " +
			"background-size: 35px 35px; padding: 15px 10px 10px 40px ; " +
			"background-repeat: no-repeat; background-position: left; " +
			"color: #1890ff; font-weight: bold; font-family: monospace; font-size: 12px; line-height: normal; "
			, "font-weight: bold; font-size: 12px;", !!data ? data : '');
		
		let separator = '';
		for (let index = 0; index < 99; index++) separator += ' ';	
		!Log.isProduction && console.log(`%c${separator}`, "border-top: #1890ff80 solid 1px;");
	},
	error: (title, data) => 
	{
		console.error(`%cERROR |%c${title}`
			, "display: inline-block ; background-image: url( 'https://cdn0.iconfinder.com/data/icons/brown-bear-emoticon-filled/64/cute_bear_face_avatar-18-512.png' ) ; " +
			"background-size: 35px 35px; padding: 15px 10px 10px 40px ; " +
			"background-repeat: no-repeat; background-position: left; " +
			"color: #ff0000; font-weight: bold; font-family: monospace; font-size: 12px; line-height: normal; "
			, "font-weight: bold; font-size: 12px;", !!data ? data : '');
		console.trace();
	},
	warn: (title, data) => 
	{
		!Log.isProduction && console.warn(`%cWARN |%c${title}`
			, "display: inline-block ; background-image: url( 'https://cdn0.iconfinder.com/data/icons/brown-bear-emoticon-filled/64/cute_bear_face_avatar-23-512.png' ) ; " +
			"background-size: 35px 35px; padding: 15px 10px 10px 40px ; " +
			"background-repeat: no-repeat; background-position: left; " +
			"color: #c69f11; font-weight: bold; font-family: monospace; font-size: 12px; line-height: normal; "
			, "font-weight: bold; font-size: 12px;", !!data ? data : '');
	}
}

export function timeDifference(givenTime)
{
	givenTime = new Date(givenTime);
	const milliseconds = new Date().getTime() - givenTime.getTime();
	const numberEnding = number =>
	{
		return number > 1 ? 's' : '';
	};
	const number = num => (num > 9 ? '' + num : '0' + num);
	const getTime = () =>
	{
		let temp = Math.floor(milliseconds / 1000);
		const years = Math.floor(temp / 31536000);
		if (years)
		{
			const month = number(givenTime.getUTCMonth() + 1);
			const day = number(givenTime.getUTCDate());
			const year = givenTime.getUTCFullYear() % 100;
			return `${day}-${month}-${year}`;
		}
		const days = Math.floor((temp %= 31536000) / 86400);
		if (days)
		{
			if (days < 28)
			{
				return days + ' day' + numberEnding(days);
			} else
			{
				const months = [
					'Jan',
					'Feb',
					'Mar',
					'Apr',
					'May',
					'Jun',
					'Jul',
					'Aug',
					'Sep',
					'Oct',
					'Nov',
					'Dec'
				];
				const month = months[givenTime.getUTCMonth()];
				const day = number(givenTime.getUTCDate());
				return `${day} ${month}`;
			}
		}
		const hours = Math.floor((temp %= 86400) / 3600);
		if (hours)
		{
			return `${hours} hour${numberEnding(hours)} ago`;
		}
		const minutes = Math.floor((temp %= 3600) / 60);
		if (minutes)
		{
			return `${minutes} minute${numberEnding(minutes)} ago`;
		}
		return 'a few seconds ago';
	};
	return getTime();
}

export function removeUndefined(data: any)
{
	let keys = Object.keys(data);
	keys.forEach(KEY =>
	{
		if (data[KEY] === undefined)
			delete data[KEY];
	});
	return data;
}

export function formatPrice(value, currency = Enum.typeCurrency.mxn, useSymbol = true, numDecimals = 2)
{
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: Enum.typeCurrency.usd,
		minimumFractionDigits: numDecimals
	})

	let text = !isNull(value) ? formatter.format(value) : '';
	text = useSymbol ? text : text.replace('$', '');
	return isNull(value) ? <span>{'- - -'}</span>  : 
		<span>
			{`${text} `}
			<span style={{ fontSize: 9, verticalAlign: 'super', lineHeight: '11px' }}>
				{currency}
			</span>
		</span>
}

export function formatTime(totalSeconds, format = 'hh:mm:ss')
{
	let dur_total = moment.duration(totalSeconds, 'seconds');
	return dur_total.format(format);
}

export function diffTime(dateUnix)
{
    let dateCurrent = moment().utc();
    let dateLimit = moment.unix(dateUnix).utc();
    let days = dateCurrent.diff(dateLimit, 'days', true);
    let duration = moment.duration(days, 'days')

	let msg = 'hace ' + duration.humanize() + '.';
	msg = msg.replace('seconds.', 'seg.');
	msg = msg.replace('minutes.', 'min.');
	msg = msg.replace('hours.', 'hrs.');
	msg = msg.replace('days.', 'días');
	msg = msg.replace('months.', 'meses');
	msg = msg.replace('years.', 'años');

	msg = msg.replace('a second.', '1 seg.');
	msg = msg.replace('a minute.', '1 min.');
	msg = msg.replace('a hour.', '1 hr');
	msg = msg.replace('a day.', 'un día');
	msg = msg.replace('a month.', 'un mes');
	msg = msg.replace('a year.', 'un año');
    return msg;
}

export function nowUnix() { return moment().local().unix(); }

export function convertToUnix(value)
{
	const typeField = typeof value;
	if (typeField === 'string')
	{
		const valueUnix = moment(value, ["MM/DD/YYYY", "DD/MM/YYYY"]).unix();
		return valueUnix;
	}

	if (typeField === 'number')
		return value.toString().length > 12 ? value / 1000 : value;

	return nowUnix();
}

export function unixToDate(dateUnix: any, format: string = 'DD/MM/YYYY HH:mm')
{
	if (isNull(dateUnix))
		return '';

	if (dateUnix.length > 12)
	{
		var date = new Date(parseInt(dateUnix));
		return moment(date).format(format);
	}
	else
		return moment.unix(dateUnix).format(format);
}

export function getDates(startDate: Number, endDate: Number): moment.Moment[]
{
	let isOneDay = moment.unix(startDate).format('DD/MM/YYYY') === moment.unix(endDate).format('DD/MM/YYYY');

	let format = isOneDay ? 'DD/MM/YYYY HH' : 'DD/MM/YYYY';
	let addOption = isOneDay ? 'hour' : 'day';

	var result = [];
	var day_current = moment.unix(startDate);
	result.push(moment(day_current));
	while (day_current.format(format) !== moment.unix(endDate).format(format))
	{
		day_current = day_current.add(1, addOption);
		result.push(moment(day_current));
	}
	return result;
}

export function fieldsAudit(idUserCurrent: String, action: 'CREATE' | 'UPDATE') 
{
	let isEmpty = isNullOrEmpty(idUserCurrent);
	if (action === 'CREATE')
		return isEmpty ? { createdAt: nowUnix() } : { createdAt: nowUnix(), createdUser: idUserCurrent };
	else
		return isEmpty ? { updatedAt: nowUnix() } : { updatedAt: nowUnix(), updatedUser: idUserCurrent };
}

export function getValueByPath(obj: any, path: string)
{
	let paths = path.split('.', 10)
	let current = obj;
	for (let i = 0; i < paths.length; ++i)
	{
		if (current[paths[i]] === undefined || current[paths[i]] === null)
			return null;
		else
			current = current[paths[i]];
	}
	return current;
}

/**Si el valor es `null` o `undefined` devuelve el valor por defecto
 * @param value Valor por validar y recuperar
 * @param defaultValue Valor por defecto `null` 
*/
export function getValue(value, defaultValue = null) { return isNull(value) ? defaultValue : value; }

/**Devuelve el valor máximo que exista en una lista
 * @param list Arreglo de elementos
 * @param key Clave usada para determinar el valor máximo
 * @param isFloat `Default false` True si la clave almacena números con decimal
*/
export function getValueMax(list, key, isFloat = false)
{
	if (list.length === 0) return 0;
	let numbers = list.map(element => { return isFloat ? parseFloat(element[key]) : parseInt(element[key]); });
	return Math.max(...numbers);
}

/**Devuelve el valor mínimo que exista en una lista
 * @param list Arreglo de elementos
 * @param key Clave usada para determinar el valor mínimo
 * @param isFloat `Default false` True si la clave almacena números con decimal
*/
export function getValueMin(list, key, isFloat = false)
{
	if (list.length === 0) return 0;
	let numbers = list.map(element => { return isFloat ? parseFloat(element[key]) : parseInt(element[key]); });
	return Math.min(...numbers);
}

/**Devuelve la suma de una clave específica de una lista
 * @param list Arreglo de elementos
 * @param key Clave usada para sumar 
 * @param isFloat `Default false` True si la clave almacena números con decimal
*/
export function getSum(list, key, isFloat = false)
{
	let sum = 0;
	if (list.length === 0) return sum;
	list.map(element => 
	{
		let value = element[key];
		if(isNull(value))
		{
			value = 0;
			console.log('ITEM_VALUE_NULL:'+ key, element);
		}

		if (typeof value === 'string')
			value = isFloat ? parseFloat(value) : parseInt(value);

		sum += isFloat ? parseFloat(value) : parseInt(value);
		return element;
	});
	return isFloat ? parseFloat(sum.toFixed(2)) : sum;
}

/**Habilita el ordenamiento de la columna de un componente Table 
 * @param a Objeto A de donde se extraerá el valor para ordenar
 * @param b Objeto B de donde se extraerá el valor para ordenar
 * @param key Clave del objeto que se usará para ordenar los elementos
 * @param isNumber Define si el valor de la clave es un número (default: false)*/
export function sortColumn(a, b, key, isNumber = false)
{
	let valA = isNumber ? 0 : ' ';
	let valB = isNumber ? 0 : ' ';
	if (!(isNull(a) && isNull(b)))
	{
		valA = isNull(a[key]) ?
			isNumber ? 0 : ' ' :
			isNumber ? a[key] : a[key].toUpperCase();
		valB = isNull(b[key]) ?
			isNumber ? 0 : ' ' :
			isNumber ? b[key] : b[key].toUpperCase();
	}

	if (isNumber)
		return valA - valB;
	else
	{
		if (valA > valB) return 1;
		else if (valA < valB) return -1;
		return 0;
	}
}

/**Ordena los elementos de una lista
 * @param list Lista de elementos
 * @param key Clave que se usará para ordenar los elementos (Pase null si es un tipo de dato nativo)
 * @param isNumber Define si el valor de la clave es un número (default: false)
 * @param ASC Por defecto true, para ordenar los elementos de manera ascendente 
 * @returns `OPCIONAL` No es necesario usar la lista devuelta
*/
export function sortList(list: [], key, isNumber = false, ASC = true)
{
	return list.sort((a, b) => 
	{
		let valA = isNull(key) ? a : a[key];
		let valB = isNull(key) ? b : b[key];
		valA = isNull(valA) ?
			isNumber ? 0 : ' ' :
			isNumber ? valA : normalize(valA.toUpperCase());
		valB = isNull(valB) ?
			isNumber ? 0 : ' ' :
			isNumber ? valB : normalize(valB.toUpperCase());

		if (ASC === false)
		{
			let backup = valA;
			valA = valB;
			valB = backup;
		}

		if (isNumber)
			return valA - valB;
		else
		{
			if (valA > valB) return 1;
			else if (valA < valB) return -1;
			return 0;
		}
	});
}

export function isNull(item) { return item === null || item === undefined; }

export function isNullOrEmpty(item) { return isNull(item) || item.trim() === ''; }

export function getCombinations(valuesArray: String[])
{
	var combi = [];
	var temp = [];
	var slent = Math.pow(2, valuesArray.length);

	for (var i = 0; i < slent; i++)
	{
		temp = [];
		for (var j = 0; j < valuesArray.length; j++)
		{
			if ((i & Math.pow(2, j)))
			{
				temp.push(valuesArray[j]);
			}
		}
		if (temp.length > 0)
		{
			combi.push(temp);
		}
	}

	combi.sort((a, b) => a.length - b.length);
	// console.log(combi.join("\n"));
	return combi;
}

export function removeLevelPath(pathname: String, countLevels)
{
	let result = pathname;
	for (let i = 1; i <= countLevels; i++)
	{
		let pos = result.lastIndexOf('/');
		result = result.substring(0, pos);
	}
	return result;
}

export function getKeyFromString(value: String)
{
	let strValue = isNullOrEmpty(value) ? '' : value.trim();
	if (strValue.includes(' '))
	{
		let split = strValue.split(' ', 5);
		let key = '';
		split.forEach(elem =>
		{
			if (split.length >= 4)
				key += elem[0].toUpperCase();
			else
				key += elem.length > 1 ? `${elem[0]}${elem[1]}`.toUpperCase() : elem[0].toUpperCase();
		});
		return key;
	}
	else
		return strValue.length > 4 ? strValue.toUpperCase().substring(0, 4) : strValue.toUpperCase();
}

/**
 * Función que valida si existe el arreglo entre las combinaciones válidas
 * @param combinations Combinaciones válidas admitidas
 * @param arrayValidate Arreglo por validar 
 * @param useContains Por defecto falase, Si es true basta con que alguna combinación 
 * contenga los valores del arreglo, en caso contrario se valida primero que sean de la misma longitud.
 */
export function existsCombination(combinations: [], arrayValidate: [], useContains = false)
{
	let exists = false;
	let filter = useContains ? combinations : combinations.filter(x => x.length === arrayValidate.length);
	filter.map(combi =>
	{
		if (!exists)
		{
			let allEquals = true;
			combi.map(element =>
			{
				if (!arrayValidate.some(x => x === element) && allEquals)
					allEquals = false;

				return element;
			});
			exists = allEquals;
		}
		return combi;
	});
	return exists;
}

export function getTabulationPermissions(youPermission: Number)
{
	let allPermissions = [];
	let permissionInit = 1;
	while (permissionInit <= youPermission)
	{
		allPermissions.push(permissionInit);
		permissionInit += permissionInit;
	}

	let allowPermissions = [];
	for (let index = allPermissions.length - 1; index >= 0; index--)
	{
		const value = allPermissions[index];
		if (youPermission >= value)
		{
			youPermission -= value;
			allowPermissions.push(value);
		}
	}
	return allowPermissions;
}

export function hasPermission(KeyModule, stateAuth, options: { user: UserModel, roles: RoleModel[], modules: RoleModuleModel[] } = null)
{
	let user: UserModel = null; let roles: RoleModel[] = null; let modules: RoleModuleModel[];
	if (stateAuth != null)
	{
		user = stateAuth.user;
		roles = stateAuth.roles;
		modules = stateAuth.modules;
	}
	else if (options != null)
	{
		user = options.user;
		roles = options.roles;
		modules = options.modules;
	}
	if (user != null && roles != null && modules != null)
	{
		if (KeyModule === Enum.RoleModule.AUTH)
			return user.roles.length > 0 && user.roles.some(x => x.permissionModule > 0);
		else
		{
			let posModule = modules.findIndex(x => x.key === KeyModule);
			if (posModule >= 0)
			{
				let allow = false;
				let permissionRequired = modules[posModule].permission;
				for (let index = 0; index < user.roles.length; index++)
				{
					if (allow === false)
					{
						const youPermission = user.roles[index].permissionModule;
						const tabulation = getTabulationPermissions(youPermission);
						if (tabulation.some(x => x === permissionRequired))
							allow = true;
					}
				}
				return allow;
			}
		}
	}
	return false;
}

export function hasRolesUser(user: UserModel, keyRoles: String[])
{
	const combi = getCombinations(keyRoles);
	let keyRolesUser = user.roles.map(element => { return element.key });
	return existsCombination(combi, keyRolesUser, true);
}

export function getIdOwner(user: UserModel)
{
	let IdOwner = null;
	if (hasRolesUser(user, [Enum.Role.SADMA, Enum.Role.SADM]))
		IdOwner = user.id;
	else
		IdOwner = user.idOwnerLinked;

	return IdOwner;
}

export function percentProfile(user: UserModel)
{
	if (!!user)
	{
		let pathExp = 'profile.experience.questions';
		let fields = ['name', 'profile.infoCreator.biography', 'photoURL'];
		let total = fields.length;

		let advance = 0;
		if (isNull(getValueByPath(user, pathExp)))
			total += PollQuestions.length;
		else
		{
			total += user.profile.experience.questions.length;
			user.profile.experience.questions.forEach(elem =>
			{
				if (elem.selectAnswer >= 0)
					advance++;
			});
		}
		fields.forEach(elem =>
		{
			let value = getValueByPath(user, elem);
			if (!!value && value.trim().length > 0)
				advance++;
		});

		let percent = (advance / total) * 100;
		percent = parseInt(percent.toFixed(0));
		return percent;
	}
	return 0;
}

/**Genera un log si el status del response es false*/
export function logError(infoMethod: String, response: Response)
{
	if (!response.status)
		console.log(infoMethod, !!response.error_data ? response.error_data.message : '');
}

export function splitArray(array, chunk_size)
{
	return Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size))
}

/**Normaliza la cadena quitando los caracteres especiales como acentos*/
export function normalize(str)
{
	let from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
		to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
		mapping = {};

	for (let i = 0, j = from.length; i < j; i++)
		mapping[from.charAt(i)] = to.charAt(i);

	let ret = [];
	for (let i = 0, j = str.length; i < j; i++)
	{
		let c = str.charAt(i);
		if (mapping.hasOwnProperty(str.charAt(i)))
			ret.push(mapping[c]);
		else
			ret.push(c);
	}
	return ret.join('');
}

export function normalizeToURL(value: String)
{
	let chars = ['"', '/', '*', ':', ',', '.', 'ñ', '(', ')', '[', ']', '{', '}', '+', '?', '¿', '¡', '!', '$', '%', '&', '#', '@'];
	let result = normalize(value.toLowerCase().trim());

	let newStr = [];
	for (let i = 0, j = result.length; i < j; i++)
	{
		let c = result.charAt(i);
		if (!chars.some(x => x === c))
			newStr.push(c);
	}
	result = newStr.join('');
	result = result.replace(/ +(?= )/g,'');
	result = result.replace(/\W/g, '-');
	return result;
}

export const esLocaleRMCD = {
    // months list by order
    months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],

    // week days by order
    weekDays: [
        {
            name: 'Domingo', // used for accessibility 
            short: 'D', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: 'Lunes',
            short: 'L',
        },
        {
            name: 'Martes',
            short: 'M',
        },
        {
            name: 'Miercoles',
            short: 'M',
        },
        {
            name: 'Jueves',
            short: 'J',
        },
        {
            name: 'Viernes',
            short: 'V',
        },
        {
            name: 'Sabado',
            short: 'S',
            isWeekend: true,
        },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    defaultPlaceholder: 'Select...',

    // for input range value
    from: 'de',
    to: 'a',


    // used for input value when multi dates are selected
    digitSeparator: ',',

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
}

export function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}