import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';
import Auth0 from './helpers/auth0';
import FirebaseAuthChanged from './FirebaseAuthChanged';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
	<Route
		{...rest}
		render={props => <Component {...props} />} />
);

class PublicRoutes extends React.Component
{
	render()
	{
		return (
			<>
				<FirebaseAuthChanged />
				<BrowserRouter>
					<div>
						<Route
							exact
							path={'/'}
							component={asyncComponent(() => import('./containers/Page/signin'))}
						/>
						<Route
							exact
							path={'/404'}
							component={asyncComponent(() => import('./containers/Page/404'))}
						/>
						<Route
							exact
							path={'/500'}
							component={asyncComponent(() => import('./containers/Page/500'))}
						/>
						<Route
							exact
							path={'/terminos-y-condiciones'}
							component={asyncComponent(() => import('./containers/Page/termsAndCond'))}
						/>
						<Route
							exact
							path={'/aviso-de-privacidad'}
							component={asyncComponent(() => import('./containers/Page/noticePrivacy'))}
						/>

						<Route
							exact
							path={'/signup'}
							component={asyncComponent(() => import('./containers/Page/signup'))}
						/>
						<Route
							exact
							path={'/forgotpassword'}
							component={asyncComponent(() =>
								import('./containers/Page/forgotPassword')
							)}
						/>
						<Route
							exact
							path={'/resetpassword'}
							component={asyncComponent(() =>
								import('./containers/Page/resetPassword')
							)}
						/>

						<Route
							path="/auth0loginCallback"
							render={props =>
							{
								Auth0.handleAuthentication(props);
							}}
						/>
						<RestrictedRoute
							path="/dashboard"
							component={App}
						// isLoggedIn={isLoggedIn}
						/>
					</div>
				</BrowserRouter>
			</>
		);
	}
}

export default connect(state => ({
	isLoggedIn: state.Auth.idToken !== null,
	firebaseAuthInit: state.Auth.firebaseAuthInit,
	idToken: state.Auth.idToken
}))(PublicRoutes);