import Response from "../containers/Administration/utils/Response";
import { showNotify } from '../containers/Administration/utils/UtilsForm';
import { fieldsAudit, nowUnix, logError, sortList, isNull, isNullOrEmpty } from '../helpers/utility';
import { QuizModel, UserModel } from '../models';
import { Table, Enum } from '../constants';
import SendNotif from '../constants/SendNotif';
import { firestore } from 'firebase';

import { QuizStatus } from "../models/QuizStatus";
import { categorieService } from './';

const db = firestore();

async function ListQuizs(idUserOwner: String = null, onlyActives = false, limit = 0): Promise<Response<QuizModel[]>> {

    let response: Response<QuizModel[]> = new Response(false);

    try {
        let ref = db.collection(Table.Quiz);
        let query = idUserOwner != null ? ref.where('quizsUserOwnerId', '==', idUserOwner) : ref;
        query = onlyActives ? query.where('status', '==', true) : query;
        if (limit > 0)
            query = query.limit(limit);

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item: QuizModel = element.data();
            item.id = element.id;

            if (isNull(item.minQualificationRequired))
                item.minQualificationRequired = 0;

            if (isNull(item.typeQualification))
                item.typeQualification = '';

            return item
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) {
        response.error = "Empty data";
        response.error_data = e;
    }
    return response;
}

async function listByIds(ids: String[]): Promise<Response<QuizModel[]>> {
    let response: Response<QuizModel[]> = new Response(false);
    try {
        response.data = [];
        let ref = db.collection(Table.Quiz);
        let data = await Promise.all(ids.map(ID => { return ref.doc(ID).get(); }));
        data.forEach(element => {
            if (element.exists) {
                let item: QuizModel = element.data();
                item.id = element.id;
                item.questionsCount = isNull(item.questionsCount) ? 0 : item.questionsCount;
                response.data.push(item);
            }
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    !response.status && console.log('quizService.js:listByIds', response.error_data);
    return response;
}

async function getQuiz(quizId: String = null): Promise<Response<QuizModel>> {
    let response: Response<QuizModel> = new Response(false);
    try {
        let doc = await db.collection(Table.Quiz).doc(quizId).get();
        if (doc.exists) {
            let item = doc.data();
            item.id = doc.id;
            item.questionsCount = isNull(item.questionsCount) ? 0 : item.questionsCount;

            response.data = item;
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('quizService.js:getQuiz', response);
    return response;
}

async function getQuizByName(nameQuiz: String): Promise<Response<QuizModel>> {
    let response: Response<QuizModel> = new Response(false);
    try {
        let query = await db.collection(Table.Quiz)
            .where(Table.$Quiz.name, '==', nameQuiz).get();
        if (query.size > 0) {
            let item = query.docs[0].data();
            item.id = query.docs[0].id;
            item.questionsCount = isNull(item.questionsCount) ? 0 : item.questionsCount;

            response.data = item;
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('quizService.js:getQuizByName', response);
    return response;
}

async function hasQuizs(idUserOwner: String, onlyActives = false): Promise<Response<Boolean>> {
    let response: Response<Boolean> = new Response(false);
    try {
        let ref = db.collection(Table.Quiz).where(Table.$Quiz.quizsUserOwnerId, '==', idUserOwner)
        let query = onlyActives ? ref.where(Table.$Quiz.status, '==', true) : ref;

        const data = await query.limit(1).get();
        response.data = data.docs.length > 0;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('quizService.js:hasQuizs', response);
    return response;
}

async function CreateQuiz(quiz: QuizModel, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...quiz,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };

        if (isNullOrEmpty(data.suggestionCategorie)) {
            delete data.suggestionCategorie;
        }

        const insert = await db.collection(Table.Quiz).add(data);
        quiz.id = insert.id;
        quiz.createdAt = nowUnix();

        response.data = quiz;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('quizService.js:CreateQuiz', response);
    return response;
}


async function UpdateQuiz(quiz: any, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...quiz,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        delete data['quizStatus'];
        await db.collection(Table.Quiz).doc(quiz.id).update(data);

        response.data = quiz;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('quizService.js:UpdateQuiz', response);
    return response;
}



async function ChangeStatusQuiz(idQuiz, status, idUserCurrent = '') {
    let response = new Response(false);
    try {
        let data = {
            status: status,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };

        await db.collection(Table.Quiz).doc(idQuiz).update(data);

        response.data = null;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}


async function deleteQuiz(quizId): Promise<Response> {
    let response = new Response(false);
    return response;
}


// Subcoleccion QuizStatus
async function getQuizStatus(idQuiz: String): Promise<Response<QuizStatus>> {
    let response: Response<QuizStatus> = new Response(false);
    try {
        let doc = await db.doc(`/${Table.Quiz}/${idQuiz}`).collection('QuizStatus').doc('progress').get();
        if (doc.exists) {
            response.data = doc.data();
            // response.data.id = doc.id;
        } else {
            response.data = new QuizStatus();
            // response.data.id = 'progress';
        }
        response.status = true;
    }
    catch (e) {
        response.error = "Empty data";
        response.error_data = e;
    }
    return response;
}

async function createQuizStatus(idQuiz: String, data: QuizStatus): Promise<Response<QuizStatus>> {
    let response: Response<QuizStatus> = new Response(false);
    try {
        await db.collection(Table.Quiz).doc(idQuiz).collection('QuizStatus').doc('progress').set({ ...data });
        response.data = data;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('quizService:createQuizStatus', response);
    return response;
}


async function updateQuizStatus(idQuiz: String, data: QuizStatus): Promise<Response<QuizStatus>> {
    let response: Response<QuizStatus> = new Response(false);
    try {
        let data_db = { ...data };
        delete data_db['id'];
        await db.doc(`/${Table.Quiz}/${idQuiz}`).collection('QuizStatus').doc('progress').update(data_db);
        response.data = data;
        response.status = true;
    }
    catch (e) {
        let error = JSON.parse(JSON.stringify(e));

        if (!!error && error.code === 'not-found') {
            response = await createQuizStatus(idQuiz, data);
        }

        response.error = "Empty data";
        response.error_data = e;
    }
    // logError('quizService:updateQuizStatus', response);
    return response;
}

async function sendEmailChangeStatus(quiz: QuizModel): Promise<Response> {
    let response = new Response(false);
    try {
        let responseFetch = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/mail-sendChangeQuizStatus`, {
            method: 'POST',
            body: JSON.stringify({ quiz }),
            headers: new Headers()
        });
        let infoJSON = await responseFetch.json();
        response.data = infoJSON;
        response.status = infoJSON.status;

        if (!response.status)
            response.error_data = infoJSON.error;
    }
    catch (err) { response.error_data = err; }
    logError('quizService:sendEmailChangeStatus', response);
    return response;
}

async function approveQuiz(quiz: QuizModel, user: UserModel) {
    quiz.quizStatus.status = Enum.typeStatusQuiz.PUBLISHED;
    quiz.status = true;

    await UpdateQuiz({ id: quiz.id, status: true }, user.id);
    await updateQuizStatus(quiz.id, { status: Enum.typeStatusQuiz.PUBLISHED });
    await SendNotif.statusQuizPublished(quiz.quizsUserOwnerId, quiz.id, user);
    await categorieService.updateCategorie({ id: quiz.quizsCategorieId, status: true });
    await sendEmailChangeStatus(quiz);

    showNotify('Examen aprobado', 'El examen ha sido aprobado y se encuentra disponible en la plataforma.', 'success');
    return quiz;
}

async function rejectQuiz(quiz: QuizModel, user: UserModel, reason: String) {
    quiz.quizStatus.status = Enum.typeStatusQuiz.REJECTED;
    quiz.quizStatus.reason = reason;

    await updateQuizStatus(quiz.id, { status: Enum.typeStatusQuiz.REJECTED, reason });
    await SendNotif.statusQuizRejected(quiz.quizsUserOwnerId, quiz.id, user);
    await sendEmailChangeStatus(quiz);

    showNotify('Examen rechazado', 'El examen fue rechazado exitosamente.', 'success');
    return quiz;
}

async function updatePriceInApp(price: Number, quizes: String[]): Promise<Response> {
    let response = new Response(false);
    try {
        let res_fetch = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/appmision_playStore-updatePrice`, {
            method: 'POST',
            body: JSON.stringify({ price, quizes }),
            headers: new Headers()
        });
        let infoJSON = await res_fetch.json();
        response.data = infoJSON;
        response.status = true;
    }
    catch (err) { response.error_data = err; }
    logError('quizService:updatePriceInApp', response);
    return response;
}

async function getItemPlayStore(productId): Promise<Response> {
    let response = new Response();
    try {
        let responseFetch = await fetch(process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS + '/appmision_playStore-getItem', {
            method: 'POST',
            body: JSON.stringify({
                productId: productId
            }),
            headers: new Headers()
        });
        response.data = await responseFetch.json();;
        response.status = true;
    }
    catch (error) { 
        response.status = false;
        response.error = error;
    }
    return response;
}

export default {
    ListQuizs,
    listByIds,
    hasQuizs,
    getQuizByName,
    CreateQuiz,
    UpdateQuiz,
    deleteQuiz,
    ChangeStatusQuiz,
    getQuiz,
    getQuizStatus,
    createQuizStatus,
    updateQuizStatus,
    sendEmailChangeStatus,
    approveQuiz,
    rejectQuiz,
    updatePriceInApp,
    getItemPlayStore
};