import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, sortList } from '../helpers/utility';
import { RoleModel, RoleModuleModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function listRoles(onlyActives: Boolean): Promise<Response<RoleModel[]>>
{
    let response: Response<RoleModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.Role);
        let query = onlyActives ? ref.where('status', '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'permission', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function listModules(onlyActives: Boolean): Promise<Response<RoleModuleModel[]>>
{
    let response: Response<RoleModuleModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.RoleModule);
        let query = onlyActives ? ref.where('status', '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'permission', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createRole(rol: RoleModel, idUserCurrent = ''): Promise<Response<RoleModel>>
{
    let response: Response<RoleModel> = new Response(false);
    try
    {
        let data = {
            ...rol,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.Role).add(data);
        rol.id = insert.id;

        response.data = rol;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createModule(rmodule: RoleModuleModel, idUserCurrent = ''): Promise<Response<RoleModuleModel>>
{
    let response: Response<RoleModuleModel> = new Response(false);
    try
    {
        let data = {
            ...rmodule,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.RoleModule).add(data);
        rmodule.id = insert.id;

        response.data = rmodule;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function updateRole(rol, idUserCurrent = ''): Promise<Response<RoleModel>>
{
    let response: Response<RoleModel> = new Response(false);
    try
    {
        let data = {
            ...rol,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.Role).doc(rol.id).update(data);

        response.data = rol;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}

async function updateModule(rmodule, idUserCurrent = ''): Promise<Response<RoleModuleModel>>
{
    let response: Response<RoleModuleModel> = new Response(false);
    try
    {
        let data = {
            ...rmodule,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.RoleModule).doc(rmodule.id).update(data);

        response.data = rmodule;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}

export default {
    listRoles, listModules,
    createRole, createModule,
    updateRole, updateModule
}