export default class Blog
{
    id: String;
    name: String;
    title: String;
    shortDescription: String;
    body: String;
    author: String;
    order: Number;
    releaseDate: Number;
    urlImage: String;
    videoId: String;
    status: Boolean;

    fileImage: File;
    urlImageBackup: String;

    constructor()
    {
        this.releaseDate = 0;
        this.status = true;
    }
}