export default class Bank
{
    id: String;
    key: String;
    name: String;
    description: String;
    bankUserOwnerId: String;
    bankQuizId: String;
    status: Boolean;
    
    nameUserOwner: String;

    constructor()
    {
        this.bankQuizId = null;
        this.status = true;
    }
}