const BinnacleActionKey = {
    assignaccess: 'assignaccess',
    changemember: 'changemember',
    changesimulator: 'changesimulator',
    activeuser: 'activeuser',
    activemember: 'activemember',
    activemember_fraud: 'activemember_fraud',
    inactiveuser: 'inactiveuser',
    inactivemember: 'inactivemember',
    inactivemember_fraud: 'inactivemember_fraud'
};
const BinnacleModule = {
    access: {
        key: 'access',
        title: 'Asignación de accesos',
        actions: [
            BinnacleActionKey.assignaccess
        ]
    },
    changeMember: {
        key: 'changeMember',
        title: 'Cambios de miembro',
        actions: [
            BinnacleActionKey.changemember
        ]
    },
    changeQuiz: {
        key: 'changeQuiz',
        title: 'Cambios de simulador',
        actions: [
            BinnacleActionKey.changesimulator
        ]
    },
    members: {
        key: 'members',
        title: 'Miembros',
        actions: [
            BinnacleActionKey.activemember,
            BinnacleActionKey.activemember_fraud,
            BinnacleActionKey.inactivemember,
            BinnacleActionKey.inactivemember_fraud
        ]
    },
    users: {
        key: 'users',
        title: 'Usuarios',
        actions: [
            BinnacleActionKey.activeuser,
            BinnacleActionKey.inactiveuser
        ]
    }
};
const PaymentStatus = {
    /**Confirmado*/CONF: 'CONF',
    /**Conciliado*/CONC: 'CONC',
    /**Depositado*/DEP: 'DEP',
    /**Sin Procesar*/SPR: 'SPR'
};
const PaymentType = {
    /**Play Store*/PLS: 'PLS',
    /**App Store*/APS: 'APS',
    /**Tarjeta de crédito*/TC: 'TC',
    /**Tarjeta de débito*/TD: 'TD',
    /**Tarjeta de crédito - stripe*/TCS: 'TCS',
    /**Tarjeta de débito - stripe*/TDS: 'TDS',
    /**Pago con OXXO*/OXXO: 'OXXO',
    /**Pago Gratuito*/FREE: 'FREE',
};
const Reason = {
    /**Transacción duplicada*/TD: 'TD',
    /**Sospecha de fraude*/SF: 'SF',
    /**Otro*/OTR: 'OTR',
    /**Solicitado por cliente*/SC: 'SC',
    /**No se puede cumplir*/NSC: 'NSC'
};
const Role = {
    /**Super administrador Appmisión*/SADMA: 'SADMA',
    /**Administrador Appmisión*/ADMA: 'ADMA',
    /**Super administrador*/SADM: 'SADM',
    /**Administrador*/ADM: 'ADM',
    /**Profesor*/PRO: 'PRO',
    /**Estudiante*/EST: 'EST'
};
const RoleModule = {
    /**Denegar accesso*/DENIED: 'DENIED',
    /**Autentificación*/AUTH: 'AUTH',
    /**Exámenes*/EXA: 'EXA',
    /**Preguntas*/PRE: 'PRE',
    /**Catálogos*/CAT: 'CAT',
    /**Mensajes*/MEN: 'MEN',
    /**Usuarios*/USU: 'USU',
    /**Finanzas locales*/FINL: 'FINL',
    /**Finanzas globales*/FING: 'FING',
    /**Blogs*/BLG: 'BLG',
    /**Perfil*/PERF: 'PERF',
    /**Ayuda*/HLP: 'HLP'
};
const RoleAction = {
    /**Full Info*/FINF: 'FINF',
    /**Create*/CRE: 'CRE',
    /**Read*/REA: 'REA',
    /**Update*/UPD: 'UPD',
    /**Delete or Inactive*/DEL: 'DEL'
};
const CategoryDT = {
    Tienda: 'Tienda',
    Propietario: 'Propietario',
    ASPIT: 'ASPIT',
    Otro: 'Otro'
};

const CatalogCategory = {
    Otro: 'OTR'
};

const AmountReceivableType = {
    extractPercentage: 'extractPercentage',
    $extractPercentage: 'Permite obtener el valor sin el porcentaje indicado. Ej. 16% seria: $199 / 1.16 = 171.55',
    
    percentage: 'percentage',
    $percentage: 'Permite obtener el valor que corresponda al porcentaje especificado. Ej: 30% seria: $199 * .30 = 59.7',

    number: 'number',
    $number: 'Permite sumar el valor especificado. Ej: 10.4 seria: $199 + 10.4 = 209.4'
};
const KeyAmountPayment = {
    /**Monto propietario (amountOutDiscount)*/owner: 'owner',
    /**Precio simulador (amount)*/price: 'price',
    /**Monto ASPIT (Deduction.amount)*/ASPIT: 'ASPIT'
};
const SubModuleFIN = {
    Sales: 'Sales',
    Conciliation: 'Conciliation',
    Deposit: 'Deposit',
    Refund: 'Refund',
    Charged: 'Charged',
    Charge: 'Charge'
}
const UserType = {
    /**Usuario registrado*/REG: 'REG',
    /**Usuario miembro*/MEM: 'MEM',
    /**Usuario subscrito*/SUB: 'SUB'
};
const QuizType = {
    /*Estudiar*/EST: 'EST',
    /*Examen*/EXA: 'EXA',
    /*Estático*/ETT: 'ETT'
};
const typeQualification = {
    /**Número de preguntas correctas*/NUM_QUESTIONS: 'NUM_QUESTIONS',
    /**Porcentaje*/PERCENT: 'PERCENT',
    /**Puntos acumulados*/POINTS: 'POINTS'
};
const typeStatusQuiz = {
    /**Publicado*/PUBLISHED: 'PUBLISHED',
    /**Sin publicar*/UNPUBLISH: 'UNPUBLISH',
    /**En Proceso*/IN_PROCESS: 'IN_PROCESS',
    /**En Revisión*/IN_REVIEW: 'IN_REVIEW',
    /**Rechazado*/REJECTED: 'REJECTED'
}
const typePlanQuiz = {
    free: 'plan_free',
    monetize: 'plan_monetize'
}
const typeCurrency = {
    mxn: 'MXN',
    usd: 'USD'
}
const messages = {
    fieldNotEmpty: "El campo no puede quedar vacío.",
    reallyCancel: "¿Deseas realmente cancelar?",
    reallyDelete: "¿Desea realmente eliminarlo?",
    success: "Operación realizada con éxito.",
    errorTryAgain: "Hubo un problema, revise su conexión e intente nuevamente."
};
const StoreDeposit = {
    Stripe: 'stripe',
    PlayStore: 'play_store',
    AppStore: 'app_store',
    Manual: 'manual'
};
const TabProfile = {
    dataUsr: 'tbpInfo',
    photo: 'tbpPhoto',
    experience: 'tbpExp',
    privacity: 'tbpPriv'
};
const TypeNotif = {
    /**Cuando se genera una nueva reseña de simulador*/
    reviewNew: 'review_new',
    /**Cuando el usuario final actualiza la reseña del simulador*/
    reviewUpdated: 'review_updated',
    /**Cuando se responde a la reseña del simulador*/
    reviewAnswered: 'review_answered',

    /**Cuando se genera un nuevo reporte de pregunta*/
    reportQuestionNew: 'report_question_new',
    /**Cuando el usuario final actualiza el reporte de pregunta*/
    reportQuestionUpdated: 'report_question_updated',
    /**Cuando se responde al reporte de la pregunta*/
    reportQuestionAnswered: 'report_question_answered',

    /**Cuando se reembolsa un simulador*/
    refundQuiz: 'refund_quiz',

    /**Cuando el propietario cambia el precio sugerido del simulador*/
    priceQuizChanged: 'price_quiz_changed',
    /**Cuando el SPADMA asigna el precio al simulador en base a las tiendas (PLS, APS)*/
    priceQuizAssigned: 'price_quiz_assigned',

    /**Cuando el estatus del simulador cambia a `IN_REVIEW - En revisión`*/
    statusQuizInReview: 'status_quiz_inreview',
    /**Cuando el estatus del simulador cambia a `REJECTED - Rechazado`*/
    statusQuizRejected: 'status_quiz_rejected',
    /**Cuando el estatus del simulador cambia a `PUBLISHED - Publicado`*/
    statusQuizPublished: 'status_quiz_published',
    /**Cuando se apague el simulador y el estatus se cambia a `UNPUBLISH - Sin publicar`*/
    statusQuizUnpublish: 'status_quiz_unpublish',
}
export default {
    BinnacleActionKey,
    BinnacleModule,
    PaymentStatus,
    PaymentType,
    Reason,
    Role,
    CatalogCategory,
    RoleModule,
    RoleAction,
    CategoryDT,
    AmountReceivableType,
    KeyAmountPayment,
    SubModuleFIN,
    UserType,
    QuizType,
    typeQualification,
    messages,
    StoreDeposit,
    typeStatusQuiz,
    typePlanQuiz,
    typeCurrency,
    TabProfile,
    TypeNotif
};