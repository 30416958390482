import { firestore } from 'firebase';
import { isNull } from '../../helpers/utility';
import { Table } from '../../constants';
import { UserSessionModel } from '../';

export default class FilterSession
{
    db: firestore.Firestore;

    /**Arreglo con plataformas
     * @description Máximo 10 Ids para query()*/
    platform: String[];

    /**Arreglo con proveedores de inicio de sesion
     * @description Máximo 10 Ids para query()*/
    provider: String[];

    /**Estatus del registro*/
    status: Boolean;

    /**Arreglo con rango de fechas en formato unix
     * @description El arreglo debe contener 2 valores*/
    rangeTimeStart: Number[];

    clone(data: FilterSession)
    {
        this.platform = data.platform;
        this.provider = data.provider;
        this.status = data.status;
        this.rangeTimeStart = data.rangeCreatedAt;
    }

    filter(fullData: UserSessionModel[]): UserSessionModel[]
    {
        let result = !!fullData ? fullData : [];

        if (!!this.platform)
            result = result.filter(x => this.platform.some(PLATFORM => PLATFORM === x.platform));

        if (!!this.provider)
            result = result.filter(x => this.provider.some(PROVIDER => PROVIDER === x.provider));

        if (!isNull(this.status))
            result = result.filter(x => x.status === this.status);

        if (!!this.rangeTimeStart && this.rangeTimeStart.length === 2)
            result = result.filter(x => x.timeStart >= this.rangeTimeStart[0] && x.timeStart <= this.rangeTimeStart[1]);

        return result;
    }

    query()
    {
        if (isNull(this.db))
            this.db = firestore();

        let query = this.db.collection(Table.SessionHistory);

        if (!!this.platform)
        {
            const isOnlyOne = this.platform.length === 1;
            query = query.where(Table.$SessionHistory.platform, isOnlyOne ? '==' : 'in', isOnlyOne ? this.platform[0] : this.platform)
        }

        if (!!this.provider)
        {
            const isOnlyOne = this.provider.length === 1;
            query = query.where(Table.$SessionHistory.provider, isOnlyOne ? '==' : 'in', isOnlyOne ? this.provider[0] : this.provider)
        }

        if (!isNull(this.status))
            query = query.where(Table.$SessionHistory.status, '==', this.status);

        if (!!this.rangeTimeStart && this.rangeTimeStart.length === 2)
        {
            query = query.where(Table.$SessionHistory.timeStart, '>=', this.rangeTimeStart[0])
            query = query.where(Table.$SessionHistory.timeStart, '<=', this.rangeTimeStart[1])
        }

        return query;
    }
}