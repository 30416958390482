import { nowUnix } from "../helpers/utility";

export default class NotifUser
{
    id: String;
    title: String;
    message: String;
    readed: Boolean;
    readedAt: Number;
    metadata: any;
    type: String;
    hash: String;
    platform: String;
    createdAt: Number;
    createdUser: String;
    status: Boolean;

    constructor()
    {
        this.title = '';
        this.message = '';
        this.readed = false;
        this.readedAt = 0;
        this.hash = '';
        this.metadata = {};
        this.platform = 'dashboard';
        this.createdAt = nowUnix();
        this.status = true;
    }
}