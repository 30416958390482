
/**
 * @description Divide un array dependiendo de la longitud
 * @param {*} array 
 * @param {*} longitud 
 */
function splitArray(array: Array, longitud: Number = 10) {
    let matriz = [];
    for (let i = 0; i < array.length; i += longitud) { matriz.push(array.slice(i, i + longitud)); }
    return matriz;
};

/**
 * @description Convierte un array mutidimecianla en un solo array (lo concatena para solo formar un solo arreglo)
 * @param {*} arr 
 * @param {*} orderBy
 */
function flatten(arr: Array, orderBy: String = 'createdAt') {
    return sort([].concat.apply([], arr), orderBy);
}

/**
 * @description ordena un array dependiendo del capo
 * @param {*} arr 
 */
function sort(arr: Array, orderBy: String = 'createdAt') {
    return arr.sort(function (a, b) {
        if (a[orderBy] > b[orderBy]) {
            return 1;
        }
        if (a[orderBy] < b[orderBy]) {
            return -1;
        }
        return 0;
    });
}

/**
 * @description ordena un array dependiendo del capo
 * @param {*} arr 
 */
function sortDesc(arr: Array, orderBy: String = 'createdAt') {
    return arr.sort(function (a, b) {
        if (a[orderBy] < b[orderBy]) {
            return 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return -1;
        }
        return 0;
    });
}


export default {
    splitArray,
    flatten,
    sort,
    sortDesc
}

