import { upload, removeFile } from '../containers/Administration/utils/UtilsUpload';
import { end_point_assets_s3 } from "../containers/Administration/utils/Global";
import Response from "../containers/Administration/utils/Response";

import { isNullOrEmpty, logError, fieldsAudit, isNull, sortList } from '../helpers/utility';
import { getIdUser } from '../containers/Administration/utils/Global';
import uuid from "uuid/v4";

import { firestore } from 'firebase';
import { Table } from "../constants";
import Question from "../models/Question";

import questionTypeService from './questionTypeService';
import bankService from './bankService';
import sectionService from "./sectionService";
import subSectionService from "./subSectionService";
import questionSequenceService from "./questionSequence.service";
import feedbackSequenceService from "./feedbackSequences.service";
import answersSequenceService from "./answersSequence.service";
import levelService from "./levelService";
import answerTypeService from "./answerTypeService";

const db = firestore();
type TypeSubInfo = 'bank' | 'section' | 'level' | 'answer-type' | 'sub-section' | 'question-type' | 'question-sequence' | 'feedback' | 'answers';

function normalizeFullInfo(question)
{
	if (isNull(question.answers)) question.answers = [];
	question.answers = question.answers.map(elem =>
	{
		delete elem['answerQuestionId'];
		delete elem['createdAt'];
		delete elem['createdUser'];
		delete elem['updatedAt'];
		delete elem['updatedUser'];
		return elem;
	});

	if (isNull(question.feedbackSequences)) question.feedbackSequences = [];
	question.feedback = question.feedbackSequences.map(elem =>
	{
		delete elem['feedbackSequenceQuestionId'];
		delete elem['createdAt'];
		delete elem['createdUser'];
		delete elem['updatedAt'];
		delete elem['updatedUser'];
		return elem;
	});

	if (isNull(question.questionSequences)) question.questionSequences = [];
	question.sequence = question.questionSequences.map(elem =>
	{
		delete elem['questionSequenceQuestionId'];
		delete elem['createdAt'];
		delete elem['createdUser'];
		delete elem['updatedAt'];
		delete elem['updatedUser'];
		return elem;
	});

	question.answers = sortList(question.answers, 'order', true);
	question.feedback = sortList(question.feedback, 'order', true);
	question.sequence = sortList(question.sequence, 'order', true);

	question.free = isNull(question.free) ? false : question.free;
	question.required = isNull(question.required) ? false : question.required;
	question.status = isNull(question.status) ? false : question.status;

	delete question['feedbackSequences'];
	delete question['questionSequences'];
	delete question['bank'];
	delete question['section'];
	delete question['level'];
	delete question['answerType'];
	delete question['subSection'];
	delete question['questionType'];
	return question;
}

/**
 * Consulta el detalle de una pregunta por ID
 */
async function getQuestionForId(questionId, filterSubInfo: TypeSubInfo[] = null): Promise<Response> {
	let response = new Response(false);
	try {
		let query = await db.collection(Table.Question).doc(questionId).get();
		if (query.exists) {
			let question: Question = { ...query.data(), id: query.id };

			if (isNull(filterSubInfo) || filterSubInfo.includes('bank'))
			{
				let bank = await bankService.getBankById(question.questionBankId);
				if (bank.status)
					question.bank = bank.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('section'))
			{
				let section = await sectionService.getSectionById(question.questionSectionId);
				if (section.status)
					question.section = section.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('level'))
			{
				let level = await levelService.getLevelById(question.questionLevelId);
				if (level.status)
					question.level = level.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('answer-type'))
			{
				let answerType = await answerTypeService.getAnswerTypeById(question.questionAnswerTypeId);
				if (answerType.status)
					question.answerType = answerType.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('sub-section'))
			{
				if (!!question.questionSubSectionId)
				{
					let subSection = await subSectionService.getSubSectionById(question.questionSubSectionId)
					if (subSection.status)
						question.subSection = subSection.data;
				}
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('question-type'))
			{
				if (!!question.questionQuestionTypeId)
				{
					let questionType = await questionTypeService.getQuestionTypeById(question.questionQuestionTypeId)
					if (questionType.status)
						question.questionType = questionType.data;
				}
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('question-sequence'))
			{
				let questionSequences = await questionSequenceService.getQuestionSequence(question.id);
				if (questionSequences.status)
					question.questionSequences = questionSequences.data;

				question.questionText = getSequenceText(question.questionSequences);
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('feedback'))
			{
				let feedbackSequence = await feedbackSequenceService.getFeedbackSequences(question.id);
				if (feedbackSequence.status)
					question.feedbackSequences = feedbackSequence.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('answers'))
			{
				let answersSequence = await answersSequenceService.getAnswersSequence(question.id);
				if (answersSequence.status)
					question.answers = answersSequence.data;
			}

			response.data = question;
			response.status = true;
		}
	}
	catch (error) { response.error_data = error; }
	logError('questionService.js:getQuestionForId', response);
	return response;
}

async function syncToQuestionFullInfo(questionId)
{
	console.log('START SYNC QUESTION', questionId);
	let res_info = await getQuestionForId(questionId, [
		'answers',
		'question-sequence',
		'feedback'
	]);

	try
	{
		if(res_info.status)
		{
			let data = { ...normalizeFullInfo(res_info.data) };
			delete data['id'];
			await db.collection(Table.QuestionFullInfo).doc(questionId).set(data);
		}
	}
	catch (error) { logError('questionService:syncToQuestionFullInfo', questionId); }
}

async function filterQuestionsBankSection(
	questionBankId: String = null,
	questionSectionId: String = null,
	onlyFree: Boolean = null,
	status: Boolean = null,
	filterSubInfo: TypeSubInfo[] = null
): Promise<Response> {

	let response = new Response(false);
	try {
		let ref = db.collection(Table.Question)

		if (questionBankId !== null) ref = ref.where(Table.$Question.questionBankId, '==', questionBankId)
		if (questionSectionId !== null) ref = ref.where(Table.$Question.questionSectionId, '==', questionSectionId)
		if (onlyFree !== null) ref = ref.where(Table.$Question.free, '==', onlyFree)
		if (status !== null) ref = ref.where(Table.$Question.status, '==', status)

		let query = await ref.get();
		let questions: Question[] = query.docs.map(e => { return { ...e.data(), id: e.id } });

		let data = await Promise.all(questions.map(async elem => {
			if (isNull(filterSubInfo) || filterSubInfo.includes('bank')) {
				let bank = await bankService.getBankById(elem.questionBankId);
				if (bank.status)
					elem.bank = bank.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('section')) {
				let section = await sectionService.getSectionById(elem.questionSectionId);
				if (section.status)
					elem.section = section.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('level')) {
				let level = await levelService.getLevelById(elem.questionLevelId);
				if (level.status)
					elem.level = level.data;

			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('answer-type')) {
				let answerType = await answerTypeService.getAnswerTypeById(elem.questionAnswerTypeId);
				if (answerType.status)
					elem.answerType = answerType.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('sub-section')) {
				if (!!elem.questionSubSectionId) {
					let subSection = await subSectionService.getSubSectionById(elem.questionSubSectionId)
					if (subSection.status)
						elem.subSection = subSection.data;
				}
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('question-type')) {
				if (!!elem.questionQuestionTypeId) {
					let questionType = await questionTypeService.getQuestionTypeById(elem.questionQuestionTypeId)
					if (questionType.status)
						elem.questionType = questionType.data;
				}
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('question-sequence')) {
				let questionSequences = await questionSequenceService.getQuestionSequence(elem.id);
				if (questionSequences.status)
					elem.questionSequences = questionSequences.data;

				elem.questionText = getSequenceText(elem.questionSequences);
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('feedback')) {
				let feedbackSequence = await feedbackSequenceService.getFeedbackSequences(elem.id);
				if (feedbackSequence.status)
					elem.feedbackSequences = feedbackSequence.data;
			}
			if (isNull(filterSubInfo) || filterSubInfo.includes('answers')) {
				let answersSequence = await answersSequenceService.getAnswersSequence(elem.id);
				if (answersSequence.status)
					elem.answers = answersSequence.data;
			}
			return elem;
		}));

		response.data = data;
		response.status = true;
	}
	catch (error) { response.error_data = error; }
	logError('questionService.js:filterQuestionsBankSection', response);
	return response;
}


async function listQuestionFree(questionBankId: String): Promise<Response<Question[]>> {
	let response = new Response(false);
	try {
		let query = await db.collection(Table.Question)
			.where(Table.$Question.questionBankId, '==', questionBankId)
			.where(Table.$Question.free, '==', true).get();

		let questions: Question[] = !query.empty ? query.docs.map(e => { return { ...e.data(), id: e.id } }) : [];

		response.data = questions;
		response.status = true;
	} catch (error) {
		console.log({ error });
		response.error_data = error;
	}
	logError('questionService.js:filterQuestionsBankSection', response);
	return response;
}

async function listQuestionByBankAndSection(questionBankId: String = null, questionSectionId: String = null): Promise<Response> {
	let response = new Response(false);
	try {
		let ref = db.collection(Table.Question).where(Table.$Question.questionBankId, '==', questionBankId).where(Table.$Question.questionSectionId, '==', questionSectionId)

		let query = await ref.get();
		let questions: Question[] = query.docs.map(e => { return { ...e.data(), id: e.id } });
		let data = await Promise.all(questions.map(async elem => {

			let questionSequences = await questionSequenceService.getQuestionSequence(elem.id);
			if (questionSequences.status)
				elem.questionSequences = questionSequences.data;

			elem.questionText = getSequenceText(elem.questionSequences);
			elem.progress = isNull(elem.progress) ? 100 : elem.progress;

			return elem;
		}));
		response.data = data;
		response.status = true;
	}
	catch (error) { response.error_data = error; }
	logError('questionService.js:filterQuestionsBankSection', response);
	return response;
}


async function listCountBySection(questionBankId: String = null, questionSectionId: String = null): Promise<Response> {
	let response = new Response(false);
	try {
		let ref = db.collection(Table.Question)
			.where(Table.$Question.questionBankId, '==', questionBankId)
			.where(Table.$Question.questionSectionId, '==', questionSectionId)
			.where(Table.$Question.status, '==', true)
			.limit(100);

		let query = await ref.get();
		let questions: Question[] = query.docs.map(e => { return { ...e.data(), id: e.id } });
		response.data = questions;
		response.status = true;
	}
	catch (error) { response.error_data = error; }
	logError('questionService.js:listCountBySection', response);
	return response;
}


function getSequenceText(questionSequence) {
	let str = "";
	if (!!questionSequence) {
		questionSequence.forEach(value => { str += `${isNullOrEmpty(value.questionText) ? '' : value.questionText} \n` });
		str = str.trimRight();
		str = str.length > 250 ? `${str.substring(0, 250)}...` : str;
	}
	return str;
}

async function getLessons(questionBankId, questionSectionId): Promise<Response<String[]>> {

	let response: Response<String[]> = new Response(false);

	try {

		let query = await db.collection(Table.Question)
			.where(Table.$Question.questionBankId, '==', questionBankId)
			.where(Table.$Question.questionSectionId, '==', questionSectionId).get();

		if (!query.empty) {
			let data = query.docs.map(e => e.data().lesson).filter(f => !!f);
			const dataArr = new Set(data);
			response.data = [...dataArr];
		} else {
			response.data = [];
		}
		response.status = true;

	}
	catch (error) {
		response.error_data = error;
		console.log('questionService.js:getLessons', error);
	}
	return response;
}




async function getQuestionForBankId(questionBankId: String, limit: Number = null): Promise<Response<Question[]>> {

	let response: Response<Question[]> = new Response(false);

	try {

		let ref = db.collection(Table.QuestionFullInfo);
		ref = ref.where(Table.$Question.questionBankId, '==', questionBankId)
		ref = ref.where(Table.$Question.questionBankId, '==', questionBankId)
		if (!!limit)
			ref = ref.limit(limit)

		let query = await ref.orderBy('createdAt', 'desc').get();

		if (!query.empty) {
			let data = query.docs.map(e => { return { ...e.data(), id: e.id } });
			response.data = data;
		} else {
			response.data = [];
		}
		response.status = true;

	}
	catch (error) {
		response.error_data = error;
		console.log('questionService.js:getQuestionForBankId', error);
	}
	return response;
}



/**
 * Persiste el arbol de información de la pregunta:
 * Question sequence
 * Answers
 * Feedback sequence
 */


async function createQuestion(question, questionId = null): Promise<Response> {

	let sub = await getIdUser();

	let response = new Response();

	let idQuestion = questionId === null ? uuid() : questionId;

	let data = {
		key: isNullOrEmpty(question.leccion) ? null : question.leccion.trim().toLowerCase(),
		free: !!question.free ? question.free : null,
		static: !!question.static ? question.static : null,
		required: !!question.required ? question.required : null,
		points: !!question.points ? question.points : null,
		status: !!question.status ? question.status : null,
		questionAnswerTypeId: !!question.questionAnswerTypeId ? question.questionAnswerTypeId : null,
		questionBankId: !!question.questionBankId ? question.questionBankId : null,
		questionLevelId: !!question.questionLevelId ? question.questionLevelId : null,
		questionSectionId: !!question.questionSectionId ? question.questionSectionId : null,
		...fieldsAudit(sub, 'CREATE')
	}

	if (isNullOrEmpty(question.questionSubSectionId) === false) {
		data.questionSubSectionId = question.questionSubSectionId;
	}

	if (isNullOrEmpty(question.questionQuestionTypeId) === false) {
		data.questionQuestionTypeId = question.questionQuestionTypeId;
	}

	try {

		await db.collection(Table.Question).doc(idQuestion).set({ ...data });


		response.data = { ...data, id: idQuestion };
		response.status = true;

	} catch (e) {
		console.log(e)
		response.status = false;
		response.error = e.errors;
	}
	return response;


}



/**
 * Función que persiste la sequencia de la pregunta
 */
async function createQuestionSequence(sequence, questionId, currentUser = ''): Promise<Response> {
	let response = new Response();
	try {

		let id = !!sequence.id ? sequence.id : uuid();

		let data = {
			questionText: !isNull(sequence.questionText) ? sequence.questionText.trimEnd() : null,
			questionImage: !isNull(sequence.questionImage) ? sequence.questionImage : null,
			questionAudio: !isNull(sequence.questionAudio) ? sequence.questionAudio : null,
			order: sequence.order,
			status: sequence.status,
			questionSequenceQuestionId: questionId,
			...fieldsAudit(currentUser, 'CREATE')
		}

		await db.collection('QuestionSequence').doc(id).set(data);

		response.data = { ...data, id: id };
	} catch (e) {
		console.log(e);
		response.status = false;
		response.error = e;
	}
	return response;
}


/**
 * Función que persiste las respuestas de la pregunta
 */
async function createAnswer(answer, questionId, currentUser = ''): Promise<Response> {
	let response = new Response();

	try {
		let answerId = uuid();

		let data = {
			answerText: !isNull(answer.answerText) ? answer.answerText.trimEnd() : null,
			answerImage: !isNull(answer.answerImage) ? answer.answerImage : null,
			correctAnswer: !isNull(answer.correctAnswer) ? answer.correctAnswer : false,
			order: answer.order,
			status: answer.status,
			answerQuestionId: questionId,
			...fieldsAudit(currentUser, 'CREATE')
		}

		await db.collection('Answer').doc(answerId).set(data);
		response.data = { ...data, id: answerId };
	} catch (e) {
		console.log(e);
		console.log("ERROR_ANS", answer);
		response.status = false;
		response.error = e;
	}
	return response;
}

/**
 * Función que persiste la sequencia del feedback
 */
async function createFeedbackSequence(feedback, idQuestion): Promise<Response> {
	let response = new Response();
	let sub = await getIdUser();
	try {
		let feedbakId = uuid();
		let data = {
			feedbackText: !isNull(feedback.feedbackText) ? feedback.feedbackText.trimEnd() : null,
			feedbackImage: !isNull(feedback.feedbackImage) ? feedback.feedbackImage : null,
			order: feedback.order,
			status: feedback.status,
			feedbackSequenceQuestionId: idQuestion,
			...fieldsAudit(sub, 'CREATE')
		}

		console.log({ data });


		await db.collection('FeedbackSequence').doc(feedbakId).set(data);
		response.data = { ...data, id: feedbakId };
	} catch (e) {
		console.log(e);
		response.status = false;
		response.error = e;
	}
	return response;
}






async function updateQuestion(question, questionID): Promise<Response> {
	let response = new Response();


	let sub = await getIdUser();


	try {
		let data = {
			required: !!question.required ? question.required : null,
			free: !!question.free ? question.free : null,
			key: question.leccion === "" || question.leccion === null || question.leccion === undefined ? null : question.leccion.toLowerCase(),
			static: !!question.static ? question.static : null,
			points: question.points,
			status: question.status,
			questionQuestionTypeId: isNull(question.questionQuestionTypeId) ? null : question.questionQuestionTypeId,
			questionAnswerTypeId: question.questionAnswerTypeId,
			questionBankId: question.questionBankId,
			questionLevelId: question.questionLevelId,
			questionSectionId: question.questionSectionId,
			questionSubSectionId: isNull(question.questionSubSectionId) ? null : question.questionSubSectionId,
			...fieldsAudit(sub, 'UPDATE')
		}


		await db.collection(Table.Question).doc(questionID).update(data)
		let responseQuestion = await getQuestionForId(questionID)
		if (responseQuestion.status) {
			response.data = responseQuestion.data;
			response.status = true;
		}

	} catch (e) {
		console.log(e)
		response.status = false;
		response.error = e;
	}
	return response;

}










/**
 * Funcion para subir la imagen a S3 
 */

async function uploadImage(file, url) {
	let upload_success = true;

	const response = await upload(file, url);
	const type = file.type.split("/")[1];
	if (!response.key.includes(type)) {
		upload_success = false;
	}
	if (upload_success) {
		//console.log("Upload questionImageFile success");
		return 'success';
	} else {
		//console.log("Upload questionImageFile error");
		return 'error';
	}

}


/**
 * 
 * Funcion para eliminar las imagenes del S3
 */

async function removeFileS3(tempImage, imagen) {

	if (tempImage === undefined && imagen === null) {
		// cod...
	} else if (tempImage === undefined && imagen.includes('https')) {
		// cod...
	} else if (tempImage !== null) {
		if (tempImage.includes('https')) {
			await removeFile(tempImage.replace(end_point_assets_s3 + '/', ''));
			//console.log(remove)
		}
	}
}





/**
 * Función que actualiza las respuestas de la pregunta
 */
async function updateQuestionSequence(sequence, questionSequenceId, currentUser = ''): Promise<Response> {

	let response = new Response();

	let data = {
		questionText: !isNullOrEmpty(sequence.questionText) ? sequence.questionText.trimEnd() : null,
		questionImage: !isNullOrEmpty(sequence.questionImage) ? sequence.questionImage : null,
		questionAudio: !isNullOrEmpty(sequence.questionAudio) ? sequence.questionAudio : null,
		order: sequence.order,
		status: sequence.status,
		...fieldsAudit(currentUser, 'UPDATE')
	}

	try {
		await db.collection('QuestionSequence').doc(questionSequenceId).update(data)
		response.data = { ...data, id: sequence.id };
	} catch (e) {
		console.log(e);
		response.status = false;
		response.error = e.errors;
	}
	return response;
}

/**
 * Función que actualiza la sequencia de la pregunta
 */
async function updateAnswer(answer): Promise<Response> {
	let response = new Response();

	let sub = await getIdUser();

	let data = {
		answerText: answer.answerText !== "" && answer.answerText !== null ? answer.answerText.trimEnd() : null,
		answerImage: answer.answerImage != null ? answer.answerImage : null,
		correctAnswer: answer.correctAnswer != null ? answer.correctAnswer : false,
		order: answer.order,
		status: answer.status,
		...fieldsAudit(sub, 'UPDATE')
	}
	try {
		db.collection('Answer').doc(answer.id).update(data);
		response.data = { ...data, id: answer.id };
	} catch (e) {
		response.status = false;
		response.error = e.errors[0].message;
	}
	return response;
}

/**
 * Función que actualiza la sequencia del feedback
 */
async function updateFeedbackSequence(feedback): Promise<Response> {

	let response = new Response();

	let data = {
		feedbackText: feedback.feedbackText !== "" && feedback.feedbackText !== null ? feedback.feedbackText.trimEnd() : null,
		feedbackImage: feedback.feedbackImage != null ? feedback.feedbackImage : null,
		order: feedback.order,
		createdUser: "1",
		status: feedback.status
	}

	try {
		db.collection('FeedbackSequence').doc(feedback.id).update(data);
		response.data = { ...data, id: feedback.id };
	} catch (e) {
		response.status = false;
		response.error = e.errors[0].message;
	}
	return response;
}



/**
 * Función que actualiza la sequencia del feedback
 */
async function updateStatusFree(questionId: string, status: Boolean): Promise<Response> {

	let response = new Response();

	try {
		await db.collection(Table.Question).doc(questionId).update({ free: status });
		response.data = { id: questionId };
		response.status = true;
	} catch (e) {
		console.log(e)
		response.status = false;
		response.error = e;
	}
	return response;
}









/**
 * Elimina question
 */
async function deleteQuestion(id) {
	let response = new Response();

	try {
		await db.collection('Question').doc(id).delete();
		let docFullInfo = await db.collection(Table.QuestionFullInfo).doc(id).get();
		if(docFullInfo.exists)
			await db.collection(Table.QuestionFullInfo).doc(id).delete();
		
		response.data = { id: id };
	} catch (e) {
		response.status = false;
		response.error = e.errors[0].message;
	}
	return response;
}

/**
 * Eliminar la sequencia de question
 */
async function deleteQuestionSequence(id): Promise<Response> {
	let response = new Response();
	try {
		await db.collection('QuestionSequence').doc(id).delete();
		response.data = { id: id };
	} catch (e) {
		response.status = false;
		response.error = e.errors[0].message;
	}
	return response;
}

/**
 * Eliminar la sequencia de answers
 */
async function deleteAnswer(id, questionId): Promise<Response> {
	let response = new Response();
	try {
		await db.collection('Answer').doc(id).delete();
		response.data = { id: id };
	} catch (e) {
		response.status = false;
		response.error = e.errors[0].message;
	}
	return response;
}

/**
 * Eliminar la sequencia de feedback
 */
async function deleteFeedbackSequence(id, questionId): Promise<Response> {
	let response = new Response();
	try {
		await db.collection('FeedbackSequence').doc(id).delete();
		response.data = { id: id };
	} catch (e) {
		response.status = false;
		response.error = e.errors[0].message;
	}
	return response;
}



async function getFilterQuestions(questionTypeId = null, bankId = null, sectionId = null, subSectionId = null, levelId = null): Promise<Response> {

	let response: Response<[]> = new Response(false);

	try {
		let ref = db.collection(Table.Question).where(Table.$Question.status, '==', true);

		if (!isNullOrEmpty(bankId)) ref = ref.where(Table.$Question.questionBankId, '==', bankId)
		if (!isNullOrEmpty(questionTypeId)) ref = ref.where(Table.$Question.questionQuestionTypeId, '==', questionTypeId)
		if (!isNullOrEmpty(sectionId)) ref = ref.where(Table.$Question.questionSectionId, '==', sectionId)
		if (!isNullOrEmpty(subSectionId)) ref = ref.where(Table.$Question.questionSubSectionId, '==', subSectionId)
		if (!isNullOrEmpty(levelId)) ref = ref.where(Table.$Question.questionLevelId, '==', levelId)

		let query = await ref.get();

		response.data = query.docs.map(e => { return { ...e.data(), id: e.id } });
		response.status = true;
	} catch (error) {
		response.error_data = error;
	}
	logError('questionService.js:getFilterQuestions', response);
	return response;
}



async function update(question, questionID): Promise<Response> {
	let response = new Response();
	let sub = await getIdUser();
	try {
		let data = {
			...question,
			...fieldsAudit(sub, 'UPDATE')
		}
		await db.collection(Table.Question).doc(questionID).update(data)
		let responseQuestion = await getQuestionForId(questionID)
		if (responseQuestion.status) {
			response.data = responseQuestion.data;
			response.status = true;
		}
	} catch (e) {
		console.log(e)
		response.status = false;
		response.error = e;
	}
	return response;

}



export default {
	normalizeFullInfo,
	getSequenceText,
	getQuestionForId,
	syncToQuestionFullInfo,
	createQuestion,
	createQuestionSequence,
	updateQuestionSequence,
	deleteQuestionSequence,
	createAnswer,
	createFeedbackSequence,
	updateFeedbackSequence,
	updateQuestion,
	updateAnswer,
	deleteQuestion,
	deleteAnswer,
	deleteFeedbackSequence,
	uploadImage,

	removeFileS3,

	getFilterQuestions,
	getQuestionForBankId,
	filterQuestionsBankSection,
	listQuestionByBankAndSection,
	listCountBySection,

	getLessons,
	listQuestionFree,
	updateStatusFree,
	update
};