import Response from "../containers/Administration/utils/Response"
import { firestore } from "firebase";

let db = firestore();


async function getAnswersSequence(id: string) {
    let response = new Response(false);
    try {
        let query = await db.collection('Answer').where('answerQuestionId', '==', id).get();
        if (!query.empty) {
            response.data = query.docs.map(e => { return { ...e.data(), id: e.id } });
            response.status = true;
        }
    } catch (error) {
        response.error = error;
    }
    return response;
}


export default {
    getAnswersSequence
}