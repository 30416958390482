const actionsQuiz =
{
    SET_LIST_QUIZ: 'SET_LIST_QUIZ',
    SET_QUIZ_CURRENT: 'SET_QUIZ_CURRENT',
    SET_CURRENT_PAGE_QUIZ: 'SET_CURRENT_PAGE_QUIZ',
    DELETE_QUIZ: 'DELETE_QUIZ',
    setListQuiz: (list) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.SET_LIST_QUIZ,
                payload: list
            })
        }
    },
    setCurrentPageQuizs: (page) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.SET_CURRENT_PAGE_QUIZ,
                payload: page
            })
        }
    },
    setQuizCurrent: (quiz) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.SET_QUIZ_CURRENT,
                payload: quiz
            })
        }
    },
    deleteQuiz: indice =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.DELETE_QUIZ,
                payload: { indice: indice }
            })
        }
    },
    CHANGE_STATUS: 'CHANGE_STATUS',
    changeStatus: (indice, status) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.CHANGE_STATUS,
                payload: {
                    status: status,
                    indice: indice
                }
            });
        }
    },
    SET_LOADING_STATUS: 'SET_LOADING_STATUS',
    setLoadingStatus: (indice, loading) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.SET_LOADING_STATUS,
                payload: {
                    loading: loading,
                    indice: indice
                }
            });
        }
    },

    SET_CATALOGS: 'SET_CATALOGS',
    setCatalogs: (catalogs) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.SET_CATALOGS,
                payload: catalogs
            })
        }
    },


    UPDATE_ITEM_TEMPLATE_QUIZ: 'UPDATE_ITEM_TEMPLATE_QUIZ',
    CREATE_TEMPLATE_QUIZ: 'CREATE_TEMPLATE_QUIZ',
    SET_ID_TEMPLATE_QUIZ: 'SET_ID_TEMPLATE_QUIZ',
    LIST_TEMPLATE_QUIZ: 'LIST_TEMPLATE_QUIZ',
    listTemplateQuiz: (templates) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.LIST_TEMPLATE_QUIZ,
                templates
            })
        }
    },
    createTemplateQuiz: (template) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.CREATE_TEMPLATE_QUIZ,
                template
            })
        }
    },
    updateItemTemplate: (item) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.UPDATE_ITEM_TEMPLATE_QUIZ,
                payload: item
            })
        }
    },
    setIdTemplateQuiz: (id, template) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.SET_ID_TEMPLATE_QUIZ,
                idTemplate: id,
                template: template
            })
        }
    },


    RESET_LIST_SECTIONS_CONFIGURATIONS: "RESET_LIST_SECTIONS_CONFIGURATIONS",
    LIST_SECTIONS_CONFIGURATIONS: "LIST_SECTIONS_CONFIGURATIONS",
    CREATE_SECTION_CONFIGURATION: 'CREATE_SECTION_CONFIGURATION',
    REMOVE_ITEM_SECTION_LIST: "REMOVE_ITEM_SECTION_LIST",
    ADD_ITEM_REMOVE_LIST: "ADD_ITEM_REMOVE_LIST",
    UPDATE_ITEM_SECTION_LIST: "UPDATE_ITEM_SECTION_LIST",
    ADD_SECTION_TO_LIST: "ADD_SECTION_TO_LIST",
    RESET_REMOVE_LIST: "RESET_REMOVE_LIST",
    resetListSectionConfiguration: () =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.RESET_LIST_SECTIONS_CONFIGURATIONS,
            });
        }
    },
    createSectionConfiguration: (section) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.CREATE_SECTION_CONFIGURATION,
                section: section
            });
        }
    },
    updateItemSectionsList: (section) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.UPDATE_ITEM_SECTION_LIST,
                payload: section
            });
        }
    },
    listSectionsConfigurations: (sections) =>
    {

        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.LIST_SECTIONS_CONFIGURATIONS,
                sections
            });
        }
    },
    removeItemSectionList: (indice) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.REMOVE_ITEM_SECTION_LIST,
                payload: indice
            });
        }
    },
    addItemRemoveList: (section) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.ADD_ITEM_REMOVE_LIST,
                payload: section
            });
        }
    },
    resetRemoveList: () =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.RESET_REMOVE_LIST,
            });
        }
    },
    addSectionToList: (section) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.ADD_SECTION_TO_LIST,
                section: section
            });
        }
    },

    UPDATE_ID_SECTION: 'UPDATE_ID_SECTION',
    SET_LOADER_SECTION: 'SET_LOADER_SECTION',
    updateIdSection: (section) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.UPDATE_ID_SECTION,
                payload: section
            });
        }
    },
    setLoaderSection: (section) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actionsQuiz.SET_LOADER_SECTION,
                payload: section
            });
        }
    },
}

export default actionsQuiz;