import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import Sidebar from '../Sidebar/Sidebar';
import ThemeSwitcher from '../../containers/ThemeSwitcher';
import AppRouter from './AppRouter';
import { siteConfig } from '../../settings';
import themes from '../../settings/themes';
import { AppHolder } from './commonStyle';
import './global.scss';
import moment from 'moment';
import { Enum } from '../../constants';

const { Content, Footer } = Layout;
const { logout, load_router_list } = authAction;
export class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      initScript: false,
      widthScreen: 0,
      heightScreen: 0
    }

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.user !== this.state.user && !this.state.initScript) {
      this.setState({ initScript: true });
      let isSADMA = prevProps.auth.user.roles.some(e => e.key === Enum.Role.SADMA);
      if (isSADMA) {
        let tag = document.getElementById("chat-application-iframe");
        !!tag && tag.remove();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ widthScreen: window.innerWidth, heightScreen: window.innerHeight });
  }

  render() {
    const { url } = this.props.match;
    const { selectedTheme, auth } = this.props;
    return (
      <ThemeProvider theme={themes[selectedTheme]}>
        <AppHolder>
          <Layout style={{ height: this.state.heightScreen, flexDirection: 'row' }}>

            <Sidebar history={this.props.history} url={url} />
            <Layout
              id="layout-page"
              className="isoContentMainLayout">
              <Content style={{ height: "auto", minHeight: "auto"}}>
                <AppRouter url={url} auth={auth} load_router_list={this.props.load_router_list} />
              </Content>
              {this.props.history.location.pathname === '/dashboard/exam-create' ? null :
                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed'
                  }}
                >
                  {siteConfig.footerText.replace("{year}", moment().year())}
                  <div className="html-version" about={process.env.REACT_APP_VERSION_DESCRIP}>
                    Versión: {process.env.REACT_APP_VERSION}
                  </div>
                </Footer>
              }
            </Layout>

            <ThemeSwitcher />
            <div id="overlay-sidebar"></div>
          </Layout>
        </AppHolder>
      </ThemeProvider>

    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    height: state.App.height
  }),
  { logout, load_router_list }
)(App);
