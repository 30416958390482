// import update from 'immutability-helper';
import actions from './actions';

const initState = [
    {
        title: 'Home',
        url: '/dashboard'
    }
]

export default function cardReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.ADD_BREADCRUMB:
            {
                let newState = [...state];
                if (!newState.some(x => x.url === action.payload.url))
                    newState.push(action.payload)
                return newState;
            }

        case actions.REMOVE_BREADCRUMB:
            return action.payload === '/dashboard' ? state : state.filter(e => e.url !== action.payload)

        default:
            return state;
    }
}