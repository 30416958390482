export default class Promotion
{
    id: String;
    name: String;
    description: String;
    percentDiscount: Number;
    validity: Number;
    status: Boolean;

    releaseAt: Number;
    releaseUser: String;
    priceRangeId: String;
    priceRangeIdToRestore: String;

    constructor()
    {
        this.name = '';
        this.description = '';
        this.percentDiscount = 1;
        this.priceRangeId = '';
        this.priceRangeIdToRestore = '';
        this.validity = 0;
        this.status = true;
    }
}