import Response from "../containers/Administration/utils/Response";
import { firestore } from 'firebase';
import { DeductionISR } from "../models/DeductionISR";
import { fieldsAudit } from "../helpers/utility";

const db = firestore();


async function getList(): Promise<Response<DeductionISR[]>> {
    let response = new Response(false);
    try {

        let query = await db.collection('DeductionISR').get()

        if (!query.empty) {
            let data = query.docs.map(e => { return { ...e.data(), id: e.id } });

            response.data = data;
            response.status = true;
        }

    } catch (error) {
        response.error = error;
    }
    return response;
}

async function getDeductionISR(amount: number): Promise<Response<DeductionISR>> {
    let response = new Response(false);
    try {

        let query = await db.collection('DeductionISR').get()

        if (!query.empty) {
            let data = query.docs.find(f => f.data().lowerLmit <= amount && f.data().upperLimit >= amount);

            if (!!data) {
                response.data = { ...data.data(), id: data.id };
                response.status = true;
            }
        }

    } catch (error) {
        response.error = error;
    }
    return response;
}


async function create(item: DeductionISR, idUserCurrent = '') {
    let response = new Response(false);
    try {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection('DeductionISR').add(data);

        response.data = { ...data, id: insert.id };
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}


async function update(item: DeductionISR, idUserCurrent = '') {
    let response = new Response(false);
    try {
        let id = item.id;

        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };

        delete data['id'];

        await db.collection('DeductionISR').doc(id).update(data);

        response.data = { ...data, id: id };
        response.status = true;
    } catch (e) {
        console.log(e)
        response.error = e;
    }
    return response;
}

export default {
    getDeductionISR,
    getList,
    create,
    update
}