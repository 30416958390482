import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, logError, sortList, isNullOrEmpty } from '../helpers/utility';
import { upload, removeFile } from '../containers/Administration/utils/UtilsUpload';
import { PathProps } from '../containers/Administration/utils/Global';
import { AssetModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function list(onlyActives = false): Promise<Response<AssetModel[]>>
{
    let response: Response<AssetModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.Asset);
        let query = onlyActives ? ref.where(Table.$Asset.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: AssetModel = element.data();
            item.id = element.id;
            item.urlFileBackup = item.urlFile;
            return item;
        });
        sortList(response.data, 'order', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function getInfoById(idAsset: String): Promise<Response<AssetModel>>
{
    let response: Response<AssetModel> = new Response(false);
    response.data = null;
    try
    {
        let doc = await db.collection(Table.Asset).doc(idAsset).get();
        if (doc.exists)
        {
            response.data = doc.data()
            response.data.id = doc.id;
            response.data.urlFileBackup = response.data.urlFile;
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('assetService:getInfoById', response);
    return response;
}

async function create(item: AssetModel, pathS3: PathProps, idUserCurrent = ''): Promise<Response<AssetModel>>
{
    let response: Response<AssetModel> = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        delete data['fileInfo'];
        delete data['urlFileBackup'];
        const insert = await db.collection(Table.Asset).add(data);
        item.id = insert.id;

        // Archivo
        await uploadFileS3(item.fileInfo, pathS3);

        response.data = item;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('assetService.js:create', response);
    return response;
}

async function update(item: AssetModel, pathS3: PathProps, idUserCurrent = ''): Promise<Response<AssetModel>>
{
    let response: Response<AssetModel> = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        delete data['fileInfo'];
        delete data['urlFileBackup'];
        await db.collection(Table.Asset).doc(item.id).update(data);

        // Archivo
        if (!!pathS3)
        {
            await uploadFileS3(item.fileInfo, pathS3);
            if (!!item.fileInfo || isNullOrEmpty(item.urlFile))
                await removeFileS3(item.urlFileBackup, pathS3);
        }
        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('assetService.js:update', response);
    return response;
}

async function deleteDoc(item: AssetModel, pathS3: PathProps): Promise<Response<AssetModel>>
{
    let response: Response<AssetModel> = new Response(false);
    try
    {
        await db.collection(Table.Asset).doc(item.id).delete();

        // Archivo
        if (!!pathS3 && !!item.urlFile)
            await removeFileS3(item.urlFile, pathS3);

        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('assetService.js:deleteDoc', response);
    return response;
}

async function uploadFileS3(file: File, pathS3: PathProps)
{
    if (!!file)
    {
        const pathS3Folder = `${pathS3.path_assets}/assets`;
        const response = await upload(file, pathS3Folder);

        const type = file.type.split("/")[1];
        return response.key.includes(type);
    }
    return true;
}

async function removeFileS3(urlImage, pathS3: PathProps)
{
    if (!isNullOrEmpty(urlImage) && urlImage.includes('https'))
        return await removeFile(urlImage.replace(pathS3.path_cloudfront + '/', ''));

    return true;
}

export default {
    list,
    getInfoById,
    create,
    update,
    deleteDoc
}