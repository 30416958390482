export class ProblemType {

    id: string;
    description: string;
    key: string;
    name: string;
    status: string;
    createdAt: number;
    createdUser: string;
    updatedAt: number;
    updatedUser: string;


    loadInfo(problemType) {

        if(!!problemType.id)
            this.id = problemType.id;

        this.description = problemType.description;
        this.key = problemType.key;
        this.name = problemType.name;
        this.status = problemType.status;
    }

}