import actions from './actions';

const initState = {
    owners: [],
    questions: []
}

export default function (state = initState, action)
{
    switch (action.type)
    {
        // CATÁLOGOS
        case actions.SET_OWNERS:
            return {
                ...state,
                owners: action.payload,
                ownerCurrent: null
            }
        case actions.SET_QUESTIONS:
            return {
                ...state,
                questions: action.payload
            }
        default:
            return state;
    }
}