import Response from "../containers/Administration/utils/Response";
import { logError, fieldsAudit, sortList } from '../helpers/utility';
import { Table } from "../constants";
import { DepositOwnerModel } from "../models";
import { firestore } from 'firebase';

const db = firestore();

async function listActives(idOwner): Promise<Response<DepositOwnerModel[]>>
{
    let response: Response<DepositOwnerModel[]> = new Response(false);
    try
    {
        let query = db.collection(Table.DepositCreator)
            .where(Table.$DepositCreator.userOwnerId, '==', idOwner)
            .where(Table.$DepositCreator.status, '==', true);

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: DepositOwnerModel = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('depositOwnerService.js:listActives', response);
    return response;
}

async function create(prmItem: DepositOwnerModel, idUserCurrent = ''): Promise<Response<DepositOwnerModel>>
{
    let response: Response<DepositOwnerModel> = new Response(false);
    try
    {
        let data = {
            ...prmItem,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.DepositCreator).add(data);
        prmItem.id = insert.id;
        prmItem.createdAt = data.createdAt;
        prmItem.createdUser = data.createdUser;

        response.data = prmItem;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('depositOwnerService.js:create', response);
    return response;
}

async function update(prmItem: DepositOwnerModel, idUserCurrent = ''): Promise<Response<DepositOwnerModel>>
{
    let response: Response<DepositOwnerModel> = new Response(false);
    try
    {
        let data = {
            ...prmItem,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.DepositCreator).doc(prmItem.id).update(data);

        response.data = prmItem;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('depositOwnerService.js:update', response);
    return response;
}

async function confirm(prmItem: DepositOwnerModel, idUserCurrent = ''): Promise<Response>
{
    let response: Response = new Response(false);
    try
    {
        let data = {
            [Table.$DepositCreator.confirmation]: prmItem.confirmation,
            [Table.$DepositCreator.dateConfirm]: prmItem.dateConfirm,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        await db.collection(Table.DepositCreator).doc(prmItem.id).update(data);
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('depositOwnerService.js:confirm', response);
    return response;
}

export default {
    listActives,
    create,
    update,
    confirm
}