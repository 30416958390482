import React from 'react';
import { WrapperFile, ButtonPlay, FileDescription, BackgroundImage } from './style';
import {
    RiImageAddLine,
    RiVolumeUpLine,
    RiPlayCircleLine,
    RiStopCircleLine,
    RiCloseCircleLine
} from "react-icons/ri";
import { Skeleton, Tooltip, Modal } from 'antd';
import { beforeUpload } from '../../../utils/UtilsForm';
import { createMetadataImage } from '../../../utils/UtilsUpload';

const ACCEPT_IMAGE = 'image/png, image/jpeg';
const ACCEPT_AUDIO = 'audio/mp3, audio/mpeg, audio/wav';

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}
interface UploadProps {
    type: 'audio' | 'image';
    key: String;
    id: String;
    accept: String,
    onChangeFile: Function,
    preview: String,
    disabled: Boolean
}
class UploadComponentIA extends React.Component<UploadProps> {

    constructor(props) {
        super(props);
        // this.AudioRef = React.createRef();
        this.state = {
            image: null,
            audio: null,
            accept: null,
            isPlaying: false,
            loadign: true,
            mimeType: '',
            visible: false
        }
    }

    componentDidMount() {
        let accept = ACCEPT_IMAGE;
        if (!!this.props.accept) {
            accept = this.props.accept;
        } else if (this.props.type === 'audio') { accept = ACCEPT_AUDIO; }

        this.setState({ accept: accept }, () => {
            this.setState({
                loadign: false,
                image: this.props.type === 'image' ? this.props.preview : null,
                audio: this.props.type === 'audio' ? this.props.preview : null,
            })
        })
    }

    componentWillUpdate(nextProps) {
        if (nextProps.preview !== this.props.preview) {
            if (typeof nextProps.preview === 'string') {
                this.setState({
                    image: this.props.type === 'image' ? nextProps.preview : null,
                    audio: this.props.type === 'audio' ? nextProps.preview : null,
                });
            }
        }
    }

    onFileChange = event => {
        let file = event.target.files[0];

        if (!!file) {
            if (beforeUpload(file) === false) {
                event.target.value = null;
                return false;
            }

            readFile(file).then(dataUrl => {
                this.setState({ mimeType: file.type }, () => {
                    let FILE = createMetadataImage.conevertBase64toImage(dataUrl);
                    if (ACCEPT_IMAGE.includes(file.type)) {
                        this.setState({ image: dataUrl });
                        this.props.onChangeFile(FILE);
                    } else if (ACCEPT_AUDIO.includes(file.type)) {
                        this.setState({ audio: dataUrl });
                        this.props.onChangeFile(FILE);
                    } else {
                        console.log("Formato no soportado");
                    }
                })
            });
        }
    }

    imagePreview = () => this.setState({ visible: true });

    removeImage = () => this.setState({ image: null }, () => this.props.onChangeFile(''));

    removeAudio = () => this.setState({ audio: null }, () => this.props.onChangeFile(''));
    

    renderInputFile = () => {
        return (
            <FileDescription disabled={this.props.disabled}>
                <input
                    disabled={this.props.disabled}
                    accept={this.state.accept}
                    className="input-file"
                    id={this.props.id}
                    type="file"
                    onChange={this.onFileChange} />
                <label htmlFor={this.props.id} className="input-file-trigger">
                    {
                        this.props.type === 'image'
                            ? <Tooltip title="Imagen que conformará parte de la pregunta.">
                                <RiImageAddLine size={18} /> Agregar
                            </Tooltip>
                            : <Tooltip title={
                                this.props.disabled
                                    ? "La secuencia no puede tener mas de un Audio"
                                    : "Audio que conformará parte de la pregunta"
                            }>
                                <RiVolumeUpLine size={18} /> Agregar
                            </Tooltip>
                    }
                </label>
            </FileDescription>
        )
    }

    renderImage = () => (
        <React.Fragment>
            {
                !!this.state.image
                    ? <React.Fragment>
                        <button type="button" className="preview" onClick={() => this.imagePreview()}>
                            <BackgroundImage image={this.state.image} />
                            <span style={{ padding: '0 5px', fontSize: 12, fontWeight: 500, color: "#4670a2" }}>Vista previa</span>
                        </button>
                        <button type="button" className="remove" onClick={() => this.removeImage()}>
                            <RiCloseCircleLine size={18} />
                        </button>
                    </React.Fragment>
                    : this.renderInputFile()
            }
        </React.Fragment>
    )

    renderAudio = () => (
        <React.Fragment>
            {
                this.state.audio
                    ? <React.Fragment>
                        <ButtonPlay type="button" onClick={(e) => this.playPreview(e)}>
                            {this.state.isPlaying
                                ? <><RiStopCircleLine size={18} style={{ marginRight: 5 }} />Detener</>
                                : <><RiPlayCircleLine size={18} style={{ marginRight: 5 }} />Reproducir</>
                            }
                        </ButtonPlay>
                        <audio onEnded={() => this.setState({ isPlaying: false })} ref={(input) => { this.AudioRef = input }}><source src={this.state.audio} type={this.state.mimeType} /></audio>
                        <button type="button" className="remove" onClick={() => this.removeAudio()}>
                            <RiCloseCircleLine size={18} />
                        </button>
                    </React.Fragment>
                    : this.renderInputFile()
            }
        </React.Fragment>
    )

    playPreview = (e) => {
        e.preventDefault();
        if (!this.state.isPlaying) {
            this.setState({ isPlaying: true });
            this.AudioRef.play();
        } else {
            this.setState({ isPlaying: false });
            this.AudioRef.pause();
            this.AudioRef.currentTime = 0;
        }
    }

    render() {
        return (
            <Skeleton loading={this.state.loadign}>
                <WrapperFile className={`wrapper-file-${this.props.type}`}>
                    {
                        this.props.type === 'image'
                            ? this.renderImage()
                            : this.renderAudio()

                    }
                </WrapperFile>
                <Modal
                    visible={this.state.visible}
                    footer={null}
                    title={null}
                    onCancel={() => this.setState({ visible: false })}
                >
                    <img src={this.state.image} alt="preview" style={{ maxWidth: '100%' }} />
                </Modal>
            </Skeleton>
        )
    }
}

UploadComponentIA.defaultProps = {
    type: 'image',
    key: '',
    id: 'file',
    accept: null,
    preview: null,
    disabled: false,
    onChangeFile: (e) => console.log(e)
}

export default UploadComponentIA;