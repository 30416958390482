import { PaymentModel, RefundModel } from './';

export default class DepositOwner
{
    id: String;
    userOwnerId: String;
    amount: Number;
    amountCharge: Number;
    voucher: String;
    deductionISR: Number;
    deductionIVA: Number;
    confirmation: Boolean;
    dateConfirm: Number;
    dateStart: Number;
    dateEnd: Number;
    concept: String;
    status: Boolean;
    createdAt: String;
    createdUser: String;

    payments: PaymentModel[];
    refunds: RefundModel[];

    constructor()
    {
        this.payments = [];
        this.refunds = [];
        this.status = true;
    }
}