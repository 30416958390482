import { Enum } from '../constants';

class AmountReceivable
{
    id: String;
    amount: Number;
    order: Number;
    type: String;

    constructor()
    {
        this.amount = 0;
        this.order = 0;
        this.type = Enum.AmountReceivableType.percentage;
    }

    convertToDB()
    {
        let data = {...this};
        delete data['id'];
        return data;
    }
}

class DeductionType
{
    id: String;
    key: String;
    name: String;
    description: String;
    category: String;
    status: Boolean;

    amountsReceivable: AmountReceivable[];

    constructor()
    {
        this.amountsReceivable = [];
        this.status = true;
    }
}

export { AmountReceivable, DeductionType }