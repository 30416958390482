import React from 'react';
import { connect } from 'react-redux';
import actions from './redux/auth/actions';

import { Auth } from "aws-amplify";
import { auth, firestore } from "firebase";
import { UserModel, UserProfileModel } from './models';
import { hasPermission, clearToken, getIdOwner, fieldsAudit, isNullOrEmpty, nowUnix } from './helpers/utility';
import { Enum, Table, Security } from './constants';

const {
    user_firebase_signed_out,
    user_firebase_signed_fail,
    load_roles_and_modules,
    load_owner_quizs,
    user_firebase_signed_in,
    setBackupTokenSession
} = actions;

class FirebaseAuthChanged extends React.Component
{
    componentDidMount()
    {
        let idToken = this.props.stateAuth.idToken;
        if (idToken == null)
            this.props.user_firebase_signed_out();

        let catalogs = null;
        const db = firestore();
        const rolServices = require('./services').rolServices;
        const quizService = require('./services').quizService;
        const userService = require('./services').userService;
        this.unsubscribe = auth().onAuthStateChanged(async (user) =>
        {
            this.props.user_firebase_signed_fail();

            if (user && !user.isAnonymous)
            {
                const userRef = db.collection(Table.User).doc(`${user.uid}`);
                let userDoc = await userRef.get();
                try
                {
                    if (userDoc.exists !== true)
                        throw new Error('USER_NOT_EXISTS_BD');

                    let fullInfoUser = new UserModel();
                    fullInfoUser.loadUserDB(userDoc.id, userDoc.data());
                    fullInfoUser.refreshToken = user.refreshToken;
                    if (fullInfoUser.status !== true)
                        throw new Error('USER_INACTIVE');

                    if (fullInfoUser.roles === null || fullInfoUser.roles.length === 0)
                        throw new Error('USER_NOT_PERMISSIONS');

                    // Carga de catálogos roles y modules
                    if (catalogs == null)
                    {
                        let res_roles = await rolServices.listRoles(true);
                        let res_modules = await rolServices.listModules(true);
                        catalogs = { roles: res_roles.data, modules: res_modules.data };
                        this.props.load_roles_and_modules({ roles: catalogs.roles, modules: catalogs.modules });
                    }

                    // Asignación de info completa de roles del usuario
                    let yourRoles = fullInfoUser.roles.map(ID_ROLE =>
                    {
                        let pos = catalogs.roles.findIndex(x => x.id === ID_ROLE);
                        if (pos >= 0)
                            return catalogs.roles[pos];
                        else
                            return null;
                    });
                    fullInfoUser.roles = yourRoles.filter(x => x !== null);

                    if (hasPermission(Enum.RoleModule.AUTH, null, { user: fullInfoUser, roles: catalogs.roles, modules: catalogs.modules }) === false)
                        throw new Error('USER_NOT_PERMISSIONS');

                    // Info token user
                    fullInfoUser.providerId = user.providerData[0].providerId;

                    let token = null;
                    let infoTokenResult = await user.getIdTokenResult();
                    if (this.props.stateAuth.isNewLogin !== true)
                    {
                        token = localStorage.getItem('token_session');
                        if (!!token)
                        {
                            let div = token.split('_', 10);
                            token = div.length === 2 ? token : null;
                        }
                        !!token && console.log('TOKEN_SESSION', token);
                    }
                    if (isNullOrEmpty(token))
                    {
                        token = `${infoTokenResult.claims['auth_time']}_${fullInfoUser.id}`;
                        console.log('USE_CLAIMS', token);
                    }

                    if (infoTokenResult.signInProvider !== 'custom')
                        fullInfoUser.providerId = infoTokenResult.signInProvider;
                    else if (!!infoTokenResult.claims.providerId)
                        fullInfoUser.providerId = infoTokenResult.claims.providerId;

                    // Crear session
                    if (this.props.stateAuth.isNewLogin === true)
                    {
                        await userService.createSession(fullInfoUser.id, token, fullInfoUser.providerId, this.props.stateAuth.linkedSessionId);
                        localStorage.setItem('token_session', token);
                    }

                    // Obtener session del usuario
                    let res_session = await userService.getSession(fullInfoUser.id, token);
                    if (res_session.status && res_session.data.status)
                        fullInfoUser.session = res_session.data;
                    else
                        throw Error('SESSION_INVALID');

                    // Sobrescribir sesion, solo si es desde el MP
                    let tokenSession = this.props.stateAuth.backupTokenSession;
                    if (Security.isSessionValid(tokenSession) && Security.isSessionExpired(tokenSession) === false)
                    {
                        let userIdSession = Security.getUserIdSession(tokenSession)
                        if (!!userIdSession && userIdSession !== fullInfoUser.id)
                        {
                            let sessionOld = res_session.data;
                            let data = {
                                id: sessionOld.linkedSessionId,
                                timeEnd: nowUnix(),
                                status: false
                            };
                            if (!!data.id && sessionOld.id.trim().length > 0)
                                await userService.updateSession(fullInfoUser.id, data);

                            data.id = sessionOld.id;
                            await userService.updateSessionAdmin(fullInfoUser.id, data);

                            throw new Error('OVERWRITE_USER');
                        }
                    }

                    // Cargar acciones permitidas
                    fullInfoUser.loadActions(catalogs.modules);

                    // Carga de quizs owner
                    let idOwner = getIdOwner(fullInfoUser);
                    if (!!idOwner)
                    {
                        let res_quizs = await quizService.ListQuizs(idOwner);
                        if (res_quizs.status)
                            this.props.load_owner_quizs(res_quizs.data)
                    }

                    // Info perfil
                    let profile = await userRef.collection(Table.$User.Profile).limit(4).get();
                    if (profile.docs.length > 0)
                    {
                        let create_Privacity = false;
                        let doc_Privacy = null;

                        fullInfoUser.profile = new UserProfileModel();
                        profile.docs.forEach(doc =>
                        {
                            if (doc.id === Table.$User.$Profile.InfoCreator)
                                fullInfoUser.profile.infoCreator = doc.data();

                            if (doc.id === Table.$User.$Profile.Experience)
                                fullInfoUser.profile.experience = doc.data();

                            if (doc.id === Table.$User.$Profile.Privacity)
                                fullInfoUser.profile.privacity = doc.data();
                            else
                                create_Privacity = true;

                            if (doc.id === 'Privacy')
                                doc_Privacy = doc;
                        });

                        if (!!doc_Privacy)
                            await doc_Privacy.ref.delete();

                        if (create_Privacity)
                        {
                            await userRef.collection(Table.$User.Profile)
                                .doc(Table.$User.$Profile.Privacity)
                                .set({ ...fullInfoUser.profile.privacity, ...fieldsAudit('', 'CREATE'), ...fieldsAudit('', 'UPDATE') });
                        }
                    }

                    // Auto login AWS
                    Auth.signIn("useranonimo@aspit.mx", "@nonimo19_pnnqeredqjasfciqg4f6cFXFva");
                    this.props.user_firebase_signed_in(fullInfoUser)
                    this.props.setBackupTokenSession(null);
                    console.log('AUTH USER', fullInfoUser);

                    // SAVE TOKEN
                    localStorage.setItem('id_token', user.refreshToken);
                }
                catch (error)
                {
                    this.props.user_firebase_signed_fail();
                    console.log('ERROR AUTH', error);
                    await actions.autoSignOut();
                }
            }
            else
            {
                clearToken();
                localStorage.removeItem('token_session');
                this.props.user_firebase_signed_out();
                console.log('AUTH USER', null);
            }
        });
    }

    componentWillUnmount() { this.unsubscribe && this.unsubscribe(); }

    render() { return null; }
}

export default connect(state => ({
    stateAuth: state.Auth
}), {
    user_firebase_signed_out,
    user_firebase_signed_fail,
    load_roles_and_modules,
    load_owner_quizs,
    user_firebase_signed_in,
    setBackupTokenSession
}
)(FirebaseAuthChanged);