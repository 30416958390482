import React from 'react';
import { WrapperSequence } from './style';
import { Typography, Modal, Button, Avatar } from 'antd';
import FormAnwer from './components/FormAnwer';
import { FooterModal, HeaderModal } from '../QuizHeader/style';
import { isNull, sortList } from '../../../../../helpers/utility';
import actions from '../../../../../redux/currentExam/actions';
import { connect } from 'react-redux';
import { FiEdit } from 'react-icons/fi';

const { Paragraph } = Typography;

interface ISequence {
    id: String;
    order: Number;
    answerImage: String | null;
    answerQuestionId: String;
    answerText: String;
    status: Boolean,
    letter: String,
    correctAnswer: Boolean
}

export const initSequencesAnwers: ISequence[] = [
    { letter: 'A', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 1, status: true, id: null },
    { letter: 'B', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 2, status: true, id: null },
    { letter: 'C', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 3, status: true, id: null },
    { letter: 'D', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 4, status: true, id: null },
];

class SequenceAnswer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            selectedSequence: null,
            answers: initSequencesAnwers
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.sequences !== this.props.sequences) {
            if (!isNull(nextProps.sequences)) {
                let answers = sortList(this.state.answers, 'order', true, true);
                let sequences = sortList(nextProps.sequences, 'order', true, true);

                sequences = sequences.map((e, index) => {
                    let answer = answers[index];
                    return {
                        ...answer,
                        ...e,
                        beforeImage: !isNull(e.beforeImage) ? e.beforeImage : e.answerImage,
                    }
                });

                console.log({ sequences });

                this.setState({ answers: sequences });
            } else {

                this.setState({
                    answers: [
                        { letter: 'A', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 1, status: true, id: null },
                        { letter: 'B', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 2, status: true, id: null },
                        { letter: 'C', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 3, status: true, id: null },
                        { letter: 'D', correctAnswer: false, answerQuestionId: "", answerText: "", answerImage: "", order: 4, status: true, id: null },
                    ]
                });
            }
        }
    }



    handleCancel = () => {
        this.setState({ visible: false, selectEdit: null });
    }

    renderHeaderModal = () => (
        <HeaderModal>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!isNull(this.state.selectedSequence) ? <Avatar size="default" style={{ backgroundColor: "#ff5722", marginRight: 10 }}>{this.state.selectedSequence.letter}</Avatar> : null}
                <Paragraph className="title" ellipsis>
                    {`${!isNull(this.state.selectedSequence) ? "Ediar" : "Agregar"} secuencia.`}
                </Paragraph>
            </div>
        </HeaderModal>
    );

    renderFooterModal = () => (
        <FooterModal>
            <Button type="default" onClick={this.handleCancel}>Salir</Button>
            <Button form="formAnswerSequence" type="primary" key="submit" htmlType="submit">
                {`${!isNull(this.state.selectedSequence) ? "Guardar cambios" : "Agregar"}`}
            </Button>
        </FooterModal>
    );

    onSelectedItem = async (item) => {
        this.setState({ visible: true, selectedSequence: item })
    }

    renderAnswer = (answer) => {
        let IS_NULL = isNull(answer.id);
        return (
            <>
                {
                    IS_NULL
                        ? <div className="add-sequence" onClick={() => this.onSelectedItem(answer)}>
                            <span className={"letter-answer"}>{answer.letter}</span>
                            <span className="add">Agregar respuesta</span>
                        </div>
                        : <div className="sequence-row">
                            <span className={answer.correctAnswer ? 'letter-answer correct' : "letter-answer"}>{answer.letter}</span>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                                <span>
                                    {!!answer.answerText ? <Paragraph className="question-paragraph" ellipsis={{ rows: 3 }}>{answer.answerText}</Paragraph> : null}
                                    {!!answer.answerImage ? <div className="content-chip"><div className="chip image" style={{ backgroundImage: 'url(' + answer.answerImage + ')' }}></div></div> : null}
                                </span>
                                <div className="icons">
                                    <button className="edit" onClick={() => this.onSelectedItem(answer)}><FiEdit /></button>
                                </div>
                            </div>
                        </div>
                }

            </>
        )
    }

    existSelectedAnswer(answers) {
        return answers.some(e => e.correctAnswer === true);
    }

    render() {
        let { answers } = this.state;
        return (
            <WrapperSequence>
                <h1><span style={{ color: 'red' }}>*</span> Respuestas</h1>
                <ul>
                    {answers.map((e, index) => <li key={`${index}-${e.letter}`}>{this.renderAnswer(e)}</li>)}
                </ul>

                <Modal
                    title={this.renderHeaderModal()}
                    footer={this.renderFooterModal()}
                    onCancel={() => this.handleCancel()}
                    className="custom-modal-quiz"
                    visible={this.state.visible}
                    maskClosable={false}
                >
                    {
                        !!this.state.visible
                            ? <FormAnwer
                                onCancel={() => this.handleCancel()}
                                selectedSequence={this.state.selectedSequence}
                                sequences={this.state.answers}
                                existSelectedAnswer={this.existSelectedAnswer(answers)}
                            />
                            : null
                    }
                </Modal>
            </WrapperSequence>

        )
    }
}


function mapStateToProps(state) {
    const { Exam } = state;
    return {
        sequences: Exam.question.answers
    };
}



export default connect(mapStateToProps, actions)(SequenceAnswer);