import Response from "../containers/Administration/utils/Response";
import { logError, fieldsAudit, splitArray, sortList } from '../helpers/utility';
import { Table } from "../constants";
import { PaymentModel } from "../models";
import { FilterPayment } from '../models/filters';
import { memberService } from './';
import { firestore } from 'firebase';

import Payment from "../models/Payment";
import userService from "./userService";

const db = firestore();

async function list(idOwner, onlyActives = false): Promise<Response<PaymentModel[]>> {
    let response: Response<PaymentModel[]> = new Response(false);
    try {
        let ref = db.collection(Table.Payment).where(Table.$Payment.quizsUserOwnerId, '==', idOwner);
        let query = onlyActives ? ref.where(Table.$Payment.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item: PaymentModel = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('paymentService.js:listActives', response);
    return response;
}

async function listAll(): Promise<Response<PaymentModel[]>> {
    let response: Response<PaymentModel[]> = new Response(false);
    try {
        let query = db.collection(Table.Payment);

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item: PaymentModel = element.data();
            item.id = element.id;
            console.log("element", element);
            return item;
        });
        sortList(response.data, 'createdAt', true);
        console.log("payments: ", response.data)
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('paymentService.js:listActives', response);
    return response;
}

async function listFilter(filterOpt: FilterPayment): Promise<Response<PaymentModel[]>> {
    let response: Response<PaymentModel[]> = new Response(false);
    try {
        const data = await filterOpt.query().get();
        response.data = data.docs.map(element => {
            let item: PaymentModel = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('paymentService.js:listFilter', response);
    return response;
}

async function listByMember(emailMember: String): Promise<Response<PaymentModel[]>> {
    let response: Response<PaymentModel[]> = new Response(false);
    try {
        let res_member = await memberService.listByEmail(emailMember);
        if (res_member.status) {
            response.data = [];
            let idsMembers = res_member.data.map(elem => { return elem.id });
            let splitMembers = splitArray(idsMembers, 10);

            await Promise.all(splitMembers.map(async (IDS_MEMBERS) => {
                let query = db.collection(Table.Payment)
                    .where(Table.$Payment.paymentMemberId, 'in', IDS_MEMBERS)
                    .where(Table.$Payment.status, '==', true);
                let data = await query.get();
                data.docs.forEach(element => {
                    let item: PaymentModel = element.data();
                    item.id = element.id;
                    response.data.push(item);
                });
                return IDS_MEMBERS;
            }));
            console.log("listByMember: ", response.data)
            sortList(response.data, 'createdAt', true);
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('paymentService.js:listByMember', response);
    return response;
}

async function listByIds(ids: String[]): Promise<Response<PaymentModel[]>> {
    let response: Response<PaymentModel[]> = new Response(false);
    try {
        response.data = [];
        let ref = db.collection(Table.Payment);
        let data = await Promise.all(
            ids.map(ID => { return ref.doc(ID).get(); })
        );
        data.forEach(element => {
            if (element.exists) {
                let item: PaymentModel = element.data();
                item.id = element.id;
                response.data.push(item);
            }
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('paymentService.js:listByIds', response);
    return response;
}

async function getByOrderId(orderId: string): Promise<Response<PaymentModel>> {
    let response: Response<PaymentModel> = new Response(false);
    try {
        let query = db.collection(Table.Payment).where(Table.$Payment.orderId, '==', orderId);
        let data = await query.get();
        if (data.docs.length > 0) {
            let doc = data.docs[0];
            response.data = { id: doc.id, ...doc.data() };
            response.status = true;
        }
        else
            throw new Error('NOT_FOUND_PAYMENT: '+ orderId);
    }
    catch (error) { response.error_data = error; }
    logError('paymentService:getByOrderId', response)
    return response;
}


async function listByOrderId(orderId: string): Promise<Response<PaymentModel>> {
    let response: Response<PaymentModel> = new Response(false);
    try {
        let query = db.collection(Table.Payment).where(Table.$Payment.orderId, '==', orderId);
        let data = await query.get();
        if (data.docs.length > 0) {
            response.data = data.docs.map(e => { return { id: e.id, ...e.data() } });
            response.status = true;
        }
        else
            throw new Error('NOT_FOUND_PAYMENT');
    }
    catch (error) { response.error_data = error; }
    logError('paymentService:getByOrderId', response)
    return response;
}


async function update(idPayment, prmData, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...prmData,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        await db.collection(Table.Payment).doc(idPayment).update(data);

        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('paymentService.js:update', response);
    return response;
}


async function getByMember(idMember: string, status: Boolean = null): Promise<Response> {
    let response = new Response(false);
    try {
        let query;

        if (!!status) {
            query = db.collection(Table.Payment)
                .where(Table.$Payment.paymentMemberId, '==', idMember)
                .where(Table.$Payment.status, '==', status)
        } else {
            query = db.collection(Table.Payment)
                .where(Table.$Payment.paymentMemberId, '==', idMember)
        }

        let data = await query.get();
        if (!data.empty) {
            let doc = data.docs[0];
            response.data = { id: doc.id, ...doc.data() };
        }
        response.status = true;
    } catch (error) {
        response.error = error;
    }
    return response;
}



async function create(payment: Payment, currentUser: string): Promise<Response<Payment>> {
    let response = new Response(false);
    try {
        let data = {
            ...payment,
            ...fieldsAudit(currentUser, 'CREATE')
        };
        delete data['id']
        db.collection(Table.Payment).doc(payment.id).set(data)
        response.data = payment;
        response.status = true;
    } catch (error) {
        response.error = error;
    }

    return response;
}


async function listPaymentForDate(start, end): Promise<Response> {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.Payment)
            .where(Table.$Payment.createdAt, '>=', start)
            .where(Table.$Payment.createdAt, '<=', end)
            .where(Table.$Payment.status, '==', true)
            .get();

        let list = query.docs.map(e => e.data());
        let data = await Promise.all(list.map(async e => {
            let item = Object.assign({}, e);
            let { data, status } = await userService.getAWSUser(e.createdUser);
            if (status) item.user = data;
            return item;
        }));

        response.data = data;
        response.status = true;
    } catch (err) {
        response.error = err;
    }
    return response;
}

async function listFilterBetween(start, end): Promise<Response> {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.Payment)
            .where(Table.$Payment.createdAt, '>=', start)
            .where(Table.$Payment.createdAt, '<=', end)
            .where(Table.$Payment.paymentPaymentTypeId, '==', '47c0fd49-59f7-4020-9dcf-b860e6016e07')
            .where(Table.$Payment.status, '==', true)
            .get();

        let data = query.docs.filter(e => e.data().amount > 0);
        data = query.docs.map(e => {
            let data = e.data();
            let google_fee = (30 / 100 * data.amount).toFixed(2);
            let tax = (data.createdAt >= 1596258000) ? (16 / 100 * google_fee).toFixed(2) : 0;
            let sum = (parseFloat(google_fee) + parseFloat(tax));
            let net = (data.amount - sum);
            return {
                ...data,
                id: e.id,
                google_fee: google_fee,
                tax: tax,
                net: net
            }
        });

        response.data = data;
        response.status = true;
    } catch (err) {
        console.log(err);
    }
    return response;
}


export default {
    list,
    listFilter,
    listByIds,
    listByMember,
    getByOrderId,
    listFilterBetween,
    listPaymentForDate,
    getByMember,
    update,
    create,
    listAll,
    listByOrderId
}