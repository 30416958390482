const actions = {
    SET_CURRENT_QUESTION: 'SET_CURRENT_QUESTION',
    setCurrentQuestion: (question) => {
        return (dispatch, getState) => {
            dispatch({
                type: actions.SET_CURRENT_QUESTION,
                payload: question,
            });
        }
    }
}

export default actions;