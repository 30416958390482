export default class UserSession
{
    /**@reference id_session */
    id: String;
    token: String;
    platform: String;
    provider: String;
    version: String;
    timeStart: Number;
    timeEnd: Number;
    linkedSessionId: String;
    status: Boolean;

    constructor()
    {
        this.platform = 'dashboard';
        this.status = true;
    }
}