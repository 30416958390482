import actions from "./actions";
import { Enum } from '../../constants';
import { isNull } from "../../helpers/utility";

const initState = {
	routerList: [],
	roles: [],
	modules: [],
	ownerQuizs: [],

	isNewLogin: false,
	linkedSessionId: '',
	backupTokenSession: null,

	firebaseAuthInit: false,
	notifId: null,
	idToken: null,
	user: null,
	error: null,
	closingSession: false,
	tabProfileKey: Enum.TabProfile.dataUsr
};

export default function authReducer(state = initState, action)
{
	switch (action.type)
	{
		case actions.LOAD_ROUTER_LIST:
			{
				return {
					...state,
					routerList: action.payload
				};
			}
		case actions.USER_BACKUP_TOKEN_SESSION:
			{
				let oldToken = state.backupTokenSession;
				let newToken = action.payload;
				if (!!oldToken && isNull(newToken))
					newToken = `${oldToken}|used`;

				return {
					...state,
					backupTokenSession: newToken
				};
			}
		case actions.USER_NOTIF_ID_URL:
			{
				return {
					...state,
					notifId: action.payload
				};
			}

		// CATÁLOGOS
		case actions.LOAD_ROLES_AND_MODULES:
			{
				return {
					...state,
					roles: action.payload.roles,
					modules: action.payload.modules
				};
			}
		case actions.LOAD_OWNER_QUIZS:
			{
				return {
					...state,
					ownerQuizs: action.payload
				};
			}

		// INICIO DE SESION
		case actions.USER_FIREBASE_NEW_LOGIN:
			{
				return {
					...state,
					isNewLogin: action.payload.isNewLogin,
					linkedSessionId: action.payload.linkedSessionId
				};
			}
		case actions.LOGIN_ACTIVE:
			{
				return {
					...state,
					idToken: action.payload,
					closingSession: false
				};
			}
		case actions.USER_FIREBASE_SIGNED_IN:
			return {
				...state,
				firebaseAuthInit: true,
				idToken: action.payload.session.token,
				user: action.payload,
				closingSession: false
			};
		case actions.USER_FIREBASE_SIGNED_FAIL:
			{
				return {
					...state,
					firebaseAuthInit: true,
					error: action.payload
				};
			}
		case actions.USER_FIREBASE_REFRESH:
			{
				return {
					...state,
					user: action.payload
				};
			}
		case actions.USER_FIREBASE_TAB_PROFILE:
			{
				return {
					...state,
					tabProfileKey: action.payload
				};
			}

		// CIERRE DE SESIÓN
		case actions.USER_FIREBASE_START_SIGNED_OUT:
			return {
				...state,
				closingSession: true
			};
		case actions.USER_FIREBASE_SIGNED_OUT:
			return {
				...state,
				idToken: null,
				user: null,
				closingSession: false
			};
		default:
			return state;
	}
}