import Response from "../containers/Administration/utils/Response";
import { fieldsAudit } from '../helpers/utility';
import { BankModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function listBanks(onlyActives = false): Promise<Response<BankModel[]>> {
    let response: Response<BankModel[]> = new Response(false);
    try {
        let ref = db.collection(Table.Bank);
        let query = onlyActives ? ref.where('status', '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function listBanksOwner(idOwner: String = null): Promise<Response<BankModel[]>> {
    let response: Response<BankModel[]> = new Response(false);
    try {
        let ref = db.collection(Table.Bank);
        if (idOwner !== null) ref = ref.where(Table.$Bank.bankUserOwnerId, '==', idOwner);
        let query = ref.where(Table.$Bank.status, '==', true)

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createBank(itemBank: BankModel, idUserCurrent = ''): Promise<Response<BankModel>> {
    let response: Response<BankModel> = new Response(false);
    try {
        let data = {
            ...itemBank,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        delete data['nameUserOwner'];
        const insert = await db.collection(Table.Bank).add(data);
        itemBank.id = insert.id;

        response.data = itemBank;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}



async function getBankById(idBank: string): Promise<Response<BankModel>> {
    let response = new Response();
    try {
        let query = await db.collection(Table.Bank).doc(idBank).get();
        if (query.exists) {
            response.data = { ...query.data(), id: query.id }
            response.status = true;
        }
    } catch (error) {
        response.error = error;
    }
    return response;
}



async function updateBank(itemBank: BankModel, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...itemBank,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        delete data['nameUserOwner'];
        await db.collection(Table.Bank).doc(itemBank.id).update(data);

        response.data = itemBank;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}


export default {
    listBanks,
    createBank,
    updateBank,
    listBanksOwner,

    getBankById
}