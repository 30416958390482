import Response from "../containers/Administration/utils/Response";
import { logError, sortList } from '../helpers/utility';
import { DeductionModel } from '../models';
import { Table, Enum } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function listByPaymentId(paymentId): Promise<Response<DeductionModel[]>>
{
    let response: Response<DeductionModel[]> = new Response(false);
    try
    {
        let query = db.collection(Table.Deduction)
            .where(Table.$Deduction.paymentId, '==', paymentId)
            .where(Table.$Deduction.status, '==', true);

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item: DeductionModel = element.data();
            item.id = element.id;
            sortList(item.amountsReceivable, 'order', true);
            return item;
        });
        sortList(response.data, 'order', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('deductionService.js:listByPaymentId', response);
    return response;
}

async function listTypeASPIT(paymentsId: String[]): Promise<Response<DeductionModel[]>>
{
    let response: Response<DeductionModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.Deduction);
        let promises = paymentsId.map(ID => 
        {
            return ref
                .where(Table.$Deduction.paymentId, '==', ID)
                .where(Table.$Deduction.deductionTypeCategory, '==', Enum.CategoryDT.ASPIT)
                .limit(1).get();
        });
        let results = await Promise.all(promises);
        response.data = results.map(data => 
        {
            let item: DeductionModel = { id: '', paymentId: '', amount: 0, amountsReceivable: [] };
            if (data.docs.length > 0)
            {
                item = data.docs[0].data();
                item.id = data.docs[0].id;
            }
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('deductionService.js:listTypeASPIT', response);
    return response;
}

export default {
    listByPaymentId,
    listTypeASPIT
}