import { firestore } from 'firebase';
import { Table } from '../constants';
import Response from '../containers/Administration/utils/Response';
import { TemplateQuizModel, SectionConfiguration } from '../models';
import { fieldsAudit, isNull } from '../helpers/utility';
import { sectionConfigurationService } from './';

const db = firestore();

async function listTemplateQuizs(idQuiz): Promise<Response<TemplateQuizModel[]>> {

    let response = new Response(false);

    try {
        let query = await db.collection(Table.TemplateQuiz)
            .where(Table.$TemplateQuiz.templateQuizQuizId, '==', idQuiz).get();

        response.data = query.docs.map(elem =>
        {
            let item = { ...elem.data(), id: elem.id };
            item.progress = isNull(item.progress) ? 100 : item.progress;
            return item;
        });
        response.status = true;

    } catch (error) {
        response.error = error;
    }

    return response;
}

async function info(idTemplate): Promise<Response<TemplateQuizModel>>
{
    let response: Response<TemplateQuizModel> = new Response(false);
    try
    {
        let query = await db.collection(Table.TemplateQuiz).doc(idTemplate).get();
        if (query.exists)
        {
            let item: TemplateQuizModel = { ...query.data(), id: query.id };
            item.progress = isNull(item.progress) ? 100 : item.progress;
            
            let res_sections = await getSectionsTemplate(idTemplate);
            item.sections = res_sections.status ? res_sections.data : [];

            response.data = item;
            response.status = true;
        }
    }
    catch (error) { response.error_data = error; }
    !response.status && console.warn('templateQuizService:info', response)
    return response;
}

async function getSectionsTemplate(idTemplate): Promise<Response<SectionConfiguration[]>>
{
    let response: Response<SectionConfiguration[]> = new Response(false);
    try
    {
        let query = await db.collection(Table.TemplateQuizSectionConfiguration)
            .where(Table.$TemplateQuizSectionConfiguration.templateQuizSectionConfigurationTemplateQuizId, '==', idTemplate)
            .get();
        response.data = await Promise.all(query.docs.map(async (doc) =>
        {
            let data = doc.data();
            let idSectionConfig = data[Table.$TemplateQuizSectionConfiguration.templateQuizSectionConfigurationSectionConfigurationId];

            let section = new SectionConfiguration();
            section.id = idSectionConfig;

            let res_sec = await sectionConfigurationService.getSectionConfigurationById(section.id);
            if (res_sec.status)
                section = res_sec.data;

            section.templateQuizSectionConfigurationId = doc.id;
            return section;
        }));
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    !response.status && console.warn('templateQuizService:getSections', response)
    return response;
}

async function CreateTemplateQuiz(template: TemplateQuizModel, idUserCurrent: string): Promise<Response<TemplateQuizModel>> {
    let response = new Response(false);
    try {
        let data = {
            ...template,
            ...fieldsAudit(idUserCurrent, 'CREATE'),
        };
        delete data['id'];
        delete data['sections'];

        let query = await db.collection(Table.TemplateQuiz).add({ ...data });
        template.id = query.id;
        response.data = template;
        response.status = true;
    } catch (error) {
        response.error = error;
    }
    return response;
}


async function UpdateTemplateQuiz(template: TemplateQuizModel, idTemplate: string, idUserCurrent: string): Promise<Response<TemplateQuizModel>> {
    let response = new Response(false);
    try {
        let data = {
            ...template,
            ...fieldsAudit(idUserCurrent, 'UPDATE'),
        };
        delete data['id'];
        delete data['sections'];

        db.collection(Table.TemplateQuiz).doc(idTemplate).update({ ...data });
        response.data = { ...data, id: idTemplate };
        response.status = true;
    } catch (error) {
        response.error = error;
    }

    return response;
}


export default {
    listTemplateQuizs,
    info,
    UpdateTemplateQuiz,
    CreateTemplateQuiz
}