import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { Avatar, Progress, Skeleton, Tooltip, Icon, Spin } from "antd";
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaStoreAlt } from "react-icons/fa";
import { auth, firestore } from 'firebase';

import { siteConfig } from '../../settings';
import siteIcon from '../../image/appmission_white.png';
import options from "./options";
import Scrollbars from "../../components/utility/customScrollBar.js";
import IntlMessages from "../../components/utility/intlMessages";
import { hasPermission, isNullOrEmpty, percentProfile, isNull, nowUnix, Log } from '../../helpers/utility';
import { Table, Enum } from '../../constants';
import { UserModel } from '../../models';
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import actions_user from '../../redux/auth/actions';
import ac_FINGlobal from '../../redux/finances/actions';
import actions_question from '../../redux/question/actions';
import 'react-pro-sidebar/dist/css/styles.css';

const {
	toggleOpenDrawer,
	changeOpenKeys,
	changeCurrent,
	toggleCollapsed,
	setCollapsed
} = appActions;

const stripTrailingSlash = str => {
	if (str.substr(-1) === "/")
		return str.substr(0, str.length - 1);

	return str;
};

interface COMProps {
	history: any;
}
class Sidebar extends Component<COMProps>
{
	startingSnapSession = false;
	db: firestore.Firestore;

	constructor(props) {
		super(props);
		this.state = {
			user: null,
			loading: true,
			closing: false,
			current_path: !!this.props.history && !!this.props.history.location ? this.props.history.location.pathname : null,
			showInfoNotVerify: true
		}
	}

	componentDidMount() {
		this.db = firestore();
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);

		if (!!this.props.Auth.user) {
			this.setState({ user: this.props.Auth.user, loading: false });
			this.startSnapSession();
			this.startSnapDocUser();
		}

		this.unlistenHistory = this.props.history.listen((location, action) => {
			if (this.state.current_path !== location.pathname)
				this.setState({ current_path: location.pathname });
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.Auth.user !== this.props.Auth.user) {
			this.setState({ user: this.props.Auth.user, loading: false });

			let beforeUserId = !!prevProps.Auth.user ? prevProps.Auth.user.id : '';
			if (!!this.props.Auth.user)
			{
				if (beforeUserId !== this.props.Auth.user.id)
				{
					this.startSnapSession();
					this.startSnapDocUser();
				}
			}
		}
	}

	componentWillUnmount() {
		this.unlistenHistory();
		this.stopSnapSession();
		this.stopSnapDocUser();
		window.removeEventListener("resize", this.updateDimensions);
	}

	startSnapSession() {
		let user = this.props.Auth.user;
		if (isNull(user)) return;

		console.log('START SNAP SESSION', user.session);
		let idUser = user.id;
		let idSession = user.session.id;
		let closingSession = false;

		this.goToNotification();
		this.stopSnapSession();

		this.startingSnapSession = true;
		this.deleteSnapSession = this.db.collection(Table.User)
			.doc(idUser)
			.collection(Table.$User.SessionAdmin)
			.doc(idSession)
			.onSnapshot((snapshot) => {
				if (!closingSession) {
					let autoSignOff = false;
					if (snapshot.exists === true) {
						let data = snapshot.data();
						autoSignOff = data.status === false;
					}
					else
						autoSignOff = true;

					if (autoSignOff === true) {
						closingSession = true;
						this.stopSnapSession();
						setTimeout(async () => {
							this.doSignOut();
						}, 1000);
					}
				}
			});
	}

	stopSnapSession() {
		this.deleteSnapSession && this.deleteSnapSession();
		this.startingSnapSession = false;
	}

	stopSnapDocUser() { this.snapDocUser && this.snapDocUser(); }
	startSnapDocUser()
	{
		let user: UserModel = this.props.Auth.user;
		if (isNull(user)) return;

		let fields = ['name', 'username', 'photoURL', 'emailVerified'];

		this.stopSnapDocUser();
		this.snapDocUser = this.db.collection(Table.User)
			.doc(user.id)
			.onSnapshot((snapshot) =>
			{
				let existChanges = false;
				if (snapshot.exists === true)
				{
					let data = snapshot.data();
					fields.forEach(KEY =>
					{
						let newValue = data[KEY];
						if(newValue !== undefined && newValue !== user[KEY])
						{
							user[KEY] = newValue;
							existChanges = true;
						}
					});

					if (existChanges)
						this.props.userFireRefresh(user);
				}
			});
	}

	async goToNotification() {
		if (!isNullOrEmpty(this.props.Auth.notifId)) {
			let notifUserService = require('../../services').notifUserService;

			let user = this.props.Auth.user;
			let res_notif = await notifUserService.getInfo(user.id, this.props.Auth.notifId);
			if (res_notif.status) {
				let notif = res_notif.data;
				let goToComments = [
					Enum.TypeNotif.reviewNew,
					Enum.TypeNotif.reviewUpdated,
					Enum.TypeNotif.reviewAnswered
				];
				let goToRptQuestion = [
					Enum.TypeNotif.reportQuestionNew,
					Enum.TypeNotif.reportQuestionUpdated,
					Enum.TypeNotif.reportQuestionAnswered
				];
				let goToQuiz = [
					Enum.TypeNotif.statusQuizInReview,
					Enum.TypeNotif.statusQuizRejected,
					Enum.TypeNotif.statusQuizPublished,
					Enum.TypeNotif.statusQuizUnpublish,
					Enum.TypeNotif.priceQuizChanged,
					Enum.TypeNotif.priceQuizAssigned,
				];
				if (goToComments.some(x => x === notif.type))
					setTimeout(() => { this.props.history.push(`/dashboard/comments`); }, 1000);

				if (goToRptQuestion.some(x => x === notif.type))
					setTimeout(() => { this.props.history.push(`/dashboard/report`); }, 1000);

				if (goToQuiz.some(x => x === notif.type)) {
					setTimeout(() => { this.props.history.push(`/dashboard/exam/${notif.metadata.quizId}`); }, 1000);
					this.props.setNotifIdURL(null);
				}
			}
		}
	}

	updateDimensions = () => {
		let isMedia1330 = window.innerWidth <= 1330;
		this.props.setCollapsed(isMedia1330);
	}

	getPercent() { return percentProfile(this.props.Auth.user); }

	getUrlMarket() { return Log.isProduction ? 'https://appmision.com/' : 'https://appmision-sandbox.web.app/'; }

	autoClose(urlLinkTo) {
		if (this.props.responsive.isMedia1330) {
			if (this.props.app.collapsed === false)
				this.props.setCollapsed(true);
		}
		this.setState({ current_path: urlLinkTo });
	}

	isCurrentOpen(urlLinkTo: String) {
		let current_path: String = this.state.current_path;
		if (urlLinkTo === '/dashboard') {
			if (!this.hasAction(Enum.RoleAction.FINF)) {
				let coincidences: String[] = ['/dashboard/exam', '/dashboard/exam-create'];
				return current_path === urlLinkTo || coincidences.some(value => current_path.includes(value));
			}
			return false;
		}

		if (!!current_path) {
			let includesValue = current_path.includes(`${urlLinkTo}/`);
			let isSameURL = current_path === urlLinkTo || includesValue;
			if (isSameURL)
				return true;
			else {
				let coincidences: String[] = [];
				if (urlLinkTo === '/dashboard/question')
					coincidences = ['/questionGroup'];

				return coincidences.some(value => current_path.includes(value));
			}
		}
		return false;
	}

	getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
		const { key, label, LeftIcon, children } = singleOption;
		const url = stripTrailingSlash(this.props.url);
		if (children) {
			let exist_current_open = false;
			let filterChild = children.filter(x => x.allow === true);
			let renderChilds = filterChild.map(child => {
				const linkTo = child.withoutDashboard
					? `/${child.key}`
					: `${url}/${child.key}`;
				let isOpened = this.isCurrentOpen(linkTo);
				if (!exist_current_open && isOpened)
					exist_current_open = true;

				return (
					<MenuItem key={child.key}>
						<Link
							style={{ color: isOpened ? 'orange' : 'white' }}
							to={linkTo} onClick={() => this.autoClose(linkTo)}>
							<IntlMessages id={child.label} />
						</Link>
					</MenuItem>
				);
			});
			return (
				<SubMenu
					icon={<LeftIcon />}
					defaultOpen={exist_current_open}
					className="sub-menu-pro"
					key={key}
					title={
						<span className="isoMenuHolder">
							<span className="nav-text">
								<IntlMessages id={label} />
							</span>
						</span>
					}>
					{renderChilds}
				</SubMenu>
			);
		}

		if (this.props.app.collapsed)
			return (<Tooltip key={`t-${key}`} placement="right" title={<IntlMessages id={label} />}>{this.renderMenuItem(LeftIcon, url, key, submenuColor, label)}</Tooltip>);
		else
			return this.renderMenuItem(LeftIcon, url, key, submenuColor, label);
	};

	renderMenuItem = (LeftIcon, url, key, submenuColor, label) => {
		let linkTo = isNullOrEmpty(key) ? `${url}` : `${url}/${key}`;
		return (
			<MenuItem icon={<LeftIcon />} key={key}>
				<Link to={linkTo} onClick={() => this.autoClose(linkTo)}>
					<span className="isoMenuHolder">
						<span className="nav-text" style={{ color: this.isCurrentOpen(linkTo) ? 'orange' : 'white' }}>
							<IntlMessages id={label} />
						</span>
					</span>
				</Link>
			</MenuItem>
		);
	}

	getKeyModule(keyOption) {
		let routerList: [] = !!this.props.Auth.routerList ? this.props.Auth.routerList : [];
		let infoRouter = routerList.find(x => x.path === keyOption);
		if (!!infoRouter)
			return isNullOrEmpty(infoRouter.keyModule) ? null : infoRouter.keyModule;
		else
			return null;
	}

	allowMenuItem(singleOption) {
		let key_module = this.getKeyModule(singleOption.key);
		let allow = key_module == null || hasPermission(key_module, this.props.Auth);
		if (singleOption.children != null && allow) {
			let countChildrenAllow = 0;
			for (let index = 0; index < singleOption.children.length; index++) {
				let child = singleOption.children[index];
				child.allow = false;

				let child_key_module = this.getKeyModule(child.key);
				if (child_key_module == null || hasPermission(child_key_module, this.props.Auth)) {
					child.allow = true;
					countChildrenAllow++;
				}
			}
			allow = countChildrenAllow > 0;
		}
		return allow;
	}

	doSignOut() {
		this.props.startSignOut();
		auth().signOut().then(async () => {
			this.props.resetFinances();
			this.props.setListQuestions([]);
		}).catch(err => {
			console.log('topbarUser.js:handleLogout', err)
		});
	}

	onPressMP() {
		if (this.state.loading) return;
		window.open(this.getUrlMarket(), '_self');
	}

	async onPressLogout() {
		this.setState({ closing: true });
		this.props.setBackupTokenSession(null);
		if (this.startingSnapSession === true) {
			let userService = require('../../services').userService;
			let user: UserModel = this.props.Auth.user;
			user.session.timeEnd = nowUnix();
			user.session.status = false;

			if (!!user.session.linkedSessionId && user.session.linkedSessionId.trim().length > 0) {
				let data = {
					id: user.session.linkedSessionId,
					timeEnd: user.session.timeEnd,
					status: false
				};
				await userService.updateSession(user.id, data);
			}
			await userService.updateSessionAdmin(user.id, user.session);
		}
		else
			this.doSignOut();
	}

	hasAction = (ACTION_USER) => {
		return !!this.props.Auth.user && !!this.props.Auth.user.actions ?
			this.props.Auth.user.actions[Enum.RoleModule.EXA].some(x => x === ACTION_USER)
			: false;
	};

	renderMenu(collapsed) {
		const { customizedTheme } = this.props;
		const submenuStyle = {
			backgroundColor: "rgba(0,0,0,0.3)",
			color: customizedTheme.textColor
		};
		const submenuColor = { color: customizedTheme.textColor };

		let filter_options = [];
		options.forEach((singleOption, index) => {
			let allow = this.allowMenuItem(singleOption);
			if (singleOption.name === 'quiz')
				singleOption.key = this.hasAction(Enum.RoleAction.FINF) ? 'quiz' : '';

			if (singleOption.name === 'question')
				allow = this.hasAction(Enum.RoleAction.FINF);

			if (allow)
				filter_options.push(this.getMenuItem({ submenuStyle, submenuColor, singleOption }));
		});

		return (
			<Menu iconShape="circle" key="menu">
				{filter_options.map(optionMenu => optionMenu)}
			</Menu>
		);
	}

	removeOverlay = () => {
		if (!!document.querySelector('.overlay-sidebar'))
			document.querySelector('.overlay-sidebar').remove();
	}

	validEmailVerified() {
		let { user } = this.state;
		if (!!user && user.providerId.includes('password')) {
			return user.username && user.emailVerified
		}
		return true;
	}

	renderNotVerified()
	{
		if (!this.validEmailVerified())
		{
			let showInfoNotVerify = this.state.showInfoNotVerify;
			return (
				<div className="account-not-verified">
					<div className="not-verified-header" onClick={() => this.setState({ showInfoNotVerify: !showInfoNotVerify })}>
						<div>
							<Icon type="warning" style={{ fontSize: 18 }} theme="filled" />
							<span style={{ marginLeft: 10 }}>Cuenta no verificada</span>
						</div>
						<Icon type={showInfoNotVerify ? "up-circle" : "down-circle"} style={{ fontSize: 14 }} />
					</div>
					<div className={showInfoNotVerify ? 'more-info-show' : 'more-info-hide'}>
						<span>
							Tu cuenta aún no ha sido verificada, se te envío un correo electrónico con el enlace de verificación.
						</span>
					</div>
				</div>
			);
		}
		return null;
	}

	render() {
		const { app } = this.props;
		const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
		const antIcon = <Icon type="loading" style={{ fontSize: 18, color: 'white', marginRight: 5 }} spin />;
		let percentConfigUser = this.getPercent();

		return (
			<SidebarWrapper>
				<ProSidebar
					toggled={!collapsed} width={70} collapsed={false} breakPoint="xl"
					onToggle={(value) => { this.props.toggleCollapsed(); }}>
					<SidebarHeader>
						<div className="isoLogoWrapper">
							<Link to="/dashboard" onClick={() => this.autoClose('/dashboard')}>
								<img srcSet={siteIcon} alt="Appmisión" />
							</Link>
							{collapsed ? "" : (
								<h3>
									<Link to="/dashboard" onClick={() => this.autoClose('/dashboard')}>{siteConfig.siteName}</Link>
								</h3>
							)}
						</div>
					</SidebarHeader>
					<SidebarContent>

						<div className={"market"} onClick={() => this.onPressMP()}>
							<Skeleton loading={this.state.loading} paragraph={false} active avatar>
								<FaStoreAlt size={20} />
								<span>Ir al Marketplace</span>
							</Skeleton>
						</div>
						<div className="profile" onClick={() => {
							if (!this.state.loading) {
								this.props.history.push('/dashboard/profile');
								this.autoClose('/dashboard/profile');
							}
						}}>
							<Skeleton loading={this.state.loading} paragraph={false} active avatar>
								<div className={"profile__avatar"}>
									{
										!!this.state.user
											? !!this.state.user.photoURL ? <Avatar src={this.state.user.photoURL} size="large" /> : <Avatar icon="user" size="large" />
											: <Avatar icon="user" size="large" />
									}
								</div>
								{
									!!this.state.user
										? <div className="profile__info">
											<h1>{this.state.user.name}</h1>
											<p>{this.state.user.username}</p>
											<Progress size="small" percent={percentConfigUser} />
											<Icon type="right" className="arrow" />
										</div>
										: null
								}
							</Skeleton>
						</div>
						{this.renderNotVerified()}
						<Scrollbars>
							{this.renderMenu(collapsed)}
						</Scrollbars>
					</SidebarContent>
					<SidebarFooter>
						<button className="btn-close-session" disabled={this.state.closing} onClick={() => this.onPressLogout()}>
							{this.state.closing ? <Spin size="small" indicator={antIcon} /> : null}
							<span>
								Cerrar sesión
							</span>
						</button>
					</SidebarFooter>
				</ProSidebar>
			</SidebarWrapper>
		);
	}
}

const COMSidebar = (props) => {
	const isMedia1330 = useMediaQuery({ maxWidth: 1330 });
	const responsive = { isMedia1330 }
	return (<Sidebar responsive={responsive} {...props} />);
};
export default connect(
	state => ({
		Auth: state.Auth,
		app: state.App,
		customizedTheme: state.ThemeSwitcher.sidebarTheme,
	}),
	{
		...actions_user,
		resetFinances: ac_FINGlobal.resetFinances,
		setListQuestions: actions_question.setListQuestions,
		toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed, setCollapsed
	}
)(COMSidebar);