export class Binnacle {
    actionKey: String;
    affectedSimulator: String;
    affectedUser: String;
    affectedMember: String;
    createdAt: String;
    createdUser: String;
    currentSimulator: String;
    currentUser: String;
    description: String;
    paymentId: String;
    paymentProvider: String;

}
