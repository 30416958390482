import Response from "../containers/Administration/utils/Response";
import { UserModel, RoleModel, UserSessionModel } from '../models';
import { fieldsAudit, isNullOrEmpty, logError, nowUnix, removeUndefined } from '../helpers/utility';
import { Table } from '../constants';
import { firestore } from "firebase";

const db = firestore();
async function listAll(roles: RoleModel[]): Promise<Response> {
    let response = new Response(false);
    response.data = [];
    try {
        const query = db.collection(Table.User);
        const data = await query.get();

        response.data = await Promise.all(data.docs.map(async (userDoc) => {
            let item: UserModel = userDoc.data();
            item.id = userDoc.id;
            if (!!item.roles) {
                let yourRoles = item.roles.map(ID_ROLE => {
                    let pos = roles.findIndex(x => x.id === ID_ROLE);
                    if (pos >= 0) {
                        let roleUser: RoleModel = new RoleModel();
                        roleUser.id = roles[pos].id;
                        roleUser.key = roles[pos].key;
                        roleUser.name = roles[pos].name;
                        roleUser.description = roles[pos].description;
                        return roleUser;
                    }
                    else
                        return null;
                });
                item.roles = yourRoles.filter(x => x !== null);
            }
            return item;
        }));
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userService.js:listAll', response);
    return response;
}

async function listByIds(ids: String[]): Promise<Response<UserModel[]>>
{
    let response: Response<UserModel[]> = new Response(false);
    try
    {
        response.data = [];
        let ref = db.collection(Table.User);
        let data = await Promise.all(
            ids.map(ID => { return ref.doc(ID).get(); })
        );
        data.forEach(element =>
        {
            if (element.exists)
            {
                let item: UserModel = element.data();
                item.id = element.id;
                response.data.push(item);
            }
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('userService.js:listByIds', response);
    return response;
}

async function listByLimit(limit, lastUser: UserModel = null): Promise<Response<UserModel[]>>
{
    let response: Response<UserModel[]> = new Response(false);
    response.data = [];
    try
    {
        let query = db.collection(Table.User).orderBy('username').orderBy('createdAt');
        if (!!lastUser)
            query = query.startAfter(lastUser.username, lastUser.createdAt);

        const data = await query.limit(limit).get();
        response.data = data.docs.map((userDoc) =>
        {
            let item: UserModel = userDoc.data();
            item.id = userDoc.id;
            return item;
        });
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userService.js:listByLimit', response);
    return response;
}

async function startWithField(keyField: 'username' | 'name', start_value: String): Promise<Response<UserModel[]>>
{
    let response: Response<UserModel[]> = new Response(false);
    try
    {
        const end = start_value.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

        let query = db.collection(Table.User)
            .where(Table.$User[keyField], '>=', start_value)
            .where(Table.$User[keyField], '<', end)
        let data = await query.limit(10).get();

        response.data = data.docs.map(element =>
        {
            let item: UserModel = data.docs[0].data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userService.js:startWithField', response);
    return response;
}

async function listByKeyRole(keyRoles: String[], roles: RoleModel[]): Promise<Response> {
    let response = new Response(false);
    try {
        let idsRoles = [];
        keyRoles.forEach(KEY_ROLE => {
            let pos = roles.findIndex(x => x.key === KEY_ROLE);
            if (pos >= 0)
                idsRoles.push(roles[pos].id);
        });
        const isOnlyOne = idsRoles.length === 1;
        const query = db.collection(Table.User)
            .where(Table.$User.roles, isOnlyOne ? 'array-contains' : 'array-contains-any', isOnlyOne ? idsRoles[0] : idsRoles)
            .where(Table.$User.status, '==', true);
        const data = await query.get();

        response.data = await Promise.all(data.docs.map(async (userDoc) => {
            let item: UserModel = userDoc.data();
            item.id = userDoc.id;

            if (item.roles !== null) {
                let yourRoles = item.roles.map(ID_ROLE => {
                    let pos = roles.findIndex(x => x.id === ID_ROLE);
                    if (pos >= 0) {
                        let roleUser: RoleModel = new RoleModel();
                        roleUser.id = roles[pos].id;
                        roleUser.key = roles[pos].key;
                        roleUser.name = roles[pos].name;
                        roleUser.description = roles[pos].description;
                        return roleUser;
                    }
                    else
                        return null;
                });
                item.roles = yourRoles.filter(x => x !== null);
            }
            return item;
        }));
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userService.js:listByKeyRole', response);
    return response;
}

async function info(idUser: String): Promise<Response<UserModel>> {
    let response: Response<UserModel> = new Response(false);
    try {
        const doc = await db.collection(Table.User).doc(idUser).get();

        if (doc.exists) {
            let item = doc.data();
            item.id = doc.id;
            response.data = item;
            response.status = true;
        }
    }
    catch (error) { response.error_data = error; }
    logError('userService.js:info', response);
    return response;
}

async function create(user: UserModel, idUserCurrent: String): Promise<Response> {
    let response = new Response(false);
    try {
        // AUTH
        let params = {
            uid: user.id,
            name: user.name,
            username: user.username,
            password: user.password,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            status: user.status
        };
        let FETCH = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/authuser-create`, {
            method: 'POST',
            body: JSON.stringify(params)
        });
        const infoAuth = await FETCH.json();
        if (infoAuth.status) {
            // User
            let roles = user.roles.map(element => { return element.id; });
            let data = {
                ...user,
                ...fieldsAudit(idUserCurrent, 'CREATE')
            };
            data.roles = roles;
            delete data['profile'];
            delete data['actions'];
            delete data['session'];
            await db.collection(Table.User).doc(infoAuth.data).set(data);
            user.id = infoAuth.data;

            response.data = user;
            response.status = true;
        }
        else {
            response.error = 'ERROR FETCH';
            response.error_data = infoAuth.error;
        }
    }
    catch (error) { response.error_data = error; }

    if (!response.status) {
        if (response.error_data.message != null)
            response.error = response.error_data.message;
    }

    logError('userService.js:create', response);
    return response;
}

async function update(user: UserModel, idUserCurrent: String, useFunction: Boolean): Promise<Response> {
    let response = new Response();
    response.status = false;
    try {
        // AUTH
        let infoAuth = null;
        if (useFunction) {
            let params = {
                id: user.id,
                name: user.name,
                username: user.username,
                password: user.password,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                status: user.status
            };
            let FETCH = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/authuser-update`, {
                method: 'POST',
                body: JSON.stringify(params)
            });
            infoAuth = await FETCH.json();
        }
        if (!useFunction || infoAuth.status) {
            // USER
            let data = {
                ...user,
                ...fieldsAudit(idUserCurrent, 'UPDATE')
            };

            if (!!user.roles)
            {
                data.roles = user.roles.map(elem =>
                {
                    if (typeof elem === 'string')
                        return elem;
                    else
                        return elem.id;
                });
            }

            delete data['id'];
            delete data['profile'];
            delete data['actions'];
            delete data['session'];
            data = removeUndefined(data);
            await db.collection(Table.User).doc(user.id).update(data);

            response.data = user;
            response.status = true;
        }
        else {
            response.error = 'ERROR FETCH';
            response.error_data = infoAuth.error;
        }
    }
    catch (error) { response.error_data = error; }

    if (!response.status) {
        if (response.error_data.message != null)
            response.error = response.error_data.message;
    }

    logError('userService.js:update', response);
    return response;
}

async function getUserByEmail(email: string): Promise<Response<UserModel>> {
    let response = new Response(false);
    try {
        const doc = await db.collection(Table.User).where('username', '==', email).get();
        if (!doc.empty) {
            let member = doc.docs[0];
            let item = member.data();
            item.id = member.id;
            response.data = item;
            response.status = true;
        }
        else
            throw new Error('NOT FOUND');

    } catch (error) {
        response.error = error;
    }
    return response;
}


async function getAWSUserByEmail(email: string): Promise<Response<UserModel[]>> {
    let response = new Response(false);
    try {

        let query = await db.collection(Table.User).where(Table.$User.username, '==', email).get();

        if (!query.empty) {
            let data = query.docs[0];
            response.data = { ...data.data(), id: data.id };
            response.status = true;
        }
    } catch (e) {
        response.error = e;
    }
    return response;
}


async function getAWSUser(id: string): Promise<Response<UserModel>> {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.User).doc(id).get();
        if (query.exists) {
            response.data = { ...query.data(), id: query.id };
            response.status = true;
        }
    } catch (e) {
        response.error = e;
    }
    return response;
}


async function changeStatus(id, status): Promise<Response> {
    let response = new Response(false);
    try {

        let data = { status: status }
        db.collection(Table.User).doc(id).update(data);
        response.status = true;
    } catch (err) {
        response.error = err;
    }
    return response;
}

async function getTokenCustom(idUser: String, providerId: String): Promise<Response<String>>
{
    let response: Response<String> = new Response(false);
    try
    {
        // AUTH
        let params = {
            uid: idUser,
            providerId: providerId
        };
        let FETCH = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/authuser-getCustomToken`, {
            method: 'POST',
            body: JSON.stringify(params)
        });
        const info = await FETCH.json();
        if (info.status)
        {
            response.data = info.data;
            response.status = true;
        }
        else
        {
            response.error = 'ERROR FETCH';
            response.error_data = info.error;
        }
    }
    catch (error) { response.error_data = error; }

    if (!response.status)
    {
        if (!!response.error_data.message)
            response.error = response.error_data.message;
    }
    logError('userService.js:getTokenCustom', response);
    return response;
}

async function getSession(idUser: String, token: String): Promise<Response<UserSessionModel>>
{
    let result: Response<UserSessionModel> = new Response(false);
    result.data = null;
    try
    {
        let info = await db.collection(Table.User)
            .doc(idUser)
            .collection(Table.$User.SessionAdmin)
            .where(Table.$User.$SessionAdmin.token, '==', token).limit(1).get();
        if (info.docs.length > 0)
        {
            let item = info.docs[0].data();
            item.id = info.docs[0].id;

            result.data = item;
            result.status = true;
        }
        else
        {
            let div = token.split('_');
            let start_value = div.length > 0 ? div[0] : '';
            start_value = start_value.length > 0 ? start_value.substring(0, start_value.length - 2) : '';
            console.log('SEARCH_OTHER:' + token, start_value);

            if(!isNullOrEmpty(start_value))
            {
                const end = start_value.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
                info = await db.collection(Table.User)
                    .doc(idUser)
                    .collection(Table.$User.SessionAdmin)
                    .where(Table.$User.$SessionAdmin.token, '>=', start_value)
                    .where(Table.$User.$SessionAdmin.token, '<', end)
                    .limit(1).get();

                let item = info.docs[0].data();
                item.id = info.docs[0].id;

                result.data = item;
                result.status = true;
            }
        }

        if (!result.status)
            throw Error('NOT_FOUND:' + token);
    }
    catch (error) { result.error_data = error; }
    logError('UserService:getSession:' + idUser, result);
    return result;
}

async function createSession(idUser: String, token: String, providerId: String, linkedSessionId: String): Promise<Response<UserSessionModel>>
{
    let result: Response<UserSessionModel> = new Response(false);
    try
    {
        let session = new UserSessionModel();
        session.token = token;
        session.provider = providerId;
        session.timeStart = nowUnix();
        session.timeEnd = 0;
        session.version = process.env.REACT_APP_VERSION;
        session.linkedSessionId = linkedSessionId;
        session.status = true;

        let res_add = await db.collection(Table.User).doc(idUser).collection(Table.$User.SessionAdmin).add({...session});
        session.id = res_add.id;

        result.data = session;
        result.status = true;
    }
    catch (error) { result.error_data = error; }
    logError('UserService:createSession', result);
    return result;
}

async function updateSessionAdmin(idUser: String, session: UserSessionModel): Promise<Response<UserSessionModel>>
{
    let result: Response<UserSessionModel> = new Response(false);
    try
    {
        let data = { ...session };
        delete data['id'];
        await db.collection(Table.User).doc(idUser).collection(Table.$User.SessionAdmin).doc(session.id).update(data);

        result.data = session;
        result.status = true;
    }
    catch (error) { result.error_data = error; }
    logError('UserService:updateSessionAdmin', result);
    return result;
}

async function updateSession(idUser: String, session: UserSessionModel): Promise<Response<UserSessionModel>>
{
    let result: Response<UserSessionModel> = new Response(false);
    try
    {
        let data = { ...session };
        delete data['id'];
        await db.collection(Table.User).doc(idUser).collection(Table.$User.Session).doc(session.id).update(data);

        result.data = session;
        result.status = true;
    }
    catch (error) { result.error_data = error; }
    logError('UserService:updateSession', result);
    return result;
}

async function listSessionByUser(idUser: String): Promise<Response<UserSessionModel[]>>
{
    let result: Response<UserSessionModel[]> = new Response(false);
    try
    {
        let query = await db.collection('User').doc(idUser).collection('Session').get();
        result.data = query.docs.map(e => { return { ...e.data(), id: e.id } });
        result.status = true;
    }
    catch (error) { result.error_data = error; }
    logError('UserService:listSessionByUser', result);
    return result;
}





export default {
    listAll,
    listByIds,
    listByLimit,
    startWithField,
    listSessionByUser,
    getAWSUserByEmail,
    getAWSUser,
    getUserByEmail,
    listByKeyRole,
    changeStatus,
    info,
    create,
    update,
    getTokenCustom,
    getSession,
    createSession,
    updateSessionAdmin,
    updateSession
}