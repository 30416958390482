/**ACCIONES REDUX FINANZAS GLOBALES (FINGlobal) */
const actions = {
    // GENERAL
    SET_MEMBERS: 'SES_SET_MEMBERS',
    setMembers: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_MEMBERS,
                payload: items
            });
        }
    },
    SET_USERS: 'SES_SET_USERS',
    setUsers: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_USERS,
                payload: items
            });
        }
    },
    SET_TOTAL_USERS: 'SES_SET_TOTAL_USERS',
    setTotalUsers: (value) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_TOTAL_USERS,
                payload: value
            });
        }
    },

    SET_SESSIONS: 'SES_SET_SESSIONS',
    setSessions: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_SESSIONS,
                payload: items
            });
        }
    },
    SET_TYPE_INFO: 'SES_SET_TYPE_INFO',
    setTypeInfo: (typeInfo: 'ONLY_MEMBERS' | 'ALL_USERS') =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_TYPE_INFO,
                payload: typeInfo
            });
        }
    },
    SET_FILTER_CURRENT: 'SES_SET_FILTER_CURRENT',
    setFilterCurrent: (filterOpt) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_FILTER_CURRENT,
                payload: filterOpt
            });
        }
    },

    // CATÁLOGOS
    SET_QUIZS: 'SES_SET_QUIZS',
    setQuizs: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_QUIZS,
                payload: items
            });
        }
    }
}

export default actions;