import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, convertToUnix, sortList } from '../helpers/utility';
import { CatalogsModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function listSubSections(sectionId: String, onlyActives = false): Promise<Response<CatalogsModel[]>> {
    let response: Response<CatalogsModel[]> = new Response(false);
    try {
        let ref = db.collection(Table.SubSection).where(Table.$SubSection.subSectionSectionId, '==', sectionId);
        let query = onlyActives ? ref.where(Table.$SubSection.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item = element.data();
            item.id = element.id;
            item.createdAt = convertToUnix(item.createdAt);
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createSubsection(sectionId: String, itemCatalog: CatalogsModel, idUserCurrent = ''): Promise<Response<CatalogsModel>> {
    let response: Response<CatalogsModel> = new Response(false);
    try {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'CREATE'),
            subSectionSectionId: sectionId
        };
        delete data['id'];
        const insert = await db.collection(Table.SubSection).add(data);
        itemCatalog.id = insert.id;

        response.data = itemCatalog;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}


async function updateSubsection(sectionId: String, itemCatalog: CatalogsModel, idUserCurrent = ''): Promise<Response<CatalogsModel>> {
    let response: Response<CatalogsModel> = new Response(false);
    try {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'UPDATE'),
            subSectionSectionId: sectionId
        };
        delete data['id'];
        await db.collection(Table.SubSection).doc(itemCatalog.id).update(data);

        response.data = itemCatalog;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}

async function getSubSectionById(idSubSection: string) {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.SubSection).doc(idSubSection).get();
        if (query.exists) {
            response.data = { ...query.data(), id: query.id };
            response.status = true;
        }
    }
    catch (error) { response.error_data = error; }
    return response;
}

export default {
    listSubSections,
    createSubsection,
    updateSubsection,
    getSubSectionById
}