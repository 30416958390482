import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, isNull, sortList } from '../helpers/utility';
import { CatalogsModel } from '../models';
import { Enum, Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function listCategories(onlyActives = false): Promise<Response> {
    let response = new Response(false);
    try {
        let ref = db.collection(Table.Categorie);
        let query = onlyActives ? ref.where('status', '==', true) : ref;

        const data = await query.get();

        let list = data.docs.map(element => {
            let item = element.data();
            item.id = element.id;
            return item;
        }).filter(e => isNull(e.hide) || e.hide === false);

        list = sortList(list, 'name', false);

        let categorieOTRO = list.find(e => e.key === Enum.CatalogCategory.Otro);
        list = list.filter(e => e.key !== Enum.CatalogCategory.Otro);
        if (!!categorieOTRO) { list = [...list, categorieOTRO]; }

        response.data = list;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createCategorie(itemCatalog: CatalogsModel, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.Categorie).add(data);
        itemCatalog.id = insert.id;

        response.data = itemCatalog;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}


async function updateCategorie(itemCatalog: CatalogsModel, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.Categorie).doc(itemCatalog.id).update(data);

        response.data = itemCatalog;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}


export default {
    listCategories,
    createCategorie,
    updateCategorie
}