import update from 'immutability-helper';
import actions from './actions';

const initState = {

  DeleteListAnwersSequences: [],
  DeleteListQuestionSequences: [],
  DeleteListFeedbackSequences: [],

  ListAnwersSequences: [],
  ListQuestionSequences: [],
  ListFeedbackSequences: [],

  ListQuestions: [],

  ParamsInitFormQuestion: null,

  TableFilter: {
    subSection: [],
    section: [],
    level: [],
    lesson: [],
    filteredInfo: null || {}
  },

};

export default function cardReducer(state = initState, { type, ...action }) {

  switch (type) {

    case actions.CREATE_QUESTION:
      return {
        ...state,
        ListQuestions: [action.payload, ...state.ListQuestions]
      }

    case actions.SET_PARAMS_INIT_FORM_QUESTION:
      return {
        ...state,
        ParamsInitFormQuestion: action.payload
      }

    case actions.SET_LIST_QUESTIONS:
      return {
        ...state,
        ListQuestions: action.payload
      }


    case actions.ADD_ITEM_SEQUENCE:
      return {
        ...state,
        [action.payload.namelist]: [...state[action.payload.namelist], action.payload.item]
      }


    case actions.SET_LIST_SEQUENCES:
      return {
        ...state,
        [action.payload.namelist]: action.payload.list
      }

    case actions.ADD_ITEM_DELETE_SEQUENCE:
      return {
        ...state,
        [action.payload.namelist]: [...state[action.payload.namelist], action.payload.item]
      }

    case actions.DELETE_ITEM_LIST_SEQUENCES:
      return {
        ...state,
        [action.payload.namelist]: state[action.payload.namelist].filter((val, i) => i !== action.payload.indice)
      }


    case actions.UPDATE_FILE_LIST_SEQUENCES:
      return update(state, {
        [action.payload.listSequences]: 
        {
          [action.payload.indice]: 
          {
            [action.payload.dataIndex]: { $set: (action.payload.file == null) ? null : action.payload.file.name },
            [action.payload.typefile === 'audio' ? 'fileAudio' : 'file']: { $set: action.payload.file },
            [action.payload.typefile === 'audio' ? 'tmpaudio' : 'tmpimage']:{ $set: action.payload.tmpfile }
          }
        }
      });

    case actions.UPDATE_TEXT_LIST_SEQUENCES:
      return update(state, {
        [action.payload.listSequences]: {
          [action.payload.indice]: {
            [action.payload.dataIndex]: { $set: action.payload.text },
          }
        }
      });

    case actions.UPDATE_STATUS_SEQUENCES:
      return update(state, {
        [action.payload.listSequences]: {
          [action.payload.indice]: {
            [action.payload.dataIndex]: { $set: action.payload.status },
          }
        }
      });

    case actions.UPDATE_ITEM_LIST_QUESTION:
      return update(state, {
        ListQuestions: {
          [action.payload.indice]: { $set: action.payload.item }
        }
      });


    case actions.RESET_LIST:
      return {
        ...state,
        [action.namelist]: []
      }




    case actions.CHANGE_STATUS_FREE:
      return update(state, {
        ListQuestions: {
          [action.payload.indice]: {
            free: { $set: action.payload.free },
          }
        }
      });

    case actions.SET_LOADING_FREE:
      return update(state, {
        ListQuestions: {
          [action.payload.indice]: {
            loading: { $set: action.payload.loading },
          }
        }
      });



    case actions.SET_FILTER_TABLE_QUESTION:
      return {
        ...state,
        TableFilter: {
          ...state.TableFilter,
          [action.payload.column]: action.payload.filter
        }
      }

    case actions.SET_FILTER_INFO_TABLE_QUESTION:
      return {
        ...state,
        TableFilter: {
          ...state.TableFilter,
          filteredInfo: action.payload.filter
        }
      }


    default:
      return state;
  }
}
