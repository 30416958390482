import Response from "../containers/Administration/utils/Response";
import { UserEmailModel, CatalogsModel } from '../models';
import { fieldsAudit, logError, nowUnix, isNull, splitArray } from '../helpers/utility';
import { Table, Enum } from '../constants';
import { firestore } from "firebase";

import uuid from "uuid/v4";

const db = firestore();
async function list(onlyActives = false): Promise<Response<UserEmailModel[]>> {
    let response: Response<UserEmailModel[]> = new Response(false);
    try {
        let query = db.collection(Table.UserEmail);
        if (onlyActives)
            query = query.where(Table.$UserEmail.status, '==', true);

        const data = await query.get();
        response.data = data.docs.map(doc => {
            let item: UserEmailModel = doc.data();
            item.id = doc.id;
            if (isNull(item.emailSent))
                item.emailSent = [];
            return item;
        });
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userEmailService:list', response);
    return response;
}

async function syncInfo(old_users: UserEmailModel[], new_users: UserEmailModel[], idUserCurrent: String): Promise<Response<UserEmailModel[]>> {
    let response: Response<UserEmailModel[]> = new Response(false);
    try {
        let items_delete: UserEmailModel[] = [];
        let items_insert: UserEmailModel[] = [];
        let items_update: UserEmailModel[] = [];

        // Array delete
        old_users.map(elem => {
            let info = new_users.find(x => !!x.email && x.email.toUpperCase() === elem.email.toUpperCase());
            if (isNull(info))
                items_delete.push(elem);
            return elem;
        });

        // Array Insert o Update
        new_users.map(elem => {
            elem.lastUpdated = nowUnix();
            let info = old_users.find(x => !!elem.email && x.email.toUpperCase() === elem.email.toUpperCase());
            if (isNull(info)) {
                elem.id = uuid();
                items_insert.push({ ...elem, ...fieldsAudit(idUserCurrent, 'CREATE') });
            }
            else {
                elem.id = info.id;
                let exists_changes =
                    elem.idUserType !== info.idUserType ||
                    elem.name !== info.name ||
                    elem.email !== info.email;
                if (exists_changes)
                    items_update.push({ ...elem, ...fieldsAudit(idUserCurrent, 'UPDATE') });
            }
            return elem;
        });

        // Batch delete
        let batch = db.batch();
        items_delete.forEach(elem => {
            let refDoc = db.collection(Table.UserEmail).doc(elem.id);
            batch.delete(refDoc);
        });
        await batch.commit();

        // Batch update
        let split_update = splitArray(items_update, 450);
        await Promise.all(split_update.map(async (items) => {
            batch = null;
            batch = db.batch();
            items.forEach(elem => {
                let refDoc = db.collection(Table.UserEmail).doc(elem.id);
                batch.update(refDoc, elem);
            });
            await batch.commit();
        }));

        // Batch insert
        let split_insert = splitArray(items_insert, 450);
        await Promise.all(split_insert.map(async (items) => {
            batch = null;
            batch = db.batch();
            items.forEach(elem => {
                let refDoc = db.collection(Table.UserEmail).doc(elem.id);
                batch.set(refDoc, elem);
            });
            await batch.commit();
        }));

        response.data = new_users;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userEmailService:syncInfo', response);
    return response;
}

async function usersRegistered(userTypes: CatalogsModel[]): Promise<Response<UserEmailModel[]>> {
    
    let response: Response<UserEmailModel[]> = new Response(false);
    
    response.data = [];
    try {

        let query = await db.collection(Table.User).where(Table.$User.status, '==', true).get();

        let items = query.docs.map(e => { return { ...e.data(), id: e.id } })

        items = items.filter(x => x.complete !== false);
        let usrType = userTypes.find(x => x.key === Enum.UserType.REG);
        response.data = items.map(elem => {
            let item: UserEmailModel = new UserEmailModel();
            item.id = '';
            item.idUser = elem.id;
            item.idUserType = !!usrType ? usrType.id : '';
            item.name = !!elem.person ? elem.person.name : '';
            item.email = elem.username;
            item.platform = !!elem.platform ? elem.platform : '';
            item.status = elem.status;
            return item;
        });
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userEmailService:usersRegistered', response);
    return response;
}

async function usersMember(userTypes: CatalogsModel[]): Promise<Response<UserEmailModel[]>> {
    let response: Response<UserEmailModel[]> = new Response(false);
    
    response.data = [];
    try {
        let query = await db.collection(Table.Member).where(Table.$Member.status, '==', true).get();

        let items = query.docs.map(e => { return { ...e.data(), id: e.id } })

        let usrType = userTypes.find(x => x.key === Enum.UserType.MEM);
        items.map(elem => {
            if (!response.data.some(x => x.idUser === elem.memberUserId)) {
                let item: UserEmailModel = new UserEmailModel();
                item.id = '';
                item.idUser = elem.memberUserId;
                item.idUserType = !!usrType ? usrType.id : '';
                item.status = elem.status;
                response.data.push(item);
            }
            return elem;
        });
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userEmailService:usersMember', response);
    return response;
}

async function updateBatch(users: UserEmailModel[], idUserCurrent: String): Promise<Response<UserEmailModel[]>> {
    let response: Response<UserEmailModel[]> = new Response(false);
    try {
        let batch = null;
        let split_update = splitArray(users, 450);
        await Promise.all(split_update.map(async (items) => {
            batch = null;
            batch = db.batch();
            items.forEach(elem => {
                let refDoc = db.collection(Table.UserEmail).doc(elem.id);
                batch.update(refDoc, {
                    ...elem,
                    ...fieldsAudit(idUserCurrent, 'UPDATE')
                });
            });
            await batch.commit();
        }));
        response.data = users;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('userEmailService:updateBatch', response);
    return response;
}

export default {
    list,
    syncInfo,
    usersRegistered,
    usersMember,
    updateBatch
}