import update from 'immutability-helper';
import actions from './actions';

const initState = {
  allReports: [],
  selectedReport: [],
  selected: -1,
  filterAttr: { bucket: 'Pregunta incorrecta' },
  writeAnswer: false,
  replyReport: false,


  ListMessagesReport: [],
  ListReportsProblems: [],
  
  ReportsProblems: [],

  QuestionReply: {} || null
};

export default function mailReducer(state = initState, action) {
  switch (action.type) {



    case actions.ADD_QUESTIONS_PROBLEM_TYPE:
      return update(state, {
        ListProblemsTypes: {
          [action.payload.indice]: {
            reports: { $set: action.payload.reports }
          }
        }
      });

    case actions.SET_REPORT_PROBLEM_LIST:
      return {
        ...state,
        ListReportsProblems: action.payload
      }

    case actions.SET_QUESTION_REPLY:
      return {
        ...state,
        QuestionReply: action.payload
      }

    case actions.SET_LIST_MESSAGES_REPORTS:
      return {
        ...state,
        ListMessagesReport: action.payload
      };
    // case actions.UPDATED_VALIDATED:
    //   return update(state, {
    //     ListReportsProblems: {
    //       [action.payload.index]: {
    //         reports: { $set: action.payload.reports }
    //       }
    //     }
    //   });
    // case actions.UPDATED_REPLY:
    //   return update(state, {
    //     ListReportsProblems: {
    //       [action.payload.index]: {
    //         reports: { $set: action.payload.reports }
    //       }
    //     }
    //   });








    case actions.LOAD_REPORTS:
      {
        return {
          ...state, allReports: action.allReports
        };
      }
    case actions.LOAD_REPORT:
      {
        return {
          ...state, selectedReport: action.report
        };
      }
    case actions.FILTER_ATTRIBUTE:
      return {
        ...state,
        writeAnswer: false,
        replyReport: false,
        selected: -1,
        filterAttr: { ...action.filterAttr }
      };
    case actions.SELECTED_REPORT:
      return {
        ...state,
        replyReport: false,
        selected: action.selected,
        allReports: action.allReports
      };
    case actions.WRITE_ANSWER_:
      return {
        ...state,
        replyReport: false,
        writeAnswer: action.writeAnswer
      };
    case actions.REPLY_REPORT:
      return {
        ...state,
        replyReport: action.replyReport
      };
    default:
      return state;
  }
}
