import moment from 'moment';
import { nowUnix } from '../helpers/utility';

/**
 * Función que permite encriptar un valor tipo cadena
 * @param {string} value Cadena a encriptar 
 * @returns dato encriptado
 */
function encrypt(value: String)
{
    let now_unix = parseInt((new Date().getTime() / 1000).toFixed(0));
    let part_unix = now_unix.toString().substring(6);

    var regex = new RegExp('=', 'g');
    let str_start = '==|';
    let str_end = '|==';

    let result = [...value].reverse().join('');
    result = `${part_unix}${str_start}${[...btoa(value)].reverse().join('')}${str_end}${part_unix}`;
    result = [...result].reverse().join('')
    result = btoa(result).replace(regex, '#');
    return [...result].reverse().join('');
}

/**
 * Función que permite decifrar un dato encriptado
 * @param {string} value Dato encriptado
 * @returns Cadena con el valor decifrado
 */
function decrypt(value: String)
{
    var regex = new RegExp('#', 'g');
    let str_start = '==|';
    let str_end = '|==';

    let result = [...value].reverse().join('');
    try
    {
        result = result.replace(regex, '=');
        result = atob(result);
        result = [...result].reverse().join('');

        let posDivider = result.indexOf(str_start);
        if (posDivider >= 0)
        {
            let is_number = false;
            let beforeData = result.substring(0, posDivider);
            try { is_number = !isNaN(beforeData) && !isNaN(parseFloat(beforeData)); }
            catch (error) { }
            if (is_number !== true)
                throw new Error('divider_incorrect');

            result = result.substring(posDivider + str_start.length);
        }
        else
            throw new Error('divider_not_found');

        posDivider = result.indexOf(str_end);
        if (posDivider >= 0)
        {
            let is_number = false;
            let afterData = result.substring(posDivider + str_end.length);
            try { is_number = !isNaN(afterData) && !isNaN(parseFloat(afterData)); }
            catch (error) { }
            if (is_number !== true)
                throw new Error('divider_incorrect');

            result = result.substring(0, posDivider);
        }
        else
            throw new Error('divider_not_found');

        result = [...result].reverse().join('');
        result = atob(result);
    }
    catch (error)
    {
        console.log('DECRYPT:ERROR', error.message);
        result = null;
    }
    return result;
}

function isNumeric(value)
{
    let is_number = false;
    try { is_number = !isNaN(value) && !isNaN(parseFloat(value)); }
    catch (error) { }
    return is_number;
}

function getInfoHash(data)
{
    let result = JSON.stringify(data);
    result = btoa(result);
    return result;
}

function isSessionValid(tokenSession)
{
    if (!!tokenSession)
    {
        let dataDecrypt = decrypt(tokenSession);
        if (!!dataDecrypt && dataDecrypt.includes('|'))
            return true;
    }
    return false;
}

function isSessionExpired(tokenSession)
{
    if (isSessionValid(tokenSession))
    {
        let dataDecrypt = decrypt(tokenSession);
        let divData = dataDecrypt.split('|', 5);
        let createdAt = divData.length >= 4 ? isNumeric(divData[3]) ? parseInt(divData[3]) : 0 : 0;

        let isExpired = true;
        if (createdAt > 0)
            isExpired = moment.unix(createdAt).add(1, 'minutes').unix() <= nowUnix();

        if (isExpired === false)
            return false;
    }
    return true;
}

function getUserIdSession(tokenSession)
{
    if (isSessionValid(tokenSession))
    {
        let dataDecrypt = decrypt(tokenSession);
        let divData = dataDecrypt.split('|', 5);
        let userId = divData[0];
        return userId;
    }
    return null;
}

export default {
    encrypt,
    decrypt,
    isNumeric,
    getInfoHash,
    isSessionValid,
    isSessionExpired,
    getUserIdSession
}

