import update from 'immutability-helper';
import actions from './actions';


const initState = {
    inboxType: "reports",
    problemsReport: [],
    inboxComments: [],

    inboxListContent: [],


};

export default function inboxReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_PROBLEMS_REPORT:
            return {
                ...state,
                problemsReport: action.payload
            }

        case actions.SET_INBOX_COMMENTS:
            return {
                ...state,
                inboxComments: action.payload
            }

        case actions.SET_IBOX_LIST_CONTENT:
            return {
                ...state,
                inboxListContent: action.payload
            }
        case actions.SET_IBOX_TYPE:
            return {
                ...state,
                inboxType: action.payload
            }

        case actions.UPDATED_MESSAGE_ROBLEMS_REPORT:{            
            return update(state, {
                problemsReport: {
                    [action.payload.indiceProblesReport]: {
                        reports: {
                            [action.payload.indexReport]: {
                                reply: { $set: true },
                                validated: { $set: true },
                                reviews: { $set: [
                                    ...state.problemsReport[action.payload.indiceProblesReport].reports[action.payload.indexReport].reviews,
                                    action.payload.comment
                                ] }
                            }
                        }
                    }
                }
            });
        }

        case actions.UPDATED_REPLY_ROBLEMS_REPORT:
            return update(state, {
                problemsReport: {
                    [action.payload.indiceProblesReport]: {
                        reports: {
                            [action.payload.indexReport]: {
                                reply: { $set: action.payload.reply },
                                validated: { $set: true }
                            }
                        }
                    }
                }
            });

        case actions.UPDATED_VALIDATED_ROBLEMS_REPORT:
            return update(state, {
                problemsReport: {
                    [action.payload.indiceProblesReport]: {
                        reports: {
                            [action.payload.indexReport]: {
                                validated: { $set: action.payload.validated }
                            }
                        }
                    }
                }
            });






        case actions.UPDATED_REPLY_INBOX_COMMENTS:
            return update(state, {
                inboxComments: {
                    [action.payload.indice]: { $set: action.payload.comment }
                }
            });

        case actions.UPDATED_VALIDATED_INBOX_COMMENTS:
            return update(state, {
                inboxComments: {
                    [action.payload.indice]: {
                        validated: { $set: action.payload.validated }
                    }
                }
            });

        case actions.REMOVE_ITEM_INBOX_LIST_CONTENT:
            return {
                ...state,
                inboxListContent: state.inboxListContent.filter(f => f.id !== action.payload)
            }

        default:
            return state
    }
}