import { firestore } from 'firebase';
import Response from "../containers/Administration/utils/Response";
import { fieldsAudit } from "../helpers/utility";
import { Table } from '../constants';
import { QuizModel, PromotionModel, PriceRangeModel } from '../models';
import { quizService } from './';

const db = firestore();

async function listPromotions(onlyActives = false)
{
    let response: Response<PromotionModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.Promotion);
        let query = onlyActives ? ref.where(Table.$Promotion.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    !response.status && console.log('promotionService:listPromotions', response);
    return response;
}

async function quizesWithPromo(promotionId: String)
{
    let response: Response<QuizModel[]> = new Response(false);
    try
    {
        let query = db.collection(Table.Quiz)
            .where(Table.$Quiz.quizsPromotionId, '==', promotionId)
            .where(Table.$Quiz.status, '==', true);

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    !response.status && console.log('promotionService:quizesWithPromo', response);
    return response;
}

async function existsQuizesWithPromo(promotionId: String)
{
    let response: Response<Boolean> = new Response(false);
    try
    {
        let query = db.collection(Table.Quiz)
            .where(Table.$Quiz.quizsPromotionId, '==', promotionId)
            .where(Table.$Quiz.status, '==', true).limit(1);

        const data = await query.get();
        response.data = data.docs.length > 0;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    !response.status && console.log('promotionService:existsQuizesWithPromo', response);
    return response;
}

async function inactivePromoQuizes(priceRangeRestore: PriceRangeModel, quizes: String[])
{
    let response: Response<String[]> = new Response(false);
    try
    {
        if (!!priceRangeRestore && quizes.length > 0)
            await quizService.updatePriceInApp(priceRangeRestore.amount, quizes);

        let data_update = {
            quizsPromotionId: null,
            ...fieldsAudit(null, 'UPDATE')
        };
        await Promise.all(quizes.map(async (quizId) =>
        {
            data_update.id = quizId;
            await quizService.UpdateQuiz(data_update, null);
        }));

        response.data = quizes;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    !response.status && console.log('promotionService:inactivePromoQuizes', response);
    return response;
}

async function create(item: PromotionModel, idUserCurrent = '')
{
    let response: Response<PromotionModel> = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.Promotion).add(data);
        item.id = insert.id;

        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    !response.status && console.log('promotionService:create', response);
    return response;
}

async function update(item: PromotionModel, idUserCurrent = '')
{
    let response: Response<PromotionModel> = new Response(false);
    try
    {
        let data = {
            ...item,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        delete data['quizes'];
        await db.collection(Table.Promotion).doc(item.id).update(data);

        response.data = item;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    !response.status && console.log('promotionService:update', response);
    return response;
}

export default {
    listPromotions,
    quizesWithPromo,
    existsQuizesWithPromo,
    inactivePromoQuizes,
    create,
    update
}