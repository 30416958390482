/**ACCIONES REDUX QUESTION GROUP (QGroup) */
const actions = {
    
    // CATÁLOGOS
    SET_OWNERS: 'QGROUP_SET_OWNERS',
    setUsersOwner: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_OWNERS,
                payload: items
            });
        }
    },

    SET_QUESTIONS: 'QGROUP_SET_QUESTIONS',
    setQuestions: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_QUESTIONS,
                payload: items
            });
        }
    }
}

export default actions;