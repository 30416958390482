export default class ChargeStripe
{
    id: String;
    payment_intent: String;
    amount: Number;
    created: Number;
    currency: String;
    description: String;
    fees: Number;
    net: Number;
    type: 'Charge' | 'Adjustment' | 'Refund';
}