import React, { Component } from 'react';
import { Spin } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import { hasPermission } from '../../helpers/utility';
import Enum from '../../constants/Enum';
import { ContentSpin } from './commonStyle';

const routes = (stateAuth) =>
{
	let items = [
		{
			path: '',
			keyModule: null,
			component: asyncComponent(() => import('../Widgets/index.js')),
		},
		{
			path: 'profile',
			keyModule: null,
			component: asyncComponent(() => import('../Administration/UserProfile/Profile')),
			exact: false,
		},
		// EXAMENES
		{
			path: 'quiz',
			keyModule: Enum.RoleModule.EXA,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.EXA, stateAuth) ? import('../Administration/Quiz') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'exam/:id',
			keyModule: Enum.RoleModule.EXA,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.EXA, stateAuth) ? import('../Administration/AdministrationQuiz') : import('../Page/404')),
			exact: false,
		},
		// {
		// 	path: 'exam',
		// 	keyModule: Enum.RoleModule.EXA,
		// 	component: asyncComponent(() => hasPermission(Enum.RoleModule.EXA, stateAuth) ? import('../Administration/Exam') : import('../Page/404')),
		// 	exact: false,
		// },
		{
			path: 'exam-create',
			keyModule: Enum.RoleModule.EXA,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.EXA, stateAuth) ? import('../Administration/Exam/form-create') : import('../Page/404')),
			exact: false,
		},
		// PREGUNTAs
		{
			path: 'question',
			keyModule: Enum.RoleModule.PRE,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.PRE, stateAuth) ? import('../Administration/Question') : import('../Page/404')),
			exact: false,
		},
		// GRUPO DE PREGUNTAs
		{
			path: 'questionGroup',
			keyModule: Enum.RoleModule.PRE,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.PRE, stateAuth) ? import('../Administration/QuestionGroup') : import('../Page/404')),
			exact: false,
		},
		// CATÁLOGOS
		{
			path: 'scripts_temp',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Scripts/InfoTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'assets',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Asset/AssetsTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'section',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Section/TableSections') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'currency',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Currency/CurrencyTable') : import('../Page/404')),
		},
		{
			path: 'priceRange',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/PriceRange/PriceRangeTable') : import('../Page/404')),
		},
		{
			path: 'quizType',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/QuizType/QuizTypeTable') : import('../Page/404')),
		},
		{
			path: 'questionType',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/QuestionType/QuestionTypeTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'answerType',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/AnswerType/AnswerTypeTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'bank',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Bank/BankTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'bannerSlider',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/BannerSlider/BannerTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'level',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Level/LevelTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'categorie',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Categorie/CategorieTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'role',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Role/RoleTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'deductionType',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/DeductionType/DeductionTypeTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'deduction-isr',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/DeductionISR/DeductionISRTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'paymentType',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/PaymentType/PaymentTypeTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'trakingType',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/TrakingType/TrakingTypeTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'reason',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Reason/ReasonTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'problemType',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/ProblemType/ProblemTypeTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'userType',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/UserType/UserTypeTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'templateEmail',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/TemplateEmail/TemplateEmailTable') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'promotions',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Catalogs/Promotion/PromoTable') : import('../Page/404')),
			exact: false,
		},
		// USUARIOS
		{
			path: 'users',
			keyModule: Enum.RoleModule.USU,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.USU, stateAuth) ? import('../Administration/Users/TableUsers') : import('../Page/404')),
			exact: false,
		},
		// PREGUNTAS REPORTADAS
		{
			path: 'report',
			keyModule: Enum.RoleModule.MEN,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.MEN, stateAuth) ? import('../Administration/ReportQuestion') : import('../Page/404')),
		},
		// Comentarios
		{
			path: 'comments',
			keyModule: Enum.RoleModule.MEN,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.MEN, stateAuth) ? import('../Administration/Comments') : import('../Page/404')),
		},
		// FINANZAS LOCALES
		{
			path: 'sales',
			keyModule: hasPermission(Enum.RoleModule.FING, stateAuth) ? Enum.RoleModule.DENIED : Enum.RoleModule.FINL,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.FINL, stateAuth) ? import('../Administration/Finances/Sales') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'refunds',
			keyModule: hasPermission(Enum.RoleModule.FING, stateAuth) ? Enum.RoleModule.DENIED : Enum.RoleModule.FINL,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.FINL, stateAuth) ? import('../Administration/Finances/Refunds') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'deposits',
			keyModule: hasPermission(Enum.RoleModule.FING, stateAuth) ? Enum.RoleModule.DENIED : Enum.RoleModule.FINL,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.FINL, stateAuth) ? import('../Administration/Finances/Deposits') : import('../Page/404')),
			exact: false,
		},
		// FINANZAS GLOBALES
		{
			path: 'admin_deposit_store',
			keyModule: Enum.RoleModule.FING,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.FING, stateAuth) ? import('../Administration/Finances/Admin/DepositStore') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'admin_sales',
			keyModule: Enum.RoleModule.FING,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.FING, stateAuth) ? import('../Administration/Finances/Admin/Sales') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'admin_refunds',
			keyModule: Enum.RoleModule.FING,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.FING, stateAuth) ? import('../Administration/Finances/Admin/Refunds') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'admin_deposits',
			keyModule: Enum.RoleModule.FING,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.FING, stateAuth) ? import('../Administration/Finances/Admin/Deposits') : import('../Page/404')),
			exact: false,
		},
		// MIEMBROS
		{
			path: 'member',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => import('../Administration/Member')),
			exact: false,
		},
		{
			path: 'member-edit',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => import('../Administration/Member/edit')),
			exact: false,
		},
		{
			path: 'payments',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => import('../Administration/Member/payments')),
			exact: false,
		},
		{
			path: 'userEmail',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Member/Emails') : import('../Page/404')),
			exact: false,
		},
		{
			path: 'binnacle',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.CAT, stateAuth) ? import('../Administration/Member/Binnacle') : import('../Page/404')),
			exact: false
		},
		// BLOGS
		{
			path: 'blogs',
			keyModule: Enum.RoleModule.BLG,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.BLG, stateAuth) ? import('../Administration/Blogs') : import('../Page/404')),
			exact: false,
		},

		{
			path: 'owner-profile',
			keyModule: Enum.RoleModule.PERF,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.PERF, stateAuth) ? import('../Administration/Owner') : import('../Page/404')),
			exact: false,
		},

		{
			path: 'help-web',
			keyModule: Enum.RoleModule.HLP,
			component: asyncComponent(() => hasPermission(Enum.RoleModule.HLP, stateAuth) ? import('../Administration/Help/Web/HelpWeb') : import('../Page/404')),
			exact: false,
		},

		{
			path: 'reports-quiz',
			keyModule: Enum.RoleModule.CAT,
			component: asyncComponent(() => import('../Administration/ReportQuiz')),
			exact: false,
		},
	];

	const hasAction = (ACTION_USER) => 
	{
		return !!stateAuth.user && !!stateAuth.user.actions ?
			stateAuth.user.actions[Enum.RoleModule.EXA].some(x => x === ACTION_USER)
			: false;
	};
	if (!hasAction(Enum.RoleAction.FINF))
	{
		let pos = items.findIndex(x => x.path === 'quiz');
		if (pos >= 0)
			items.splice(pos, 1);
		pos = items.findIndex(x => x.path === 'question');
		if (pos >= 0)
			items.splice(pos, 1);
	}
	return items;
}

interface COMProps
{
	auth: any;
	url: any;
	style: any;
}
class AppRouter extends Component<COMProps>
{
	constructor(props)
	{
		super(props);
		this.state = {
			auth: this.props.auth,
			renderRoutes: null
		}
	}

	componentDidMount()
	{
		this.setState({ renderRoutes: this.getRenderRoutes() });
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevProps.auth !== this.props.auth)
		{
			let existsChanges = false;
			if (prevProps.auth.idToken !== this.props.auth.idToken)
				existsChanges = true;

			if (prevProps.auth.user !== this.props.auth.user)
				existsChanges = true;

			if (prevProps.auth.closingSession !== this.props.auth.closingSession)
				existsChanges = true;

			existsChanges && this.setState({ auth: this.props.auth }, () => 
			{
				this.setState({ renderRoutes: this.getRenderRoutes() });
			});
		}
	}

	getRenderRoutes()
	{
		let routerList = [];
		let result = routes(this.state.auth).map(singleRoute =>
		{
			const { path, exact, ...otherProps } = singleRoute;
			
			let item = {...singleRoute};
			delete item['component'];
			routerList.push(item);

			let url: String = this.props.url + "/" + singleRoute.path;

			if (url.includes('//')) { url = url.replace('//', '/'); }

			return (
				<Route
					exact={exact === false ? false : true}
					key={singleRoute.path}
					path={url}
					{...otherProps}
				/>
			);
		});

		this.props.load_router_list(routerList);
		return result;
	}

	render()
	{
		if (this.state.auth.idToken == null)
			return (<Redirect to="/" />);
		else if (this.state.auth.idToken !== null && this.state.auth.user === null)
		{
			return (
				<ContentSpin>
					<Spin tip="Cargando..."></Spin>
				</ContentSpin>
			);
		}
		else if (this.state.auth.closingSession === false)
		{
			return (
				<div style={this.props.style} className="content-main">
					{this.state.renderRoutes}
				</div>
			);
		}
		else
			return null;
	}
}

export default AppRouter;