export default class UserEmail
{
    id: String;
    idUser: String;
    idUserType: String;
    name: String;
    email: String;
    platform: String;
    status: Boolean;
    lastUpdated: Number;
    emailSent: String[];

    constructor()
    {
        this.lastUpdated = 0;
        this.status = true;
        this.emailSent = [];
    }
}