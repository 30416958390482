import Response from "../containers/Administration/utils/Response";
import { fieldsAudit } from '../helpers/utility';
import { CatalogsModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function getListTypesQuizes(onlyActives = false): Promise<Response<CatalogsModel[]>>
{
    let response: Response<CatalogsModel[]> = new Response(false);
    try
    {
        let ref = db.collection(Table.QuizType);
        let query = onlyActives ? ref.where('status', '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createTypeQuiz(itemCatalog: CatalogsModel, idUserCurrent = ''): Promise<Response<CatalogsModel>>
{
    let response: Response<CatalogsModel> = new Response(false);
    try
    {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.QuizType).add(data);
        itemCatalog.id = insert.id;

        response.data = itemCatalog;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function updateTypeQuiz(itemCatalog: CatalogsModel, idUserCurrent = ''): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.QuizType).doc(itemCatalog.id).update(data);

        response.data = itemCatalog;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}

export default {
    getListTypesQuizes, createTypeQuiz, updateTypeQuiz
}