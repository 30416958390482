import { fieldsAudit } from '../helpers/utility';
import Response from "../containers/Administration/utils/Response";

import { firestore } from 'firebase';
import { Table } from "../constants";
import { sectionService, bankService } from ".";
import { SectionConfiguration } from "../models";
import levelService from "./levelService";
import subSectionService from "./subSectionService";
import questionTypeService from "./questionTypeService";

const db = firestore();

async function CreateSectionConfiguration(section: SectionConfiguration, currentUser: string): Promise<Response> {
    let response = new Response(false);
    try {
        delete section['id'];

        section = {
            ...section,
            ...fieldsAudit(currentUser, "CREATE")
        }

        let query = await db.collection(Table.SectionConfiguration).add({ ...section })
        response.data = { ...section, id: query.id };
        response.status = true;
    }
    catch (error) {
        console.log(error);
        response.error_data = error;
    }
    return response;
}

async function UpdateSectionConfiguration(section: SectionConfiguration, currentUser: string): Promise<Response> {
    let response = new Response(false);

    try {

        let sectionConfigurationId: string = section.id;
        let data = {
            ...section,
            ...fieldsAudit(currentUser, 'UPDATE')
        }

        delete data['id']

        db.collection(Table.SectionConfiguration).doc(sectionConfigurationId).update({ ...data });
        response.data = section;
        response.status = true;
    }
    catch (error) {
        console.log(error);
        response.error_data = error;
    }
    return response;
}

async function DeleteSectionConfiguration(sectionConfigurationId): Promise<Response> {
    let response = new Response(false);
    try { 
        db.collection(Table.SectionConfiguration).doc(sectionConfigurationId).delete();
        response.status = true;
    } catch (error) {
        console.log(error);
        response.error = error;
    }
    return response;
}

async function deleteTemplateQuizSectionConfiguration(sectionConfigurationId): Promise<Response> {
    let response = new Response();
    try {

        db.collection(Table.TemplateQuizSectionConfiguration).doc(sectionConfigurationId).delete();
        response.status = true;
    } catch (error) {
        console.log(error);
        response.error = error
    }
    return response;
}


//----------------------------------------------------Firebase------------------------------------------------------

async function getSectionConfigurationById(idSection: string): Promise<Response> {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.SectionConfiguration).doc(idSection).get();
        if (query.exists) {
            let data: SectionConfiguration = query.data();
            response.data = { ...data, id: query.id, };

            let section = await sectionService.getSectionById(data.sectionConfigurationSectionId);
            response.data = (section.status) ? { ...response.data, section: section.data } : { ...response.data, section: null };

            let bank = await bankService.getBankById(data.sectionConfigurationBankId);
            response.data = (bank.status) ? { ...response.data, bank: bank.data } : { ...response.data, bank: null };

            let level = await levelService.getLevelById(data.sectionConfigurationLevelId);
            response.data = (level.status) ? { ...response.data, level: level.data } : { ...response.data, level: null };

            let subSection = await subSectionService.getSubSectionById(data.sectionConfigurationSubSectionId);
            response.data = (subSection.status) ? { ...response.data, subSection: subSection.data } : { ...response.data, subSection: null };

            let questionType = await questionTypeService.getQuestionTypeById(data.sectionConfigurationQuestionTypeId);
            response.data = (questionType.status) ? { ...response.data, questionType: questionType.data } : { ...response.data, questionType: null };

            response.status = true;
        }
    } catch (error) {
        response.error = error;
    }

    return response;
}

export default {
    CreateSectionConfiguration,
    UpdateSectionConfiguration,
    DeleteSectionConfiguration,
    deleteTemplateQuizSectionConfiguration,


    //Firebase
    getSectionConfigurationById
}