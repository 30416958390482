import styled from "styled-components";

const ContentTitle = styled.div`
    width: 320px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #f4f8fb;
    padding-right: 50px;

    div.ant-typography {
        color: #142e7a;
    }

    button {
        border: none;
        position: absolute;
        top: 2px;
        right: 2px;
        height: calc(100% - 4px);
        width: 43px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        cursor: pointer;
        background-color: #ffffff;
        
    }

    @media (min-width: 910px) and (max-width: 931px)  {
        width: 300px;
    }
    
    @media (min-width: 457px) and (max-width: 651px)  {
        width: 210px;
    }

    @media(max-width: 538px) {
        width: calc(100% - 55px);
    }
    
`;

const ContentQuestionGrid = styled.div`
    display: grid;
    grid-template-columns: 1.4fr 0.6fr;
    margin-bottom: 20px;
    gap: 20px;

    &>div {
        background-color: #eef2f7;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media(max-width: 669px) {
        grid-template-columns: 1fr;
    }
`;


const GridContent = styled.div`
    display: grid;
    gap: 20px;
    &.g-c-3{
        grid-template-columns: repeat(3, 1fr);
    }

    &.g-c-2{
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 571px){
        &.g-c-2,
        &.g-c-3{
            grid-template-columns: 1fr;
        }
    }
`;

const ContentCollapse = styled.div`
    .ant-collapse{
        background-color: #F8F9FB;
        border: none;
    }

    .ant-collapse-item-disabled {
        opacity: 0.3;
    }

    .ant-collapse-header[aria-expanded="true"] {
        background-color: #ffffff;
    }

    .ant-collapse-content-active {
        border-top: none;
    }

    .ant-collapse-content-box{
        padding: 16px 40px 16px 60px !important;
        position: relative;
    
        &::before {
            content: "";
            width: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 30px;
            background-color: #EBECEE;
            z-index: 0;
        }

        @media(max-width: 485px) {
            padding: 16px 10px 16px 10px !important;

            &::before {
                content: none;
            }
        }
    }


    .ant-collapse > .ant-collapse-item{
        border-color: #E7E8EA;
        &:last-child{
            border-bottom: none;
        } 
    }

    .selected {
        background-color: #F6F7FE;
        color: #5262F1;
    }
    .unselected {
        cursor: pointer;
    }
`;

const HeaderCollpased = styled.div`
    padding-left: 40px;
    padding-left: 40px;
    font-weight: 400;
    font-size: 16px;
    color: #4c586b;

    .step-number {
        position: absolute;
        height: 32px;
        width: 32px;
        background-color: #FFFFFF;
        left: 15px;
        top: 50%;
        border-radius: 50%;
        margin-top: -16px;
        display: flex;
        align-items: center;
        justify-content: center;

        border: 2px solid #EBECEE;
    }

    .step-check {
        position: absolute;
        right: 20px;
        color: #4caf50;
        font-size: 16px;
    }

    &::before {
        content: "";
        width: 3px;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 30px;
        background-color: #EBECEE;
        z-index: 0;
    }
`;

const HeaderModal = styled.div`
    .title {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
    }
`;

const FooterModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            font-size: 16px;
            margin-right: 10px;
        }
    }
`;



export {
    ContentTitle,
    GridContent,
    ContentQuestionGrid,
    ContentCollapse,
    HeaderCollpased,
    FooterModal,
    HeaderModal
}