import Response from "../containers/Administration/utils/Response";
import { logError, fieldsAudit, sortList } from '../helpers/utility';
import { Table } from "../constants";
import { MemberModel } from "../models";
import { firestore } from 'firebase';

import uuid from "uuid/v4";
import { paymentService } from ".";

const db = firestore();

async function info(memberId: String): Promise<Response<MemberModel>> {
    let response: Response<MemberModel> = new Response(false);
    try {
        let doc = await db.collection(Table.Member).doc(memberId).get();
        if (doc.exists) {
            response.data = doc.data();
            response.data.id = doc.id;
            response.status = true;
        }
    }
    catch (e) { response.error_data = e; }
    logError('memberService.js:info', response);
    return response;
}

async function startWithEmail(start_email: String): Promise<Response<MemberModel[]>> {
    let response: Response<MemberModel[]> = new Response(false);
    try {
        const end = start_email.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

        let query = db.collection(Table.Member)
            .where(Table.$Member.emailUser, '>=', start_email)
            .where(Table.$Member.emailUser, '<', end)
        let data = await query.limit(20).get();

        response.data = data.docs.map(element => {
            let item: MemberModel = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('memberService.js:startWithEmail', response);
    return response;
}

async function listByEmail(email: String, onlyActives = true): Promise<Response<MemberModel[]>> {
    let response: Response<MemberModel[]> = new Response(false);
    try {
        let ref = db.collection(Table.Member).where(Table.$Member.emailUser, '==', email);

        let query = onlyActives ? ref.where(Table.$Member.status, '==', true) : ref;

        let data = await query.get();

        response.data = data.docs.map(element => {
            let item: MemberModel = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('memberService.js:listByEmail', response);
    return response;
}


async function listByMemberUserId(idUser: String, onlyActives = true): Promise<Response<MemberModel[]>> {
    let response: Response<MemberModel[]> = new Response(false);
    try {
        let ref = db.collection(Table.Member).where('memberUserId', '==', idUser);

        let query = onlyActives ? ref.where(Table.$Member.status, '==', true) : ref;

        let data = await query.get();

        response.data = data.docs.map(element => {
            let item: MemberModel = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('memberService.js:listByMemberUserId', response);
    return response;
}

async function listByIds(ids: String[]): Promise<Response<MemberModel[]>> {
    let response: Response<MemberModel[]> = new Response(false);
    try {
        response.data = [];
        let ref = db.collection(Table.Member);
        let data = await Promise.all(
            ids.map(ID => { return ref.doc(ID).get(); })
        );
        data.forEach(element => {
            if (element.exists) {
                let item: MemberModel = element.data();
                item.id = element.id;
                response.data.push(item);
            }
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('memberService.js:listByIds', response);
    return response;
}

//  Update FireStore / AWS

async function update(idMember, prmData, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {
        let data = {
            ...prmData,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };

        await db.collection(Table.Member).doc(idMember).update(data);
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('memberService.js:update', response);
    return response;
}


//  Create FireStore / AWS


async function create(prmData, idUserCurrent = ''): Promise<Response> {
    let response = new Response(false);
    try {

        let id = uuid();
        let data = {
            ...prmData,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };

        db.collection(Table.Member).doc(id).set(data)
        response.data = {
            ...prmData,
            id: id
        }

        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('memberService.js:update', response);
    return response;
}


async function listAll(): Promise<Response> {
    let response = new Response(false);

    try {
        const query = db.collection(Table.Member);
        const data = await query.get();
        let contador = 0;
        response.data = data.docs.map(element => {
            let item: MemberModel = data.docs[contador++].data();
            item.id = element.id;
            return item;

        });
    } catch (err) {
        response.error_data = err;
        logError('memberService.listAll', response);
    }

    return response;
}

async function listMemberForDate(start, end): Promise<Response> {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.Member)
            .where(Table.$Member.createdAt, '>=', start)
            .where(Table.$Member.createdAt, '<=', end)
            .get();

        let list = query.docs.map(e => { return { ...e.data(), id: e.id } });
        let data = await Promise.all(list.map(async e => {
            let item = Object.assign({}, e);
            let { data, status } = await paymentService.getByMember(item.id);
            if (status) item.payment = data;
            return item;
        }));

        response.data = data;
        response.status = true;
    } catch (error) {
        response.error = error;
    }

    return response;
}

export default {
    info,
    startWithEmail,
    listByMemberUserId,
    listMemberForDate,
    listByEmail,
    listByIds,
    update,
    create,
    listAll
}