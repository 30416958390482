export default class Role
{
    id: String;
    key: String;
    name: String;
    description: String;
    permission: Number;
    permissionModule: Number;
    requestOwner: Boolean;
    status: Boolean;

    constructor()
    {
        this.requestOwner = false;
        this.status = true;
    }
}