export default class PriceRange
{
    id: String;
    tier: Number;
    amount: Number;
    currencyId: String;
    currencyKey: String;
    status: Boolean;

    constructor()
    {
        this.status = true;
    }
}