import { NotifUserModel, UserModel } from '../models';
import { notifUserService, quizService, userService } from '../services';
import { Enum, Security } from './';


/**
 * **Envío de notificación para cuando se responde a una reseña**
 * @param {*} userIdTo ID del usuario a quien se le enviará la notificación
 * @param {*} quizId ID del simulador
 * @param {*} currentUser Objeto del usuario actual
 * @returns Información de la notificación enviada
 */
async function reviewAnswered(userIdTo: String, quizId: String, currentUser: UserModel)
{
    let metadata = { quizId };
    let infoHash = Security.getInfoHash(metadata);
    let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.reviewAnswered, infoHash);
    if (res_exists.status === true)
        return null;

    let res_quiz = await quizService.getQuiz(quizId);
    if (res_quiz.status !== true)
        return null;

    let message = `${currentUser.name} ha respondido a tú reseña.`;
    if (res_quiz.data.quizsUserOwnerId === currentUser.id)
        message = `${currentUser.name} propietario del simulador, ha respondido a tú reseña.`;

    let item = new NotifUserModel();
    item.title = `Te respondieron en ${res_quiz.data.title}`;
    item.message = message;
    item.createdUser = currentUser.id;
    item.metadata = metadata;
    item.hash = infoHash;
    item.type = Enum.TypeNotif.reviewAnswered;
    return await notifUserService.create(userIdTo, item);
}



const meta_reportQuestionAnswered = { quizId: '', quizHistoryId: '', questionHistoryId: '' };
/**
 * **Envío de notificación para cuando se responde al reporte de la pregunta**
 * @param {*} userIdTo ID del usuario a quien se le enviará la notificación
 * @param {*} currentUser Objeto del usuario actual
 * @param {*} metadata Información adicional para dar seguimiento
 * @returns Información de la notificación enviada
 */
async function reportQuestionAnswered(userIdTo: String, currentUser: UserModel, metadata = meta_reportQuestionAnswered)
{
    let infoHash = Security.getInfoHash(metadata);
    let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.reportQuestionAnswered, infoHash);
    if (res_exists.status === true)
        return null;

    let res_quiz = await quizService.getQuiz(metadata.quizId);
    if (res_quiz.status !== true)
        return null;

    let message = `${currentUser.name} ha respondido a la pregunta que reportaste en ${res_quiz.data.title}.`;
    if (res_quiz.data.quizsUserOwnerId === currentUser.id)
        message = `${currentUser.name} propietario del simulador, ha respondido a la pregunta que reportaste en ${res_quiz.data.title}.`;

    let item = new NotifUserModel();
    item.title = `Te respondieron a la pregunta que reportaste`;
    item.message = message;
    item.createdUser = currentUser.id;
    item.metadata = metadata;
    item.hash = infoHash;
    item.type = Enum.TypeNotif.reportQuestionAnswered;
    return await notifUserService.create(userIdTo, item);
}



const meta_refundQuiz = { refundId: '', quizId: '' };
/**
 * **Envío de notificación para cuando se reembolsa un simulador**
 * @param {*} userIdTo ID del usuario a quien se le enviará la notificación
 * @param {*} currentUser Objeto del usuario actual
 * @param {*} metadata Información adicional para dar seguimiento
 * @returns Información de la notificación enviada
 */
async function refundQuiz(userIdTo: String, currentUser: UserModel, metadata = meta_refundQuiz)
{
    let infoHash = Security.getInfoHash(metadata);
    let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.refundQuiz, infoHash);
    if (res_exists.status === true)
        return null;

    let res_quiz = await quizService.getQuiz(metadata.quizId);
    if (res_quiz.status !== true)
        return null;

    let message = `Se ha realizado el reembolso del simulador ${res_quiz.data.title}, consulte aquí para ver más detalles.`;

    let item = new NotifUserModel();
    item.title = `Reembolso de membresía ${res_quiz.data.title}`;
    item.message = message;
    item.createdUser = currentUser.id;
    item.metadata = metadata;
    item.hash = infoHash;
    item.type = Enum.TypeNotif.refundQuiz;
    return await notifUserService.create(userIdTo, item);
}



/**
 * **Envío de notificación para cuando el propietario cambia el precio sugerido del simulador**
 * @param {*} roles Catálogo de roles
 * @param {*} quizId ID del simulador
 * @param {*} currentUser Objeto del usuario actual
 * @returns Información de la notificación enviada
 */
async function priceQuizChanged(roles: RoleModel[], quizId: String, currentUser: UserModel)
{
    let res_quiz = await quizService.getQuiz(quizId);
    if (res_quiz.status !== true)
        return null;

    let res_users = await userService.listByKeyRole([Enum.Role.SADMA], roles);
    let USERS_TO: UserModel[] = res_users.status ? res_users.data : [];

    let success = await Promise.all(USERS_TO.map(async (userTo) =>
    {
        let userIdTo = userTo.id;

        let metadata = { quizId };
        let infoHash = Security.getInfoHash(metadata);
        let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.priceQuizChanged, infoHash);
        if (res_exists.status === true)
            return null;

        let message = `${currentUser.name} desea cambiar el precio del simulador.`;
        if (res_quiz.data.quizsUserOwnerId === currentUser.id)
            message = `${currentUser.name} propietario del simulador desea cambiar el precio.`;

        let item = new NotifUserModel();
        item.title = `Cambio de precio para ${res_quiz.data.title}`;
        item.message = message;
        item.createdUser = currentUser.id;
        item.metadata = metadata;
        item.hash = infoHash;
        item.type = Enum.TypeNotif.priceQuizChanged;
        return await notifUserService.create(userIdTo, item);
    }));

    return success;
}



/**
 * **Envío de notificación para cuando el SPADMA asigna el precio al simulador en base a las tiendas (PLS, APS)**
 * @param {*} userIdTo ID del usuario a quien se le enviará la notificación
 * @param {*} quizId ID del simulador
 * @param {*} currentUser Objeto del usuario actual
 * @returns Información de la notificación enviada
 */
async function priceQuizAssigned(userIdTo: String, quizId: String, currentUser: UserModel)
{
    let metadata = { quizId };
    let infoHash = Security.getInfoHash(metadata);
    let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.priceQuizAssigned, infoHash);
    if (res_exists.status === true)
        return null;

    let res_quiz = await quizService.getQuiz(quizId);
    if (res_quiz.status !== true)
        return null;

    let message = `El equipo de Appmisión ha liberado el precio que recomendaste como sugerencia.`;

    let item = new NotifUserModel();
    item.title = `Nuevo precio disponible para ${res_quiz.data.title}`;
    item.message = message;
    item.createdUser = currentUser.id;
    item.metadata = metadata;
    item.hash = infoHash;
    item.type = Enum.TypeNotif.priceQuizAssigned;
    return await notifUserService.create(userIdTo, item);
}



/**
 * **Envío de notificación para cuando el estatus del simulador cambia a `IN_REVIEW - En revisión`**
 * @param {*} roles Catálogo de roles
 * @param {*} quizId ID del simulador
 * @param {*} currentUser Objeto del usuario actual
 * @returns Información de la notificación enviada
 */
async function statusQuizInReview(roles: RoleModel[], quizId: String, currentUser: UserModel)
{
    let res_quiz = await quizService.getQuiz(quizId);
    if (res_quiz.status !== true)
        return null;

    let res_users = await userService.listByKeyRole([Enum.Role.SADMA], roles);
    let USERS_TO: UserModel[] = res_users.status ? res_users.data : [];

    let success = await Promise.all(USERS_TO.map(async (userTo) =>
    {
        let userIdTo = userTo.id;

        let metadata = { quizId };
        let infoHash = Security.getInfoHash(metadata);
        let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.statusQuizInReview, infoHash);
        if (res_exists.status === true)
            return null;

        let message = `Se requiere revisar y aprobar un simulador que "${currentUser.name}" desea publicar.`;

        let item = new NotifUserModel();
        item.title = `Revisión pendiente de ${res_quiz.data.title}`;
        item.message = message;
        item.createdUser = currentUser.id;
        item.metadata = metadata;
        item.hash = infoHash;
        item.type = Enum.TypeNotif.statusQuizInReview;
        return await notifUserService.create(userIdTo, item);
    }));

    return success;
}



/**
 * **Envío de notificación para cuando se apague el simulador y el estatus se cambia a `UNPUBLISH - Sin publicar`**
 * @param {*} roles Catálogo de roles
 * @param {*} quizId ID del simulador
 * @param {*} currentUser Objeto del usuario actual
 * @returns Información de la notificación enviada
 */
async function statusQuizUnpublish(roles: RoleModel[], quizId: String, currentUser: UserModel)
{
    let res_quiz = await quizService.getQuiz(quizId);
    if (res_quiz.status !== true)
        return null;

    let res_users = await userService.listByKeyRole([Enum.Role.SADMA], roles);
    let USERS_TO: UserModel[] = res_users.status ? res_users.data : [];

    let success = await Promise.all(USERS_TO.map(async (userTo) =>
    {
        let userIdTo = userTo.id;

        let metadata = { quizId };
        let infoHash = Security.getInfoHash(metadata);
        let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.statusQuizUnpublish, infoHash);
        if (res_exists.status === true)
            return null;

        let message = `"${currentUser.name}" apagó el simulador ${res_quiz.data.title}.`;

        let item = new NotifUserModel();
        item.title = `El simulador ${res_quiz.data.title} fue apagado.`;
        item.message = message;
        item.createdUser = currentUser.id;
        item.metadata = metadata;
        item.hash = infoHash;
        item.type = Enum.TypeNotif.statusQuizUnpublish;
        return await notifUserService.create(userIdTo, item);
    }));

    return success;
}



/**
 * **Envío de notificación para cuando el estatus del simulador cambia a `REJECTED - Rechazado`**
 * @param {*} userIdTo ID del usuario a quien se le enviará la notificación
 * @param {*} quizId ID del simulador
 * @param {*} currentUser Objeto del usuario actual
 * @returns Información de la notificación enviada
 */
async function statusQuizRejected(userIdTo: String, quizId: String, currentUser: UserModel)
{
    let metadata = { quizId };
    let infoHash = Security.getInfoHash(metadata);
    let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.statusQuizRejected, infoHash);
    if (res_exists.status === true)
        return null;

    let res_quiz = await quizService.getQuiz(quizId);
    if (res_quiz.status !== true)
        return null;

    let message = `Se rechazó la publicación del simulador, clic para ver la razón.`;

    let item = new NotifUserModel();
    item.title = `Publicación rechazada de ${res_quiz.data.title}`;
    item.message = message;
    item.createdUser = currentUser.id;
    item.metadata = metadata;
    item.hash = infoHash;
    item.type = Enum.TypeNotif.statusQuizRejected;
    return await notifUserService.create(userIdTo, item);
}



/**
 * **Envío de notificación para cuando el estatus del simulador cambia a `PUBLISHED - Publicado`**
 * @param {*} userIdTo ID del usuario a quien se le enviará la notificación
 * @param {*} quizId ID del simulador
 * @param {*} currentUser Objeto del usuario actual
 * @returns Información de la notificación enviada
 */
async function statusQuizPublished(userIdTo: String, quizId: String, currentUser: UserModel)
{
    let metadata = { quizId };
    let infoHash = Security.getInfoHash(metadata);
    let res_exists = await notifUserService.existsUnread(userIdTo, Enum.TypeNotif.statusQuizPublished, infoHash);
    if (res_exists.status === true)
        return null;

    let res_quiz = await quizService.getQuiz(quizId);
    if (res_quiz.status !== true)
        return null;

    let message = `Se aprobó satisfactoriamente el simulador y ya se encuentra disponible en la plataforma.`;

    let item = new NotifUserModel();
    item.title = `Publicación aprobada de ${res_quiz.data.title}`;
    item.message = message;
    item.createdUser = currentUser.id;
    item.metadata = metadata;
    item.hash = infoHash;
    item.type = Enum.TypeNotif.statusQuizPublished;
    return await notifUserService.create(userIdTo, item);
}



export default {
    reviewAnswered,
    reportQuestionAnswered,
    refundQuiz,
    priceQuizChanged,
    priceQuizAssigned,
    statusQuizInReview,
    statusQuizUnpublish,
    statusQuizRejected,
    statusQuizPublished
}