
import { firestore } from 'firebase';
import { Table } from '../constants';
import { sectionConfigurationService } from './index';
import Response from "../containers/Administration/utils/Response";
import { TemplateQuizSectionConfiguration } from '../models/TemplateQuizSectionConfiguration';
import { fieldsAudit } from '../helpers/utility';

const db = firestore();

async function CreateTemplateQuizSectionConfiguration(idTemplateQuiz: string, idSectionConfiguration: string, currentUser: string): Promise<Response> {

    let response = new Response(false);
    try {

        const data = {
            status: true,
            templateQuizSectionConfigurationTemplateQuizId: idTemplateQuiz,
            templateQuizSectionConfigurationSectionConfigurationId: idSectionConfiguration,
            ...fieldsAudit(currentUser, 'CREATE')
        };

        let query = await db.collection(Table.TemplateQuizSectionConfiguration).add({ ...data });

        response.data = { ...data, id: query.id };
        response.status = true;
    } catch (e) {
        response.error = e.errors[0].message;
    }
    return response;
}



// ------------------------------------------------------------------------------------------------------------


async function getTemplateSectionConfigurationById(idTemplate: string): Promise<Response> {
    let response = new Response();
    try {

        let query = await db.collection(Table.TemplateQuizSectionConfiguration)
            .where(Table.$TemplateQuizSectionConfiguration.templateQuizSectionConfigurationTemplateQuizId, '==', idTemplate).get();

        // console.log(query)

        let promiseSectionConfig = await Promise.all(query.docs.map(async e => {
            let data: TemplateQuizSectionConfiguration = e.data();

            let sectionCnfiguration = await sectionConfigurationService.getSectionConfigurationById(data.templateQuizSectionConfigurationSectionConfigurationId);

            return {
                // ...e.data(),
                id: e.id,
                sectionConfiguration: {
                    ...sectionCnfiguration.data
                }
            }
        }))

        response.data = promiseSectionConfig;
        response.status = true;

    } catch (error) {
        response.error = error;
    }
    return response;
}

//-------------------------------------------------------------------------------------



export default {
    CreateTemplateQuizSectionConfiguration,
    getTemplateSectionConfigurationById
}