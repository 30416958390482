import Response from "../containers/Administration/utils/Response"
import { firestore } from "firebase";


let db = firestore();


async function getQuestionSequence(id: string): Promise<Response> {
    let response = new Response(false);
    try {
        let query = await db.collection('QuestionSequence').where('questionSequenceQuestionId', '==', id).get();
        if (!query.empty) {
            response.data = query.docs.map(e => { return { ...e.data(), id: e.id } });
            response.status = true;
        }
    } catch (error) {
        response.error = error;
    }
    return response;
}


export default {
    getQuestionSequence
}