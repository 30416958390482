const colors = ["#81d4fa", "#1e88e5", "#fbc02d", "#8c9eff", "#e67e22", "#448aff", "#c51162", "#ff5252", "#aed581", "#9b59b6", "#f44336", "#00bcd4", "#f06292"];


function numberFromText(text) { // numberFromText("AA");
    const charCodes = text
        .split('') // => ["A", "A"] 
        .map(char => char.charCodeAt(0)) // => [65, 65]
        .join(''); // => "6565"
    return charCodes;
};


function extractFirstLetter(name) {
    return name.substring(0, 2).toLocaleUpperCase();
}

function getBackgroundColor(name) {
    return colors[numberFromText(extractFirstLetter(name)) % colors.length]
}

export default {
    colors,
    numberFromText,
    extractFirstLetter,
    getBackgroundColor
}