import actions from './actions';
import { Enum } from '../../constants';
import { GlobalPaymentsModel } from '../../models';
import { FilterPayment } from '../../models/filters';

const initState = {
    owners: [],
    paymentStatus: [],
    paymentsTypes: [],
    reasonsRefund: [],

    ownerCurrent: null,
    ownerRefunds: [],
    [Enum.SubModuleFIN.Sales]: { ownerPayments: [] },
    [Enum.SubModuleFIN.Conciliation]: { ownerPayments: [] },
    [Enum.SubModuleFIN.Refund]: { ownerPayments: [] },
    [Enum.SubModuleFIN.Deposit]: { ownerPayments: [] },

    keyAmountPayment: Enum.KeyAmountPayment.owner,
    globalPayments: new GlobalPaymentsModel(),
    filterCurrent: new FilterPayment()
}

export default function (state = initState, action)
{
    switch (action.type)
    {
        // GENERAL
        case actions.RESET_FINANCES:
            return {
                ...state,
                ownerRefunds: [],
                [Enum.SubModuleFIN.Sales]: { ownerPayments: [] },
                [Enum.SubModuleFIN.Conciliation]: { ownerPayments: [] },
                [Enum.SubModuleFIN.Refund]: { ownerPayments: [] },
                [Enum.SubModuleFIN.Deposit]: { ownerPayments: [] },

                keyAmountPayment: Enum.KeyAmountPayment.owner,
                globalPayments: new GlobalPaymentsModel(),
                filterCurrent: new FilterPayment()
            }
        case actions.SET_OWNERS:
            return {
                ...state,
                owners: action.payload,
                ownerCurrent: null
            }

        // CATÁLOGOS
        case actions.SET_PAYMENTS_TYPES:
            return {
                ...state,
                paymentsTypes: action.payload
            }
        case actions.SET_PAYMENTS_STATUS:
            return {
                ...state,
                paymentStatus: action.payload
            }
        case actions.SET_REASONS_REFUND:
            return {
                ...state,
                reasonsRefund: action.payload
            }

        // CURRENT OWNER
        case actions.SET_OWNER_CURRENT: {
            state.globalPayments = null;
            state.filterCurrent = null;
            let current = state.owners.find(x => x.id === action.payload);
            return {
                ...state,
                ownerCurrent: current,
                ownerRefunds: [],
                [Enum.SubModuleFIN.Sales]: { ownerPayments: [] },
                [Enum.SubModuleFIN.Conciliation]: { ownerPayments: [] },
                [Enum.SubModuleFIN.Refund]: { ownerPayments: [] },
                [Enum.SubModuleFIN.Deposit]: { ownerPayments: [] },

                keyAmountPayment: Enum.KeyAmountPayment.owner,
                globalPayments: new GlobalPaymentsModel(),
                filterCurrent: new FilterPayment()
            }
        }

        case actions.SET_OWNER_KEY_AMOUNT_PAYMENT:
            return {
                ...state,
                keyAmountPayment: action.payload
            }

        case actions.SET_OWNER_GLOBAL_PAYMENTS:
            return {
                ...state,
                globalPayments: action.payload
            }

        case actions.SET_OWNER_PAYMENTS: {
            state[action.payload.subModule].ownerPayments = action.payload.items;
            return {
                ...state
            }
        }

        case actions.ADD_OWNER_PAYMENTS_WITH_ACTION: {

            let newInfo = [...state[action.payload.subModule].ownerPayments];
            action.payload.items.map(itemWithAction =>
            {
                const item = itemWithAction.item;
                const actionDB = itemWithAction.action;
                if (actionDB === 'added')
                {
                    newInfo.push(item);
                }
                if (actionDB === 'modified')
                {
                    let pos = newInfo.findIndex(x => x.id === item.id);
                    if (pos >= 0)
                        newInfo[pos] = { ...item };
                }
                if (actionDB === 'removed')
                {
                    let pos = newInfo.findIndex(x => x.id === item.id);
                    if (pos >= 0)
                        newInfo.splice(pos, 1);
                }
                return itemWithAction;
            });
            state[action.payload.subModule].ownerPayments = newInfo;
            return {
                ...state
            }
        }

        case actions.SET_OWNER_REFUNDS:
            return {
                ...state,
                ownerRefunds: action.payload
            }

        // FILTER
        case actions.SET_FILTER_CURRENT:
            {
                let newInfo = { ...action.payload };
                state.filterCurrent = null;
                state.filterCurrent = new FilterPayment();
                state.filterCurrent.idOwner = newInfo.idOwner;
                state.filterCurrent.quizsId = newInfo.quizsId;
                state.filterCurrent.status = newInfo.status;
                state.filterCurrent.paymentStatus = newInfo.paymentStatus;
                state.filterCurrent.paymentType = newInfo.paymentType;
                state.filterCurrent.rangeCreatedAt = newInfo.rangeCreatedAt;
                return { ...state }
            }

        default:
            return state;
    }
}