// import update from 'immutability-helper';
import actions from './actions';

const initState = {
    currentQuestion: {},
}

export default function cardReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.SET_CURRENT_QUESTION:
            return {
                ...state,
                currentQuestion: action.payload
            }
        default:
            return state;
    }
}