export default class OwnerModel {

    id: String;
    name: String;
    description: String;
    linkFacebook: String;
    linkTwitter: String;
    linkWebSite: String;
    linkYouTube: String;
    photoUrl: String;

    createdAt: Number;
    updatedAt: Number;
    createdUser: String;
    updatedUser: String;

    constructor() {
        this.name = null;
        this.description = null;
        this.linkFacebook = null;
        this.linkTwitter = null;
        this.linkWebSite = null;
        this.linkYouTube = null;
        this.photoUrl = null;
    }

    loadInfo(owner: any) {
        this.id = owner.id;
        this.name = owner.name;
        this.description = owner.description;
        this.linkFacebook = owner.linkFacebook;
        this.linkTwitter = owner.linkTwitter;
        this.linkWebSite = owner.linkWebSite;
        this.linkYouTube = owner.linkYouTube;
        this.photoUrl = owner.photoUrl;
    }

}