import { Storage } from 'aws-amplify';



const createMetadataImage = {
    conevertBase64toImage: function (base64: Blob, extension = null) {

        const date = new Date().valueOf();

        let text = '';
        const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
        }

        const mimeType = this.base64MimeType(base64);

        let extType = !!extension ? extension : mimeType.split("/")[1];

        const imageBlob = this.dataURItoBlob(base64, mimeType);

        const imageName = date + '.' + text + '.' + extType;

        const imageFile = new File([imageBlob], imageName, { type: mimeType });

        return imageFile;

    },

    dataURItoBlob: function (dataURI: String, mimeType: any)
    {
        let encodedStr = ''; let search = ';base64,';
        let pos = dataURI.indexOf(search);
        if (pos >= 0)
            encodedStr = dataURI.substring(pos + search.length);
        else
            encodedStr = dataURI;

        const byteString = atob(encodedStr);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++)
        {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: mimeType });
        return blob;
    },

    base64MimeType: function (encoded: any) {
        var result = null;

        if (typeof encoded !== 'string') {
            return result;
        }

        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    },
}

function getBase64(img, callback)
{
    const reader = new FileReader();
    const onLoad = () => 
    {
        reader.removeEventListener("load", onLoad);
        callback(reader.result);
    };
    reader.addEventListener('load', onLoad);
    reader.readAsDataURL(img);
}

async function upload(file: File, path: String, filename:String | null = null) {
    let name = filename === null ? file.name : filename;
    const response = await Storage.put(`${path}/${name}`, file);
    return response;
}

async function uploadList(fileList: Array, path: String) {
    fileList.forEach((file) => {
        const { name } = file;
        Storage.put(`${path}/${name}`, file).then((data) => {
            console.log(data)
        }).catch(err => {
            console.log(err)
        })
    })
}


async function removeFile(URL: String): Promise<Boolean> {
    // console.log(URL)
    return Storage.remove(URL).then(response => {
        // console.log(response)
        return true;
    }).catch(err => {
        console.log(err)
        return false;
    });
}

async function removeFolder(URL: String) {
    Storage.list(URL).then(result => {
        result.forEach((value) => {
            const { key } = value;
            removeFile(key)
        });
    }).catch(err => { console.log(err) })
}


export {
    getBase64,
    upload,
    uploadList,
    removeFile,
    removeFolder,
    createMetadataImage
}