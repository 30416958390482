import styled from "styled-components";

const WrapperSequence = styled.div`


    background-color    : #ffffff;
    box-shadow          : 0px 0px 20px 0px rgb(94 92 154 / 6%);
    padding             : 30px 20px 20px 20px;
    border-radius       : 6px;
    margin-top          : 20px;

    h1{
        font-size: 22px;
        border-bottom: 1px solid #f4f8fb;
        font-weight: 300;
        padding-bottom: 10px;
    }

    ul{
        padding     : 0;
        margin      : 20px 0 0;
        list-style  : none;
        

        li {
            position            : relative;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }


            .sequence-row{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;       
                border: 1px solid #f4f8fb;
                padding: 10px;
                border-radius: 12px;  

                .icons {
                    flex-shrink: 0;
                }
            }

            &.add-sequence button{
                background-color: transparent;
                border: 2px dashed #a9b4bd;
                color: #6c7d8c;
                font-weight: 500;
                border-radius: 4px;
                outline: none;
                padding: 10px;
                display: block;
                width: 100%;
                cursor: pointer;

                .anticon {
                    margin-right: 10px;
                    font-size: 16px;
                }
            }

            .icons {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 0 10px;
                margin-left: 30px;

                .edit,
                .delete {
                    padding: 6px 7px 0px;
                    border-radius: 8px;
                    display     : inline-block;
                    font-size   : 16px;
                    cursor      : pointer;
                    flex-shrink: 0;
                    border: none;
                }

                .delete {
                    background-color: #ffedee;
                    color: #922829;
                }

                .edit{
                    background-color: #c6ddff;
                    color: #142e7a;
                }
                
            }

            .question-paragraph {
                font-weight : 400;
                font-size   : 15px;
                color       : #172b4d;
                margin-bottom: 0;
            }

            .content-chip {
                display: flex;
                align-items: center;

                .chip {
                    height          : 32px;
                    width           : 32px;
                    display         : flex;
                    align-items     : center;
                    background-color: #f4f8fb;
                    background-position: center;
                    background-size: cover;
                    border-radius   : 100vh;
                    margin-right    : 10px;
                    margin-top: 10px;

                    .ant-avatar {
                        color           : #a9b4bd;
                        display         : flex;
                        align-items     : center;
                        justify-content : center;
                        background-color: #f4f8fb;
                    }

                    & > span {
                        color      : #a9b4bd;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
`;


const ContentMedia = styled.div`
    display: flex; 
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px 10px;

    .ant-form-item {
        margin-bottom: 0;
    }
`;


export {
    WrapperSequence,
    ContentMedia
}