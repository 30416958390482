export default class Catalogs
{
    id: String;
    key: String;
    name: String;
    description: String;
    status: Boolean;

    constructor()
    {
        this.status = true;
    }
}