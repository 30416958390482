const { default: styled } = require("styled-components");

const QuizLayout = styled.div`
    position        : fixed;
    width: calc(100% - 270px);
    top: 0;
    left: 270px;
    overflow: hidden;

    @media (max-width: 1330px) {
        width: 100%;
        left: 0;
    }
`;

const Header = styled.div`
    height               : 70px;
    background-color     : #ffffff;
    display              : grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows   : 1fr;
    padding              : 0 10px;
    position             : relative;
    box-shadow           : 0 4px 2px -2px rgb(94 92 154 / 6%);

    &>div {
        display    : flex;
        align-items: center;

        &.quiz {
            justify-content: flex-start;
            .ant-avatar{
                flex-shrink : 0;
                margin-right: 10px;
            }
            div.ant-typography, .ant-typography p{
                margin-bottom: 0;
            }
        }

        &.actions{ 
            justify-content: flex-end;
            button {
                margin-left: 10px;
            }
        }

        .monetize{
            background-color: #009688;
            color: #fff;
            border-color: #009688;
        }

        .free{
            background-color: #673ab7;
            color: #fff;
            border-color: #673ab7;
        }
    }

    @media(max-width: 910px) {
        &>div.actions button span,
        #btn-crud-template span{
            display: none;
        }
    }

    @media(max-width: 538px) {
        height: auto;
        padding: 10px;
        display: block;
        grid-template-columns: 1fr;
        grid-template-rows   : 1fr 1fr;

        & > div.actions{
            margin-top: 10px;
            display: grid;
            grid-template-columns: 0fr 0fr 1fr;
            grid-template-rows   : 1fr;
            gap: 10px;
            button {
                margin: 0 !important;
                width: 100%;

                &.publish span {
                    display: inline-block;
                }
            }
        }
    }
    
`;

const QuizBody = styled.div`
    display              : grid;
    grid-template-columns: 320px calc(100% - 320px);
    grid-template-rows   : 1fr;
    overflow: hidden;

    @media(max-width: 717px) {
        grid-template-columns: 1fr;
    }

    @media (min-width: 718px) and (max-width: 768px) {
        grid-template-columns: 220px calc(100% - 220px);
    }


    .actions-question {
        position: fixed;
        height: 100px;
        bottom: 80px;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
`;

const BodyAsideLeft = styled.div`
    
    background-color: #fff;
    overflow        : auto;
    box-shadow      : 10px 0 20px -2px rgb(94 92 154 / 6%); 

    @media(max-width: 717px) {
        position: fixed;
        bottom: 0;
        z-index: 2;
        box-shadow: inherit;
        height: 60px;
        width: 100%;
        background: #fff;
    }

    @media (min-width: 717px) and (max-width: 768px) {
        right: -220px;
        width: 220px;
    }   
`;

const BodyContent = styled.div`
    padding     : 10px 30px 20px;
    overflow        : auto;

    .mark-free-question{
        background-color: #fff;
        padding: 10px;
        border-radius: 100vh;
        box-shadow: 0px 0px 20px 0px rgb(94 92 154 / 6%);
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: 500;
    }

    @media(max-width: 1024px) {
        padding: 5px;
    }
`;


const ContentFormSequence = styled.div`
    border: 1px solid #f1f3f6;
    border-radius: 6px;
    overflow: hidden;

    .ant-input{
        margin-bottom: 10px;
    }

    textarea.ant-input{
        border: none;
        resize: none;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        &::placeholder {
            color: #a1a9b5;
        }
    }

`;


export {
    Header,
    QuizLayout,
    QuizBody,
    BodyContent,
    BodyAsideLeft,
    ContentFormSequence
}