import { UserModel } from ".";

export default class Quiz {
    id: String;
    name: String;
    title: String;
    dedicatedTime: String;
    description: String;
    duration: Number;
    intro: String;
    numberQuestions: Number;
    quantityFree: Number;
    minQualificationRequired: Number;
    typeQualification: String;
    plan: String;
    price: Number;
    productId: String;
    productIdIos: String;
    thumbnail: String;
    thumbnail_min: String;
    version: String;
    priceRangeId: String;
    quizsCategorieId: String;
    quizsCurrencyId: String;
    quizsLevelId: String;
    quizsUserOwnerId: String;
    quizsPromotionId: String;
    status: Boolean;

    ownerName: String;
    ownerPhotoURL: String;

    deductions: String[];
    createdAt: Number;
    categorie: String;
    questionsCount: number;

    quizStatus: any;

    owner: UserModel;
    suggestionCategorie: String;

    constructor() {
        this.deductions = [];

        this.duration = 4800;
        this.numberQuestions = 0;
        this.quantityFree = null;
        this.minQualificationRequired = 0;
        this.typeQualification = '';
        this.price = 0;
        this.priceRangeId = null;
        this.status = true;
        this.questionsCount = 0;
        this.suggestionCategorie = "";
    }
}