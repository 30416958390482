const Table =
{
    AnswerType: 'AnswerType', $AnswerType: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Asset: 'Asset', $Asset: {
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type String*/urlFile: 'urlFile',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Bank: 'Bank', $Bank: {
        /**@type String*/bankUserOwnerId: 'bankUserOwnerId',
        /**@type String*/bankQuizId: 'bankQuizId',
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Blog: 'Blog', $Blog: {
        /**@type String*/author: 'author',
        /**@type String*/body: 'body',
        /**@type String*/name: 'name',
        /**@type Number*/order: 'order',
        /**@type Number*/releaseDate: 'releaseDate',
        /**@type String*/shortDescription: 'shortDescription',
        /**@type String*/title: 'title',
        /**@type String*/urlImage: 'urlImage',
        /**@type String*/videoId: 'videoId',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Categorie: 'Categorie', $Categorie: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Conciliation: 'Conciliation', $Conciliation: {
        /**@type String*/id: 'id',
        /**@type String*/userOwnerId: 'userOwnerId',
        /**@type Number*/amount: 'amount',
        /**@type Number*/amountOutDiscount: 'amountOutDiscount',
        /**@type Number*/dateStart: 'dateStart',
        /**@type Number*/dateEnd: 'dateEnd',
        /**@type String[]*/payments: 'payments',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Currency: 'Currency', $Currency: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Deduction: 'Deduction', $Deduction: {
        /**@type Number*/amount: 'amount',
        /**@type String*/paymentId: 'paymentId',
        /**@type String*/deductionTypeId: 'deductionTypeId',
        /**@type String*/deductionTypeKey: 'deductionTypeKey',
        /**@type String*/deductionTypeCategory: 'deductionTypeCategory',
        /**@type Object[] {amount, order, type}*/amountsReceivable: 'amountsReceivable',
        /**@type String*/description: 'description',
        /**@type Number*/order: 'order',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser'
    },

    DeductionType: 'DeductionType', $DeductionType: {
        /**@type String*/category: 'category',
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Object[] {amount, order, type}*/amountsReceivable: 'amountsReceivable',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    DepositCreator: 'DepositCreator', $DepositCreator: {
        /**@type String*/userOwnerId: 'userOwnerId',
        /**@type Number*/amount: 'amount',
        /**@type Number*/amountCharge: 'amountCharge',
        /**@type String*/voucher: 'voucher',
        /**@type Boolean*/confirmation: 'confirmation',
        /**@type Number*/dateConfirm: 'dateConfirm',
        /**@type Number*/dateStart: 'dateStart',
        /**@type Number*/dateEnd: 'dateEnd',
        /**@type String*/concept: 'concept',
        /**@type String[]*/payments: 'payments',
        /**@type String[]*/refunds: 'refunds',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    DepositStore: 'DepositStore', $DepositStore: {
        /**@type String*/paymentStatusId: 'paymentStatusId',
        /**@type Number*/amount: 'amount',
        /**@type String*/description: 'description',
        /**@type String*/currency: 'currency',
        /**@type Number*/dateConciliation: 'dateConciliation',
        /**@type Number*/monthConciliation: 'monthConciliation',
        /**@type Boolean*/isRemediation: 'isRemediation',
        /**@type String*/observation: 'observation',
        /**@type String*/store: 'store',
        /**@type String[]*/payments: 'payments',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser',

        Charges: 'Charges', $Charges: {
            /**@type Number*/amount: 'amount',
            /**@type String*/created: 'created',
            /**@type String*/currency: 'currency',
            /**@type Number*/description: 'description',
            /**@type Number*/fees: 'fees',
            /**@type Number*/net: 'net',
            /**@type String*/type: 'type'
        }
    },

    Level: 'Level', $Level: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Member: 'Member', $Member: {
        /**@type String*/quizId: 'quizId',
        /**@type String*/nameUser: 'nameUser',
        /**@type String*/emailUser: 'emailUser',
        /**@type Number*/dateValidity: 'dateValidity',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Payment: 'Payment', $Payment: {
        /**@type String*/paymentCurrencyId: 'paymentCurrencyId',
        /**@type String*/paymentMemberId: 'paymentMemberId',
        /**@type String*/paymentPaymentTypeId: 'paymentPaymentTypeId',
        /**@type String*/paymentPromotionId: 'paymentPromotionId',
        /**@type String*/paymentQuizId: 'paymentQuizId',
        /**@type String*/paymentStatusId: 'paymentStatusId',
        /**@type String*/paymentTrakingId: 'paymentTrakingId',
        /**@type String*/paymentRefundId: 'paymentRefundId',
        /**@type String*/quizsUserOwnerId: 'quizsUserOwnerId',
        /**@type Number*/amount: 'amount',
        /**@type Number*/amountOutDiscount: 'amountOutDiscount',
        /**@type Number*/amountBusiness: 'amountBusiness',
        /**@type Boolean*/paidAt: 'paidAt',
        /**@type String*/orderId: 'orderId',
        /**@type String*/tokenId: 'tokenId',
        /**@type String*/paymentDate: 'paymentDate',
        /**@type String*/reference: 'reference',
        /**@type String*/transactionDate: 'transactionDate',
        /**@type String*/transactionReceipt: 'transactionReceipt',
        /**@type String*/platform: 'platform',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser'
    },

    PaymentStatus: 'PaymentStatus', $PaymentStatus: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    PaymentType: 'PaymentType', $PaymentType: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/icon: 'icon',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    PriceRange: 'PriceRange', $PriceRange: {
        /**@type Number*/tier: 'tier',
        /**@type Number*/amount: 'amount',
        /**@type Number*/currencyId: 'currencyId',
        /**@type Number*/currencyKey: 'currencyKey',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    ProblemType: 'ProblemType', $ProblemType: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Promotion: 'Promotion', $Promotion: {
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type String*/priceRangeId: 'priceRangeId',
        /**@type String*/priceRangeIdToRestore: 'priceRangeIdToRestore',
        /**@type Number*/percentDiscount: 'percentDiscount',
        /**@type Number*/validity: 'validity',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Question: 'Question', $Question: {
        /**@type String*/questionAnswerTypeId: 'questionAnswerTypeId',
        /**@type String*/questionBankId: 'questionBankId',
        /**@type String*/questionLevelId: 'questionLevelId',
        /**@type String*/questionQuestionTypeId: 'questionQuestionTypeId',
        /**@type String*/questionSectionId: 'questionSectionId',
        /**@type String*/questionSubSectionId: 'questionSubSectionId',
        /**@type Boolean*/free: 'free',
        /**@type String*/key: 'key',
        /**@type Number*/order: 'order',
        /**@type Number*/points: 'points',
        /**@type Boolean*/required: 'required',
        /**@type Boolean*/static: 'static',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    QuestionFullInfo: 'QuestionFullInfo', $QuestionFullInfo: {
        /**@type String*/questionAnswerTypeId: 'questionAnswerTypeId',
        /**@type String*/questionBankId: 'questionBankId',
        /**@type String*/questionLevelId: 'questionLevelId',
        /**@type String*/questionQuestionTypeId: 'questionQuestionTypeId',
        /**@type String*/questionSectionId: 'questionSectionId',
        /**@type String*/questionSubSectionId: 'questionSubSectionId',
        /**@type Boolean*/free: 'free',
        /**@type String*/lection: 'lection',
        /**@type Number*/order: 'order',
        /**@type Number*/points: 'points',
        /**@type Boolean*/required: 'required',
        /**@type Boolean*/static: 'static',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser',

        /**@type Object[] {answerImage, answerText, correctAnswer, selectAnswer, order}*/
        answers: 'answers',

        /**@type Object[] {feedbackImage, feedbackText, order}*/
        feedback: 'feedback',

        /**@type Object[] {questionAudio, questionText, questionImage, order}*/
        sequence: 'sequence'
    },

    QuestionType: 'QuestionType', $QuestionType: {
       /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Quiz: 'Quiz', $Quiz: {
        /**@type String*/id: 'id',
        /**@type String*/quizsCategorieId: 'quizsCategorieId',
        /**@type String*/quizsCurrencyId: 'quizsCurrencyId',
        /**@type String*/quizsLevelId: 'quizsLevelId',
        /**@type String*/quizsPromotionId: 'quizsPromotionId',
        /**@type String*/quizsUserOwnerId: 'quizsUserOwnerId',
        /**@type String*/priceRangeId: 'priceRangeId',
        /**@type String[]*/deductions: 'deductions',
        /**@type String*/description: 'description',
        /**@type Number*/duration: 'duration',
        /**@type String*/intro: 'intro',
        /**@type Number*/minQualificationRequired: 'minQualificationRequired',
        /**@type String*/name: 'name',
        /**@type Number*/numberQuestions: 'numberQuestions',
        /**@type Number*/price: 'price',
        /**@type String*/productId: 'productId',
        /**@type String*/productIdIos: 'productIdIos',
        /**@type Number*/ratingQuiz: 'ratingQuiz',
        /**@type Number*/ratingTotal: 'ratingTotal',
        /**@type Number*/ratingCount: 'ratingCount',
        /**@type Number*/membersCount: 'membersCount',
        /**@type String*/plan: 'plan',
        /**@type String*/ownerName: 'ownerName',
        /**@type String*/ownerPhotoURL: 'ownerPhotoURL',
        /**@type Number*/questionsCount: 'questionCount',
        /**@type Number*/quantityFree: 'quantityFree',
        /**@type Number*/quantityUserRegister: 'quantityUserRegister',
        /**@type String*/thumbnail: 'thumbnail',
        /**@type String*/thumbnail_min: 'thumbnail_min',
        /**@type String*/title: 'title',
        /**@type String*/typeQualification: 'typeQualification',
        /**@type String*/version: 'version',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    QuizType: 'QuizType', $QuizType: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Reason: 'Reason', $Reason: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser'
    },

    Refund: 'Refund', $Refund: {
        /**@type String*/userOwnerId: 'userOwnerId',
        /**@type String*/quizId: 'quizId',
        /**@type String*/memberId: 'memberId',
        /**@type String*/paymentId: 'paymentId',
        /**@type String*/paymentTypeId: 'paymentTypeId',
        /**@type String*/paymentStatusId: 'paymentStatusId',
        /**@type String*/reasonId: 'reasonId',
        /**@type Number*/amount: 'amount',
        /**@type Number*/amountOutDiscount: 'amountOutDiscount',
        /**@type String*/description: 'description',
        /**@type Boolean*/chargedToOwner: 'chargedToOwner',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser'
    },

    Role: 'Role', $Role: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Number*/permission: 'permission',
        /**@type Number*/permissionModule: 'permissionModule',
        /**@type Boolean*/requestOwner: 'requestOwner',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    RoleModule: 'RoleModule', $RoleModule: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Number*/permission: 'permission',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Section: 'Section', $Section: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type String*/sectionBankId: 'sectionBankId',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Slide: 'Slide', $Slide: {
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type String*/imageMobile: 'imageMobile',
        /**@type String*/imageWeb: 'imageWeb',
        /**@type String*/url: 'url',
        /**@type Number*/order: 'order',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    SubSection: 'SubSection', $SubSection: {
        /**@type String*/subSectionSectionId: 'subSectionSectionId',
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    TrakingType: 'TrakingType', $TrakingType: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    User: 'User', $User: {
        /**@type String*/idOwnerLinked: 'idOwnerLinked',
        /**@type Boolean*/migratedHistory: 'migratedHistory',
        /**@type Boolean*/complete: 'complete',
        /**@type String*/username: 'username',
        /**@type String*/name: 'name',
        /**@type String*/password: 'password',
        /**@type String*/phoneNumber: 'phoneNumber',
        /**@type String*/photoURL: 'photoURL',
        /**@type String*/platform: 'platform',
        /**@type String*/providerId: 'providerId',
        /**@type Boolean*/emailVerified: 'emailVerified',
        /**@type String[]*/roles: 'roles',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser',

        Session: 'Session', $Session: {
            /**@type String*/token: 'token',
            /**@type String*/platform: 'platform',
            /**@type String*/version: 'version',
            /**@type Number*/timeStart: 'timeStart',
            /**@type Number*/timeEnd: 'timeEnd',
            /**@type Boolean*/status: 'status',
        },
        SessionAdmin: 'SessionAdmin', $SessionAdmin: {
            /**@type String*/token: 'token',
            /**@type String*/platform: 'platform',
            /**@type String*/version: 'version',
            /**@type Number*/timeStart: 'timeStart',
            /**@type Number*/timeEnd: 'timeEnd',
            /**@type String*/linkedSessionId: 'linkedSessionId',
            /**@type Boolean*/status: 'status',
        },
        Notification: 'Notification', $Notification: {
            /**@type String*/title: 'title',
            /**@type String*/message: 'message',
            /**@type Boolean*/readed: 'readed',
            /**@type Number*/readedAt: 'readedAt',
            /**@type Object*/metadata: 'metadata',
            /**@type String*/type: 'type',
            /**@type String*/hash: 'hash',
            /**@type Number*/createdAt: 'createdAt',
            /**@type String*/createdUser: 'createdUser',
            /**@type Boolean*/status: 'status'
        },
        Profile: 'Profile', $Profile: {
            InfoCreator: 'InfoCreator',
            Experience: 'Experience',
            Privacity: 'Privacity'
        }
    },

    SessionHistory: 'SessionHistory', $SessionHistory: {
        /**@type String*/userId: 'userId',
        /**@type String*/token: 'token',
        /**@type String*/platform: 'platform',
        /**@type String*/version: 'version',
        /**@type String*/provider: 'provider',
        /**@type Number*/timeStart: 'timeStart',
        /**@type Number*/timeEnd: 'timeEnd',
        /**@type Boolean*/status: 'status',
    },

    TemplateEmail: 'TemplateEmail', $TemplateEmail: {
        /**@type String*/idTemplate: 'idTemplate',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    UserType: 'UserType', $UserType: {
        /**@type String*/key: 'key',
        /**@type String*/name: 'name',
        /**@type String*/description: 'description',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    UserEmail: 'UserEmail', $UserEmail: {
        /**@type String*/idUser: 'idUser',
        /**@type String*/idUserType: 'idUserType',
        /**@type String*/name: 'name',
        /**@type String*/email: 'email',
        /**@type String*/platform: 'platform',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    QuizHistory: 'QuizHistory', $QuizHistory: {
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/duration: 'duration',
        /**@type String*/durationCompletion: 'durationCompletion',
        /**@type Boolean*/finished: 'finished',
        /**@type String*/id: 'id',
        /**@type String*/intro: 'intro',
        /**@type String*/name: 'name',
        /**@type Number*/numberQuestions: 'numberQuestions',
        /**@type String*/percentageCompletion: 'percentageCompletion',
        /**@type String*/quizHistoryMemberId: 'quizHistoryMemberId',
        /**@type String*/quizHistoryQuizId: 'quizHistoryQuizId',
        /**@type String*/quizHistoryTemplateQuizId: 'quizHistoryTemplateQuizId',
        /**@type Boolean*/status: 'status',
        /**@type String*/title: 'title',
        /**@type String*/totalAnswerSuccess: 'totalAnswerSuccess',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser',
        /**@type String*/version: 'version',
        /**@type String*/platform: 'platform'
    },

    SectionConfiguration: 'SectionConfiguration', $SectionConfiguration: {
        /**@type String*/id: 'id',
        /**@type String*/name: 'name',
        /**@type Number*/numberQuestions: 'numberQuestions',
        /**@type String*/sectionConfigurationBankId: 'sectionConfigurationBankId',
        /**@type String*/sectionConfigurationLevelId: 'sectionConfigurationLevelId',
        /**@type String*/sectionConfigurationQuestionTypeId: 'sectionConfigurationQuestionTypeId',
        /**@type String*/sectionConfigurationSectionId: 'sectionConfigurationSectionId',
        /**@type String*/sectionConfigurationSubSectionId: 'sectionConfigurationSubSectionId',
        /**@type Boolean*/status: 'status',
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    Binnacle: 'Binnacle', $Binnacle: {
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/actionKey: 'actionKey',
        /**@type String*/id: 'id',
        /**@type String*/affectedCourse: 'affectedCourse',
        /**@type String*/affectedUser: 'affectedUser',
        /**@type String*/currentSimulator: 'currentSimulator',
        /**@type String*/currentUser: 'currentUser',
        /**@type String*/description: 'description',
        /**@type String*/paymentProvider: 'paymentProvider'
    },

    TemplateQuiz: 'TemplateQuiz', $TemplateQuiz: {
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/description: 'description',
        /**@type String*/id: 'id',
        /**@type String*/name: 'string',
        /**@type Number*/pointsApprove: 'pointsApprove',
        /**@type Boolean*/status: 'status',
        /**@type String*/templateQuizQuizId: 'templateQuizQuizId',
        /**@type String*/templateQuizQuizTypeId: 'templateQuizQuizTypeId',
        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },


    TemplateQuizSectionConfiguration: 'TemplateQuizSectionConfiguration', $TemplateQuizSectionConfiguration: {
        /**@type String*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type String*/id: 'id',
        /**@type Boolean*/status: 'status',

        /**@type String*/templateQuizSectionConfigurationSectionConfigurationId: 'templateQuizSectionConfigurationSectionConfigurationId',
        /**@type String*/templateQuizSectionConfigurationTemplateQuizId: 'templateQuizSectionConfigurationTemplateQuizId',


        /**@type String*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser'
    },

    CommentQualification: 'CommentQualification', $CommentQualification: {
        /**@type String*/id: 'id',
        /**@type String*/comment: 'comment',
        /**@type Number*/qualification: 'qualification',
        /**@type String*/commentQualificationQuizId: 'commentQualificationQuizId',
        /**@type String*/commentQualificationUserId: 'commentQualificationUserId',
        /**@type String*/commentQualificationUserOwnerId: 'commentQualificationUserOwnerId',
        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser',
    },


    ProblemReport: 'ProblemReport', $ProblemReport: {
         /**@type String*/id: 'id',

         /**@type String*/message: 'message',
         /**@type String*/problemReportProblemTypeId: 'problemReportProblemTypeId',
         /**@type String*/problemReportQuestionHistoryId: 'problemReportQuestionHistoryId',
         /**@type String*/problemReportReplayId: 'problemReportReplayId',
         /**@type String*/problemReportUserId: 'problemReportUserId',
         /**@type String*/problemReportUserOwnerId: 'problemReportUserOwnerId',
         /**@type String*/quizHistoryId: 'quizHistoryId',
         /**@type String*/reply: 'reply',
         /**@type String*/validated: 'validated',

        /**@type Boolean*/status: 'status',
        /**@type Number*/createdAt: 'createdAt',
        /**@type String*/createdUser: 'createdUser',
        /**@type Number*/updatedAt: 'updatedAt',
        /**@type String*/updatedUser: 'updatedUser',
    }


}
export default Table;