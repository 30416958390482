import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const SidebarWrapper = styled.div`
width: 270px;
.market {
    display: flex;
    align-items: center;
    background-color: #ff632ed6;
    position: relative;
    cursor: pointer;
    padding: 12px 30px;
    margin: 5px 5px 5px 5px;
    line-height: normal;
    border-radius: 5px;
    cursor: pointer;

    &:hover { background-color: #ff632e; }
    &:active { background-color: #ff632ed1; }
    span { margin-left: 10px; }

    .ant-skeleton-avatar {
      border-radius: 3px;
      width: 22px;
      height: 22px;
      margin-left: 0px;
    }

    .ant-skeleton-title {
      margin-top: 8px !important;
      border-radius: 100vh;
      height: 10px;
    }
  }

  .account-not-verified {
    background-color: #f7e2a4;
    color: #675319;
    font-weight: 500;
    position: relative;
    padding: 5px 20px 5px 30px;

    &::after{
      content: "";
      position: absolute;
      left: 30px;
      top: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #f7e2a4;
    }

    .not-verified-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
    }

    .more-info-show {
      display: block;
      font-size: 12px;
      font-weight: 400;
      user-select: none;
    }
    .more-info-hide {
      display: none;
    }
  }

  .profile {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: rgba(255,255,255,0.1);
    position: relative;
    cursor: pointer;
    
    &__avatar {
      margin-right: 20px;
    }

    &__info {
      h1 {
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
      }

      p {
        font-weight: 300;
        font-size: 13px;
      }
    }

    .anticon.arrow {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -7px;
    }

    .ant-skeleton-avatar {
      border-radius: 50%;
    }

    .ant-skeleton-title {
      border-radius: 100vh;
    }


    .ant-progress-text {
      color: #ffffff;
    }

    .ant-progress-status-success {
      .ant-progress-bg{
        background-color: #a6d68e;
      }
      .ant-progress-text {
        color: #a6d68e;
      }
    }
  }
  .pro-sidebar-footer{
    position: relative;
    .btn-close-session {
      background-color: rgba(255,255,255,0.1);
      height: 100%;
      padding: 1em;
      width: 100%;
      border: none;
      cursor: pointer;

      &:disabled {
        cursor: default;
        opacity: 0.6;
      }
    }
  }
`;

export default WithDirection(SidebarWrapper);
