import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, sortList } from '../helpers/utility';
import { CatalogsModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function list(onlyActives = false): Promise<Response<CatalogsModel[]>> {
    let response: Response<CatalogsModel[]> = new Response(false);
    try {
        let ref = db.collection(Table.QuestionType);
        let query = onlyActives ? ref.where(Table.$QuestionType.status, '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function create(itemCatalog: CatalogsModel, idUserCurrent = ''): Promise<Response<CatalogsModel>> {
    let response: Response<CatalogsModel> = new Response(false);
    try {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.QuestionType).add(data);
        itemCatalog.id = insert.id;

        response.data = itemCatalog;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}


async function update(itemCatalog: CatalogsModel, idUserCurrent = ''): Promise<Response<CatalogsModel>> {
    let response: Response<CatalogsModel> = new Response(false);
    try {
        let data = {
            ...itemCatalog,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.QuestionType).doc(itemCatalog.id).update(data);


        response.data = itemCatalog;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}



async function getQuestionTypeById(idQuestionType: string) {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.QuestionType).doc(idQuestionType).get();
        if (query.exists) {
            response.data = { ...query.data(), id: query.id };
            response.status = true;
        }
    }
    catch (error) { response.error_data = error; }
    return response;
}



export default {
    list,
    create,
    update,
    getQuestionTypeById
}