export default class FrequentQuestion {
    id: String;
    description: String;
    image: String;
    question: String;

    createdAt: String;
    updatedAt: String;
    createdUser: String;
    updatedUser: String;
    status: Boolean;

    order: Number;

    constructor() {
        this.id = null;
        this.description = null;
        this.image = null;
        this.question = null;
    }


    loadInfo(question) {
        this.order = question.order;
        this.question = question.question;
        this.description = question.description;
        this.image = question.image;
        this.status = question.status;
    }
}