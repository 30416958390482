import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, isNullOrEmpty } from '../helpers/utility';
import { upload, removeFile } from '../containers/Administration/utils/UtilsUpload';
import { PathProps } from '../containers/Administration/utils/Global';
import { firestore } from 'firebase';
import FrequentQuestion from "../models/FrequentQuestion";

const db = firestore();

async function create(question: any, idUserCurrent: String, pathS3: String): Promise<Response<FrequentQuestion>> {
    let response = new Response();
    try {
        let objQuestion = new FrequentQuestion();
        objQuestion.loadInfo(question);

        let data = {
            ...objQuestion,
            ...fieldsAudit(idUserCurrent, 'CREATE'),
            status: true
        }


        console.log(data)

        let query = await db.collection('FrequentQuestion').add({...data});

        await uploadFileS3(question.fileImage, pathS3);

        response.data = {
            ...data,
            id: query.id
        };

        response.status = true;
    } catch (error) {
        console.log(error)
        response.error = error;
    }
    return response;
}


async function update(question: any, idUserCurrent: String, pathS3: String): Promise<Response<FrequentQuestion>> {
    let response = new Response();
    try {
        let objQuestion = new FrequentQuestion();
        objQuestion.loadInfo(question);
        objQuestion.id = question.id;

        let data = {
            ...objQuestion,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        }


        await db.collection('FrequentQuestion').doc(question.id).update(data);

        await uploadFileS3(question.fileImage, pathS3);
        if (!!question.fileImage || isNullOrEmpty(question.urlImage))
            await removeFileS3(question.urlImageBackup, pathS3);

        response.data = data;
        response.status = true;
    } catch (error) {
        response.error = error;
    }
    return response;
}



async function updateOrder(id: string, order: number, idUserCurrent): Promise<Response> {
    let response = new Response();
    try {
       
        let data = {
            order: order,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        }

        await db.collection('FrequentQuestion').doc(id).update(data);

        response.data = data;
        response.status = true;
    } catch (error) {
        response.error = error;
    }
    return response;
}




async function updateStatus(id: string, status: Boolean, idUserCurrent: String): Promise<Response<FrequentQuestion>> {
    let response = new Response();
    try {
        let data = {
            status: status,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        }

        await db.collection('FrequentQuestion').doc(id).update(data);
        response.status = true;
    } catch (error) {
        response.error = error;
    }
    return response;
}

async function getList(): Promise<Response<FrequentQuestion[]>> {

    let response = new Response(false);

    try {

        let query = await db.collection('FrequentQuestion').get();
        if (!query.empty) {
            response.data = query.docs.map(e => { return { ...e.data(), id: e.id, }; })
            response.status = true;
        }
    } catch (error) {
        response.error = error;
    }

    return response;
}



async function deleteItem(id:string): Promise<Response> {
    let response = new Response(false);
    try {
        await db.collection('FrequentQuestion').doc(id).delete();
        response.status = true;
    } catch (error) {
        response.error = error;
    }

    return response;
}



async function uploadFileS3(file: File, pathS3: PathProps) {
    if (!!file) {
        const pathS3Folder = `${pathS3.path_assets}/frequentQuestions`;
        const response = await upload(file, pathS3Folder);

        const type = file.type.split("/")[1];
        return response.key.includes(type);
    }
    return true;
}

async function removeFileS3(urlImage, pathS3: PathProps) {
    if (!isNullOrEmpty(urlImage) && urlImage.includes('https'))
        return await removeFile(urlImage.replace(pathS3.path_cloudfront + '/', ''));

    return true;
}


export default {
    updateStatus,
    updateOrder,
    deleteItem,
    getList,
    create,
    update
}