import Response from "../containers/Administration/utils/Response";
import { logError, fieldsAudit, sortList, isNull, splitArray } from '../helpers/utility';
import { Table, Enum } from "../constants";
import { DepositStoreModel, PaymentStatusModel, ChargeStripeModel } from "../models";
import { firestore } from 'firebase';

const db = firestore();

async function listActives(store: String = Enum.StoreDeposit.Stripe): Promise<Response<DepositStoreModel[]>> {
    let response: Response<DepositStoreModel[]> = new Response(false);
    try {
        let query = db.collection(Table.DepositStore)
            .where(Table.$DepositStore.status, '==', true)
            .where(Table.$DepositStore.store, '==', store);

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item: DepositStoreModel = element.data();
            item.id = element.id;
            item.amount = !!item.amount ? item.amount : 0;
            item.amount = item.store === Enum.StoreDeposit.Stripe ? item.amount / 100 : item.amount;
            item.payments = !!item.payments ? item.payments : [];
            item.paymentsUnused = !!item.paymentsUnused ? item.paymentsUnused : [];
            return item;
        });
        sortList(response.data, 'createdAt', true, false);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('depositStoreService.js:listActives', response);
    return response;
}

async function getInfoById(idDepositStore: String): Promise<Response<DepositStoreModel>> {
    let response: Response<DepositStoreModel> = new Response(false);
    try {
        const doc = await db.collection(Table.DepositStore).doc(idDepositStore).get();
        if (doc.exists) {
            let item: DepositStoreModel = doc.data();
            item.id = doc.id;
            item.amount = !!item.amount ? item.amount : 0;
            item.amount = item.store === Enum.StoreDeposit.Stripe ? item.amount / 100 : item.amount;
            item.payments = !!item.payments ? item.payments : [];
            item.paymentsUnused = !!item.paymentsUnused ? item.paymentsUnused : [];

            response.data = item;
            response.status = true;
        }
        else
            throw new Error('NOT_FOUND_DEPOSIT_STORE');
    }
    catch (e) { response.error_data = e; }
    logError('depositStoreService.js:getInfoById', response);
    return response;
}

async function listCharges(idDepositStore): Promise<Response<ChargeStripeModel[]>> {
    let response: Response<ChargeStripeModel[]> = new Response(false);
    try {
        let query = db.collection(Table.DepositStore)
            .doc(idDepositStore)
            .collection(Table.$DepositStore.Charges);

        const data = await query.get();
        response.data = data.docs.map(element => {
            let item: ChargeStripeModel = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true, false);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('depositStoreService.js:listCharges', response);
    return response;
}

async function stripeDeposits(lastItemID: String, paymentStatus: PaymentStatusModel[]): Promise<Response<DepositStoreModel[]>> {
    let response: Response<DepositStoreModel[]> = new Response(false);
    try {
        let parameters = { limit: 25 };
        if (!!lastItemID && lastItemID.length > 0)
            parameters.starting_after = lastItemID;

        let responseFetch = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/payment-payoutsStripe`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'LIST',
                parameters: parameters
            })
        });
        let infoJSON = await responseFetch.json();
        let items: [] = infoJSON.status === true ? infoJSON.data.data : [];
        let has_more = infoJSON.status === true ? infoJSON.data.has_more : false;

        response.nextToken = !!has_more && has_more === true ? items[items.length - 1].id : null;
        response.data = items.map(elem => {
            let initPaymentStatus = paymentStatus.find(x => x.key === Enum.PaymentStatus.CONF);

            let item = { ...elem };
            let createdAt = item.created;
            delete item['created'];
            delete item['object'];
            delete item['livemode'];
            delete item['statement_descriptor'];
            delete item['metadata'];
            delete item['status'];

            item.paymentStatusId = initPaymentStatus.id;
            item.createdAt = createdAt;
            item.status = true;
            item.store = Enum.StoreDeposit.Stripe;
            return item;
        });
        response.status = infoJSON.status;

        if (!response.status)
            response.error_data = infoJSON.error;
    }
    catch (e) { response.error_data = e; }
    logError('depositStoreService.js:stripeDeposits', response);
    return response;
}

async function stripeCharges(dateStart: Number, dateEnd: Number, lastItemID: String): Promise<Response<any[]>> {
    let response: Response<any[]> = new Response(false);
    try {
        let parameters = {
            limit: 20,
            created: {
                gte: dateStart,
                lte: dateEnd
            },
        };
        if (!!lastItemID && lastItemID.length > 0)
            parameters.starting_after = lastItemID;

        let responseFetch = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/payment-chargesStripe`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'LIST',
                parameters: parameters
            })
        });
        let infoJSON = await responseFetch.json();
        let items: [] = infoJSON.status === true ? infoJSON.data.data : [];
        let has_more = infoJSON.status === true ? infoJSON.data.has_more : false;

        response.nextToken = !!has_more && has_more === true ? items[items.length - 1].id : null;
        response.data = items;
        response.status = infoJSON.status;

        if (!response.status)
            response.error_data = infoJSON.error;
    }
    catch (e) { response.error_data = e; }
    logError('depositStoreService.js:stripeCharges', response);
    return response;
}

async function stripeChargesById(itemID: String): Promise<Response<any>> {
    let response: Response<any> = new Response(false);
    try {
        let responseFetch = await fetch(`${process.env.REACT_APP_FIREBASE_ENDPOINT_FUNCTIONS}/payment-chargesStripe`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'INFO',
                ID: itemID
            })
        });
        let infoJSON = await responseFetch.json();
        response.data = infoJSON.status === true ? infoJSON.data : null;
        response.status = infoJSON.status;

        if (!response.status)
            response.error_data = infoJSON.error;
    }
    catch (e) { response.error_data = e; }
    logError('depositStoreService.js:stripeChargesById', response);
    return response;
}

async function syncInfoStripe(currentInfo: DepositStoreModel[], newInfo: DepositStoreModel[], idUserCurrent = '') {
    let response: Response<DepositStoreModel[]> = new Response(false);
    try {
        let items_insert: DepositStoreModel[] = [];

        // Array Insert o Update
        newInfo.map(elem => {
            let info = currentInfo.find(x => x.id === elem.id);
            if (isNull(info)) {
                elem.createdUser = idUserCurrent;
                items_insert.push({ ...elem });
                currentInfo.push(elem);
            }
            return elem;
        });

        // Batch insert
        let split_insert = splitArray(items_insert, 450);
        await Promise.all(split_insert.map(async (items) => {
            let batch = db.batch();
            items.forEach(elem => {
                let ID = elem.id;
                let refDoc = db.collection(Table.DepositStore).doc(ID);
                delete elem['id'];
                batch.set(refDoc, elem);
            });
            await batch.commit();
        }));

        response.data = currentInfo;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('depositStoreService:syncInfoStripe', response);
    return response;
}

async function saveChargesStripe(idDepositStore: String, charges: ChargeStripeModel[], idUserCurrent = '') {
    let response: Response<ChargeStripeModel[]> = new Response(false);
    try {
        // Batch insert
        let split_insert = splitArray(charges, 450);
        await Promise.all(split_insert.map(async (items) => {
            let batch = db.batch();
            items.forEach((elem, index) => {
                let data = {
                    ...elem,
                    ...fieldsAudit(idUserCurrent, 'CREATE')
                };
                // delete data['id'];
                delete data['quiz'];
                let refDoc = db.collection(Table.DepositStore)
                    .doc(idDepositStore)
                    .collection(Table.$DepositStore.Charges)
                    .doc(`${elem.id}_${index}`);
                batch.set(refDoc, data);
            });
            await batch.commit();
        }));
        response.data = charges;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('depositStoreService:saveChargesStripe', response);
    return response;
}

async function removeChargesStripe(idDepositStore: String, charges: ChargeStripeModel[], idUserCurrent = '')
{
    let response: Response<ChargeStripeModel[]> = new Response(false);
    try
    {
        // Batch delete
        let split_items = splitArray(charges, 450);
        await Promise.all(split_items.map(async (items) =>
        {
            let batch = db.batch();
            items.forEach((elem, index) =>
            {
                let refDoc = db.collection(Table.DepositStore)
                    .doc(idDepositStore)
                    .collection(Table.$DepositStore.Charges)
                    .doc(elem.id);
                batch.delete(refDoc);
            });
            await batch.commit();
        }));
        response.data = charges;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('depositStoreService:removeChargesStripe', response);
    return response;
}

async function create(prmItem: DepositStoreModel, idUserCurrent = ''): Promise<Response<DepositStoreModel>> {
    let response: Response<DepositStoreModel> = new Response(false);
    try {
        let data = {
            ...prmItem,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        };
        const insert = await db.collection(Table.DepositStore).add(data);
        prmItem.id = insert.id;
        prmItem.createdAt = data.createdAt;
        prmItem.createdUser = data.createdUser;

        response.data = prmItem;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    logError('depositStoreService.js:create', response);
    return response;
}

async function update(prmItem: DepositStoreModel, idUserCurrent = ''): Promise<Response<DepositStoreModel>> {
    let response: Response<DepositStoreModel> = new Response(false);
    try {
        let data = {
            ...prmItem,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        };
        delete data['id'];
        await db.collection(Table.DepositStore).doc(prmItem.id).update(data);

        response.data = prmItem;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    logError('depositStoreService.js:update', response);
    return response;
}

async function existeMonthConciliation(month: String, depositStore: String): Promise<Response<String[]>> {
    let response: Response<String[]> = new Response(false);
    try {
        let query = await db.collection(Table.DepositStore)
            .where(Table.$DepositStore.monthConciliation, '==', month)
            .where(Table.$DepositStore.store, '==', depositStore)
            .get();

        response.data = query.docs.map(x => x.id);
        response.status = true;
    }
    catch (error) { response.error = error; }
    logError('depositStoreService.js:update', response);
    return response;
}

async function depositStripeByAmount(amount): Promise<Response<String[]>> {
    let response: Response<String[]> = new Response(false);
    try {
        amount = amount.toString()
        amount = amount.replace(/[$.]/g, '');

        let query = await db.collection(Table.DepositStore)
            .where(Table.$DepositStore.amount, '==', parseInt(amount))
            .where(Table.$DepositStore.dateConciliation, '>', 0)
            .where(Table.$DepositStore.store, '==', 'stripe')
            .get();

        let data = query.docs.map(x => { return { ...x.data(), id: x.id } });

        if (!query.empty) {
            let charges = await Promise.all(data.map(async (e) => {
                let queryCharges = await db.collection(Table.DepositStore).doc(e.id).collection('Charges').get();
                return !queryCharges.empty ? queryCharges.docs.map(x => x.id) : [];
            }));

            response.data = charges.flat();
            response.status = true;
        }
    } catch (error) { response.error = error; }
    logError('depositStripeByAmount.js:query', response);
    return response;
}

export default {
    listActives,
    listCharges,
    getInfoById,
    stripeDeposits,
    stripeCharges,
    stripeChargesById,
    syncInfoStripe,
    existeMonthConciliation,
    depositStripeByAmount,
    saveChargesStripe,
    removeChargesStripe,
    create,
    update
}