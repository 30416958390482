
import { Table } from '.';
import { getIdOwner, isNull, isNullOrEmpty } from '../helpers/utility';
import { BankModel, QuizModel, UserModel } from '../models';
import Enum from './Enum';

const SectionField = {
    exam: 'config_exam',
    plan: 'config_plan'
};

const fieldsRequired = [
    'name',
    'title',
    'duration',
    'categorie',
    'thumbnail',
    'thumbnail_min',
    'description',
    'numberQuestions',
    'plan',
    'quantityFree',
    'quizsCategorieId',
    'typeQualification',
    'minQualificationRequired',
];

const fullFields = [
    { key: 'quizsCurrencyId', text: 'Moneda', section: SectionField.plan },
    { key: 'plan', text: 'Plan de pago', section: SectionField.plan },
    { key: 'price', text: 'Precio', section: SectionField.plan },
    { key: 'productId', text: 'ID de Producto Android', section: SectionField.plan },
    { key: 'productIdIos', text: 'ID de Producto iOS', section: SectionField.plan },
    { key: 'categorie', text: 'Categoría', section: SectionField.exam },
    { key: 'quizsCategorieId', text: 'Categoría', section: SectionField.exam },
    { key: 'quizsLevelId', text: ' Nivel', section: SectionField.exam },
    { key: 'quizsPromotionId', text: 'Promoción', section: SectionField.exam },
    { key: 'quizsUserOwnerId', text: 'Propietario', section: SectionField.exam },
    { key: 'deductions', text: 'Deducciones', section: SectionField.exam },
    { key: 'title', text: 'Título', section: SectionField.exam },
    { key: 'intro', text: 'Introducción', section: SectionField.exam },
    { key: 'name', text: 'Nombre', section: SectionField.exam },
    { key: 'description', text: 'Descripción', section: SectionField.exam },
    { key: 'duration', text: 'Duración', section: SectionField.exam },
    { key: 'quantityFree', text: 'Número de preguntas gratuitas', section: SectionField.exam },
    { key: 'typeQualification', text: 'Tipo de evaluación', section: SectionField.exam },
    { key: 'minQualificationRequired', text: 'Valor mínimo requerido', section: SectionField.exam },
    { key: 'numberQuestions', text: 'Número de preguntas', section: SectionField.exam },
    { key: 'thumbnail', text: 'Portada web', section: SectionField.exam },
    { key: 'thumbnail_min', text: 'Portada móvil', section: SectionField.exam },
    { key: 'version', text: 'Versión', section: SectionField.exam },
    { key: 'status', text: 'Estatus', section: SectionField.exam }
];

function hasAction(user: UserModel) { return user.actions[Enum.RoleModule.EXA].some(x => x === Enum.RoleAction.FINF); }

async function getInfoQuiz(quizId)
{
    let quizService = require('../services').quizService;
    let res_info = await quizService.getQuiz(quizId);
    if (res_info.status)
        return res_info.data;

    return null;
}

function evaluateQuiz(quiz: QuizModel, extraFields: String[] = []): { incomplete: String[], fields: String[] }
{
    let incomplete: String[] = [];

    let fields = [...fieldsRequired];
    extraFields.forEach(elem => { fields.push(elem); });

    if (isNull(quiz))
        return { incomplete: [...fields], fields };

    const verifyField = (FIELD) =>
    {
        let valueField = quiz[FIELD];
        if (isNull(valueField))
            incomplete.push(FIELD);
        else if (typeof valueField === 'number' && valueField <= 0)
            incomplete.push(FIELD);
        else if (typeof valueField === 'string' && isNullOrEmpty(valueField))
            incomplete.push(FIELD);
    };

    fields.forEach(FIELD => { verifyField(FIELD); });

    let pos = incomplete.findIndex(x => x === 'plan');
    if (pos < 0 && quiz.plan === Enum.typePlanQuiz.monetize)
    {
        verifyField('priceRangeId');
        fields.push('priceRangeId');
    }

    return { incomplete, fields };
};

function transformList(incomplete: String[])
{
    let newList = [];
    incomplete.forEach(FIELD =>
    {
        let info = fullFields.find(x => x.key === FIELD);
        if (!!info)
            newList.push({ ...info });
        else
            newList.push({ key: FIELD, text: FIELD, section: SectionField.exam });
    });

    return {
        all: newList,
        configExam: newList.filter(x => x.section === SectionField.exam),
        configPlan: newList.filter(x => x.section === SectionField.plan),
    };
}

function fieldsIncomplete(quiz: QuizModel, user: UserModel)
{
    let extraFields = [];
    if (!!user && hasAction(user))
    {
            extraFields = [
                Table.$Quiz.productId,
                Table.$Quiz.productIdIos
            ];
    }
    let result = evaluateQuiz(quiz, extraFields);
    return transformList(result.incomplete);
}
async function fieldsIncompleteById(quizId: String, user: UserModel)
{
    let quiz = await getInfoQuiz(quizId);
    return fieldsIncomplete(quiz, user);
}

function progress(quiz: QuizModel)
{
    let result = evaluateQuiz(quiz);

    let porcentage = 100;
    if (result.incomplete.length > 0)
        porcentage = (result.fields.length - result.incomplete.length) * 100 / result.fields.length;

    return Math.round(porcentage);
}
async function progressById(quizId: String)
{
    let quiz = await getInfoQuiz(quizId);
    return progress(quiz);
}

async function getBank(quiz: QuizModel, user: UserModel)
{
    let bankService = require('../services').bankService;

    let bank = null;
    let idOwner = hasAction(user) ? quiz.quizsUserOwnerId : getIdOwner(user);

    let res_bank = await bankService.listBanksOwner(idOwner);
    let banks: BankModel[] = res_bank.status ? res_bank.data : [];
    if (banks.length > 0)
    {
        bank = banks.find(x => x.bankQuizId === quiz.id);
        bank = !!bank ? bank : banks[0];
    }
    return bank;
}

export default {
    fieldsIncomplete,
    fieldsIncompleteById,
    progress,
    progressById,
    getBank
}