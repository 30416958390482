import Response from "../containers/Administration/utils/Response";
import { CatalogsModel } from '../models';
import { Table } from '../constants';
import { firestore } from 'firebase';

const db = firestore();

async function listReasons(onlyActives = false): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let ref = db.collection(Table.Reason);
        let query = onlyActives ? ref.where('status', '==', true) : ref;

        const data = await query.get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function createReason(itemCatalog: CatalogsModel)
{
    let response = new Response(false);
    try
    {
        let data = {
            ...itemCatalog
        };
        const insert = await db.collection(Table.Reason).add(data);
        itemCatalog.id = insert.id;

        response.data = itemCatalog;
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function updateReason(itemCatalog: CatalogsModel): Promise<Response>
{
    let response = new Response(false);
    try
    {
        let data = {
            ...itemCatalog
        };
        delete data['id'];
        await db.collection(Table.Reason).doc(itemCatalog.id).update(data);

        response.data = itemCatalog;
        response.status = true;
    }
    catch (error) { response.error_data = error; }
    return response;
}

export default {
    listReasons,
    createReason,
    updateReason
}