

export default class SectionConfiguration {

    id: string;
    name: string;
    numberQuestions: number;
    sectionConfigurationBankId: string;
    sectionConfigurationLevelId: string;
    sectionConfigurationQuestionTypeId: string;
    sectionConfigurationSectionId: string;
    sectionConfigurationSubSectionId: string;
    status: boolean;

    questions: String[];
    questionsFree: String[];

    createdAt: number;
    createdUser: string;
    updatedAt: number;
    updatedUser: string;

    loadInfo(section: any) {

        let data = section.sectionConfiguration;

        this.id = data.id;
        this.name = data.name;
        this.numberQuestions = parseInt(data.numberQuestions.toString());
        this.sectionConfigurationBankId = !!data.bank ? data.bank.id : null;
        this.sectionConfigurationLevelId = !!data.level ? data.level.id : null;
        this.sectionConfigurationQuestionTypeId = !!data.questionType ? data.questionType.id : null;
        this.sectionConfigurationSectionId = !!data.section ? data.section.id : null;
        this.sectionConfigurationSubSectionId = !!data.subSection ? data.subSection.id : null;
        this.status = true;

        this.questions = !!data.questions ? data.questions : [];
        this.questionsFree = !!data.questionsFree ? data.questionsFree : [];
    }
}