import Response from "../containers/Administration/utils/Response";
import { UserProfileModel, InfoCreatorModel, ExperienceModel, PrivacySettingsModel } from '../models';
import { fieldsAudit, logError } from '../helpers/utility';
import { Table } from '../constants';
import { firestore } from "firebase";

const db = firestore();
async function getInfo(idUser: String): Promise<Response<UserProfileModel>>
{
    let response = new Response(false);
    response.data = [];
    try
    {
        const query = db.collection(Table.User).doc(idUser).collection(Table.$User.Profile).limit(6);
        const data = await query.get();
        if (data.docs.length > 0)
        {
            let item: UserProfileModel = new UserProfileModel();
            data.docs.forEach(doc =>
            {
                if (doc.id === Table.$User.$Profile.InfoCreator)
                    item.infoCreator = doc.data();

                if (doc.id === Table.$User.$Profile.Experience)
                    item.experience = doc.data();

                if (doc.id === Table.$User.$Profile.Privacity)
                    item.privacity = doc.data();
            });
            response.data = item;
            response.status = true;
        }
        else
            response.error_data = 'NOT_FOUND_PROFILE';
    }
    catch (error) { response.error_data = error; }
    logError('userProfileService:getInfo', response);
    return response;
}

async function create(idUser: String, profile: UserProfileModel): Promise<Response<UserProfileModel>>
{
    let result: Response<UserProfileModel> = new Response(false);
    try
    {
        await db.collection(Table.User).doc(idUser).collection(Table.$User.Profile)
            .doc(Table.$User.$Profile.InfoCreator)
            .set({ ...profile.infoCreator, ...fieldsAudit('', 'CREATE'), ...fieldsAudit('', 'UPDATE') });

        let data_exp = { ...profile.experience };
        data_exp.questions = data_exp.questions.map(elem => { return { ...elem } });
        await db.collection(Table.User).doc(idUser).collection(Table.$User.Profile)
            .doc(Table.$User.$Profile.Experience)
            .set({ ...data_exp, ...fieldsAudit('', 'CREATE'), ...fieldsAudit('', 'UPDATE') });

        await db.collection(Table.User).doc(idUser).collection(Table.$User.Profile)
            .doc(Table.$User.$Profile.Privacity)
            .set({ ...profile.privacity, ...fieldsAudit('', 'CREATE'), ...fieldsAudit('', 'UPDATE') });

        result.data = profile;
        result.status = true;
    }
    catch (error) { result.error_data = error; }
    logError('userProfileService:create', result);
    return result;
}

async function updateCreator(idUser: String, info: InfoCreatorModel): Promise<Response<InfoCreatorModel>>
{
    let result: Response<InfoCreatorModel> = new Response(false);
    try
    {
        let data = {
            ...info,
            ...fieldsAudit('', 'UPDATE')
        };
        await db.collection(Table.User).doc(idUser).collection(Table.$User.Profile)
            .doc(Table.$User.$Profile.InfoCreator).update(data);

        result.data = info;
        result.status = true;
    }
    catch (error) { result.error_data = error; }
    logError('userProfileService:updateCreator', result);
    return result;
}

async function updateExperience(idUser: String, info: ExperienceModel): Promise<Response<ExperienceModel>>
{
    let result: Response<ExperienceModel> = new Response(false);
    try
    {
        let data = {
            ...info,
            ...fieldsAudit('', 'UPDATE')
        };
        data.questions = data.questions.map(elem => { return { ...elem } });
        await db.collection(Table.User).doc(idUser).collection(Table.$User.Profile)
            .doc(Table.$User.$Profile.Experience).update(data);

        result.data = info;
        result.status = true;
    }
    catch (error) { result.error_data = error; }
    logError('userProfileService:updateExperience', result);
    return result;
}

async function updatePrivacy(idUser: String, info: PrivacySettingsModel): Promise<Response<PrivacySettingsModel>>
{
    let result: Response<PrivacySettingsModel> = new Response(false);
    try
    {
        let data = {
            ...info,
            ...fieldsAudit('', 'UPDATE')
        };
        await db.collection(Table.User).doc(idUser).collection(Table.$User.Profile)
            .doc(Table.$User.$Profile.Privacity).update(data);

        result.data = info;
        result.status = true;
    }
    catch (error) { result.error_data = error; }
    logError('userProfileService:updatePrivacy', result);
    return result;
}

export default {
    getInfo,
    create,
    updateCreator,
    updateExperience,
    updatePrivacy
}