export default class BannerSlider
{
    id: String;
    name: String;
    description: String;
    imageMobile: String;
    imageWeb: String;
    url: String;
    order: Number;
    status: Boolean;

    fileMobile: File;
    fileWeb: File;
    imageMobileBackup: String;
    imageWebBackup: String;

    constructor()
    {
        this.status = true;
    }
}