import { firestore } from 'firebase';
import { isNull } from '../../helpers/utility';
import { Table } from '../../constants';
import { PaymentModel } from '../';

export default class FilterPayment
{
    db: firestore.Firestore;

    /**Id del propietario*/
    idOwner: String;

    /**Arreglo con Ids de los quiz.
     * @description Máximo 10 Ids para query()*/
    quizsId: String[];

    /**Estatus del registro*/
    status: Boolean;

    /**Arreglo con Ids del catálogo PaymentStatus*/
    paymentStatus: String[];

    /**Arreglo con ids del catálogo PaymentType*/
    paymentType: String[];

    /**Arreglo con rango de fechas en formato unix
     * @description El arreglo debe contener 2 valores*/
    rangeCreatedAt: Number[];

    clone(data: FilterPayment)
    {
        this.idOwner = data.idOwner;
        this.quizsId = data.quizsId;
        this.status = data.status;
        this.paymentStatus = data.paymentStatus;
        this.paymentType = data.paymentType;
        this.rangeCreatedAt = data.rangeCreatedAt;
    }

    filter(fullData: PaymentModel[]): PaymentModel[]
    {
        let result = fullData;

        if (!!this.idOwner)
            result = result.filter(x => x.quizsUserOwnerId === this.idOwner);

        if (!!this.quizsId)
            result = result.filter(x => this.quizsId.some(ID_QUIZ => ID_QUIZ === x.paymentQuizId));

        if (!isNull(this.status))
            result = result.filter(x => x.status === this.status);

        if (!!this.paymentStatus)
            result = result.filter(x => this.paymentStatus.some(PS_ID => PS_ID === x.paymentStatusId));

        if (!!this.paymentType)
            result = result.filter(x => this.paymentType.some(PT_ID => PT_ID === x.paymentPaymentTypeId));

        if (!!this.rangeCreatedAt && this.rangeCreatedAt.length === 2)
            result = result.filter(x => x.createdAt >= this.rangeCreatedAt[0] && x.createdAt <= this.rangeCreatedAt[1]);

        return result;
    }

    query()
    {
        if (isNull(this.db))
            this.db = firestore();

        let query = this.db.collection(Table.Payment);

        if (!!this.idOwner)
            query = query.where(Table.$Payment.quizsUserOwnerId, '==', this.idOwner);

        if (!!this.quizsId)
        {
            const isOnlyOne = this.quizsId.length === 1;
            query = query.where(Table.$Payment.paymentQuizId, isOnlyOne ? '==' : 'in', isOnlyOne ? this.quizsId[0] : this.quizsId)
        }

        if (!isNull(this.status))
            query = query.where(Table.$Payment.status, '==', this.status);

        if (!!this.paymentStatus)
        {
            const isOnlyOne = this.paymentStatus.length === 1;
            query = query.where(Table.$Payment.paymentStatusId, isOnlyOne ? '==' : 'in', isOnlyOne ? this.paymentStatus[0] : this.paymentStatus)
        }

        if (!!this.paymentType)
        {
            const isOnlyOne = this.paymentType.length === 1;
            query = query.where(Table.$Payment.paymentPaymentTypeId, isOnlyOne ? '==' : 'in', isOnlyOne ? this.paymentType[0] : this.paymentType)
        }

        if (!!this.rangeCreatedAt && this.rangeCreatedAt.length === 2)
        {
            query = query.where(Table.$Payment.createdAt, '>=', this.rangeCreatedAt[0])
            query = query.where(Table.$Payment.createdAt, '<=', this.rangeCreatedAt[1])
        }

        return query;
    }
}