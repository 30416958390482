import { PaymentModel, CatalogsModel, QuizModel, PaymentStatusModel } from './';

export default class Refund
{
    id: String;
    userOwnerId: String;
    quizId: String;
    memberId: String;
    paymentId: String;
    paymentTypeId: String;
    paymentStatusId: String;
    reasonId: String;
    amount: Number;
    amountOutDiscount: Number;
    description: String;
    chargedToOwner: Boolean;
    status: Boolean;
    createdAt: Number;
    createdUser: String;

    quiz: QuizModel;
    paymentType: CatalogsModel;
    paymentStatus: PaymentStatusModel;
    reason: CatalogsModel;
    payment: PaymentModel;

    constructor()
    {
        this.chargedToOwner = false;
        this.status = true;
    }

    toDB()
    {
        let data = { ...this };
        delete data['quiz'];
        delete data['paymentType'];
        delete data['paymentStatus'];
        delete data['reason'];
        delete data['payment'];
        return data;
    }
}