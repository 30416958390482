import React from "react";
import { RiBugFill, RiQuestionAnswerFill, RiChat3Fill } from "react-icons/ri";
import { IoMdHelpCircle } from "react-icons/io";
import { HiUserCircle } from "react-icons/hi";
import { FaUsers, FaBlog, FaUserTie, FaChartLine } from "react-icons/fa";
import { MdReportProblem } from "react-icons/md";
import { ImBookmarks, ImBooks } from "react-icons/im";

const options = [
    {
        key: 'quiz',
        name: 'quiz',
        label: 'sidebar.quizes',
        LeftIcon: () => <ImBookmarks fontSize={20} />,
    },
    {
        key: 'question',
        name: 'question',
        label: 'sidebar.questions',
        LeftIcon: () => <RiQuestionAnswerFill fontSize={20} />,
    },
    {
        key: 'catalogs',
        label: 'sidebar.catalogs',
        LeftIcon: () => <ImBooks fontSize={20} />,

        children: [
            {
                key: 'scripts_temp',
                label: 'sidebar.scripts',
            },
            {
                key: 'assets',
                label: 'sidebar.file',
            },
            {
                key: 'bank',
                label: 'sidebar.catalogs.bank',
            },
            {
                key: 'bannerSlider',
                label: 'sidebar.catalogs.bannerSlider',
            },
            {
                key: 'categorie',
                label: 'sidebar.catalogs.categorie',
            },
            {
                key: 'deduction-isr',
                label: 'sidebar.catalogs.deductionISR'
            },
            {
                key: 'paymentType',
                label: 'sidebar.catalogs.paymentType',
            },
            {
                key: 'level',
                label: 'sidebar.catalogs.level',
            },
            {
                key: 'templateEmail',
                label: 'sidebar.mailTemplates',
            },
            {
                key: 'promotions',
                label: 'sidebar.catalogs.promotions',
            },
            {
                key: 'priceRange',
                label: 'sidebar.rangePrice',
            },
            {
                key: 'reason',
                label: 'sidebar.catalogs.reason',
            },
            {
                key: 'role',
                label: 'sidebar.catalogs.role',
            },
            {
                key: 'section',
                label: 'sidebar.catalogs.section',
            },
            {
                key: 'deductionType',
                label: 'sidebar.typeDeduction',
            },
            {
                key: 'currency',
                label: 'sidebar.catalogs.currency',
            },
            {
                key: 'questionType',
                label: 'sidebar.typeQuestion',
            },
            {
                key: 'problemType',
                label: 'sidebar.catalogs.problemType',
            },
            {
                key: 'answerType',
                label: 'sidebar.catalogs.answertype',
            },
            {
                key: 'trakingType',
                label: 'sidebar.catalogs.trakingType',
            },
            {
                key: 'quizType',
                label: 'sidebar.catalogs.quizType',
            },
            {
                key: 'userType',
                label: 'sidebar.typeUser',
            }
        ],
    },
    {
        key: 'users',
        label: 'sidebar.user',
        LeftIcon: () => <FaUsers fontSize={20} />,
    },
    {
        key: 'report',
        label: 'sidebar.reportQuestion',
        LeftIcon: () => <RiBugFill fontSize={20} />,
    },
    {
        key: 'comments',
        label: 'sidebar.comments',
        LeftIcon: () => <RiChat3Fill fontSize={20} />,
    },
    {
        key: 'finance',
        label: 'sidebar.finance',
        LeftIcon: () => <FaChartLine fontSize={20} />,

        children: [
            // FINANZAS LOCALES
            {
                key: 'sales',
                label: 'sidebar.finance.sales',
            },
            {
                key: 'refunds',
                label: 'sidebar.sidebar.refund',
            },
            {
                key: 'deposits',
                label: 'sidebar.finance.deposits',
            },
            // FINANZAS GLOBALES
            {
                key: 'admin_deposit_store',
                label: 'sidebar.finance.adminConciliation',
            },
            {
                key: 'admin_sales',
                label: 'sidebar.finance.adminSales',
            },
            {
                key: 'admin_refunds',
                label: 'sidebar.sidebar.adminRefund',
            },
            {
                key: 'admin_deposits',
                label: 'sidebar.sidebar.adminDeposits',
            }
        ],
    },
    {
        key: 'member',
        label: 'sidebar.member',
        LeftIcon: () => <FaUserTie fontSize={20} />,
        children: [
            {
                key: 'member',
                label: 'sidebar.member.history',
            },
            {
                key: 'member-edit',
                label: 'sidebar.member.edit',
            },
            {
                key: 'payments',
                label: 'sidebar.member.payment',
            },
            {
                key: 'userEmail',
                label: 'sidebar.member.mails',
            },
            {
                key: 'binnacle',
                label: 'sidebar.member.binnacle',
            }
        ]
    },
    {
        key: 'blogs',
        label: 'sidebar.blog',
        LeftIcon: () => <FaBlog fontSize={20} />,
    },
    {
        key: 'owner-profile',
        label: 'sidebar.profile',
        LeftIcon: () => <HiUserCircle fontSize={20} />,
    },
    {
        key: 'help-web',
        label: 'sidebar.help',
        LeftIcon: () => <IoMdHelpCircle fontSize={20} />,
    },
    {
        key: 'reports-quiz',
        label: 'sidebar.reportQuiz',
        LeftIcon: () => <MdReportProblem fontSize={20} />,
    },
];

export default options;