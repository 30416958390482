const infoQuiz = `
    id
    name
    intro
    description
    title
    price
    thumbnail
    thumbnail_min
    duration
    numberQuestions
    quantityUserRegister
    quantityFree
    typeQualification
    minQualificationRequired
    version
    createdAt
    updatedAt
    createdUser
    updatedUser
    status
    productId
    productIdIos
    quizsCategorieId
    quizsUserOwnerId
    #quizsPriceRangeId

    categorie
    {
        id
        name
    }

    level
    {
      id
      name
    }

    currency
    {
      id
      key
      name
    }

    promotion
    {
      id
      name
      description
      code
      percentDiscount
      amountDiscount
      thumbnail
      status
    }

    userOwner
    {
      person
      {
        name
        lastName
      }
    }

    templateQuizes
    (
      filter:
      {
        status: { eq: true }
      }
      limit: 999999999
    )
    {
      items
      {
        id
        name
        description
        pointsApprove
        templateQuizQuizTypeId
      }
    }

    commentQualifications 
    (
      filter: 
      { 
        status: { eq: true } 
      },
      limit: 999999999,
    )
    {
      items
      {
        id
        comment
        qualification
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        commentQualificationQuizId
        commentQualificationUserId
        user 
        {
          id
          person 
          {
            id
            name
            lastName
          }
        }
      }
    }
`;

export const createQuizs = `mutation CreateQuizs($input: CreateQuizsInput!) {
  createQuizs(input: $input) {
    ${infoQuiz}
  }
}
`;

export const updateQuizs = `mutation UpdateQuizs($input: UpdateQuizsInput!) {
  updateQuizs(input: $input) {
    ${infoQuiz}
  }
}
`;

export const changeStatusQuiz = `mutation UpdateQuizs($input: UpdateQuizsInput!) {
  updateQuizs(input: $input) {
    ${infoQuiz}
  }
}
`;

export const createTemplateQuiz = `mutation CreateTemplateQuiz($input: CreateTemplateQuizInput!) {
    createTemplateQuiz(input: $input) {
        id
        name
        description
        pointsApprove
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        templateQuizQuizId
        templateQuizQuizTypeId
    }
}
`;


export const updateTemplateQuiz = `mutation UpdateTemplateQuiz($input: UpdateTemplateQuizInput!) {
  updateTemplateQuiz(input: $input) {
      id
      name
      description
      pointsApprove
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      templateQuizQuizId
      templateQuizQuizTypeId
  }
}
`;

export const createSectionConfiguration = `mutation CreateSectionConfiguration($input: CreateSectionConfigurationInput!) {
  createSectionConfiguration(input: $input) {
    id
    numberQuestions
    createdAt
    updatedAt
    createdUser
    updatedUser
    status
    sectionConfigurationBankId
    sectionConfigurationLevelId
    sectionConfigurationSectionId
    sectionConfigurationSubSectionId
    bank {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      bankUserOwnerId
    }
    section {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
    subSection {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
    level {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
    templateQuizSectionConfigurations {
      nextToken
    }
    questionType {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
  }
}
`;
export const updateSectionConfiguration = `mutation UpdateSectionConfiguration($input: UpdateSectionConfigurationInput!) {
  updateSectionConfiguration(input: $input) {
    id
    numberQuestions
    createdAt
    updatedAt
    createdUser
    updatedUser
    status
    sectionConfigurationBankId
    sectionConfigurationLevelId
    sectionConfigurationSectionId
    sectionConfigurationSubSectionId
    bank {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      bankUserOwnerId
    }
    section {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
    subSection {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
    level {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
    templateQuizSectionConfigurations {
      nextToken
    }
    questionType {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
  }
}
`;

export const createTemplateQuizSectionConfiguration = `mutation CreateTemplateQuizSectionConfiguration(
    $input: CreateTemplateQuizSectionConfigurationInput!
  ) {
    createTemplateQuizSectionConfiguration(input: $input) {
      id
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      templateQuizSectionConfigurationTemplateQuizId
      templateQuizSectionConfigurationSectionConfigurationId
      templateQuiz {
        id
        name
        description
        pointsApprove
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        templateQuizQuizId
        templateQuizQuizTypeId
      }
      sectionConfiguration {
        id
        numberQuestions
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        sectionConfigurationBankId
        sectionConfigurationLevelId
        sectionConfigurationSectionId
      }
    }
  }
  `;

export const createQuizType = `mutation CreateQuizType($input: CreateQuizTypeInput!) {
    createQuizType(input: $input) {
      id
      name
      intro
      description
      title
      price
      thumbnail
      thumbnail_min
      duration
      numberQuestions
      version
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      productId
      quizCategorieId
      
      level
      {
        id
        name
      }

      currency
      {
        id
        key
        name
      }

      promotion
      {
        id
        name
        description
        code
        percentDiscount
        amountDiscount
        thumbnail
        status
      }

      userOwner
      {
        person
        {
          name
          lastName
        }
      }
    }
  }
  `;
export const createAnswer = `mutation CreateAnswer($input: CreateAnswerInput!) {
    createAnswer(input: $input) {
      id
      answerText
      answerImage
      order
      correctAnswer
      createdAt
      updatedAt
      createdUser
      updatedUser
      answerQuestionId
      status
    }
  }
  `;
export const updateAnswer = `mutation UpdateAnswer($input: UpdateAnswerInput!) {
    updateAnswer(input: $input) {
      id
      answerText
      answerImage
      order
      correctAnswer
      createdAt
      updatedAt
      createdUser
      updatedUser
      answerQuestionId
      status
    }
  }
  `;


export const createQuestion = `mutation CreateQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      id
      key
      required
      createdAt
      updatedAt
      createdUser
      updatedUser
      static
      points
      status
      free
      order
      questionAnswerTypeId
      questionBankId
      questionLevelId
      questionSectionId
      questionQuestionTypeId
      questionSubSectionId
      level {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      section {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      subSection{
        id
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      bank {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        bankUserOwnerId
      }
      answerType {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      feedbackSequences {
        nextToken
      }
      questionSequences {
        items{
          id
          questionText
          questionImage
          status
        }
        nextToken
      }
      answers {
        nextToken
      }
      questionType {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
    }
  }
  `;


export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      person {
        id
        name
        lastName
        email
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      accessType {
        id
        key
        name
        description
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
      }
      username
      password
      photoUrl
      createdAt
      updatedAt
      createdUser
      updatedUser
      sub
      complete
      platform
      status
      userTrakingId
      test
      members {
        id
        dateValidity
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        memberUserId
      }
      traking {
        id
        identifierTraking
        name
        description
        thumbnail
        price
        createdAt
        updatedAt
        createdUser
        updatedUser
        status
        trakingUserId
      }
    }
  }
  `;



export const createQuestionSequence = `mutation CreateQuestionSequence($input: CreateQuestionSequenceInput!) {
    createQuestionSequence(input: $input) {
      id
      questionText
      questionImage
      order
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      questionSequenceQuestionId
    }
  }
  `;





export const deleteSectionConfiguration = `mutation DeleteSectionConfiguration($input: DeleteSectionConfigurationInput!) {
    deleteSectionConfiguration(input: $input) {
      id
    }
  }
  `;

export const deleteTemplateQuizSectionConfiguration = `mutation DeleteTemplateQuizSectionConfiguration(
    $input: DeleteTemplateQuizSectionConfigurationInput!
  ) {
    deleteTemplateQuizSectionConfiguration(input: $input) {
      id
    }
  }
  `;





export const createFeedbackSequence = `mutation CreateFeedbackSequence($input: CreateFeedbackSequenceInput!) {
    createFeedbackSequence(input: $input) {
      id
      feedbackText
      feedbackImage
      order
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
  }
  `;

export const updateFeedbackSequence = `mutation UpdateFeedbackSequence($input: UpdateFeedbackSequenceInput!) {
    updateFeedbackSequence(input: $input) {
      id
      feedbackText
      feedbackImage
      order
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
    }
  }
  `;




export const createHelpType = `mutation CreateHelpType($input: CreateHelpTypeInput!) {
    createHelpType(input: $input) {
      id
      key
      name
      description
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      help {
        nextToken
      }
    }
  }
  `;

export const createProblemReport = `mutation CreateProblemReport($input: CreateProblemReportInput!) {
    createProblemReport(input: $input) {
      id
      message
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      problemReportProblemTypeId
      problemReportUserId
      problemReportQuestionHistoryId
      problemReportReplayId
      user{
        id
        username
        password
        photoUrl
        createdAt
        updatedAt
        createdUser
        updatedUser
        sub
        status
        person {
          id
          name
          lastName
          email
          createdAt
          updatedAt
          createdUser
          updatedUser
          status
        }
      }
      problemType {
        id
        name
        description
      }
    }
  }
  `;

export const createMember = `mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      id
      dateValidity
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      memberUserId
      user {
        id
        username
        password
        photoUrl
        createdAt
        updatedAt
        createdUser
        updatedUser
        sub
        complete
        platform
        status
        userTrakingId
        test
      }
      quizHistories {
        nextToken
      }
      payments {
        id
        amount
        paidAt
        reference
        platform
        paymentDate
        createdAt
        updatedAt
        createdUser
        updatedUser
        tokenId
        orderId
        transactionDate
        transactionReceipt
        status
        paymentMemberId
        paymentQuizId
        paymentCurrencyId
        paymentPaymentTypeId
        paymentPromotionId
        paymentTrakingId
      }
    }
  }
  `;

export const createCommentQualification = `mutation CreateCommentQualification($input: CreateCommentQualificationInput!) {
    createCommentQualification(input: $input) {
      id
      comment
      qualification
      createdAt
      updatedAt
      createdUser
      updatedUser
      status
      replay
      validated
      reply
      commentQualificationUserOwnerId
      quiz {
        id
        name
        intro
        description
        instructions
        title
        price
        thumbnail
        thumbnail_min
        duration
        version
        createdAt
        updatedAt
        createdUser
        updatedUser
        numberQuestions
        productId
        productIdIos
        status
        quizsPromotionId
        quizsCategorieId
        quizsUserOwnerId
        quizsCurrencyId
        quizsLevelId
      }
      user {
        id
        username
        password
        photoUrl
        createdAt
        updatedAt
        createdUser
        updatedUser
        sub
        complete
        platform
        status
        userTrakingId
        test
        person{
          name
        }
      }
      commentQualificationQuizId
      commentQualificationUserId
    }
  }
  `;

export const updatePayment = `mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      id
    }
  }
  `;

export const updateMember = `mutation UpdateMember($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      id
    }
  }
  `;

export const updateProblemReport = `mutation UpdateProblemReport($input: UpdateProblemReportInput!) {
    updateProblemReport(input: $input) {
      id
    }
  }
  `;

export const updateCommentQualification = `mutation UpdateCommentQualification($input: UpdateCommentQualificationInput!) {
    updateCommentQualification(input: $input) {
      id
    }
  }
  `;