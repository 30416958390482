import Response from "../containers/Administration/utils/Response";
import { fieldsAudit, isNullOrEmpty } from '../helpers/utility';
import { upload, removeFile } from '../containers/Administration/utils/UtilsUpload';
import { PathProps } from '../containers/Administration/utils/Global';
import { OwnerModel } from '../models';
import { firestore } from 'firebase';

const db = firestore();

async function create(owner: any, idUserCurrent: String, pathS3: String): Promise<Response<OwnerModel>> {
    let response = new Response();
    try {
        let objOwner = new OwnerModel();
        objOwner.loadInfo(owner);

        let data = {
            ...objOwner,
            ...fieldsAudit(idUserCurrent, 'CREATE')
        }

        await db.collection('Owner').doc(owner.id).set(data);

        await uploadFileS3(owner.fileImage, pathS3);

        response.data = data;
        response.status = true;
    } catch (error) {
        response.error = error;
    }
    return response;
}


async function update(owner: any, idUserCurrent: String, pathS3: String): Promise<Response<OwnerModel>> {
    let response = new Response();
    try {
        let objOwner = new OwnerModel();
        objOwner.loadInfo(owner);

        let data = {
            ...objOwner,
            ...fieldsAudit(idUserCurrent, 'UPDATE')
        }

        await db.collection('Owner').doc(owner.id).update(data);

        await uploadFileS3(owner.fileImage, pathS3);
        if (!!owner.fileImage || isNullOrEmpty(owner.urlImage))
            await removeFileS3(owner.urlImageBackup, pathS3);

        response.data = data;
        response.status = true;
    } catch (error) {
        response.error = error;
    }
    return response;
}

async function getOwner(uid: string): Promise<Response<OwnerModel[]>> {

    let response = new Response(false);

    try {

        let query = await db.collection('Owner').doc(uid).get();
        if (query.exists) {
            response.data = { id: query.id, ...query.data() };
            response.status = true;
        }
    } catch (error) {
        response.error = error;
    }

    return response;
}


async function uploadFileS3(file: File, pathS3: PathProps) {
    if (!!file) {
        const pathS3Folder = `${pathS3.path_assets}/owner`;
        const response = await upload(file, pathS3Folder);

        const type = file.type.split("/")[1];
        return response.key.includes(type);
    }
    return true;
}

async function removeFileS3(urlImage, pathS3: PathProps) {
    if (!isNullOrEmpty(urlImage) && urlImage.includes('https'))
        return await removeFile(urlImage.replace(pathS3.path_cloudfront + '/', ''));

    return true;
}


export default {
    getOwner,
    create,
    update
}