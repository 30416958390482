import { LessonSequenceModel } from './';
import uuid from "uuid/v4";

export default class QuestionGroup
{
    id: String;
    questionGroupUserId: String;
    sectionId: String;
    name: String;
    instructions: String;
    groupAudio: String;
    progress: Number;
    status: Boolean;

    questions: [];
    lesson: LessonSequenceModel[];

    fileAudio: File;
    groupAudioBackup: String;

    constructor()
    {
        this.id = uuid();
        this.progress = 0;
        this.sectionId = null;
        this.status = true;

        this.lesson = [];
        this.questions = [];
    }
}