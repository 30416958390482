export default class Asset
{
    id: String;
    name: String;
    description: String;
    type: String;
    size: Number;
    urlFile: String;
    status: Boolean;

    fileInfo: File;
    urlFileBackup: String;

    constructor()
    {
        this.status = true;
    }
}