import Response from "../containers/Administration/utils/Response";
import { Table } from "../constants";
import { sortList } from "../helpers/utility";
import { firestore } from 'firebase';
import { BinnacleModel } from "../models";

const db = firestore();

async function list(actionKeys: String[]): Promise<Response<BinnacleModel[]>>
{
    let response: Response<BinnacleModel[]> = new Response(false);
    try
    {
        const data = await db.collection(Table.Binnacle)
            .where(Table.$Binnacle.actionKey, 'in', actionKeys).get();
        response.data = data.docs.map(element =>
        {
            let item = element.data();
            item.id = element.id;
            return item;
        });
        sortList(response.data, 'createdAt', true, false);
        response.status = true;
    }
    catch (e) { response.error_data = e; }
    return response;
}

async function addBinnacle(data: BinnacleModel): Promise<Response<{ id: String }>> {
    let response = new Response(false);
    try {
        let query = await db.collection(Table.Binnacle).add({ ...data });
        response.data = query.id;
        response.status = true;
    } catch (error) {
        response.status = false;
    }
    return response;
}


export default {
    list,
    addBinnacle
}