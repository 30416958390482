/**ACCIONES REDUX FINANZAS GLOBALES (FINGlobal) */
const actions = {
    // GENERAL
    RESET_FINANCES: 'FINGLOBAL_RESET_FINANCES',
    resetFinances: () =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.RESET_FINANCES,
                payload: null
            });
        }
    },

    SET_OWNERS: 'FINGLOBAL_SET_OWNERS',
    setUsersOwner: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_OWNERS,
                payload: items
            });
        }
    },

    // CATÁLOGOS
    SET_PAYMENTS_TYPES: 'FINGLOBAL_SET_PAYMENTS_TYPES',
    setPaymentTypes: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_PAYMENTS_TYPES,
                payload: items
            });
        }
    },

    SET_PAYMENTS_STATUS: 'FINGLOBAL_SET_PAYMENTS_STATUS',
    setPaymentStatus: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_PAYMENTS_STATUS,
                payload: items
            });
        }
    },

    SET_REASONS_REFUND: 'FINGLOBAL_SET_REASONS_REFUND',
    setReasonsRefund: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_REASONS_REFUND,
                payload: items
            });
        }
    },

    // CURRENT OWNER
    SET_OWNER_CURRENT: 'FINGLOBAL_SET_OWNER_CURRENT',
    setOwnerCurrent: (idOwner) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_OWNER_CURRENT,
                payload: idOwner
            });
        }
    },

    SET_OWNER_KEY_AMOUNT_PAYMENT: 'FINGLOBAL_SET_OWNER_KEY_AMOUNT_PAYMENT',
    setOwnerKeyAmountPayment: (keyAmountPayment) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_OWNER_KEY_AMOUNT_PAYMENT,
                payload: keyAmountPayment,
            });
        }
    },

    SET_OWNER_GLOBAL_PAYMENTS: 'FINGLOBAL_SET_OWNER_GLOBAL_PAYMENTS',
    setOwnerGlobalPayments: (infoTotals) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_OWNER_GLOBAL_PAYMENTS,
                payload: infoTotals,
            });
        }
    },

    SET_OWNER_PAYMENTS: 'FINGLOBAL_SET_OWNER_PAYMENTS',
    setOwnerPayments: (subModule, items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_OWNER_PAYMENTS,
                payload: { subModule: subModule, items: items }
            });
        }
    },

    ADD_OWNER_PAYMENTS_WITH_ACTION: 'FINGLOBAL_ADD_OWNER_PAYMENTS_WITH_ACTION',
    addOwnerPaymentsWithAction: (subModule, itemsWithAction) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.ADD_OWNER_PAYMENTS_WITH_ACTION,
                payload: { subModule: subModule, items: itemsWithAction }
            });
        }
    },

    SET_OWNER_REFUNDS: 'FINGLOBAL_SET_OWNER_REFUNDS',
    setOwnerRefunds: (items) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_OWNER_REFUNDS,
                payload: items
            });
        }
    },

    // FILTER
    SET_FILTER_CURRENT: 'FINGLOBAL_SET_FILTER_CURRENT',
    setFilterCurrent: (filterOpt) =>
    {
        return (dispatch, getState) =>
        {
            dispatch({
                type: actions.SET_FILTER_CURRENT,
                payload: filterOpt
            });
        }
    },
}

export default actions;