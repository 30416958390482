import { RoleModel, RoleModuleModel, UserProfileModel, UserSessionModel } from './';
import { Enum } from '../constants';
import { hasRolesUser } from '../helpers/utility';

export default class User
{
    id: String;
    idOwnerLinked: String;
    name: String;
    username: String;
    password: String;
    phoneNumber: String;
    photoURL: String;
    platform: String;
    providerId: String;
    complete: Boolean;
    emailVerified: Boolean;
    status: Boolean;

    roles: RoleModel[];
    session: UserSessionModel;
    profile: UserProfileModel;

    constructor()
    {
        this.phoneNumber = null;
        this.photoURL = null;
        this.platform = 'dashboard';
        this.providerId = '';
        this.complete = true;
        this.emailVerified = true;
        this.status = true;

        this.roles = [];
    }

    loadUserDB(docId, docData)
    {
        this.id = docId;
        this.idOwnerLinked = docData.idOwnerLinked;
        this.name = docData.name;
        this.username = docData.username;
        this.password = docData.password;
        this.phoneNumber = docData.phoneNumber;
        this.photoURL = docData.photoURL;
        this.platform = docData.platform;
        this.providerId = docData.providerId;
        this.complete = docData.complete;
        this.emailVerified = docData.emailVerified;
        this.status = docData.status;

        this.roles = docData.roles;
    }

    loadActions(modules: RoleModuleModel[])
    {
        let full = [
            Enum.RoleAction.CRE,
            Enum.RoleAction.REA,
            Enum.RoleAction.UPD,
            Enum.RoleAction.FINF,
            Enum.RoleAction.DEL
        ];
        let limited = [
            Enum.RoleAction.CRE,
            Enum.RoleAction.REA,
            Enum.RoleAction.UPD
        ];
        if (hasRolesUser(this, [Enum.Role.SADMA, Enum.Role.ADMA]))
        {
            limited.push(Enum.RoleAction.FINF);
            limited.push(Enum.RoleAction.DEL);
        }

        this.actions = {};
        modules.forEach(element => { this.actions[element.key] = full; });

        this.actions[Enum.RoleModule.EXA] = limited;
        this.actions[Enum.RoleModule.PRE] = limited;
        this.actions[Enum.RoleModule.PERF] = limited;
    }
}